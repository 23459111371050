// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: druid/timeseries_api_common.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "druid";

/** ChainOperator separates two or more simple operands. */
export enum ChainOperator {
  CHAIN_OPERAND_INVALID = 0,
  AND = 1,
  OR = 2,
  NOT = 3,
  UNRECOGNIZED = -1,
}

export function chainOperatorFromJSON(object: any): ChainOperator {
  switch (object) {
    case 0:
    case "CHAIN_OPERAND_INVALID":
      return ChainOperator.CHAIN_OPERAND_INVALID;
    case 1:
    case "AND":
      return ChainOperator.AND;
    case 2:
    case "OR":
      return ChainOperator.OR;
    case 3:
    case "NOT":
      return ChainOperator.NOT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChainOperator.UNRECOGNIZED;
  }
}

export function chainOperatorToJSON(object: ChainOperator): string {
  switch (object) {
    case ChainOperator.CHAIN_OPERAND_INVALID:
      return "CHAIN_OPERAND_INVALID";
    case ChainOperator.AND:
      return "AND";
    case ChainOperator.OR:
      return "OR";
    case ChainOperator.NOT:
      return "NOT";
    case ChainOperator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** SortOrder denotes the ordering of values pertaining to a dimension. */
export enum SortOrder {
  SORT_ORDER_UNSPECIFIED = 0,
  SORT_ORDER_ASCENDING = 1,
  SORT_ORDER_DESCENDING = 2,
  UNRECOGNIZED = -1,
}

export function sortOrderFromJSON(object: any): SortOrder {
  switch (object) {
    case 0:
    case "SORT_ORDER_UNSPECIFIED":
      return SortOrder.SORT_ORDER_UNSPECIFIED;
    case 1:
    case "SORT_ORDER_ASCENDING":
      return SortOrder.SORT_ORDER_ASCENDING;
    case 2:
    case "SORT_ORDER_DESCENDING":
      return SortOrder.SORT_ORDER_DESCENDING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SortOrder.UNRECOGNIZED;
  }
}

export function sortOrderToJSON(object: SortOrder): string {
  switch (object) {
    case SortOrder.SORT_ORDER_UNSPECIFIED:
      return "SORT_ORDER_UNSPECIFIED";
    case SortOrder.SORT_ORDER_ASCENDING:
      return "SORT_ORDER_ASCENDING";
    case SortOrder.SORT_ORDER_DESCENDING:
      return "SORT_ORDER_DESCENDING";
    case SortOrder.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Operator separates one or more operands.
 * NOTE: more operators may be supported. However, these are not context aware of the type of value or the operand.
 */
export enum Operator {
  OPERATOR_INVALID = 0,
  /** EQUALS - Similar to SQL 'EQUALS' syntax. */
  EQUALS = 1,
  /** NOT_EQUALS - Similar to SQL 'NOT EQUALS' syntax. */
  NOT_EQUALS = 2,
  /**
   * LIKE - Similar to SQL 'LIKE' syntax. This operator does not apply to numeric fields - such as metrics
   * and enum integer identifiers.
   */
  LIKE = 3,
  /**
   * NOT_LIKE - Similar to SQL 'NOT LIKE' syntax. This operator does not apply to numeric fields - such as metrics
   * and enum integer identifiers.
   */
  NOT_LIKE = 4,
  /**
   * IN - Similar to SQL 'IN' syntax. Note: the filter value expression must be a single-quoted comma-separated string.
   * E.g., "'1','3.14','400','5532FEEF-A984-45C1-A71D-D5CEFE423E88'"
   */
  IN = 5,
  /**
   * NOT_IN - Similar to SQL 'NOT IN' syntax. Note: the filter value expression must be a single-quoted comma-separated string.
   * E.g., "'1','3.14','400','5532FEEF-A984-45C1-A71D-D5CEFE423E88'"
   */
  NOT_IN = 6,
  /** GREATER_THAN - Similar to SQL '>' operator. */
  GREATER_THAN = 7,
  /** GREATER_THAN_EQUAL - Similar to SQL '>=' operator. */
  GREATER_THAN_EQUAL = 8,
  /** LESSER_THAN - Similar to SQL '<' operator. */
  LESSER_THAN = 9,
  /** LESSER_THAN_EQUAL - Similar to SQL '<=' operator. */
  LESSER_THAN_EQUAL = 10,
  REGEXP_LIKE = 11,
  NOT_REGEXP_LIKE = 12,
  /** IS_NULL - similar to SQL 'IS NULL' syntax. */
  IS_NULL = 13,
  /** IS_NOT_NULL - Similar to SQL 'IS NOT NULL' syntax. */
  IS_NOT_NULL = 14,
  UNRECOGNIZED = -1,
}

export function operatorFromJSON(object: any): Operator {
  switch (object) {
    case 0:
    case "OPERATOR_INVALID":
      return Operator.OPERATOR_INVALID;
    case 1:
    case "EQUALS":
      return Operator.EQUALS;
    case 2:
    case "NOT_EQUALS":
      return Operator.NOT_EQUALS;
    case 3:
    case "LIKE":
      return Operator.LIKE;
    case 4:
    case "NOT_LIKE":
      return Operator.NOT_LIKE;
    case 5:
    case "IN":
      return Operator.IN;
    case 6:
    case "NOT_IN":
      return Operator.NOT_IN;
    case 7:
    case "GREATER_THAN":
      return Operator.GREATER_THAN;
    case 8:
    case "GREATER_THAN_EQUAL":
      return Operator.GREATER_THAN_EQUAL;
    case 9:
    case "LESSER_THAN":
      return Operator.LESSER_THAN;
    case 10:
    case "LESSER_THAN_EQUAL":
      return Operator.LESSER_THAN_EQUAL;
    case 11:
    case "REGEXP_LIKE":
      return Operator.REGEXP_LIKE;
    case 12:
    case "NOT_REGEXP_LIKE":
      return Operator.NOT_REGEXP_LIKE;
    case 13:
    case "IS_NULL":
      return Operator.IS_NULL;
    case 14:
    case "IS_NOT_NULL":
      return Operator.IS_NOT_NULL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Operator.UNRECOGNIZED;
  }
}

export function operatorToJSON(object: Operator): string {
  switch (object) {
    case Operator.OPERATOR_INVALID:
      return "OPERATOR_INVALID";
    case Operator.EQUALS:
      return "EQUALS";
    case Operator.NOT_EQUALS:
      return "NOT_EQUALS";
    case Operator.LIKE:
      return "LIKE";
    case Operator.NOT_LIKE:
      return "NOT_LIKE";
    case Operator.IN:
      return "IN";
    case Operator.NOT_IN:
      return "NOT_IN";
    case Operator.GREATER_THAN:
      return "GREATER_THAN";
    case Operator.GREATER_THAN_EQUAL:
      return "GREATER_THAN_EQUAL";
    case Operator.LESSER_THAN:
      return "LESSER_THAN";
    case Operator.LESSER_THAN_EQUAL:
      return "LESSER_THAN_EQUAL";
    case Operator.REGEXP_LIKE:
      return "REGEXP_LIKE";
    case Operator.NOT_REGEXP_LIKE:
      return "NOT_REGEXP_LIKE";
    case Operator.IS_NULL:
      return "IS_NULL";
    case Operator.IS_NOT_NULL:
      return "IS_NOT_NULL";
    case Operator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Granularity is an ISO8601 duration/period.
 * Note: the timestamps will be rounded *down* to the closest milliseconds for the duration specified. It is strongly
 * recommended that for larger time ranges, a wider granularity/resolution is specified.
 * NOTE: RUSS deleted PT25M and PT45M because they dont divide evenly like all the other ones do
 */
export enum Granularity {
  GRANULARITY_UNSPECIFIED = 0,
  PT1M = 1,
  PT5M = 2,
  PT10M = 3,
  PT15M = 100,
  PT20M = 105,
  PT30M = 4,
  PT1H = 10,
  PT2H = 120,
  PT3H = 125,
  PT4H = 130,
  PT5H = 135,
  PT6H = 11,
  PT7H = 140,
  PT8H = 145,
  PT9H = 150,
  PT10H = 155,
  PT11H = 160,
  PT12H = 12,
  P1D = 15,
  P2D = 165,
  P3D = 30,
  P1W = 35,
  P1M = 40,
  P2M = 45,
  P3M = 50,
  UNRECOGNIZED = -1,
}

export function granularityFromJSON(object: any): Granularity {
  switch (object) {
    case 0:
    case "GRANULARITY_UNSPECIFIED":
      return Granularity.GRANULARITY_UNSPECIFIED;
    case 1:
    case "PT1M":
      return Granularity.PT1M;
    case 2:
    case "PT5M":
      return Granularity.PT5M;
    case 3:
    case "PT10M":
      return Granularity.PT10M;
    case 100:
    case "PT15M":
      return Granularity.PT15M;
    case 105:
    case "PT20M":
      return Granularity.PT20M;
    case 4:
    case "PT30M":
      return Granularity.PT30M;
    case 10:
    case "PT1H":
      return Granularity.PT1H;
    case 120:
    case "PT2H":
      return Granularity.PT2H;
    case 125:
    case "PT3H":
      return Granularity.PT3H;
    case 130:
    case "PT4H":
      return Granularity.PT4H;
    case 135:
    case "PT5H":
      return Granularity.PT5H;
    case 11:
    case "PT6H":
      return Granularity.PT6H;
    case 140:
    case "PT7H":
      return Granularity.PT7H;
    case 145:
    case "PT8H":
      return Granularity.PT8H;
    case 150:
    case "PT9H":
      return Granularity.PT9H;
    case 155:
    case "PT10H":
      return Granularity.PT10H;
    case 160:
    case "PT11H":
      return Granularity.PT11H;
    case 12:
    case "PT12H":
      return Granularity.PT12H;
    case 15:
    case "P1D":
      return Granularity.P1D;
    case 165:
    case "P2D":
      return Granularity.P2D;
    case 30:
    case "P3D":
      return Granularity.P3D;
    case 35:
    case "P1W":
      return Granularity.P1W;
    case 40:
    case "P1M":
      return Granularity.P1M;
    case 45:
    case "P2M":
      return Granularity.P2M;
    case 50:
    case "P3M":
      return Granularity.P3M;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Granularity.UNRECOGNIZED;
  }
}

export function granularityToJSON(object: Granularity): string {
  switch (object) {
    case Granularity.GRANULARITY_UNSPECIFIED:
      return "GRANULARITY_UNSPECIFIED";
    case Granularity.PT1M:
      return "PT1M";
    case Granularity.PT5M:
      return "PT5M";
    case Granularity.PT10M:
      return "PT10M";
    case Granularity.PT15M:
      return "PT15M";
    case Granularity.PT20M:
      return "PT20M";
    case Granularity.PT30M:
      return "PT30M";
    case Granularity.PT1H:
      return "PT1H";
    case Granularity.PT2H:
      return "PT2H";
    case Granularity.PT3H:
      return "PT3H";
    case Granularity.PT4H:
      return "PT4H";
    case Granularity.PT5H:
      return "PT5H";
    case Granularity.PT6H:
      return "PT6H";
    case Granularity.PT7H:
      return "PT7H";
    case Granularity.PT8H:
      return "PT8H";
    case Granularity.PT9H:
      return "PT9H";
    case Granularity.PT10H:
      return "PT10H";
    case Granularity.PT11H:
      return "PT11H";
    case Granularity.PT12H:
      return "PT12H";
    case Granularity.P1D:
      return "P1D";
    case Granularity.P2D:
      return "P2D";
    case Granularity.P3D:
      return "P3D";
    case Granularity.P1W:
      return "P1W";
    case Granularity.P1M:
      return "P1M";
    case Granularity.P2M:
      return "P2M";
    case Granularity.P3M:
      return "P3M";
    case Granularity.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** ApiError is the API error common to all time series APIs. */
export interface ApiError {
  /** The type of error returned. */
  err: ApiError_ErrorType;
  /** The error string returned. */
  errMessage: string;
}

export enum ApiError_ErrorType {
  /** ERR_UNSPECIFIED - Unspecified error. */
  ERR_UNSPECIFIED = 0,
  /** ERR_REQUEST_INVALID - Invalid request - e.g., specified filtering/grouping/ordering is invalid. */
  ERR_REQUEST_INVALID = 1,
  /** ERR_RESPONSE_TOO_BIG - Response too big, narrow down the search window by using filters or use limit. */
  ERR_RESPONSE_TOO_BIG = 2,
  /** ERR_REQUEST_TIMEOUT - Request timed out, narrow down the search window by using filters. */
  ERR_REQUEST_TIMEOUT = 3,
  /** ERR_INTERNAL - An internal system error. */
  ERR_INTERNAL = 10,
  /** ERR_TOO_MANY_REQUESTS - Too many requests. Caller should retry after some time. */
  ERR_TOO_MANY_REQUESTS = 20,
  UNRECOGNIZED = -1,
}

export function apiError_ErrorTypeFromJSON(object: any): ApiError_ErrorType {
  switch (object) {
    case 0:
    case "ERR_UNSPECIFIED":
      return ApiError_ErrorType.ERR_UNSPECIFIED;
    case 1:
    case "ERR_REQUEST_INVALID":
      return ApiError_ErrorType.ERR_REQUEST_INVALID;
    case 2:
    case "ERR_RESPONSE_TOO_BIG":
      return ApiError_ErrorType.ERR_RESPONSE_TOO_BIG;
    case 3:
    case "ERR_REQUEST_TIMEOUT":
      return ApiError_ErrorType.ERR_REQUEST_TIMEOUT;
    case 10:
    case "ERR_INTERNAL":
      return ApiError_ErrorType.ERR_INTERNAL;
    case 20:
    case "ERR_TOO_MANY_REQUESTS":
      return ApiError_ErrorType.ERR_TOO_MANY_REQUESTS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ApiError_ErrorType.UNRECOGNIZED;
  }
}

export function apiError_ErrorTypeToJSON(object: ApiError_ErrorType): string {
  switch (object) {
    case ApiError_ErrorType.ERR_UNSPECIFIED:
      return "ERR_UNSPECIFIED";
    case ApiError_ErrorType.ERR_REQUEST_INVALID:
      return "ERR_REQUEST_INVALID";
    case ApiError_ErrorType.ERR_RESPONSE_TOO_BIG:
      return "ERR_RESPONSE_TOO_BIG";
    case ApiError_ErrorType.ERR_REQUEST_TIMEOUT:
      return "ERR_REQUEST_TIMEOUT";
    case ApiError_ErrorType.ERR_INTERNAL:
      return "ERR_INTERNAL";
    case ApiError_ErrorType.ERR_TOO_MANY_REQUESTS:
      return "ERR_TOO_MANY_REQUESTS";
    case ApiError_ErrorType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * RequestMetadata captures any metadata about the request that can be useful for tracing and instrumentation.
 * This is optionally set by the client.
 */
export interface RequestMetadata {
  /** An optional identifier that can uniquely trace a request. */
  id: string;
  /** An optional comment. */
  comment: string;
}

function createBaseApiError(): ApiError {
  return { err: 0, errMessage: "" };
}

export const ApiError = {
  encode(message: ApiError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.err !== 0) {
      writer.uint32(8).int32(message.err);
    }
    if (message.errMessage !== "") {
      writer.uint32(18).string(message.errMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiError {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.err = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiError {
    return {
      err: isSet(object.err) ? apiError_ErrorTypeFromJSON(object.err) : 0,
      errMessage: isSet(object.errMessage) ? globalThis.String(object.errMessage) : "",
    };
  },

  toJSON(message: ApiError): unknown {
    const obj: any = {};
    if (message.err !== 0) {
      obj.err = apiError_ErrorTypeToJSON(message.err);
    }
    if (message.errMessage !== "") {
      obj.errMessage = message.errMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiError>, I>>(base?: I): ApiError {
    return ApiError.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiError>, I>>(object: I): ApiError {
    const message = createBaseApiError();
    message.err = object.err ?? 0;
    message.errMessage = object.errMessage ?? "";
    return message;
  },
};

function createBaseRequestMetadata(): RequestMetadata {
  return { id: "", comment: "" };
}

export const RequestMetadata = {
  encode(message: RequestMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.comment !== "") {
      writer.uint32(18).string(message.comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.comment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RequestMetadata {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      comment: isSet(object.comment) ? globalThis.String(object.comment) : "",
    };
  },

  toJSON(message: RequestMetadata): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.comment !== "") {
      obj.comment = message.comment;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RequestMetadata>, I>>(base?: I): RequestMetadata {
    return RequestMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RequestMetadata>, I>>(object: I): RequestMetadata {
    const message = createBaseRequestMetadata();
    message.id = object.id ?? "";
    message.comment = object.comment ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
