import { SextantClientImpl } from "src/gen/schema/sextant/service";
import {
  HistoryRequest,
  HistoryResponse
} from "src/gen/schema/reader/reader_api";
import { GrpcDebugableWebImpl } from "src/utils/api/grpc/grpcDebugableWebImpl";

const rpc = new GrpcDebugableWebImpl("", { debug: false });
const sextantClient = new SextantClientImpl(rpc);
export const Sextant = {
  getQueryAssertionHistory: (
    req: Partial<HistoryRequest>
  ): Promise<HistoryResponse> => {
    return sextantClient.QueryAssertionHistoryMessage(req);
  },
  getDruidTelemetry: (
    req: Partial<HistoryRequest>
  ): Promise<HistoryResponse> => {
    return sextantClient.DruidTelemetryHistoryReader(req);
  }
};
