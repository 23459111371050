// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: schema_path/schema_path.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "schema_path";

export enum SchemaAssertType {
  ASSERT_TYPE_INVALID = 0,
  ASSERT_TYPE_DEVICE = 1,
  ASSERT_TYPE_PORT = 2,
  ASSERT_TYPE_CONFIG = 3,
  ASSERT_TYPE_SUB_INTERFACE = 4,
  UNRECOGNIZED = -1,
}

export function schemaAssertTypeFromJSON(object: any): SchemaAssertType {
  switch (object) {
    case 0:
    case "ASSERT_TYPE_INVALID":
      return SchemaAssertType.ASSERT_TYPE_INVALID;
    case 1:
    case "ASSERT_TYPE_DEVICE":
      return SchemaAssertType.ASSERT_TYPE_DEVICE;
    case 2:
    case "ASSERT_TYPE_PORT":
      return SchemaAssertType.ASSERT_TYPE_PORT;
    case 3:
    case "ASSERT_TYPE_CONFIG":
      return SchemaAssertType.ASSERT_TYPE_CONFIG;
    case 4:
    case "ASSERT_TYPE_SUB_INTERFACE":
      return SchemaAssertType.ASSERT_TYPE_SUB_INTERFACE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SchemaAssertType.UNRECOGNIZED;
  }
}

export function schemaAssertTypeToJSON(object: SchemaAssertType): string {
  switch (object) {
    case SchemaAssertType.ASSERT_TYPE_INVALID:
      return "ASSERT_TYPE_INVALID";
    case SchemaAssertType.ASSERT_TYPE_DEVICE:
      return "ASSERT_TYPE_DEVICE";
    case SchemaAssertType.ASSERT_TYPE_PORT:
      return "ASSERT_TYPE_PORT";
    case SchemaAssertType.ASSERT_TYPE_CONFIG:
      return "ASSERT_TYPE_CONFIG";
    case SchemaAssertType.ASSERT_TYPE_SUB_INTERFACE:
      return "ASSERT_TYPE_SUB_INTERFACE";
    case SchemaAssertType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SchemaType {
  /** NullType - The enum values here must uniquely map to message types defined in the schema. */
  NullType = 0,
  SyncCursorType = 1,
  AsyncCursorType = 2,
  DeleteCursorType = 3,
  /** FabricType - Index value: Fabric ID */
  FabricType = 4,
  /** DeviceType - Index value: Device ID */
  DeviceType = 5,
  FanType = 6,
  PsuType = 7,
  TempSensorType = 8,
  LineCardType = 9,
  PortType = 10,
  DacQsfpType = 11,
  FiberQsfpType = 12,
  BreakoutType = 13,
  PortNeighborType = 14,
  VtepType = 15,
  VniPortType = 16,
  MacType = 17,
  ProcTableType = 18,
  PortVlanType = 19,
  VlanStateType = 20,
  QsfpDomSensorType = 22,
  PortConfigType = 27,
  /** NodeConfigType - Index value: Constant (0) */
  NodeConfigType = 28,
  /** FabricConfigType - Index value: Fabric ID */
  FabricConfigType = 29,
  FabricConfigEventType = 30,
  L2FdbType = 31,
  PortCountersType = 32,
  RemedyActionType = 33,
  QsfpInfoType = 34,
  FabricDeviceType = 35,
  AssertConfigPortLinkUpType = 36,
  AssertPortLinkUpType = 37,
  AssertConfigDeviceConnectedToCloudType = 38,
  AssertDeviceConnectedToCloudType = 39,
  AssertConfigPortExpectedNeighborType = 40,
  AssertPortExpectedNeighborType = 41,
  AssertConfigPortNotConnectedToFabricType = 42,
  AssertPortNotConnectedToFabricType = 43,
  AssertConfigDeviceConnectedToFabricType = 44,
  AssertDeviceConnectedToFabricType = 45,
  AssertConfigPortLinkDownType = 46,
  AssertPortLinkDownType = 47,
  AssertConfigPortConnectionSpeedMatchType = 48,
  AssertPortConnectionSpeedMatchType = 49,
  AssertConfigPortSpeedConsistentType = 50,
  AssertPortSpeedConsistentType = 51,
  AllAssertTypes = 52,
  UnderlayInfoType = 53,
  ProxyStatsType = 54,
  VlanCountersType = 55,
  TestPlaceHolder1SecretType = 56,
  AssertConfigVlanHasTrafficType = 57,
  AssertVlanHasTrafficType = 58,
  RemoteVtepType = 59,
  QsfpDomThresholdType = 60,
  /** SextantFabricAssignmentType - Index value: Fabric ID */
  SextantFabricAssignmentType = 61,
  L3TableType = 62,
  VlanVniMapType = 63,
  /**
   * ProxyConnectionStateType - A string representation of the TCP 4-tuple: <src IP>|<src port>|<dest IP>|<dest port>
   * e.g., 192.168.123.13|41308|172.20.187.191|31033
   */
  ProxyConnectionStateType = 64,
  ProxyConnectionStatsType = 65,
  L2MacType = 66,
  BmcType = 67,
  SmartNicDpuType = 68,
  ServerVoltageSensorType = 69,
  ServerTemperatureSensorType = 70,
  IpRouteTableType = 71,
  VrfType = 72,
  IpNextHopType = 73,
  IpPrefixType = 74,
  SubInterfaceType = 75,
  AssertConfigStaticDefaultRouteExistsType = 76,
  AssertStaticDefaultRouteExistsType = 77,
  SpyglassBrokerEntryType = 78,
  ManagementStateType = 79,
  AllDeviceAssertTypes = 80,
  AllPortAssertTypes = 81,
  AssertConfigManagementPortConfigSameType = 82,
  AssertManagementPortConfigSameType = 83,
  FabricConfigNotificationType = 84,
  /** SpyglassGingerEntryType - DEPRECATED */
  SpyglassGingerEntryType = 85,
  PlannedConfigType = 86,
  RoutedInterfaceCountersType = 87,
  IpmRawLatencyCountersType = 88,
  IpmProcLatencyCountersType = 89,
  IpmProcLossCountersType = 90,
  IpmLivenessCountersType = 91,
  IpmSessionIdType = 92,
  SystemStatsType = 99,
  TestRootOneType = 100,
  TestRootTwoType = 101,
  TestMidOneType = 102,
  TestMidTwoType = 103,
  TestLeafOneType = 104,
  TestLeafTwoType = 105,
  TestIpAddressType = 106,
  TestIpPrefixType = 107,
  TestMacAddressType = 108,
  AssertConfigDeviceResourceUsageBelowThresholdType = 109,
  AssertDeviceResourceUsageBelowThresholdType = 110,
  FabricTransactionType = 111,
  /** BgpNeighborSummaryType - BgpFamilyType = 112 [(index_type) = INDEX_STRING32]; DEPRECATED */
  BgpNeighborSummaryType = 113,
  BmcConfigType = 114,
  DeviceConnectionType = 115,
  BgpNeighborFamilyType = 116,
  AssertConfigBgpPeerEstablishedType = 117,
  AssertBgpPeerEstablishedType = 118,
  /** SpyglassSshSessionEntryType - Krang list of all intended Spyglass sessions */
  SpyglassSshSessionEntryType = 119,
  PortChannelMemberStateType = 120,
  PortChannelStateType = 121,
  AssertConfigIpmLatencyBelowThresholdType = 122,
  AssertIpmLatencyBelowThresholdType = 123,
  AssertConfigIpmLossBelowThresholdType = 124,
  AssertIpmLossBelowThresholdType = 125,
  SpyglassDeviceType = 126,
  /** SpyglassTcpListenerType - Indexed by TCP listen port */
  SpyglassTcpListenerType = 127,
  /** SpyglassTcpSessionType - Indexed by TCP port of cxn ("remote") */
  SpyglassTcpSessionType = 128,
  UnknownVlanType = 129,
  UnknownVlanPortType = 130,
  AssertConfigExpectedPortBreakoutExistsType = 131,
  AssertExpectedPortBreakoutExistsType = 132,
  AssertConfigSubInterfaceUpType = 133,
  AssertSubInterfaceUpType = 134,
  AssertSubInterfaceDownType = 135,
  AssertConfigSubInterfaceDownType = 136,
  AllSubInterfaceAssertTypes = 137,
  DeviceClaimType = 138,
  AggregatedDeviceClaimType = 139,
  AssertPortChannelMemberUpType = 140,
  AssertConfigPortChannelMemberUpType = 141,
  EsiInfoType = 142,
  EsiVtepInfoType = 143,
  /** OrgUuidType - Infrastructure types. See notes above.  There are no typed blocks within this enum */
  OrgUuidType = 200,
  FabricUuidType = 201,
  OrgServiceProtoType = 202,
  OrgServiceSvcType = 203,
  OrgServiceRouteType = 204,
  ApiPathType = 205,
  ApiPathMethodType = 206,
  ApiServiceMappingType = 207,
  OrgToCellPairMappingType = 208,
  TenantUuidType = 209,
  UserEmailType = 210,
  ManagementConfigType = 211,
  /** ServiceInstanceHealthType - Index: Instance ID (e.g. sextant-0) */
  ServiceInstanceHealthType = 212,
  /** FabricAssignmentStatusType - Index: Fabric ID */
  FabricAssignmentStatusType = 213,
  /** KubernetesServiceInstanceType - Index: Kubernetes Pod UID */
  KubernetesServiceInstanceType = 215,
  /** BearerTokenType - Index: Bearer token fingerprint */
  BearerTokenType = 222,
  /** ApiKeyType - Index: API key fingerprint */
  ApiKeyType = 223,
  FabricMemoryUsageType = 224,
  TypeMemoryUsageType = 225,
  ManifestRunningImageStatusType = 226,
  ManifestPartitionFileCacheStatusType = 227,
  StpPortStateType = 228,
  StpVlanStateType = 229,
  StpVlanPortStateType = 230,
  AssertDeviceEnvPsuUsageBelowThresholdType = 231,
  AssertDeviceEnvFanUsageBelowThresholdType = 232,
  AssertDeviceEnvTempSensorUsageBelowThresholdType = 233,
  AssertConfigDeviceEnvPsuUsageBelowThresholdType = 234,
  AssertConfigDeviceEnvFanUsageBelowThresholdType = 235,
  AssertConfigDeviceEnvTempSensorUsageBelowThresholdType = 236,
  AllSmartHandsTypes = 237,
  UnpackAndAssembleTaskType = 238,
  RackAndPowerOnTaskType = 239,
  ClaimAndBindTaskType = 240,
  PortSetupTaskType = 241,
  FabricConnectionTaskType = 242,
  NegativeConnectionTaskType = 243,
  /** ApplicationType - Index: Integer value of schema.Application enum */
  ApplicationType = 244,
  /** ResourceAssignmentType - Index: Assignment ID */
  ResourceAssignmentType = 245,
  NodeType = 246,
  NodeLineCardType = 247,
  NodePortType = 248,
  NodeBreakoutType = 249,
  UNRECOGNIZED = -1,
}

export function schemaTypeFromJSON(object: any): SchemaType {
  switch (object) {
    case 0:
    case "NullType":
      return SchemaType.NullType;
    case 1:
    case "SyncCursorType":
      return SchemaType.SyncCursorType;
    case 2:
    case "AsyncCursorType":
      return SchemaType.AsyncCursorType;
    case 3:
    case "DeleteCursorType":
      return SchemaType.DeleteCursorType;
    case 4:
    case "FabricType":
      return SchemaType.FabricType;
    case 5:
    case "DeviceType":
      return SchemaType.DeviceType;
    case 6:
    case "FanType":
      return SchemaType.FanType;
    case 7:
    case "PsuType":
      return SchemaType.PsuType;
    case 8:
    case "TempSensorType":
      return SchemaType.TempSensorType;
    case 9:
    case "LineCardType":
      return SchemaType.LineCardType;
    case 10:
    case "PortType":
      return SchemaType.PortType;
    case 11:
    case "DacQsfpType":
      return SchemaType.DacQsfpType;
    case 12:
    case "FiberQsfpType":
      return SchemaType.FiberQsfpType;
    case 13:
    case "BreakoutType":
      return SchemaType.BreakoutType;
    case 14:
    case "PortNeighborType":
      return SchemaType.PortNeighborType;
    case 15:
    case "VtepType":
      return SchemaType.VtepType;
    case 16:
    case "VniPortType":
      return SchemaType.VniPortType;
    case 17:
    case "MacType":
      return SchemaType.MacType;
    case 18:
    case "ProcTableType":
      return SchemaType.ProcTableType;
    case 19:
    case "PortVlanType":
      return SchemaType.PortVlanType;
    case 20:
    case "VlanStateType":
      return SchemaType.VlanStateType;
    case 22:
    case "QsfpDomSensorType":
      return SchemaType.QsfpDomSensorType;
    case 27:
    case "PortConfigType":
      return SchemaType.PortConfigType;
    case 28:
    case "NodeConfigType":
      return SchemaType.NodeConfigType;
    case 29:
    case "FabricConfigType":
      return SchemaType.FabricConfigType;
    case 30:
    case "FabricConfigEventType":
      return SchemaType.FabricConfigEventType;
    case 31:
    case "L2FdbType":
      return SchemaType.L2FdbType;
    case 32:
    case "PortCountersType":
      return SchemaType.PortCountersType;
    case 33:
    case "RemedyActionType":
      return SchemaType.RemedyActionType;
    case 34:
    case "QsfpInfoType":
      return SchemaType.QsfpInfoType;
    case 35:
    case "FabricDeviceType":
      return SchemaType.FabricDeviceType;
    case 36:
    case "AssertConfigPortLinkUpType":
      return SchemaType.AssertConfigPortLinkUpType;
    case 37:
    case "AssertPortLinkUpType":
      return SchemaType.AssertPortLinkUpType;
    case 38:
    case "AssertConfigDeviceConnectedToCloudType":
      return SchemaType.AssertConfigDeviceConnectedToCloudType;
    case 39:
    case "AssertDeviceConnectedToCloudType":
      return SchemaType.AssertDeviceConnectedToCloudType;
    case 40:
    case "AssertConfigPortExpectedNeighborType":
      return SchemaType.AssertConfigPortExpectedNeighborType;
    case 41:
    case "AssertPortExpectedNeighborType":
      return SchemaType.AssertPortExpectedNeighborType;
    case 42:
    case "AssertConfigPortNotConnectedToFabricType":
      return SchemaType.AssertConfigPortNotConnectedToFabricType;
    case 43:
    case "AssertPortNotConnectedToFabricType":
      return SchemaType.AssertPortNotConnectedToFabricType;
    case 44:
    case "AssertConfigDeviceConnectedToFabricType":
      return SchemaType.AssertConfigDeviceConnectedToFabricType;
    case 45:
    case "AssertDeviceConnectedToFabricType":
      return SchemaType.AssertDeviceConnectedToFabricType;
    case 46:
    case "AssertConfigPortLinkDownType":
      return SchemaType.AssertConfigPortLinkDownType;
    case 47:
    case "AssertPortLinkDownType":
      return SchemaType.AssertPortLinkDownType;
    case 48:
    case "AssertConfigPortConnectionSpeedMatchType":
      return SchemaType.AssertConfigPortConnectionSpeedMatchType;
    case 49:
    case "AssertPortConnectionSpeedMatchType":
      return SchemaType.AssertPortConnectionSpeedMatchType;
    case 50:
    case "AssertConfigPortSpeedConsistentType":
      return SchemaType.AssertConfigPortSpeedConsistentType;
    case 51:
    case "AssertPortSpeedConsistentType":
      return SchemaType.AssertPortSpeedConsistentType;
    case 52:
    case "AllAssertTypes":
      return SchemaType.AllAssertTypes;
    case 53:
    case "UnderlayInfoType":
      return SchemaType.UnderlayInfoType;
    case 54:
    case "ProxyStatsType":
      return SchemaType.ProxyStatsType;
    case 55:
    case "VlanCountersType":
      return SchemaType.VlanCountersType;
    case 56:
    case "TestPlaceHolder1SecretType":
      return SchemaType.TestPlaceHolder1SecretType;
    case 57:
    case "AssertConfigVlanHasTrafficType":
      return SchemaType.AssertConfigVlanHasTrafficType;
    case 58:
    case "AssertVlanHasTrafficType":
      return SchemaType.AssertVlanHasTrafficType;
    case 59:
    case "RemoteVtepType":
      return SchemaType.RemoteVtepType;
    case 60:
    case "QsfpDomThresholdType":
      return SchemaType.QsfpDomThresholdType;
    case 61:
    case "SextantFabricAssignmentType":
      return SchemaType.SextantFabricAssignmentType;
    case 62:
    case "L3TableType":
      return SchemaType.L3TableType;
    case 63:
    case "VlanVniMapType":
      return SchemaType.VlanVniMapType;
    case 64:
    case "ProxyConnectionStateType":
      return SchemaType.ProxyConnectionStateType;
    case 65:
    case "ProxyConnectionStatsType":
      return SchemaType.ProxyConnectionStatsType;
    case 66:
    case "L2MacType":
      return SchemaType.L2MacType;
    case 67:
    case "BmcType":
      return SchemaType.BmcType;
    case 68:
    case "SmartNicDpuType":
      return SchemaType.SmartNicDpuType;
    case 69:
    case "ServerVoltageSensorType":
      return SchemaType.ServerVoltageSensorType;
    case 70:
    case "ServerTemperatureSensorType":
      return SchemaType.ServerTemperatureSensorType;
    case 71:
    case "IpRouteTableType":
      return SchemaType.IpRouteTableType;
    case 72:
    case "VrfType":
      return SchemaType.VrfType;
    case 73:
    case "IpNextHopType":
      return SchemaType.IpNextHopType;
    case 74:
    case "IpPrefixType":
      return SchemaType.IpPrefixType;
    case 75:
    case "SubInterfaceType":
      return SchemaType.SubInterfaceType;
    case 76:
    case "AssertConfigStaticDefaultRouteExistsType":
      return SchemaType.AssertConfigStaticDefaultRouteExistsType;
    case 77:
    case "AssertStaticDefaultRouteExistsType":
      return SchemaType.AssertStaticDefaultRouteExistsType;
    case 78:
    case "SpyglassBrokerEntryType":
      return SchemaType.SpyglassBrokerEntryType;
    case 79:
    case "ManagementStateType":
      return SchemaType.ManagementStateType;
    case 80:
    case "AllDeviceAssertTypes":
      return SchemaType.AllDeviceAssertTypes;
    case 81:
    case "AllPortAssertTypes":
      return SchemaType.AllPortAssertTypes;
    case 82:
    case "AssertConfigManagementPortConfigSameType":
      return SchemaType.AssertConfigManagementPortConfigSameType;
    case 83:
    case "AssertManagementPortConfigSameType":
      return SchemaType.AssertManagementPortConfigSameType;
    case 84:
    case "FabricConfigNotificationType":
      return SchemaType.FabricConfigNotificationType;
    case 85:
    case "SpyglassGingerEntryType":
      return SchemaType.SpyglassGingerEntryType;
    case 86:
    case "PlannedConfigType":
      return SchemaType.PlannedConfigType;
    case 87:
    case "RoutedInterfaceCountersType":
      return SchemaType.RoutedInterfaceCountersType;
    case 88:
    case "IpmRawLatencyCountersType":
      return SchemaType.IpmRawLatencyCountersType;
    case 89:
    case "IpmProcLatencyCountersType":
      return SchemaType.IpmProcLatencyCountersType;
    case 90:
    case "IpmProcLossCountersType":
      return SchemaType.IpmProcLossCountersType;
    case 91:
    case "IpmLivenessCountersType":
      return SchemaType.IpmLivenessCountersType;
    case 92:
    case "IpmSessionIdType":
      return SchemaType.IpmSessionIdType;
    case 99:
    case "SystemStatsType":
      return SchemaType.SystemStatsType;
    case 100:
    case "TestRootOneType":
      return SchemaType.TestRootOneType;
    case 101:
    case "TestRootTwoType":
      return SchemaType.TestRootTwoType;
    case 102:
    case "TestMidOneType":
      return SchemaType.TestMidOneType;
    case 103:
    case "TestMidTwoType":
      return SchemaType.TestMidTwoType;
    case 104:
    case "TestLeafOneType":
      return SchemaType.TestLeafOneType;
    case 105:
    case "TestLeafTwoType":
      return SchemaType.TestLeafTwoType;
    case 106:
    case "TestIpAddressType":
      return SchemaType.TestIpAddressType;
    case 107:
    case "TestIpPrefixType":
      return SchemaType.TestIpPrefixType;
    case 108:
    case "TestMacAddressType":
      return SchemaType.TestMacAddressType;
    case 109:
    case "AssertConfigDeviceResourceUsageBelowThresholdType":
      return SchemaType.AssertConfigDeviceResourceUsageBelowThresholdType;
    case 110:
    case "AssertDeviceResourceUsageBelowThresholdType":
      return SchemaType.AssertDeviceResourceUsageBelowThresholdType;
    case 111:
    case "FabricTransactionType":
      return SchemaType.FabricTransactionType;
    case 113:
    case "BgpNeighborSummaryType":
      return SchemaType.BgpNeighborSummaryType;
    case 114:
    case "BmcConfigType":
      return SchemaType.BmcConfigType;
    case 115:
    case "DeviceConnectionType":
      return SchemaType.DeviceConnectionType;
    case 116:
    case "BgpNeighborFamilyType":
      return SchemaType.BgpNeighborFamilyType;
    case 117:
    case "AssertConfigBgpPeerEstablishedType":
      return SchemaType.AssertConfigBgpPeerEstablishedType;
    case 118:
    case "AssertBgpPeerEstablishedType":
      return SchemaType.AssertBgpPeerEstablishedType;
    case 119:
    case "SpyglassSshSessionEntryType":
      return SchemaType.SpyglassSshSessionEntryType;
    case 120:
    case "PortChannelMemberStateType":
      return SchemaType.PortChannelMemberStateType;
    case 121:
    case "PortChannelStateType":
      return SchemaType.PortChannelStateType;
    case 122:
    case "AssertConfigIpmLatencyBelowThresholdType":
      return SchemaType.AssertConfigIpmLatencyBelowThresholdType;
    case 123:
    case "AssertIpmLatencyBelowThresholdType":
      return SchemaType.AssertIpmLatencyBelowThresholdType;
    case 124:
    case "AssertConfigIpmLossBelowThresholdType":
      return SchemaType.AssertConfigIpmLossBelowThresholdType;
    case 125:
    case "AssertIpmLossBelowThresholdType":
      return SchemaType.AssertIpmLossBelowThresholdType;
    case 126:
    case "SpyglassDeviceType":
      return SchemaType.SpyglassDeviceType;
    case 127:
    case "SpyglassTcpListenerType":
      return SchemaType.SpyglassTcpListenerType;
    case 128:
    case "SpyglassTcpSessionType":
      return SchemaType.SpyglassTcpSessionType;
    case 129:
    case "UnknownVlanType":
      return SchemaType.UnknownVlanType;
    case 130:
    case "UnknownVlanPortType":
      return SchemaType.UnknownVlanPortType;
    case 131:
    case "AssertConfigExpectedPortBreakoutExistsType":
      return SchemaType.AssertConfigExpectedPortBreakoutExistsType;
    case 132:
    case "AssertExpectedPortBreakoutExistsType":
      return SchemaType.AssertExpectedPortBreakoutExistsType;
    case 133:
    case "AssertConfigSubInterfaceUpType":
      return SchemaType.AssertConfigSubInterfaceUpType;
    case 134:
    case "AssertSubInterfaceUpType":
      return SchemaType.AssertSubInterfaceUpType;
    case 135:
    case "AssertSubInterfaceDownType":
      return SchemaType.AssertSubInterfaceDownType;
    case 136:
    case "AssertConfigSubInterfaceDownType":
      return SchemaType.AssertConfigSubInterfaceDownType;
    case 137:
    case "AllSubInterfaceAssertTypes":
      return SchemaType.AllSubInterfaceAssertTypes;
    case 138:
    case "DeviceClaimType":
      return SchemaType.DeviceClaimType;
    case 139:
    case "AggregatedDeviceClaimType":
      return SchemaType.AggregatedDeviceClaimType;
    case 140:
    case "AssertPortChannelMemberUpType":
      return SchemaType.AssertPortChannelMemberUpType;
    case 141:
    case "AssertConfigPortChannelMemberUpType":
      return SchemaType.AssertConfigPortChannelMemberUpType;
    case 142:
    case "EsiInfoType":
      return SchemaType.EsiInfoType;
    case 143:
    case "EsiVtepInfoType":
      return SchemaType.EsiVtepInfoType;
    case 200:
    case "OrgUuidType":
      return SchemaType.OrgUuidType;
    case 201:
    case "FabricUuidType":
      return SchemaType.FabricUuidType;
    case 202:
    case "OrgServiceProtoType":
      return SchemaType.OrgServiceProtoType;
    case 203:
    case "OrgServiceSvcType":
      return SchemaType.OrgServiceSvcType;
    case 204:
    case "OrgServiceRouteType":
      return SchemaType.OrgServiceRouteType;
    case 205:
    case "ApiPathType":
      return SchemaType.ApiPathType;
    case 206:
    case "ApiPathMethodType":
      return SchemaType.ApiPathMethodType;
    case 207:
    case "ApiServiceMappingType":
      return SchemaType.ApiServiceMappingType;
    case 208:
    case "OrgToCellPairMappingType":
      return SchemaType.OrgToCellPairMappingType;
    case 209:
    case "TenantUuidType":
      return SchemaType.TenantUuidType;
    case 210:
    case "UserEmailType":
      return SchemaType.UserEmailType;
    case 211:
    case "ManagementConfigType":
      return SchemaType.ManagementConfigType;
    case 212:
    case "ServiceInstanceHealthType":
      return SchemaType.ServiceInstanceHealthType;
    case 213:
    case "FabricAssignmentStatusType":
      return SchemaType.FabricAssignmentStatusType;
    case 215:
    case "KubernetesServiceInstanceType":
      return SchemaType.KubernetesServiceInstanceType;
    case 222:
    case "BearerTokenType":
      return SchemaType.BearerTokenType;
    case 223:
    case "ApiKeyType":
      return SchemaType.ApiKeyType;
    case 224:
    case "FabricMemoryUsageType":
      return SchemaType.FabricMemoryUsageType;
    case 225:
    case "TypeMemoryUsageType":
      return SchemaType.TypeMemoryUsageType;
    case 226:
    case "ManifestRunningImageStatusType":
      return SchemaType.ManifestRunningImageStatusType;
    case 227:
    case "ManifestPartitionFileCacheStatusType":
      return SchemaType.ManifestPartitionFileCacheStatusType;
    case 228:
    case "StpPortStateType":
      return SchemaType.StpPortStateType;
    case 229:
    case "StpVlanStateType":
      return SchemaType.StpVlanStateType;
    case 230:
    case "StpVlanPortStateType":
      return SchemaType.StpVlanPortStateType;
    case 231:
    case "AssertDeviceEnvPsuUsageBelowThresholdType":
      return SchemaType.AssertDeviceEnvPsuUsageBelowThresholdType;
    case 232:
    case "AssertDeviceEnvFanUsageBelowThresholdType":
      return SchemaType.AssertDeviceEnvFanUsageBelowThresholdType;
    case 233:
    case "AssertDeviceEnvTempSensorUsageBelowThresholdType":
      return SchemaType.AssertDeviceEnvTempSensorUsageBelowThresholdType;
    case 234:
    case "AssertConfigDeviceEnvPsuUsageBelowThresholdType":
      return SchemaType.AssertConfigDeviceEnvPsuUsageBelowThresholdType;
    case 235:
    case "AssertConfigDeviceEnvFanUsageBelowThresholdType":
      return SchemaType.AssertConfigDeviceEnvFanUsageBelowThresholdType;
    case 236:
    case "AssertConfigDeviceEnvTempSensorUsageBelowThresholdType":
      return SchemaType.AssertConfigDeviceEnvTempSensorUsageBelowThresholdType;
    case 237:
    case "AllSmartHandsTypes":
      return SchemaType.AllSmartHandsTypes;
    case 238:
    case "UnpackAndAssembleTaskType":
      return SchemaType.UnpackAndAssembleTaskType;
    case 239:
    case "RackAndPowerOnTaskType":
      return SchemaType.RackAndPowerOnTaskType;
    case 240:
    case "ClaimAndBindTaskType":
      return SchemaType.ClaimAndBindTaskType;
    case 241:
    case "PortSetupTaskType":
      return SchemaType.PortSetupTaskType;
    case 242:
    case "FabricConnectionTaskType":
      return SchemaType.FabricConnectionTaskType;
    case 243:
    case "NegativeConnectionTaskType":
      return SchemaType.NegativeConnectionTaskType;
    case 244:
    case "ApplicationType":
      return SchemaType.ApplicationType;
    case 245:
    case "ResourceAssignmentType":
      return SchemaType.ResourceAssignmentType;
    case 246:
    case "NodeType":
      return SchemaType.NodeType;
    case 247:
    case "NodeLineCardType":
      return SchemaType.NodeLineCardType;
    case 248:
    case "NodePortType":
      return SchemaType.NodePortType;
    case 249:
    case "NodeBreakoutType":
      return SchemaType.NodeBreakoutType;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SchemaType.UNRECOGNIZED;
  }
}

export function schemaTypeToJSON(object: SchemaType): string {
  switch (object) {
    case SchemaType.NullType:
      return "NullType";
    case SchemaType.SyncCursorType:
      return "SyncCursorType";
    case SchemaType.AsyncCursorType:
      return "AsyncCursorType";
    case SchemaType.DeleteCursorType:
      return "DeleteCursorType";
    case SchemaType.FabricType:
      return "FabricType";
    case SchemaType.DeviceType:
      return "DeviceType";
    case SchemaType.FanType:
      return "FanType";
    case SchemaType.PsuType:
      return "PsuType";
    case SchemaType.TempSensorType:
      return "TempSensorType";
    case SchemaType.LineCardType:
      return "LineCardType";
    case SchemaType.PortType:
      return "PortType";
    case SchemaType.DacQsfpType:
      return "DacQsfpType";
    case SchemaType.FiberQsfpType:
      return "FiberQsfpType";
    case SchemaType.BreakoutType:
      return "BreakoutType";
    case SchemaType.PortNeighborType:
      return "PortNeighborType";
    case SchemaType.VtepType:
      return "VtepType";
    case SchemaType.VniPortType:
      return "VniPortType";
    case SchemaType.MacType:
      return "MacType";
    case SchemaType.ProcTableType:
      return "ProcTableType";
    case SchemaType.PortVlanType:
      return "PortVlanType";
    case SchemaType.VlanStateType:
      return "VlanStateType";
    case SchemaType.QsfpDomSensorType:
      return "QsfpDomSensorType";
    case SchemaType.PortConfigType:
      return "PortConfigType";
    case SchemaType.NodeConfigType:
      return "NodeConfigType";
    case SchemaType.FabricConfigType:
      return "FabricConfigType";
    case SchemaType.FabricConfigEventType:
      return "FabricConfigEventType";
    case SchemaType.L2FdbType:
      return "L2FdbType";
    case SchemaType.PortCountersType:
      return "PortCountersType";
    case SchemaType.RemedyActionType:
      return "RemedyActionType";
    case SchemaType.QsfpInfoType:
      return "QsfpInfoType";
    case SchemaType.FabricDeviceType:
      return "FabricDeviceType";
    case SchemaType.AssertConfigPortLinkUpType:
      return "AssertConfigPortLinkUpType";
    case SchemaType.AssertPortLinkUpType:
      return "AssertPortLinkUpType";
    case SchemaType.AssertConfigDeviceConnectedToCloudType:
      return "AssertConfigDeviceConnectedToCloudType";
    case SchemaType.AssertDeviceConnectedToCloudType:
      return "AssertDeviceConnectedToCloudType";
    case SchemaType.AssertConfigPortExpectedNeighborType:
      return "AssertConfigPortExpectedNeighborType";
    case SchemaType.AssertPortExpectedNeighborType:
      return "AssertPortExpectedNeighborType";
    case SchemaType.AssertConfigPortNotConnectedToFabricType:
      return "AssertConfigPortNotConnectedToFabricType";
    case SchemaType.AssertPortNotConnectedToFabricType:
      return "AssertPortNotConnectedToFabricType";
    case SchemaType.AssertConfigDeviceConnectedToFabricType:
      return "AssertConfigDeviceConnectedToFabricType";
    case SchemaType.AssertDeviceConnectedToFabricType:
      return "AssertDeviceConnectedToFabricType";
    case SchemaType.AssertConfigPortLinkDownType:
      return "AssertConfigPortLinkDownType";
    case SchemaType.AssertPortLinkDownType:
      return "AssertPortLinkDownType";
    case SchemaType.AssertConfigPortConnectionSpeedMatchType:
      return "AssertConfigPortConnectionSpeedMatchType";
    case SchemaType.AssertPortConnectionSpeedMatchType:
      return "AssertPortConnectionSpeedMatchType";
    case SchemaType.AssertConfigPortSpeedConsistentType:
      return "AssertConfigPortSpeedConsistentType";
    case SchemaType.AssertPortSpeedConsistentType:
      return "AssertPortSpeedConsistentType";
    case SchemaType.AllAssertTypes:
      return "AllAssertTypes";
    case SchemaType.UnderlayInfoType:
      return "UnderlayInfoType";
    case SchemaType.ProxyStatsType:
      return "ProxyStatsType";
    case SchemaType.VlanCountersType:
      return "VlanCountersType";
    case SchemaType.TestPlaceHolder1SecretType:
      return "TestPlaceHolder1SecretType";
    case SchemaType.AssertConfigVlanHasTrafficType:
      return "AssertConfigVlanHasTrafficType";
    case SchemaType.AssertVlanHasTrafficType:
      return "AssertVlanHasTrafficType";
    case SchemaType.RemoteVtepType:
      return "RemoteVtepType";
    case SchemaType.QsfpDomThresholdType:
      return "QsfpDomThresholdType";
    case SchemaType.SextantFabricAssignmentType:
      return "SextantFabricAssignmentType";
    case SchemaType.L3TableType:
      return "L3TableType";
    case SchemaType.VlanVniMapType:
      return "VlanVniMapType";
    case SchemaType.ProxyConnectionStateType:
      return "ProxyConnectionStateType";
    case SchemaType.ProxyConnectionStatsType:
      return "ProxyConnectionStatsType";
    case SchemaType.L2MacType:
      return "L2MacType";
    case SchemaType.BmcType:
      return "BmcType";
    case SchemaType.SmartNicDpuType:
      return "SmartNicDpuType";
    case SchemaType.ServerVoltageSensorType:
      return "ServerVoltageSensorType";
    case SchemaType.ServerTemperatureSensorType:
      return "ServerTemperatureSensorType";
    case SchemaType.IpRouteTableType:
      return "IpRouteTableType";
    case SchemaType.VrfType:
      return "VrfType";
    case SchemaType.IpNextHopType:
      return "IpNextHopType";
    case SchemaType.IpPrefixType:
      return "IpPrefixType";
    case SchemaType.SubInterfaceType:
      return "SubInterfaceType";
    case SchemaType.AssertConfigStaticDefaultRouteExistsType:
      return "AssertConfigStaticDefaultRouteExistsType";
    case SchemaType.AssertStaticDefaultRouteExistsType:
      return "AssertStaticDefaultRouteExistsType";
    case SchemaType.SpyglassBrokerEntryType:
      return "SpyglassBrokerEntryType";
    case SchemaType.ManagementStateType:
      return "ManagementStateType";
    case SchemaType.AllDeviceAssertTypes:
      return "AllDeviceAssertTypes";
    case SchemaType.AllPortAssertTypes:
      return "AllPortAssertTypes";
    case SchemaType.AssertConfigManagementPortConfigSameType:
      return "AssertConfigManagementPortConfigSameType";
    case SchemaType.AssertManagementPortConfigSameType:
      return "AssertManagementPortConfigSameType";
    case SchemaType.FabricConfigNotificationType:
      return "FabricConfigNotificationType";
    case SchemaType.SpyglassGingerEntryType:
      return "SpyglassGingerEntryType";
    case SchemaType.PlannedConfigType:
      return "PlannedConfigType";
    case SchemaType.RoutedInterfaceCountersType:
      return "RoutedInterfaceCountersType";
    case SchemaType.IpmRawLatencyCountersType:
      return "IpmRawLatencyCountersType";
    case SchemaType.IpmProcLatencyCountersType:
      return "IpmProcLatencyCountersType";
    case SchemaType.IpmProcLossCountersType:
      return "IpmProcLossCountersType";
    case SchemaType.IpmLivenessCountersType:
      return "IpmLivenessCountersType";
    case SchemaType.IpmSessionIdType:
      return "IpmSessionIdType";
    case SchemaType.SystemStatsType:
      return "SystemStatsType";
    case SchemaType.TestRootOneType:
      return "TestRootOneType";
    case SchemaType.TestRootTwoType:
      return "TestRootTwoType";
    case SchemaType.TestMidOneType:
      return "TestMidOneType";
    case SchemaType.TestMidTwoType:
      return "TestMidTwoType";
    case SchemaType.TestLeafOneType:
      return "TestLeafOneType";
    case SchemaType.TestLeafTwoType:
      return "TestLeafTwoType";
    case SchemaType.TestIpAddressType:
      return "TestIpAddressType";
    case SchemaType.TestIpPrefixType:
      return "TestIpPrefixType";
    case SchemaType.TestMacAddressType:
      return "TestMacAddressType";
    case SchemaType.AssertConfigDeviceResourceUsageBelowThresholdType:
      return "AssertConfigDeviceResourceUsageBelowThresholdType";
    case SchemaType.AssertDeviceResourceUsageBelowThresholdType:
      return "AssertDeviceResourceUsageBelowThresholdType";
    case SchemaType.FabricTransactionType:
      return "FabricTransactionType";
    case SchemaType.BgpNeighborSummaryType:
      return "BgpNeighborSummaryType";
    case SchemaType.BmcConfigType:
      return "BmcConfigType";
    case SchemaType.DeviceConnectionType:
      return "DeviceConnectionType";
    case SchemaType.BgpNeighborFamilyType:
      return "BgpNeighborFamilyType";
    case SchemaType.AssertConfigBgpPeerEstablishedType:
      return "AssertConfigBgpPeerEstablishedType";
    case SchemaType.AssertBgpPeerEstablishedType:
      return "AssertBgpPeerEstablishedType";
    case SchemaType.SpyglassSshSessionEntryType:
      return "SpyglassSshSessionEntryType";
    case SchemaType.PortChannelMemberStateType:
      return "PortChannelMemberStateType";
    case SchemaType.PortChannelStateType:
      return "PortChannelStateType";
    case SchemaType.AssertConfigIpmLatencyBelowThresholdType:
      return "AssertConfigIpmLatencyBelowThresholdType";
    case SchemaType.AssertIpmLatencyBelowThresholdType:
      return "AssertIpmLatencyBelowThresholdType";
    case SchemaType.AssertConfigIpmLossBelowThresholdType:
      return "AssertConfigIpmLossBelowThresholdType";
    case SchemaType.AssertIpmLossBelowThresholdType:
      return "AssertIpmLossBelowThresholdType";
    case SchemaType.SpyglassDeviceType:
      return "SpyglassDeviceType";
    case SchemaType.SpyglassTcpListenerType:
      return "SpyglassTcpListenerType";
    case SchemaType.SpyglassTcpSessionType:
      return "SpyglassTcpSessionType";
    case SchemaType.UnknownVlanType:
      return "UnknownVlanType";
    case SchemaType.UnknownVlanPortType:
      return "UnknownVlanPortType";
    case SchemaType.AssertConfigExpectedPortBreakoutExistsType:
      return "AssertConfigExpectedPortBreakoutExistsType";
    case SchemaType.AssertExpectedPortBreakoutExistsType:
      return "AssertExpectedPortBreakoutExistsType";
    case SchemaType.AssertConfigSubInterfaceUpType:
      return "AssertConfigSubInterfaceUpType";
    case SchemaType.AssertSubInterfaceUpType:
      return "AssertSubInterfaceUpType";
    case SchemaType.AssertSubInterfaceDownType:
      return "AssertSubInterfaceDownType";
    case SchemaType.AssertConfigSubInterfaceDownType:
      return "AssertConfigSubInterfaceDownType";
    case SchemaType.AllSubInterfaceAssertTypes:
      return "AllSubInterfaceAssertTypes";
    case SchemaType.DeviceClaimType:
      return "DeviceClaimType";
    case SchemaType.AggregatedDeviceClaimType:
      return "AggregatedDeviceClaimType";
    case SchemaType.AssertPortChannelMemberUpType:
      return "AssertPortChannelMemberUpType";
    case SchemaType.AssertConfigPortChannelMemberUpType:
      return "AssertConfigPortChannelMemberUpType";
    case SchemaType.EsiInfoType:
      return "EsiInfoType";
    case SchemaType.EsiVtepInfoType:
      return "EsiVtepInfoType";
    case SchemaType.OrgUuidType:
      return "OrgUuidType";
    case SchemaType.FabricUuidType:
      return "FabricUuidType";
    case SchemaType.OrgServiceProtoType:
      return "OrgServiceProtoType";
    case SchemaType.OrgServiceSvcType:
      return "OrgServiceSvcType";
    case SchemaType.OrgServiceRouteType:
      return "OrgServiceRouteType";
    case SchemaType.ApiPathType:
      return "ApiPathType";
    case SchemaType.ApiPathMethodType:
      return "ApiPathMethodType";
    case SchemaType.ApiServiceMappingType:
      return "ApiServiceMappingType";
    case SchemaType.OrgToCellPairMappingType:
      return "OrgToCellPairMappingType";
    case SchemaType.TenantUuidType:
      return "TenantUuidType";
    case SchemaType.UserEmailType:
      return "UserEmailType";
    case SchemaType.ManagementConfigType:
      return "ManagementConfigType";
    case SchemaType.ServiceInstanceHealthType:
      return "ServiceInstanceHealthType";
    case SchemaType.FabricAssignmentStatusType:
      return "FabricAssignmentStatusType";
    case SchemaType.KubernetesServiceInstanceType:
      return "KubernetesServiceInstanceType";
    case SchemaType.BearerTokenType:
      return "BearerTokenType";
    case SchemaType.ApiKeyType:
      return "ApiKeyType";
    case SchemaType.FabricMemoryUsageType:
      return "FabricMemoryUsageType";
    case SchemaType.TypeMemoryUsageType:
      return "TypeMemoryUsageType";
    case SchemaType.ManifestRunningImageStatusType:
      return "ManifestRunningImageStatusType";
    case SchemaType.ManifestPartitionFileCacheStatusType:
      return "ManifestPartitionFileCacheStatusType";
    case SchemaType.StpPortStateType:
      return "StpPortStateType";
    case SchemaType.StpVlanStateType:
      return "StpVlanStateType";
    case SchemaType.StpVlanPortStateType:
      return "StpVlanPortStateType";
    case SchemaType.AssertDeviceEnvPsuUsageBelowThresholdType:
      return "AssertDeviceEnvPsuUsageBelowThresholdType";
    case SchemaType.AssertDeviceEnvFanUsageBelowThresholdType:
      return "AssertDeviceEnvFanUsageBelowThresholdType";
    case SchemaType.AssertDeviceEnvTempSensorUsageBelowThresholdType:
      return "AssertDeviceEnvTempSensorUsageBelowThresholdType";
    case SchemaType.AssertConfigDeviceEnvPsuUsageBelowThresholdType:
      return "AssertConfigDeviceEnvPsuUsageBelowThresholdType";
    case SchemaType.AssertConfigDeviceEnvFanUsageBelowThresholdType:
      return "AssertConfigDeviceEnvFanUsageBelowThresholdType";
    case SchemaType.AssertConfigDeviceEnvTempSensorUsageBelowThresholdType:
      return "AssertConfigDeviceEnvTempSensorUsageBelowThresholdType";
    case SchemaType.AllSmartHandsTypes:
      return "AllSmartHandsTypes";
    case SchemaType.UnpackAndAssembleTaskType:
      return "UnpackAndAssembleTaskType";
    case SchemaType.RackAndPowerOnTaskType:
      return "RackAndPowerOnTaskType";
    case SchemaType.ClaimAndBindTaskType:
      return "ClaimAndBindTaskType";
    case SchemaType.PortSetupTaskType:
      return "PortSetupTaskType";
    case SchemaType.FabricConnectionTaskType:
      return "FabricConnectionTaskType";
    case SchemaType.NegativeConnectionTaskType:
      return "NegativeConnectionTaskType";
    case SchemaType.ApplicationType:
      return "ApplicationType";
    case SchemaType.ResourceAssignmentType:
      return "ResourceAssignmentType";
    case SchemaType.NodeType:
      return "NodeType";
    case SchemaType.NodeLineCardType:
      return "NodeLineCardType";
    case SchemaType.NodePortType:
      return "NodePortType";
    case SchemaType.NodeBreakoutType:
      return "NodeBreakoutType";
    case SchemaType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SchemaTypeOptions {
  /** This required field assigns the schema node type of the message. */
  type: SchemaType;
  /** This optional field assigns the parent type of the message. */
  parent?:
    | SchemaType
    | undefined;
  /** This optional field assigns a non-default arena size for threadlist objects of this type. */
  arenaSize?:
    | number
    | undefined;
  /** This optional field indicates that we have made an exception for this type and allow it to contain pointers. */
  containsPointers?: boolean | undefined;
  assert?:
    | SchemaAssertType
    | undefined;
  /** Types marked as smarthands are the only types that can be read by a smarthands client - that client is not logged in so we need to lock down access. */
  smarthands?: boolean | undefined;
}

/** A (type, index) tuple, part of a threadlist object's path. */
export interface TypeIndex {
  type: SchemaType;
  index: number;
  indexStr: string;
}

function createBaseSchemaTypeOptions(): SchemaTypeOptions {
  return {
    type: 0,
    parent: undefined,
    arenaSize: undefined,
    containsPointers: undefined,
    assert: undefined,
    smarthands: undefined,
  };
}

export const SchemaTypeOptions = {
  encode(message: SchemaTypeOptions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.parent !== undefined) {
      writer.uint32(16).int32(message.parent);
    }
    if (message.arenaSize !== undefined) {
      writer.uint32(24).uint32(message.arenaSize);
    }
    if (message.containsPointers !== undefined) {
      writer.uint32(32).bool(message.containsPointers);
    }
    if (message.assert !== undefined) {
      writer.uint32(40).int32(message.assert);
    }
    if (message.smarthands !== undefined) {
      writer.uint32(48).bool(message.smarthands);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SchemaTypeOptions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSchemaTypeOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.parent = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.arenaSize = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.containsPointers = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.assert = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.smarthands = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SchemaTypeOptions {
    return {
      type: isSet(object.type) ? schemaTypeFromJSON(object.type) : 0,
      parent: isSet(object.parent) ? schemaTypeFromJSON(object.parent) : undefined,
      arenaSize: isSet(object.arenaSize) ? globalThis.Number(object.arenaSize) : undefined,
      containsPointers: isSet(object.containsPointers) ? globalThis.Boolean(object.containsPointers) : undefined,
      assert: isSet(object.assert) ? schemaAssertTypeFromJSON(object.assert) : undefined,
      smarthands: isSet(object.smarthands) ? globalThis.Boolean(object.smarthands) : undefined,
    };
  },

  toJSON(message: SchemaTypeOptions): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = schemaTypeToJSON(message.type);
    }
    if (message.parent !== undefined) {
      obj.parent = schemaTypeToJSON(message.parent);
    }
    if (message.arenaSize !== undefined) {
      obj.arenaSize = Math.round(message.arenaSize);
    }
    if (message.containsPointers !== undefined) {
      obj.containsPointers = message.containsPointers;
    }
    if (message.assert !== undefined) {
      obj.assert = schemaAssertTypeToJSON(message.assert);
    }
    if (message.smarthands !== undefined) {
      obj.smarthands = message.smarthands;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SchemaTypeOptions>, I>>(base?: I): SchemaTypeOptions {
    return SchemaTypeOptions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SchemaTypeOptions>, I>>(object: I): SchemaTypeOptions {
    const message = createBaseSchemaTypeOptions();
    message.type = object.type ?? 0;
    message.parent = object.parent ?? undefined;
    message.arenaSize = object.arenaSize ?? undefined;
    message.containsPointers = object.containsPointers ?? undefined;
    message.assert = object.assert ?? undefined;
    message.smarthands = object.smarthands ?? undefined;
    return message;
  },
};

function createBaseTypeIndex(): TypeIndex {
  return { type: 0, index: 0, indexStr: "" };
}

export const TypeIndex = {
  encode(message: TypeIndex, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.index !== 0) {
      writer.uint32(24).uint32(message.index);
    }
    if (message.indexStr !== "") {
      writer.uint32(34).string(message.indexStr);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TypeIndex {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTypeIndex();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.index = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.indexStr = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TypeIndex {
    return {
      type: isSet(object.type) ? schemaTypeFromJSON(object.type) : 0,
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      indexStr: isSet(object.indexStr) ? globalThis.String(object.indexStr) : "",
    };
  },

  toJSON(message: TypeIndex): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = schemaTypeToJSON(message.type);
    }
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.indexStr !== "") {
      obj.indexStr = message.indexStr;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TypeIndex>, I>>(base?: I): TypeIndex {
    return TypeIndex.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TypeIndex>, I>>(object: I): TypeIndex {
    const message = createBaseTypeIndex();
    message.type = object.type ?? 0;
    message.index = object.index ?? 0;
    message.indexStr = object.indexStr ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
