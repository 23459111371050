// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/onprem.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { WorkflowFeedback } from "../onprem/onprem";
import { ChassisInventory, MgmtConfig } from "../statsd/statsd";

export const protobufPackage = "configd";

/** SetDeviceInventoryRequest is used by Lira to upload LLDP data to ConfigD. */
export interface SetDeviceInventoryRequest {
  inventories: ChassisInventory[];
}

export interface SetDeviceInventoryResponse {
}

/** SetMgmtConfigRequest is to upload mgmt interface config from lira to ConfigD */
export interface SetManagementConfigRequest {
  mgmtConfig: MgmtConfig[];
}

export interface SetMgmtConfigResponse {
}

/**
 * AgentConfigRequest encapsulates an RSA public key, and a configFp. ConfigFp
 * should be the fingerprint of current config that agent knows of.
 * NOTE:
 *    Caller must provide either FabricId or DeviceId.
 */
export interface AgentConfigRequest {
  /** RSA public key. */
  publicKey: Uint8Array;
  /** The fingerprint of current config. */
  configFp: string;
  /** Optional identifier of fabric. */
  fabricId: string;
  /** The identifier of device. */
  deviceId: string;
  /** The workflow engine version. */
  workflowVersion: string;
  /** The operating system version. */
  osVersion: string;
}

/**
 * AgentConfigResponse encapsulates an encrypted AES-256 key, and encrypted config.
 * Encrypted config maybe either FabricConfig or DeviceConfig. On-premises agent must
 * upload an RSA public key during config query, and ConfigD uses the public key
 * to encrypt a AES-256 key, and encrypts FabricConfig/DeviceConfig using AES-256.
 *
 * Agent:
 *    - Uploads RSA public key.
 * ConfigD:
 *    - Generates a symmetric encryption key.
 *    - Encrypts config (FabricConfig, for example) using AES-256.
 *    - Encrypts symmetric key (AES-256 key) using RSA public key.
 */
export interface AgentConfigResponse {
  /** Indicates that there are no changes in config. */
  noUpdate: boolean;
  /** Agent must send a new RSA public key. */
  needKey: boolean;
  /** Symmetric encryption key (AES-256 key). */
  aesKey: Uint8Array;
  /** Encrypted FabricConfig object. */
  config: Uint8Array;
}

/**
 * SetWorkflowFeedbacksRequest encapsulates a set of workflow feedback
 * messages uploaded by Azimuth.
 */
export interface SetWorkflowFeedbacksRequest {
  /** A set of workflow feedbacks. */
  feedbacks: WorkflowFeedback[];
  /** The size of compressed data. */
  size: number;
  /** Serialized and compressed SetWorkflowFeedbacksRequest object. */
  data: Uint8Array;
}

/** SetWorkflowFeedbacksResponse encapsulates response to feedback post. */
export interface SetWorkflowFeedbacksResponse {
  /** Number of messages saved. */
  count: number;
}

/** SetAgentPlatformInfoRequest encapsulates platform info. */
export interface SetAgentPlatformInfoRequest {
  /** The device identifier. */
  deviceId: string;
  /** Service's version. */
  serviceVersion: string;
  /** Workflow engine version. */
  workflowVersion: string;
  /** SONiC platform version info. */
  platformInfo: string;
  /** SONiC agent version, */
  imageVersion: string;
  /** SONiC agent image SHA256. */
  imageSha256: string;
  /** H/W platform architecture. */
  arch: string;
}

export interface SetAgentPlatformInfoResponse {
}

/** GetImageManifestRequest encapsulates request to query the image manifest for a device */
export interface GetImageManifestRequest {
  /** The type of device */
  deviceType: string;
  /** The device identifier. */
  deviceId: string;
  /** H/W architecture (amd64 or arm64). */
  arch: string;
}

/** GetImageManifestResponse contains the signatures and the binary */
export interface GetImageManifestResponse {
  /** contains at least on valid signature of manifest_body */
  signatures: string[];
  /** DER encoded certificate that was used to sign the body bytes */
  certificate: string;
  /** signature algorithm */
  algorithm: string;
  /** binary byte array containing a marshaled GetImageManifestResponseBody */
  body: Uint8Array;
}

function createBaseSetDeviceInventoryRequest(): SetDeviceInventoryRequest {
  return { inventories: [] };
}

export const SetDeviceInventoryRequest = {
  encode(message: SetDeviceInventoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.inventories) {
      ChassisInventory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetDeviceInventoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDeviceInventoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.inventories.push(ChassisInventory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetDeviceInventoryRequest {
    return {
      inventories: globalThis.Array.isArray(object?.inventories)
        ? object.inventories.map((e: any) => ChassisInventory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetDeviceInventoryRequest): unknown {
    const obj: any = {};
    if (message.inventories?.length) {
      obj.inventories = message.inventories.map((e) => ChassisInventory.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDeviceInventoryRequest>, I>>(base?: I): SetDeviceInventoryRequest {
    return SetDeviceInventoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetDeviceInventoryRequest>, I>>(object: I): SetDeviceInventoryRequest {
    const message = createBaseSetDeviceInventoryRequest();
    message.inventories = object.inventories?.map((e) => ChassisInventory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetDeviceInventoryResponse(): SetDeviceInventoryResponse {
  return {};
}

export const SetDeviceInventoryResponse = {
  encode(_: SetDeviceInventoryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetDeviceInventoryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDeviceInventoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetDeviceInventoryResponse {
    return {};
  },

  toJSON(_: SetDeviceInventoryResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDeviceInventoryResponse>, I>>(base?: I): SetDeviceInventoryResponse {
    return SetDeviceInventoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetDeviceInventoryResponse>, I>>(_: I): SetDeviceInventoryResponse {
    const message = createBaseSetDeviceInventoryResponse();
    return message;
  },
};

function createBaseSetManagementConfigRequest(): SetManagementConfigRequest {
  return { mgmtConfig: [] };
}

export const SetManagementConfigRequest = {
  encode(message: SetManagementConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.mgmtConfig) {
      MgmtConfig.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetManagementConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetManagementConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mgmtConfig.push(MgmtConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetManagementConfigRequest {
    return {
      mgmtConfig: globalThis.Array.isArray(object?.mgmtConfig)
        ? object.mgmtConfig.map((e: any) => MgmtConfig.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetManagementConfigRequest): unknown {
    const obj: any = {};
    if (message.mgmtConfig?.length) {
      obj.mgmtConfig = message.mgmtConfig.map((e) => MgmtConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetManagementConfigRequest>, I>>(base?: I): SetManagementConfigRequest {
    return SetManagementConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetManagementConfigRequest>, I>>(object: I): SetManagementConfigRequest {
    const message = createBaseSetManagementConfigRequest();
    message.mgmtConfig = object.mgmtConfig?.map((e) => MgmtConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetMgmtConfigResponse(): SetMgmtConfigResponse {
  return {};
}

export const SetMgmtConfigResponse = {
  encode(_: SetMgmtConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetMgmtConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetMgmtConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetMgmtConfigResponse {
    return {};
  },

  toJSON(_: SetMgmtConfigResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetMgmtConfigResponse>, I>>(base?: I): SetMgmtConfigResponse {
    return SetMgmtConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetMgmtConfigResponse>, I>>(_: I): SetMgmtConfigResponse {
    const message = createBaseSetMgmtConfigResponse();
    return message;
  },
};

function createBaseAgentConfigRequest(): AgentConfigRequest {
  return { publicKey: new Uint8Array(0), configFp: "", fabricId: "", deviceId: "", workflowVersion: "", osVersion: "" };
}

export const AgentConfigRequest = {
  encode(message: AgentConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publicKey.length !== 0) {
      writer.uint32(10).bytes(message.publicKey);
    }
    if (message.configFp !== "") {
      writer.uint32(18).string(message.configFp);
    }
    if (message.fabricId !== "") {
      writer.uint32(26).string(message.fabricId);
    }
    if (message.deviceId !== "") {
      writer.uint32(34).string(message.deviceId);
    }
    if (message.workflowVersion !== "") {
      writer.uint32(42).string(message.workflowVersion);
    }
    if (message.osVersion !== "") {
      writer.uint32(50).string(message.osVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AgentConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgentConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publicKey = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.configFp = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.workflowVersion = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.osVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AgentConfigRequest {
    return {
      publicKey: isSet(object.publicKey) ? bytesFromBase64(object.publicKey) : new Uint8Array(0),
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      workflowVersion: isSet(object.workflowVersion) ? globalThis.String(object.workflowVersion) : "",
      osVersion: isSet(object.osVersion) ? globalThis.String(object.osVersion) : "",
    };
  },

  toJSON(message: AgentConfigRequest): unknown {
    const obj: any = {};
    if (message.publicKey.length !== 0) {
      obj.publicKey = base64FromBytes(message.publicKey);
    }
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.workflowVersion !== "") {
      obj.workflowVersion = message.workflowVersion;
    }
    if (message.osVersion !== "") {
      obj.osVersion = message.osVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentConfigRequest>, I>>(base?: I): AgentConfigRequest {
    return AgentConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AgentConfigRequest>, I>>(object: I): AgentConfigRequest {
    const message = createBaseAgentConfigRequest();
    message.publicKey = object.publicKey ?? new Uint8Array(0);
    message.configFp = object.configFp ?? "";
    message.fabricId = object.fabricId ?? "";
    message.deviceId = object.deviceId ?? "";
    message.workflowVersion = object.workflowVersion ?? "";
    message.osVersion = object.osVersion ?? "";
    return message;
  },
};

function createBaseAgentConfigResponse(): AgentConfigResponse {
  return { noUpdate: false, needKey: false, aesKey: new Uint8Array(0), config: new Uint8Array(0) };
}

export const AgentConfigResponse = {
  encode(message: AgentConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.noUpdate !== false) {
      writer.uint32(8).bool(message.noUpdate);
    }
    if (message.needKey !== false) {
      writer.uint32(16).bool(message.needKey);
    }
    if (message.aesKey.length !== 0) {
      writer.uint32(42).bytes(message.aesKey);
    }
    if (message.config.length !== 0) {
      writer.uint32(50).bytes(message.config);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AgentConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAgentConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.noUpdate = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.needKey = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.aesKey = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AgentConfigResponse {
    return {
      noUpdate: isSet(object.noUpdate) ? globalThis.Boolean(object.noUpdate) : false,
      needKey: isSet(object.needKey) ? globalThis.Boolean(object.needKey) : false,
      aesKey: isSet(object.aesKey) ? bytesFromBase64(object.aesKey) : new Uint8Array(0),
      config: isSet(object.config) ? bytesFromBase64(object.config) : new Uint8Array(0),
    };
  },

  toJSON(message: AgentConfigResponse): unknown {
    const obj: any = {};
    if (message.noUpdate !== false) {
      obj.noUpdate = message.noUpdate;
    }
    if (message.needKey !== false) {
      obj.needKey = message.needKey;
    }
    if (message.aesKey.length !== 0) {
      obj.aesKey = base64FromBytes(message.aesKey);
    }
    if (message.config.length !== 0) {
      obj.config = base64FromBytes(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AgentConfigResponse>, I>>(base?: I): AgentConfigResponse {
    return AgentConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AgentConfigResponse>, I>>(object: I): AgentConfigResponse {
    const message = createBaseAgentConfigResponse();
    message.noUpdate = object.noUpdate ?? false;
    message.needKey = object.needKey ?? false;
    message.aesKey = object.aesKey ?? new Uint8Array(0);
    message.config = object.config ?? new Uint8Array(0);
    return message;
  },
};

function createBaseSetWorkflowFeedbacksRequest(): SetWorkflowFeedbacksRequest {
  return { feedbacks: [], size: 0, data: new Uint8Array(0) };
}

export const SetWorkflowFeedbacksRequest = {
  encode(message: SetWorkflowFeedbacksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.feedbacks) {
      WorkflowFeedback.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.size !== 0) {
      writer.uint32(16).uint32(message.size);
    }
    if (message.data.length !== 0) {
      writer.uint32(26).bytes(message.data);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetWorkflowFeedbacksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetWorkflowFeedbacksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.feedbacks.push(WorkflowFeedback.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.size = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetWorkflowFeedbacksRequest {
    return {
      feedbacks: globalThis.Array.isArray(object?.feedbacks)
        ? object.feedbacks.map((e: any) => WorkflowFeedback.fromJSON(e))
        : [],
      size: isSet(object.size) ? globalThis.Number(object.size) : 0,
      data: isSet(object.data) ? bytesFromBase64(object.data) : new Uint8Array(0),
    };
  },

  toJSON(message: SetWorkflowFeedbacksRequest): unknown {
    const obj: any = {};
    if (message.feedbacks?.length) {
      obj.feedbacks = message.feedbacks.map((e) => WorkflowFeedback.toJSON(e));
    }
    if (message.size !== 0) {
      obj.size = Math.round(message.size);
    }
    if (message.data.length !== 0) {
      obj.data = base64FromBytes(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetWorkflowFeedbacksRequest>, I>>(base?: I): SetWorkflowFeedbacksRequest {
    return SetWorkflowFeedbacksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetWorkflowFeedbacksRequest>, I>>(object: I): SetWorkflowFeedbacksRequest {
    const message = createBaseSetWorkflowFeedbacksRequest();
    message.feedbacks = object.feedbacks?.map((e) => WorkflowFeedback.fromPartial(e)) || [];
    message.size = object.size ?? 0;
    message.data = object.data ?? new Uint8Array(0);
    return message;
  },
};

function createBaseSetWorkflowFeedbacksResponse(): SetWorkflowFeedbacksResponse {
  return { count: 0 };
}

export const SetWorkflowFeedbacksResponse = {
  encode(message: SetWorkflowFeedbacksResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.count !== 0) {
      writer.uint32(16).uint32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetWorkflowFeedbacksResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetWorkflowFeedbacksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetWorkflowFeedbacksResponse {
    return { count: isSet(object.count) ? globalThis.Number(object.count) : 0 };
  },

  toJSON(message: SetWorkflowFeedbacksResponse): unknown {
    const obj: any = {};
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetWorkflowFeedbacksResponse>, I>>(base?: I): SetWorkflowFeedbacksResponse {
    return SetWorkflowFeedbacksResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetWorkflowFeedbacksResponse>, I>>(object: I): SetWorkflowFeedbacksResponse {
    const message = createBaseSetWorkflowFeedbacksResponse();
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseSetAgentPlatformInfoRequest(): SetAgentPlatformInfoRequest {
  return {
    deviceId: "",
    serviceVersion: "",
    workflowVersion: "",
    platformInfo: "",
    imageVersion: "",
    imageSha256: "",
    arch: "",
  };
}

export const SetAgentPlatformInfoRequest = {
  encode(message: SetAgentPlatformInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.serviceVersion !== "") {
      writer.uint32(18).string(message.serviceVersion);
    }
    if (message.workflowVersion !== "") {
      writer.uint32(26).string(message.workflowVersion);
    }
    if (message.platformInfo !== "") {
      writer.uint32(34).string(message.platformInfo);
    }
    if (message.imageVersion !== "") {
      writer.uint32(42).string(message.imageVersion);
    }
    if (message.imageSha256 !== "") {
      writer.uint32(50).string(message.imageSha256);
    }
    if (message.arch !== "") {
      writer.uint32(58).string(message.arch);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetAgentPlatformInfoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAgentPlatformInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serviceVersion = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.workflowVersion = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.platformInfo = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imageVersion = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.imageSha256 = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.arch = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetAgentPlatformInfoRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      serviceVersion: isSet(object.serviceVersion) ? globalThis.String(object.serviceVersion) : "",
      workflowVersion: isSet(object.workflowVersion) ? globalThis.String(object.workflowVersion) : "",
      platformInfo: isSet(object.platformInfo) ? globalThis.String(object.platformInfo) : "",
      imageVersion: isSet(object.imageVersion) ? globalThis.String(object.imageVersion) : "",
      imageSha256: isSet(object.imageSha256) ? globalThis.String(object.imageSha256) : "",
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
    };
  },

  toJSON(message: SetAgentPlatformInfoRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.serviceVersion !== "") {
      obj.serviceVersion = message.serviceVersion;
    }
    if (message.workflowVersion !== "") {
      obj.workflowVersion = message.workflowVersion;
    }
    if (message.platformInfo !== "") {
      obj.platformInfo = message.platformInfo;
    }
    if (message.imageVersion !== "") {
      obj.imageVersion = message.imageVersion;
    }
    if (message.imageSha256 !== "") {
      obj.imageSha256 = message.imageSha256;
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetAgentPlatformInfoRequest>, I>>(base?: I): SetAgentPlatformInfoRequest {
    return SetAgentPlatformInfoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetAgentPlatformInfoRequest>, I>>(object: I): SetAgentPlatformInfoRequest {
    const message = createBaseSetAgentPlatformInfoRequest();
    message.deviceId = object.deviceId ?? "";
    message.serviceVersion = object.serviceVersion ?? "";
    message.workflowVersion = object.workflowVersion ?? "";
    message.platformInfo = object.platformInfo ?? "";
    message.imageVersion = object.imageVersion ?? "";
    message.imageSha256 = object.imageSha256 ?? "";
    message.arch = object.arch ?? "";
    return message;
  },
};

function createBaseSetAgentPlatformInfoResponse(): SetAgentPlatformInfoResponse {
  return {};
}

export const SetAgentPlatformInfoResponse = {
  encode(_: SetAgentPlatformInfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetAgentPlatformInfoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAgentPlatformInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetAgentPlatformInfoResponse {
    return {};
  },

  toJSON(_: SetAgentPlatformInfoResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetAgentPlatformInfoResponse>, I>>(base?: I): SetAgentPlatformInfoResponse {
    return SetAgentPlatformInfoResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetAgentPlatformInfoResponse>, I>>(_: I): SetAgentPlatformInfoResponse {
    const message = createBaseSetAgentPlatformInfoResponse();
    return message;
  },
};

function createBaseGetImageManifestRequest(): GetImageManifestRequest {
  return { deviceType: "", deviceId: "", arch: "" };
}

export const GetImageManifestRequest = {
  encode(message: GetImageManifestRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceType !== "") {
      writer.uint32(10).string(message.deviceType);
    }
    if (message.deviceId !== "") {
      writer.uint32(18).string(message.deviceId);
    }
    if (message.arch !== "") {
      writer.uint32(26).string(message.arch);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImageManifestRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImageManifestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceType = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.arch = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImageManifestRequest {
    return {
      deviceType: isSet(object.deviceType) ? globalThis.String(object.deviceType) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      arch: isSet(object.arch) ? globalThis.String(object.arch) : "",
    };
  },

  toJSON(message: GetImageManifestRequest): unknown {
    const obj: any = {};
    if (message.deviceType !== "") {
      obj.deviceType = message.deviceType;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.arch !== "") {
      obj.arch = message.arch;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImageManifestRequest>, I>>(base?: I): GetImageManifestRequest {
    return GetImageManifestRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImageManifestRequest>, I>>(object: I): GetImageManifestRequest {
    const message = createBaseGetImageManifestRequest();
    message.deviceType = object.deviceType ?? "";
    message.deviceId = object.deviceId ?? "";
    message.arch = object.arch ?? "";
    return message;
  },
};

function createBaseGetImageManifestResponse(): GetImageManifestResponse {
  return { signatures: [], certificate: "", algorithm: "", body: new Uint8Array(0) };
}

export const GetImageManifestResponse = {
  encode(message: GetImageManifestResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.signatures) {
      writer.uint32(10).string(v!);
    }
    if (message.certificate !== "") {
      writer.uint32(18).string(message.certificate);
    }
    if (message.algorithm !== "") {
      writer.uint32(26).string(message.algorithm);
    }
    if (message.body.length !== 0) {
      writer.uint32(34).bytes(message.body);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetImageManifestResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetImageManifestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.signatures.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.certificate = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.algorithm = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.body = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetImageManifestResponse {
    return {
      signatures: globalThis.Array.isArray(object?.signatures)
        ? object.signatures.map((e: any) => globalThis.String(e))
        : [],
      certificate: isSet(object.certificate) ? globalThis.String(object.certificate) : "",
      algorithm: isSet(object.algorithm) ? globalThis.String(object.algorithm) : "",
      body: isSet(object.body) ? bytesFromBase64(object.body) : new Uint8Array(0),
    };
  },

  toJSON(message: GetImageManifestResponse): unknown {
    const obj: any = {};
    if (message.signatures?.length) {
      obj.signatures = message.signatures;
    }
    if (message.certificate !== "") {
      obj.certificate = message.certificate;
    }
    if (message.algorithm !== "") {
      obj.algorithm = message.algorithm;
    }
    if (message.body.length !== 0) {
      obj.body = base64FromBytes(message.body);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetImageManifestResponse>, I>>(base?: I): GetImageManifestResponse {
    return GetImageManifestResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetImageManifestResponse>, I>>(object: I): GetImageManifestResponse {
    const message = createBaseGetImageManifestResponse();
    message.signatures = object.signatures?.map((e) => e) || [];
    message.certificate = object.certificate ?? "";
    message.algorithm = object.algorithm ?? "";
    message.body = object.body ?? new Uint8Array(0);
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
