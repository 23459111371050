import { BrigClientImpl } from "src/gen/schema/brig/bservice";
import {
  DeleteUsersRequest,
  GetUserSessionRequest,
  GetUserSessionResponse,
  GetUsersRequest,
  NewOrgRequest,
  NewOrgResponse,
  RefreshUserSessionRequest,
  SetUsersRequest,
  StartUserSessionResponse,
  UsersResponse
} from "src/gen/schema/brig/bapi";
import {
  BearerToken,
  BearerTokensResponse,
  CreateBearerTokenRequest,
  DeleteBearerTokenRequest,
  GetBearerTokensRequest
} from "src/gen/schema/brig/btokens";
import { Empty } from "src/gen/schema/common/empty";
import { GrpcDebugableWebImpl } from "src/utils/api/grpc/grpcDebugableWebImpl";

const rpc = new GrpcDebugableWebImpl("", {
  debug: false // change later
});

const brigClient = new BrigClientImpl(rpc);

export const Brig = {
  getUserSession: (
    req: GetUserSessionRequest = GetUserSessionRequest.create()
  ): Promise<GetUserSessionResponse> => {
    return brigClient.GetUserSession(req);
  },
  getUsers: (
    req: GetUsersRequest = GetUsersRequest.create()
  ): Promise<UsersResponse> => {
    return brigClient.GetUsers(req);
  },
  setUsers: (req: SetUsersRequest): Promise<UsersResponse> => {
    return brigClient.SetUsers(req);
  },
  newOrg: (
    req: NewOrgRequest = NewOrgRequest.create()
  ): Promise<NewOrgResponse> => {
    return brigClient.NewOrg(req);
  },
  deleteUsers: (req: DeleteUsersRequest): Promise<UsersResponse> => {
    return brigClient.DeleteUsers(req);
  },
  refreshUserSession: (
    req: RefreshUserSessionRequest = RefreshUserSessionRequest.create()
  ): Promise<StartUserSessionResponse> => {
    return brigClient.RefreshUserSession(req);
  },
  getBearerTokens: (
    req: GetBearerTokensRequest = GetBearerTokensRequest.create()
  ): Promise<BearerTokensResponse> => {
    return brigClient.GetBearerTokens(req);
  },
  createBearerToken: (req: CreateBearerTokenRequest): Promise<BearerToken> => {
    return brigClient.CreateBearerToken(req);
  },
  deleteBearerToken: (req: DeleteBearerTokenRequest): Promise<Empty> => {
    return brigClient.DeleteBearerToken(req);
  }
};
