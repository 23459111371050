import { Table, TableProps } from "@magnetic/table";
import { useMemo } from "react";
import {
  BaseColumnConfig,
  ColumnConfig
} from "./tables/columnConfigs/baseColumnConfig";
import { Results } from "./results";
import VFlex from "./vFlex";
import { EmptyContainer } from "./emptyContainer";
import { Container } from "@magnetic/container";

export type ExtractType<Type> = Type extends BaseColumnConfig<infer X>
  ? X
  : undefined;

type Props<Column> = {
  readonly name: string;
  readonly columns: {
    [k in keyof Column]: Column[k];
  };
  readonly data?: TableData<Column>[];
  readonly displayResultCount?: boolean;
} & Omit<TableProps<unknown>, "columns" | "data">;
export type TableData<T> = { [k in keyof T]: ExtractType<T[k]> | undefined };

export type Column<V extends BaseColumnConfig<VT>, VT> = {
  [k in string]: V;
};

export default function TypedTable<V extends BaseColumnConfig<VT>, VT>(
  props: Props<Column<V, VT>>
) {
  const {
    columns,
    data = [],
    stickyHeader = true,
    displayResultCount,
    ...rest
  } = props;
  // create meraki columns
  const columnModel = useMemo(() => {
    // unpack the object literal
    return Object.entries(columns).map((field) => {
      const fieldName = field[0];
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const dataType = field[1] as ColumnConfig<any>;
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const colConfig: any = {
        header: dataType.getConfig().label,
        accessorKey: fieldName,
        sortable: dataType.getConfig().sortable,
        size: dataType.getConfig().size,
        // TODO fix types
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        sortingFn: (rowA: any, rowB: any, columnId: string) => {
          return dataType?.compare?.(
            rowA.getValue(columnId),
            rowB.getValue(columnId)
          );
        },
        meta: {
          align: "left"
        },
        hidden: dataType.getConfig().hidden,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        cell: (value: any) => {
          return (
            <div
              key={value.cell.getValue()?.toString()}
              className="flashOnUpdate" // test if this works, maybe make this optional, not sure yet
            >
              {(dataType.render && dataType.render(value.cell.getValue())) ||
                value.cell.getValue()}
            </div>
          );
        }
      };

      // if (col.styleLikeNetworking) {
      //   colConfig.meta.dataType = "networking";
      // }

      return colConfig;
    });
  }, [columns]);
  return (
    <VFlex gap="none" id={`${props.name}_result_count`} data-qa={props.name}>
      {displayResultCount && <Results size={data.length} />}
      <VFlex id={props.name}>
        {data.length === 0 ? (
          <EmptyContainer text={`No Data found`} />
        ) : (
          <Container>
            <Table
              {...rest}
              columns={columnModel.filter((c) => !c.hidden)}
              data={data}
              stickyHeader={stickyHeader}
              size="compact"
            />
          </Container>
        )}
      </VFlex>
    </VFlex>
  );
}
