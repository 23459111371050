import { Observable } from "./observable";
import { logger } from "src/core/globals";

type EVENT = "reconnect" | "connect" | "open" | "message" | "error" | "close";
const HEART_BEAT = 30000;
const MAX_COOLDOWN = 10000;
const KEEP_ALIVE_MSG = "Keep Alive";
const KEEP_ALIVE_TYPE = "echoResponse";

const isKeepAlive = (msg: string): boolean => {
  return msg.includes(KEEP_ALIVE_TYPE) && msg.includes(KEEP_ALIVE_MSG);
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export class Socket extends Observable<any> {
  static RECONNECT: EVENT = "reconnect";
  static CONNECT: EVENT = "connect";
  static OPEN: EVENT = "open";
  static MESSAGE: EVENT = "message";
  static ERROR: EVENT = "error";
  static CLOSE: EVENT = "close";

  private keepAliveTimerId: NodeJS.Timeout | null = null;
  private pingTimeoutId: NodeJS.Timeout | null = null;

  public reconnect: boolean = true;
  public connected: boolean = false;
  /* Private instance variables */
  private socket: WebSocket | undefined;
  /**
   * queue will buffer messages if the
   * websocket is not connected. These
   * messages will be sent when the
   * websocket is connected.
   */
  private queue: string[] = [];
  private cooldown = 500;

  constructor() {
    super();
    this.addEvents([Socket.ERROR, Socket.OPEN, Socket.MESSAGE, Socket.CLOSE]);
  }

  /**
   * The init method initializes the WebSocket connection.
   * It checks the current state of the WebSocket and, if
   * it is not already connecting or open, creates a new
   * WebSocket instance
   */
  init(url: string) {
    try {
      const readyState = this.socket?.readyState;
      switch (readyState) {
        /**
         * There exists a race condition
         * where if we try to send a flurry
         * of requests the socket will
         * open again and again while it
         * is connecting. If we are already
         * connecting just return and pass the
         * request. it will be queued and sent
         * when the socket is open.
         *
         * Also, a small chance it
         * opened while this was sent.
         */
        case WebSocket.CONNECTING:
        case WebSocket.OPEN:
          return;
        default: {
          const ws = new WebSocket(url);
          /**
           * Event handlers are set up for the WebSocket
           * to handle onopen, onmessage, onerror, and
           * onclose events.
           * For example, the onopen event
           * handler sets the connected flag to true, sends
           * any queued messages, and starts the keep-alive
           * mechanism
           */
          ws.onopen = () => {
            this.connected = true;
            this.fireEvent(Socket.OPEN, true);
            this.queue.forEach((msg: string) => {
              if (msg) {
                this.send(msg);
              }
            });
            /* once the messages have been sent reset the queue */
            this.queue = [];
            this.cooldown = 500;
            this.keepAlive();
          };
          ws.onmessage = (m: MessageEvent<string>) => {
            /**
             * If we get any message on the socket
             * after we sent the ping we can clear
             * the ping timeout. This is just to
             * be sure if we send a ping and get
             * no response we close the socket
             * and reconnect.
             */
            if (this.pingTimeoutId) {
              clearTimeout(this.pingTimeoutId);
            }
            if (!isKeepAlive(m.data)) {
              this.fireEvent(Socket.MESSAGE, m);
            }
          };
          ws.onerror = (e: Event) => {
            this.fireEvent(Socket.ERROR, e);
            logger.error(e);
          };
          ws.onclose = (e: CloseEvent) => {
            if (this.reconnect) {
              /**
               * What is the behavior if
               * we get disconnected? do
               * we have to rebuild the
               * subscriptions or are they
               * kept with the session?
               */
              setTimeout(() => {
                this.init(url);
                this.fireEvent(Socket.RECONNECT, true);
                /**
                 * Each time you try to reconnect double
                 * the cooldown interval up to 10 seconds,
                 * so we do not spam the server if you
                 * cannot connect.
                 */
                this.cooldown = Math.min(this.cooldown * 2, MAX_COOLDOWN);
              }, this.cooldown);
            }
            this.connected = false;
            this.queue.length = 0;
            this.fireEvent(Socket.CLOSE, e);
          };

          this.socket = ws;
        }
      }
    } catch (e: unknown) {
      logger.error(e);
    }
  }

  close() {
    /* clean up the keep alive interval */
    if (this.keepAliveTimerId) {
      clearInterval(this.keepAliveTimerId);
    }
    /* clean up the ping timeout */
    if (this.pingTimeoutId) {
      clearTimeout(this.pingTimeoutId);
    }
    this.reconnect = false;
    this.socket?.close();
  }

  /**
   * Keeps the WebSocket connection alive by sending periodic ping messages.
   *
   * This method sets up an interval that sends a ping message
   * to the WebSocket server every 30 seconds. The interval is
   * stored in `this.keepAliveTimerId` so it can be cleared
   * later if needed.
   */
  keepAlive() {
    const socket = this.socket;
    if (this.keepAliveTimerId) {
      clearInterval(this.keepAliveTimerId);
    }
    this.keepAliveTimerId = setInterval(() => {
      if (socket) {
        const requestTs = new Date();
        const msg = JSON.stringify(
          {
            echoRequest: {
              numResponses: 1,
              message: KEEP_ALIVE_MSG,
              waitSecs: 0,
              requestTs
            }
          },
          null,
          "  "
        );
        socket.send(msg);
        /**
         * when we send a ping message, we
         * also set a timeout to close the
         * socket if we do not get a response
         * in 5 seconds.
         */
        if (this.pingTimeoutId) {
          clearTimeout(this.pingTimeoutId);
        }
        this.pingTimeoutId = setTimeout(() => {
          socket.close();
        }, 5000);
      }
    }, HEART_BEAT);
  }

  send(msg: string) {
    if (this.connected && this.socket?.readyState === WebSocket.OPEN) {
      try {
        this.socket?.send(msg);
        return;
      } catch (e: unknown) {
        // we do nothing with this.
      }
    }
    /**
     * Since there is a chance that a
     * component might send a message
     * before the socket is open, this
     * will add queue up those messages,
     * they will be sent when the
     * socket opens.
     */
    this.queue.push(msg);
  }
}
