// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: reader/assert_record_api.gen.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import {
  AssertCategoryType,
  assertCategoryTypeFromJSON,
  assertCategoryTypeToJSON,
  AssertStateType,
  assertStateTypeFromJSON,
  assertStateTypeToJSON,
} from "../models/assert";

export const protobufPackage = "reader";

/**
 * PROPRIETARY AND CONFIDENTIAL. Cisco Systems, Inc. considers the contents of this
 * file to be highly confidential trade secret information.
 *
 * COPYRIGHT 2024 Cisco Systems, Inc., All rights reserved.
 *
 * Code generated by protoc-gen-assert-types.  DO NOT EDIT.
 * This file is generated, but its contents are intentionally committed to source control to allow for easier
 * "bootstrapped" builds when building from a fresh checkout.
 */

export interface AssertDeviceConnectedToCloud {
  modifiedAt: Date | undefined;
  device: string;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  category: AssertCategoryType;
  assertState: AssertStateType;
  masked: boolean;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertDeviceConnectedToFabric {
  modifiedAt: Date | undefined;
  device: string;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  category: AssertCategoryType;
  assertState: AssertStateType;
  masked: boolean;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertManagementPortConfigSame {
  modifiedAt: Date | undefined;
  device: string;
  lineCard: number;
  managementState: string;
  masked: boolean;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  category: AssertCategoryType;
  assertState: AssertStateType;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertPortConnectionSpeedMatch {
  modifiedAt: Date | undefined;
  breakout: number;
  device: string;
  lineCard: number;
  port: string;
  latchTime: number;
  remoteDevice: string;
  localPortSpeed: number;
  assertState: AssertStateType;
  masked: boolean;
  latchedAt: Date | undefined;
  remotePortName: string;
  category: AssertCategoryType;
  portName: string;
  implicit: boolean;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertPortExpectedNeighbor {
  modifiedAt: Date | undefined;
  breakout: number;
  device: string;
  lineCard: number;
  port: string;
  masked: boolean;
  latchedAt: Date | undefined;
  numNeighbors: number;
  remotePortName: string;
  remoteDevice: string;
  category: AssertCategoryType;
  assertState: AssertStateType;
  portName: string;
  latchTime: number;
  implicit: boolean;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertPortLinkDown {
  modifiedAt: Date | undefined;
  breakout: number;
  device: string;
  lineCard: number;
  port: string;
  remotePortName: string;
  portName: string;
  masked: boolean;
  assertState: AssertStateType;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  remoteDevice: string;
  category: AssertCategoryType;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertPortLinkUp {
  modifiedAt: Date | undefined;
  breakout: number;
  device: string;
  lineCard: number;
  port: string;
  portName: string;
  masked: boolean;
  latchTime: number;
  category: AssertCategoryType;
  assertState: AssertStateType;
  latchedAt: Date | undefined;
  implicit: boolean;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertPortNotConnectedToFabric {
  modifiedAt: Date | undefined;
  breakout: number;
  device: string;
  lineCard: number;
  port: string;
  portName: string;
  masked: boolean;
  latchTime: number;
  remotePortName: string;
  remoteDevice: string;
  category: AssertCategoryType;
  assertState: AssertStateType;
  latchedAt: Date | undefined;
  implicit: boolean;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertPortSpeedConsistent {
  modifiedAt: Date | undefined;
  breakout: number;
  device: string;
  lineCard: number;
  port: string;
  masked: boolean;
  assertState: AssertStateType;
  portName: string;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  localPortSpeed: number;
  category: AssertCategoryType;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertStaticDefaultRouteExists {
  modifiedAt: Date | undefined;
  device: string;
  lineCard: number;
  vrf: string;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  category: AssertCategoryType;
  assertState: AssertStateType;
  masked: boolean;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertVlanHasTraffic {
  modifiedAt: Date | undefined;
  device: string;
  lineCard: number;
  vlanState: number;
  category: AssertCategoryType;
  assertState: AssertStateType;
  masked: boolean;
  latchedAt: Date | undefined;
  latchTime: number;
  lastTrafficTime: Date | undefined;
  implicit: boolean;
  lastTrafficBytes: number;
  recentTrafficBytes: number;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertDeviceResourceUsageBelowThreshold {
  modifiedAt: Date | undefined;
  device: string;
  masked: boolean;
  latchedAt: Date | undefined;
  implicit: boolean;
  highCpuUtil: boolean;
  highMemUtil: boolean;
  category: AssertCategoryType;
  assertState: AssertStateType;
  latchTime: number;
  highDiskUtil: boolean;
  fabric: string;
  nodeId: string;
  remoteNodeId: string;
}

export interface AssertConfigAddedToDb {
  modifiedAt: Date | undefined;
  objectName: string;
  objectType: string;
  objectId: string;
  childId: string;
  orgId: string;
  txnId: string;
  parentId: string;
  username: string;
  operation: string;
}

export interface AssertHistoryApiRecord {
  assertType: string;
  deviceConnectedToCloud: AssertDeviceConnectedToCloud | undefined;
  deviceConnectedToFabric: AssertDeviceConnectedToFabric | undefined;
  managementPortConfigSame: AssertManagementPortConfigSame | undefined;
  portConnectionSpeedMatch: AssertPortConnectionSpeedMatch | undefined;
  portExpectedNeighbor: AssertPortExpectedNeighbor | undefined;
  portLinkDown: AssertPortLinkDown | undefined;
  portLinkUp: AssertPortLinkUp | undefined;
  portNotConnectedToFabric: AssertPortNotConnectedToFabric | undefined;
  portSpeedConsistent: AssertPortSpeedConsistent | undefined;
  staticDefaultRouteExists: AssertStaticDefaultRouteExists | undefined;
  vlanHasTraffic: AssertVlanHasTraffic | undefined;
  deviceResourceUsageBelowThreshold: AssertDeviceResourceUsageBelowThreshold | undefined;
  configAddedToDb: AssertConfigAddedToDb | undefined;
}

function createBaseAssertDeviceConnectedToCloud(): AssertDeviceConnectedToCloud {
  return {
    modifiedAt: undefined,
    device: "",
    latchedAt: undefined,
    latchTime: 0,
    implicit: false,
    category: 0,
    assertState: 0,
    masked: false,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertDeviceConnectedToCloud = {
  encode(message: AssertDeviceConnectedToCloud, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.device !== "") {
      writer.uint32(18).string(message.device);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(32).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.category !== 0) {
      writer.uint32(48).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(56).int32(message.assertState);
    }
    if (message.masked !== false) {
      writer.uint32(64).bool(message.masked);
    }
    if (message.fabric !== "") {
      writer.uint32(74).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(82).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(90).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertDeviceConnectedToCloud {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertDeviceConnectedToCloud();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.device = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertDeviceConnectedToCloud {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertDeviceConnectedToCloud): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertDeviceConnectedToCloud>, I>>(base?: I): AssertDeviceConnectedToCloud {
    return AssertDeviceConnectedToCloud.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertDeviceConnectedToCloud>, I>>(object: I): AssertDeviceConnectedToCloud {
    const message = createBaseAssertDeviceConnectedToCloud();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.device = object.device ?? "";
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.masked = object.masked ?? false;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertDeviceConnectedToFabric(): AssertDeviceConnectedToFabric {
  return {
    modifiedAt: undefined,
    device: "",
    latchedAt: undefined,
    latchTime: 0,
    implicit: false,
    category: 0,
    assertState: 0,
    masked: false,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertDeviceConnectedToFabric = {
  encode(message: AssertDeviceConnectedToFabric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.device !== "") {
      writer.uint32(18).string(message.device);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(32).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.category !== 0) {
      writer.uint32(48).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(56).int32(message.assertState);
    }
    if (message.masked !== false) {
      writer.uint32(64).bool(message.masked);
    }
    if (message.fabric !== "") {
      writer.uint32(74).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(82).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(90).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertDeviceConnectedToFabric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertDeviceConnectedToFabric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.device = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertDeviceConnectedToFabric {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertDeviceConnectedToFabric): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertDeviceConnectedToFabric>, I>>(base?: I): AssertDeviceConnectedToFabric {
    return AssertDeviceConnectedToFabric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertDeviceConnectedToFabric>, I>>(
    object: I,
  ): AssertDeviceConnectedToFabric {
    const message = createBaseAssertDeviceConnectedToFabric();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.device = object.device ?? "";
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.masked = object.masked ?? false;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertManagementPortConfigSame(): AssertManagementPortConfigSame {
  return {
    modifiedAt: undefined,
    device: "",
    lineCard: 0,
    managementState: "",
    masked: false,
    latchedAt: undefined,
    latchTime: 0,
    implicit: false,
    category: 0,
    assertState: 0,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertManagementPortConfigSame = {
  encode(message: AssertManagementPortConfigSame, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.device !== "") {
      writer.uint32(18).string(message.device);
    }
    if (message.lineCard !== 0) {
      writer.uint32(24).uint32(message.lineCard);
    }
    if (message.managementState !== "") {
      writer.uint32(34).string(message.managementState);
    }
    if (message.masked !== false) {
      writer.uint32(40).bool(message.masked);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(56).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(64).bool(message.implicit);
    }
    if (message.category !== 0) {
      writer.uint32(72).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(80).int32(message.assertState);
    }
    if (message.fabric !== "") {
      writer.uint32(90).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(98).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(106).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertManagementPortConfigSame {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertManagementPortConfigSame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.device = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.managementState = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertManagementPortConfigSame {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      managementState: isSet(object.managementState) ? globalThis.String(object.managementState) : "",
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertManagementPortConfigSame): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.managementState !== "") {
      obj.managementState = message.managementState;
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertManagementPortConfigSame>, I>>(base?: I): AssertManagementPortConfigSame {
    return AssertManagementPortConfigSame.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertManagementPortConfigSame>, I>>(
    object: I,
  ): AssertManagementPortConfigSame {
    const message = createBaseAssertManagementPortConfigSame();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? 0;
    message.managementState = object.managementState ?? "";
    message.masked = object.masked ?? false;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertPortConnectionSpeedMatch(): AssertPortConnectionSpeedMatch {
  return {
    modifiedAt: undefined,
    breakout: 0,
    device: "",
    lineCard: 0,
    port: "",
    latchTime: 0,
    remoteDevice: "",
    localPortSpeed: 0,
    assertState: 0,
    masked: false,
    latchedAt: undefined,
    remotePortName: "",
    category: 0,
    portName: "",
    implicit: false,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertPortConnectionSpeedMatch = {
  encode(message: AssertPortConnectionSpeedMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.breakout !== 0) {
      writer.uint32(16).uint32(message.breakout);
    }
    if (message.device !== "") {
      writer.uint32(26).string(message.device);
    }
    if (message.lineCard !== 0) {
      writer.uint32(32).uint32(message.lineCard);
    }
    if (message.port !== "") {
      writer.uint32(42).string(message.port);
    }
    if (message.latchTime !== 0) {
      writer.uint32(48).int64(message.latchTime);
    }
    if (message.remoteDevice !== "") {
      writer.uint32(58).string(message.remoteDevice);
    }
    if (message.localPortSpeed !== 0) {
      writer.uint32(64).int64(message.localPortSpeed);
    }
    if (message.assertState !== 0) {
      writer.uint32(72).int32(message.assertState);
    }
    if (message.masked !== false) {
      writer.uint32(80).bool(message.masked);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(90).fork()).ldelim();
    }
    if (message.remotePortName !== "") {
      writer.uint32(98).string(message.remotePortName);
    }
    if (message.category !== 0) {
      writer.uint32(104).int32(message.category);
    }
    if (message.portName !== "") {
      writer.uint32(114).string(message.portName);
    }
    if (message.implicit !== false) {
      writer.uint32(120).bool(message.implicit);
    }
    if (message.fabric !== "") {
      writer.uint32(130).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(138).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(146).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortConnectionSpeedMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortConnectionSpeedMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.breakout = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.device = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.port = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.remoteDevice = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.localPortSpeed = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.remotePortName = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortConnectionSpeedMatch {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      breakout: isSet(object.breakout) ? globalThis.Number(object.breakout) : 0,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      remoteDevice: isSet(object.remoteDevice) ? globalThis.String(object.remoteDevice) : "",
      localPortSpeed: isSet(object.localPortSpeed) ? globalThis.Number(object.localPortSpeed) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      remotePortName: isSet(object.remotePortName) ? globalThis.String(object.remotePortName) : "",
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertPortConnectionSpeedMatch): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.breakout !== 0) {
      obj.breakout = Math.round(message.breakout);
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.remoteDevice !== "") {
      obj.remoteDevice = message.remoteDevice;
    }
    if (message.localPortSpeed !== 0) {
      obj.localPortSpeed = Math.round(message.localPortSpeed);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.remotePortName !== "") {
      obj.remotePortName = message.remotePortName;
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortConnectionSpeedMatch>, I>>(base?: I): AssertPortConnectionSpeedMatch {
    return AssertPortConnectionSpeedMatch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortConnectionSpeedMatch>, I>>(
    object: I,
  ): AssertPortConnectionSpeedMatch {
    const message = createBaseAssertPortConnectionSpeedMatch();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.breakout = object.breakout ?? 0;
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? 0;
    message.port = object.port ?? "";
    message.latchTime = object.latchTime ?? 0;
    message.remoteDevice = object.remoteDevice ?? "";
    message.localPortSpeed = object.localPortSpeed ?? 0;
    message.assertState = object.assertState ?? 0;
    message.masked = object.masked ?? false;
    message.latchedAt = object.latchedAt ?? undefined;
    message.remotePortName = object.remotePortName ?? "";
    message.category = object.category ?? 0;
    message.portName = object.portName ?? "";
    message.implicit = object.implicit ?? false;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertPortExpectedNeighbor(): AssertPortExpectedNeighbor {
  return {
    modifiedAt: undefined,
    breakout: 0,
    device: "",
    lineCard: 0,
    port: "",
    masked: false,
    latchedAt: undefined,
    numNeighbors: 0,
    remotePortName: "",
    remoteDevice: "",
    category: 0,
    assertState: 0,
    portName: "",
    latchTime: 0,
    implicit: false,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertPortExpectedNeighbor = {
  encode(message: AssertPortExpectedNeighbor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.breakout !== 0) {
      writer.uint32(16).uint32(message.breakout);
    }
    if (message.device !== "") {
      writer.uint32(26).string(message.device);
    }
    if (message.lineCard !== 0) {
      writer.uint32(32).uint32(message.lineCard);
    }
    if (message.port !== "") {
      writer.uint32(42).string(message.port);
    }
    if (message.masked !== false) {
      writer.uint32(48).bool(message.masked);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(58).fork()).ldelim();
    }
    if (message.numNeighbors !== 0) {
      writer.uint32(64).uint32(message.numNeighbors);
    }
    if (message.remotePortName !== "") {
      writer.uint32(74).string(message.remotePortName);
    }
    if (message.remoteDevice !== "") {
      writer.uint32(82).string(message.remoteDevice);
    }
    if (message.category !== 0) {
      writer.uint32(88).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(96).int32(message.assertState);
    }
    if (message.portName !== "") {
      writer.uint32(106).string(message.portName);
    }
    if (message.latchTime !== 0) {
      writer.uint32(112).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(120).bool(message.implicit);
    }
    if (message.fabric !== "") {
      writer.uint32(130).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(138).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(146).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortExpectedNeighbor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortExpectedNeighbor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.breakout = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.device = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.port = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.numNeighbors = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.remotePortName = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.remoteDevice = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortExpectedNeighbor {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      breakout: isSet(object.breakout) ? globalThis.Number(object.breakout) : 0,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      numNeighbors: isSet(object.numNeighbors) ? globalThis.Number(object.numNeighbors) : 0,
      remotePortName: isSet(object.remotePortName) ? globalThis.String(object.remotePortName) : "",
      remoteDevice: isSet(object.remoteDevice) ? globalThis.String(object.remoteDevice) : "",
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertPortExpectedNeighbor): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.breakout !== 0) {
      obj.breakout = Math.round(message.breakout);
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.numNeighbors !== 0) {
      obj.numNeighbors = Math.round(message.numNeighbors);
    }
    if (message.remotePortName !== "") {
      obj.remotePortName = message.remotePortName;
    }
    if (message.remoteDevice !== "") {
      obj.remoteDevice = message.remoteDevice;
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortExpectedNeighbor>, I>>(base?: I): AssertPortExpectedNeighbor {
    return AssertPortExpectedNeighbor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortExpectedNeighbor>, I>>(object: I): AssertPortExpectedNeighbor {
    const message = createBaseAssertPortExpectedNeighbor();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.breakout = object.breakout ?? 0;
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? 0;
    message.port = object.port ?? "";
    message.masked = object.masked ?? false;
    message.latchedAt = object.latchedAt ?? undefined;
    message.numNeighbors = object.numNeighbors ?? 0;
    message.remotePortName = object.remotePortName ?? "";
    message.remoteDevice = object.remoteDevice ?? "";
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.portName = object.portName ?? "";
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertPortLinkDown(): AssertPortLinkDown {
  return {
    modifiedAt: undefined,
    breakout: 0,
    device: "",
    lineCard: 0,
    port: "",
    remotePortName: "",
    portName: "",
    masked: false,
    assertState: 0,
    latchedAt: undefined,
    latchTime: 0,
    implicit: false,
    remoteDevice: "",
    category: 0,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertPortLinkDown = {
  encode(message: AssertPortLinkDown, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.breakout !== 0) {
      writer.uint32(16).uint32(message.breakout);
    }
    if (message.device !== "") {
      writer.uint32(26).string(message.device);
    }
    if (message.lineCard !== 0) {
      writer.uint32(32).uint32(message.lineCard);
    }
    if (message.port !== "") {
      writer.uint32(42).string(message.port);
    }
    if (message.remotePortName !== "") {
      writer.uint32(50).string(message.remotePortName);
    }
    if (message.portName !== "") {
      writer.uint32(58).string(message.portName);
    }
    if (message.masked !== false) {
      writer.uint32(64).bool(message.masked);
    }
    if (message.assertState !== 0) {
      writer.uint32(72).int32(message.assertState);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(82).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(88).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(96).bool(message.implicit);
    }
    if (message.remoteDevice !== "") {
      writer.uint32(106).string(message.remoteDevice);
    }
    if (message.category !== 0) {
      writer.uint32(112).int32(message.category);
    }
    if (message.fabric !== "") {
      writer.uint32(122).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(130).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(138).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortLinkDown {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortLinkDown();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.breakout = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.device = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.port = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.remotePortName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.remoteDevice = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortLinkDown {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      breakout: isSet(object.breakout) ? globalThis.Number(object.breakout) : 0,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      remotePortName: isSet(object.remotePortName) ? globalThis.String(object.remotePortName) : "",
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      remoteDevice: isSet(object.remoteDevice) ? globalThis.String(object.remoteDevice) : "",
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertPortLinkDown): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.breakout !== 0) {
      obj.breakout = Math.round(message.breakout);
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.remotePortName !== "") {
      obj.remotePortName = message.remotePortName;
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.remoteDevice !== "") {
      obj.remoteDevice = message.remoteDevice;
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortLinkDown>, I>>(base?: I): AssertPortLinkDown {
    return AssertPortLinkDown.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortLinkDown>, I>>(object: I): AssertPortLinkDown {
    const message = createBaseAssertPortLinkDown();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.breakout = object.breakout ?? 0;
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? 0;
    message.port = object.port ?? "";
    message.remotePortName = object.remotePortName ?? "";
    message.portName = object.portName ?? "";
    message.masked = object.masked ?? false;
    message.assertState = object.assertState ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.remoteDevice = object.remoteDevice ?? "";
    message.category = object.category ?? 0;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertPortLinkUp(): AssertPortLinkUp {
  return {
    modifiedAt: undefined,
    breakout: 0,
    device: "",
    lineCard: 0,
    port: "",
    portName: "",
    masked: false,
    latchTime: 0,
    category: 0,
    assertState: 0,
    latchedAt: undefined,
    implicit: false,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertPortLinkUp = {
  encode(message: AssertPortLinkUp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.breakout !== 0) {
      writer.uint32(16).uint32(message.breakout);
    }
    if (message.device !== "") {
      writer.uint32(26).string(message.device);
    }
    if (message.lineCard !== 0) {
      writer.uint32(32).uint32(message.lineCard);
    }
    if (message.port !== "") {
      writer.uint32(42).string(message.port);
    }
    if (message.portName !== "") {
      writer.uint32(50).string(message.portName);
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.latchTime !== 0) {
      writer.uint32(64).int64(message.latchTime);
    }
    if (message.category !== 0) {
      writer.uint32(72).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(80).int32(message.assertState);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(90).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(96).bool(message.implicit);
    }
    if (message.fabric !== "") {
      writer.uint32(106).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(114).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(122).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortLinkUp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortLinkUp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.breakout = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.device = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.port = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortLinkUp {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      breakout: isSet(object.breakout) ? globalThis.Number(object.breakout) : 0,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertPortLinkUp): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.breakout !== 0) {
      obj.breakout = Math.round(message.breakout);
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortLinkUp>, I>>(base?: I): AssertPortLinkUp {
    return AssertPortLinkUp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortLinkUp>, I>>(object: I): AssertPortLinkUp {
    const message = createBaseAssertPortLinkUp();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.breakout = object.breakout ?? 0;
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? 0;
    message.port = object.port ?? "";
    message.portName = object.portName ?? "";
    message.masked = object.masked ?? false;
    message.latchTime = object.latchTime ?? 0;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.implicit = object.implicit ?? false;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertPortNotConnectedToFabric(): AssertPortNotConnectedToFabric {
  return {
    modifiedAt: undefined,
    breakout: 0,
    device: "",
    lineCard: 0,
    port: "",
    portName: "",
    masked: false,
    latchTime: 0,
    remotePortName: "",
    remoteDevice: "",
    category: 0,
    assertState: 0,
    latchedAt: undefined,
    implicit: false,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertPortNotConnectedToFabric = {
  encode(message: AssertPortNotConnectedToFabric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.breakout !== 0) {
      writer.uint32(16).uint32(message.breakout);
    }
    if (message.device !== "") {
      writer.uint32(26).string(message.device);
    }
    if (message.lineCard !== 0) {
      writer.uint32(32).uint32(message.lineCard);
    }
    if (message.port !== "") {
      writer.uint32(42).string(message.port);
    }
    if (message.portName !== "") {
      writer.uint32(50).string(message.portName);
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.latchTime !== 0) {
      writer.uint32(64).int64(message.latchTime);
    }
    if (message.remotePortName !== "") {
      writer.uint32(74).string(message.remotePortName);
    }
    if (message.remoteDevice !== "") {
      writer.uint32(82).string(message.remoteDevice);
    }
    if (message.category !== 0) {
      writer.uint32(88).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(96).int32(message.assertState);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(106).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(112).bool(message.implicit);
    }
    if (message.fabric !== "") {
      writer.uint32(122).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(130).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(138).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortNotConnectedToFabric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortNotConnectedToFabric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.breakout = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.device = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.port = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.remotePortName = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.remoteDevice = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortNotConnectedToFabric {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      breakout: isSet(object.breakout) ? globalThis.Number(object.breakout) : 0,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      remotePortName: isSet(object.remotePortName) ? globalThis.String(object.remotePortName) : "",
      remoteDevice: isSet(object.remoteDevice) ? globalThis.String(object.remoteDevice) : "",
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertPortNotConnectedToFabric): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.breakout !== 0) {
      obj.breakout = Math.round(message.breakout);
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.remotePortName !== "") {
      obj.remotePortName = message.remotePortName;
    }
    if (message.remoteDevice !== "") {
      obj.remoteDevice = message.remoteDevice;
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortNotConnectedToFabric>, I>>(base?: I): AssertPortNotConnectedToFabric {
    return AssertPortNotConnectedToFabric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortNotConnectedToFabric>, I>>(
    object: I,
  ): AssertPortNotConnectedToFabric {
    const message = createBaseAssertPortNotConnectedToFabric();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.breakout = object.breakout ?? 0;
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? 0;
    message.port = object.port ?? "";
    message.portName = object.portName ?? "";
    message.masked = object.masked ?? false;
    message.latchTime = object.latchTime ?? 0;
    message.remotePortName = object.remotePortName ?? "";
    message.remoteDevice = object.remoteDevice ?? "";
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.implicit = object.implicit ?? false;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertPortSpeedConsistent(): AssertPortSpeedConsistent {
  return {
    modifiedAt: undefined,
    breakout: 0,
    device: "",
    lineCard: 0,
    port: "",
    masked: false,
    assertState: 0,
    portName: "",
    latchedAt: undefined,
    latchTime: 0,
    implicit: false,
    localPortSpeed: 0,
    category: 0,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertPortSpeedConsistent = {
  encode(message: AssertPortSpeedConsistent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.breakout !== 0) {
      writer.uint32(16).uint32(message.breakout);
    }
    if (message.device !== "") {
      writer.uint32(26).string(message.device);
    }
    if (message.lineCard !== 0) {
      writer.uint32(32).uint32(message.lineCard);
    }
    if (message.port !== "") {
      writer.uint32(42).string(message.port);
    }
    if (message.masked !== false) {
      writer.uint32(48).bool(message.masked);
    }
    if (message.assertState !== 0) {
      writer.uint32(56).int32(message.assertState);
    }
    if (message.portName !== "") {
      writer.uint32(66).string(message.portName);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(74).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(80).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(88).bool(message.implicit);
    }
    if (message.localPortSpeed !== 0) {
      writer.uint32(96).int64(message.localPortSpeed);
    }
    if (message.category !== 0) {
      writer.uint32(104).int32(message.category);
    }
    if (message.fabric !== "") {
      writer.uint32(114).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(122).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(130).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortSpeedConsistent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortSpeedConsistent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.breakout = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.device = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.port = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.localPortSpeed = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortSpeedConsistent {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      breakout: isSet(object.breakout) ? globalThis.Number(object.breakout) : 0,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      localPortSpeed: isSet(object.localPortSpeed) ? globalThis.Number(object.localPortSpeed) : 0,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertPortSpeedConsistent): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.breakout !== 0) {
      obj.breakout = Math.round(message.breakout);
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.localPortSpeed !== 0) {
      obj.localPortSpeed = Math.round(message.localPortSpeed);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortSpeedConsistent>, I>>(base?: I): AssertPortSpeedConsistent {
    return AssertPortSpeedConsistent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortSpeedConsistent>, I>>(object: I): AssertPortSpeedConsistent {
    const message = createBaseAssertPortSpeedConsistent();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.breakout = object.breakout ?? 0;
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? 0;
    message.port = object.port ?? "";
    message.masked = object.masked ?? false;
    message.assertState = object.assertState ?? 0;
    message.portName = object.portName ?? "";
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.localPortSpeed = object.localPortSpeed ?? 0;
    message.category = object.category ?? 0;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertStaticDefaultRouteExists(): AssertStaticDefaultRouteExists {
  return {
    modifiedAt: undefined,
    device: "",
    lineCard: 0,
    vrf: "",
    latchedAt: undefined,
    latchTime: 0,
    implicit: false,
    category: 0,
    assertState: 0,
    masked: false,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertStaticDefaultRouteExists = {
  encode(message: AssertStaticDefaultRouteExists, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.device !== "") {
      writer.uint32(18).string(message.device);
    }
    if (message.lineCard !== 0) {
      writer.uint32(24).uint32(message.lineCard);
    }
    if (message.vrf !== "") {
      writer.uint32(34).string(message.vrf);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(48).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(56).bool(message.implicit);
    }
    if (message.category !== 0) {
      writer.uint32(64).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(72).int32(message.assertState);
    }
    if (message.masked !== false) {
      writer.uint32(80).bool(message.masked);
    }
    if (message.fabric !== "") {
      writer.uint32(90).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(98).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(106).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertStaticDefaultRouteExists {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertStaticDefaultRouteExists();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.device = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.vrf = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertStaticDefaultRouteExists {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      vrf: isSet(object.vrf) ? globalThis.String(object.vrf) : "",
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertStaticDefaultRouteExists): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.vrf !== "") {
      obj.vrf = message.vrf;
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertStaticDefaultRouteExists>, I>>(base?: I): AssertStaticDefaultRouteExists {
    return AssertStaticDefaultRouteExists.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertStaticDefaultRouteExists>, I>>(
    object: I,
  ): AssertStaticDefaultRouteExists {
    const message = createBaseAssertStaticDefaultRouteExists();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? 0;
    message.vrf = object.vrf ?? "";
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.masked = object.masked ?? false;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertVlanHasTraffic(): AssertVlanHasTraffic {
  return {
    modifiedAt: undefined,
    device: "",
    lineCard: 0,
    vlanState: 0,
    category: 0,
    assertState: 0,
    masked: false,
    latchedAt: undefined,
    latchTime: 0,
    lastTrafficTime: undefined,
    implicit: false,
    lastTrafficBytes: 0,
    recentTrafficBytes: 0,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertVlanHasTraffic = {
  encode(message: AssertVlanHasTraffic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.device !== "") {
      writer.uint32(18).string(message.device);
    }
    if (message.lineCard !== 0) {
      writer.uint32(24).uint32(message.lineCard);
    }
    if (message.vlanState !== 0) {
      writer.uint32(32).uint32(message.vlanState);
    }
    if (message.category !== 0) {
      writer.uint32(40).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(48).int32(message.assertState);
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(72).int64(message.latchTime);
    }
    if (message.lastTrafficTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastTrafficTime), writer.uint32(82).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(88).bool(message.implicit);
    }
    if (message.lastTrafficBytes !== 0) {
      writer.uint32(96).int64(message.lastTrafficBytes);
    }
    if (message.recentTrafficBytes !== 0) {
      writer.uint32(104).int64(message.recentTrafficBytes);
    }
    if (message.fabric !== "") {
      writer.uint32(114).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(122).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(130).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertVlanHasTraffic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertVlanHasTraffic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.device = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.vlanState = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lastTrafficTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.lastTrafficBytes = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.recentTrafficBytes = longToNumber(reader.int64() as Long);
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertVlanHasTraffic {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      vlanState: isSet(object.vlanState) ? globalThis.Number(object.vlanState) : 0,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      lastTrafficTime: isSet(object.lastTrafficTime) ? fromJsonTimestamp(object.lastTrafficTime) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      lastTrafficBytes: isSet(object.lastTrafficBytes) ? globalThis.Number(object.lastTrafficBytes) : 0,
      recentTrafficBytes: isSet(object.recentTrafficBytes) ? globalThis.Number(object.recentTrafficBytes) : 0,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertVlanHasTraffic): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.vlanState !== 0) {
      obj.vlanState = Math.round(message.vlanState);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.lastTrafficTime !== undefined) {
      obj.lastTrafficTime = message.lastTrafficTime.toISOString();
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.lastTrafficBytes !== 0) {
      obj.lastTrafficBytes = Math.round(message.lastTrafficBytes);
    }
    if (message.recentTrafficBytes !== 0) {
      obj.recentTrafficBytes = Math.round(message.recentTrafficBytes);
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertVlanHasTraffic>, I>>(base?: I): AssertVlanHasTraffic {
    return AssertVlanHasTraffic.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertVlanHasTraffic>, I>>(object: I): AssertVlanHasTraffic {
    const message = createBaseAssertVlanHasTraffic();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.device = object.device ?? "";
    message.lineCard = object.lineCard ?? 0;
    message.vlanState = object.vlanState ?? 0;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.masked = object.masked ?? false;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.lastTrafficTime = object.lastTrafficTime ?? undefined;
    message.implicit = object.implicit ?? false;
    message.lastTrafficBytes = object.lastTrafficBytes ?? 0;
    message.recentTrafficBytes = object.recentTrafficBytes ?? 0;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertDeviceResourceUsageBelowThreshold(): AssertDeviceResourceUsageBelowThreshold {
  return {
    modifiedAt: undefined,
    device: "",
    masked: false,
    latchedAt: undefined,
    implicit: false,
    highCpuUtil: false,
    highMemUtil: false,
    category: 0,
    assertState: 0,
    latchTime: 0,
    highDiskUtil: false,
    fabric: "",
    nodeId: "",
    remoteNodeId: "",
  };
}

export const AssertDeviceResourceUsageBelowThreshold = {
  encode(message: AssertDeviceResourceUsageBelowThreshold, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.device !== "") {
      writer.uint32(18).string(message.device);
    }
    if (message.masked !== false) {
      writer.uint32(24).bool(message.masked);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.highCpuUtil !== false) {
      writer.uint32(48).bool(message.highCpuUtil);
    }
    if (message.highMemUtil !== false) {
      writer.uint32(56).bool(message.highMemUtil);
    }
    if (message.category !== 0) {
      writer.uint32(64).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(72).int32(message.assertState);
    }
    if (message.latchTime !== 0) {
      writer.uint32(80).int64(message.latchTime);
    }
    if (message.highDiskUtil !== false) {
      writer.uint32(88).bool(message.highDiskUtil);
    }
    if (message.fabric !== "") {
      writer.uint32(98).string(message.fabric);
    }
    if (message.nodeId !== "") {
      writer.uint32(106).string(message.nodeId);
    }
    if (message.remoteNodeId !== "") {
      writer.uint32(114).string(message.remoteNodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertDeviceResourceUsageBelowThreshold {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertDeviceResourceUsageBelowThreshold();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.device = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.highCpuUtil = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.highMemUtil = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.highDiskUtil = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.remoteNodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertDeviceResourceUsageBelowThreshold {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      highCpuUtil: isSet(object.highCpuUtil) ? globalThis.Boolean(object.highCpuUtil) : false,
      highMemUtil: isSet(object.highMemUtil) ? globalThis.Boolean(object.highMemUtil) : false,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      highDiskUtil: isSet(object.highDiskUtil) ? globalThis.Boolean(object.highDiskUtil) : false,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      remoteNodeId: isSet(object.remoteNodeId) ? globalThis.String(object.remoteNodeId) : "",
    };
  },

  toJSON(message: AssertDeviceResourceUsageBelowThreshold): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.highCpuUtil !== false) {
      obj.highCpuUtil = message.highCpuUtil;
    }
    if (message.highMemUtil !== false) {
      obj.highMemUtil = message.highMemUtil;
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.highDiskUtil !== false) {
      obj.highDiskUtil = message.highDiskUtil;
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.remoteNodeId !== "") {
      obj.remoteNodeId = message.remoteNodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertDeviceResourceUsageBelowThreshold>, I>>(
    base?: I,
  ): AssertDeviceResourceUsageBelowThreshold {
    return AssertDeviceResourceUsageBelowThreshold.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertDeviceResourceUsageBelowThreshold>, I>>(
    object: I,
  ): AssertDeviceResourceUsageBelowThreshold {
    const message = createBaseAssertDeviceResourceUsageBelowThreshold();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.device = object.device ?? "";
    message.masked = object.masked ?? false;
    message.latchedAt = object.latchedAt ?? undefined;
    message.implicit = object.implicit ?? false;
    message.highCpuUtil = object.highCpuUtil ?? false;
    message.highMemUtil = object.highMemUtil ?? false;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.latchTime = object.latchTime ?? 0;
    message.highDiskUtil = object.highDiskUtil ?? false;
    message.fabric = object.fabric ?? "";
    message.nodeId = object.nodeId ?? "";
    message.remoteNodeId = object.remoteNodeId ?? "";
    return message;
  },
};

function createBaseAssertConfigAddedToDb(): AssertConfigAddedToDb {
  return {
    modifiedAt: undefined,
    objectName: "",
    objectType: "",
    objectId: "",
    childId: "",
    orgId: "",
    txnId: "",
    parentId: "",
    username: "",
    operation: "",
  };
}

export const AssertConfigAddedToDb = {
  encode(message: AssertConfigAddedToDb, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(10).fork()).ldelim();
    }
    if (message.objectName !== "") {
      writer.uint32(18).string(message.objectName);
    }
    if (message.objectType !== "") {
      writer.uint32(26).string(message.objectType);
    }
    if (message.objectId !== "") {
      writer.uint32(34).string(message.objectId);
    }
    if (message.childId !== "") {
      writer.uint32(42).string(message.childId);
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.txnId !== "") {
      writer.uint32(58).string(message.txnId);
    }
    if (message.parentId !== "") {
      writer.uint32(66).string(message.parentId);
    }
    if (message.username !== "") {
      writer.uint32(74).string(message.username);
    }
    if (message.operation !== "") {
      writer.uint32(82).string(message.operation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigAddedToDb {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigAddedToDb();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.objectName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.objectType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.childId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.txnId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.username = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.operation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigAddedToDb {
    return {
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      objectName: isSet(object.objectName) ? globalThis.String(object.objectName) : "",
      objectType: isSet(object.objectType) ? globalThis.String(object.objectType) : "",
      objectId: isSet(object.objectId) ? globalThis.String(object.objectId) : "",
      childId: isSet(object.childId) ? globalThis.String(object.childId) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      txnId: isSet(object.txnId) ? globalThis.String(object.txnId) : "",
      parentId: isSet(object.parentId) ? globalThis.String(object.parentId) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      operation: isSet(object.operation) ? globalThis.String(object.operation) : "",
    };
  },

  toJSON(message: AssertConfigAddedToDb): unknown {
    const obj: any = {};
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.objectName !== "") {
      obj.objectName = message.objectName;
    }
    if (message.objectType !== "") {
      obj.objectType = message.objectType;
    }
    if (message.objectId !== "") {
      obj.objectId = message.objectId;
    }
    if (message.childId !== "") {
      obj.childId = message.childId;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.txnId !== "") {
      obj.txnId = message.txnId;
    }
    if (message.parentId !== "") {
      obj.parentId = message.parentId;
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.operation !== "") {
      obj.operation = message.operation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigAddedToDb>, I>>(base?: I): AssertConfigAddedToDb {
    return AssertConfigAddedToDb.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigAddedToDb>, I>>(object: I): AssertConfigAddedToDb {
    const message = createBaseAssertConfigAddedToDb();
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.objectName = object.objectName ?? "";
    message.objectType = object.objectType ?? "";
    message.objectId = object.objectId ?? "";
    message.childId = object.childId ?? "";
    message.orgId = object.orgId ?? "";
    message.txnId = object.txnId ?? "";
    message.parentId = object.parentId ?? "";
    message.username = object.username ?? "";
    message.operation = object.operation ?? "";
    return message;
  },
};

function createBaseAssertHistoryApiRecord(): AssertHistoryApiRecord {
  return {
    assertType: "",
    deviceConnectedToCloud: undefined,
    deviceConnectedToFabric: undefined,
    managementPortConfigSame: undefined,
    portConnectionSpeedMatch: undefined,
    portExpectedNeighbor: undefined,
    portLinkDown: undefined,
    portLinkUp: undefined,
    portNotConnectedToFabric: undefined,
    portSpeedConsistent: undefined,
    staticDefaultRouteExists: undefined,
    vlanHasTraffic: undefined,
    deviceResourceUsageBelowThreshold: undefined,
    configAddedToDb: undefined,
  };
}

export const AssertHistoryApiRecord = {
  encode(message: AssertHistoryApiRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.assertType !== "") {
      writer.uint32(10).string(message.assertType);
    }
    if (message.deviceConnectedToCloud !== undefined) {
      AssertDeviceConnectedToCloud.encode(message.deviceConnectedToCloud, writer.uint32(18).fork()).ldelim();
    }
    if (message.deviceConnectedToFabric !== undefined) {
      AssertDeviceConnectedToFabric.encode(message.deviceConnectedToFabric, writer.uint32(26).fork()).ldelim();
    }
    if (message.managementPortConfigSame !== undefined) {
      AssertManagementPortConfigSame.encode(message.managementPortConfigSame, writer.uint32(34).fork()).ldelim();
    }
    if (message.portConnectionSpeedMatch !== undefined) {
      AssertPortConnectionSpeedMatch.encode(message.portConnectionSpeedMatch, writer.uint32(42).fork()).ldelim();
    }
    if (message.portExpectedNeighbor !== undefined) {
      AssertPortExpectedNeighbor.encode(message.portExpectedNeighbor, writer.uint32(50).fork()).ldelim();
    }
    if (message.portLinkDown !== undefined) {
      AssertPortLinkDown.encode(message.portLinkDown, writer.uint32(58).fork()).ldelim();
    }
    if (message.portLinkUp !== undefined) {
      AssertPortLinkUp.encode(message.portLinkUp, writer.uint32(66).fork()).ldelim();
    }
    if (message.portNotConnectedToFabric !== undefined) {
      AssertPortNotConnectedToFabric.encode(message.portNotConnectedToFabric, writer.uint32(74).fork()).ldelim();
    }
    if (message.portSpeedConsistent !== undefined) {
      AssertPortSpeedConsistent.encode(message.portSpeedConsistent, writer.uint32(82).fork()).ldelim();
    }
    if (message.staticDefaultRouteExists !== undefined) {
      AssertStaticDefaultRouteExists.encode(message.staticDefaultRouteExists, writer.uint32(90).fork()).ldelim();
    }
    if (message.vlanHasTraffic !== undefined) {
      AssertVlanHasTraffic.encode(message.vlanHasTraffic, writer.uint32(98).fork()).ldelim();
    }
    if (message.deviceResourceUsageBelowThreshold !== undefined) {
      AssertDeviceResourceUsageBelowThreshold.encode(
        message.deviceResourceUsageBelowThreshold,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.configAddedToDb !== undefined) {
      AssertConfigAddedToDb.encode(message.configAddedToDb, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertHistoryApiRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertHistoryApiRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assertType = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceConnectedToCloud = AssertDeviceConnectedToCloud.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceConnectedToFabric = AssertDeviceConnectedToFabric.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.managementPortConfigSame = AssertManagementPortConfigSame.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.portConnectionSpeedMatch = AssertPortConnectionSpeedMatch.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.portExpectedNeighbor = AssertPortExpectedNeighbor.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.portLinkDown = AssertPortLinkDown.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.portLinkUp = AssertPortLinkUp.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.portNotConnectedToFabric = AssertPortNotConnectedToFabric.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.portSpeedConsistent = AssertPortSpeedConsistent.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.staticDefaultRouteExists = AssertStaticDefaultRouteExists.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.vlanHasTraffic = AssertVlanHasTraffic.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.deviceResourceUsageBelowThreshold = AssertDeviceResourceUsageBelowThreshold.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.configAddedToDb = AssertConfigAddedToDb.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertHistoryApiRecord {
    return {
      assertType: isSet(object.assertType) ? globalThis.String(object.assertType) : "",
      deviceConnectedToCloud: isSet(object.deviceConnectedToCloud)
        ? AssertDeviceConnectedToCloud.fromJSON(object.deviceConnectedToCloud)
        : undefined,
      deviceConnectedToFabric: isSet(object.deviceConnectedToFabric)
        ? AssertDeviceConnectedToFabric.fromJSON(object.deviceConnectedToFabric)
        : undefined,
      managementPortConfigSame: isSet(object.managementPortConfigSame)
        ? AssertManagementPortConfigSame.fromJSON(object.managementPortConfigSame)
        : undefined,
      portConnectionSpeedMatch: isSet(object.portConnectionSpeedMatch)
        ? AssertPortConnectionSpeedMatch.fromJSON(object.portConnectionSpeedMatch)
        : undefined,
      portExpectedNeighbor: isSet(object.portExpectedNeighbor)
        ? AssertPortExpectedNeighbor.fromJSON(object.portExpectedNeighbor)
        : undefined,
      portLinkDown: isSet(object.portLinkDown) ? AssertPortLinkDown.fromJSON(object.portLinkDown) : undefined,
      portLinkUp: isSet(object.portLinkUp) ? AssertPortLinkUp.fromJSON(object.portLinkUp) : undefined,
      portNotConnectedToFabric: isSet(object.portNotConnectedToFabric)
        ? AssertPortNotConnectedToFabric.fromJSON(object.portNotConnectedToFabric)
        : undefined,
      portSpeedConsistent: isSet(object.portSpeedConsistent)
        ? AssertPortSpeedConsistent.fromJSON(object.portSpeedConsistent)
        : undefined,
      staticDefaultRouteExists: isSet(object.staticDefaultRouteExists)
        ? AssertStaticDefaultRouteExists.fromJSON(object.staticDefaultRouteExists)
        : undefined,
      vlanHasTraffic: isSet(object.vlanHasTraffic) ? AssertVlanHasTraffic.fromJSON(object.vlanHasTraffic) : undefined,
      deviceResourceUsageBelowThreshold: isSet(object.deviceResourceUsageBelowThreshold)
        ? AssertDeviceResourceUsageBelowThreshold.fromJSON(object.deviceResourceUsageBelowThreshold)
        : undefined,
      configAddedToDb: isSet(object.configAddedToDb)
        ? AssertConfigAddedToDb.fromJSON(object.configAddedToDb)
        : undefined,
    };
  },

  toJSON(message: AssertHistoryApiRecord): unknown {
    const obj: any = {};
    if (message.assertType !== "") {
      obj.assertType = message.assertType;
    }
    if (message.deviceConnectedToCloud !== undefined) {
      obj.deviceConnectedToCloud = AssertDeviceConnectedToCloud.toJSON(message.deviceConnectedToCloud);
    }
    if (message.deviceConnectedToFabric !== undefined) {
      obj.deviceConnectedToFabric = AssertDeviceConnectedToFabric.toJSON(message.deviceConnectedToFabric);
    }
    if (message.managementPortConfigSame !== undefined) {
      obj.managementPortConfigSame = AssertManagementPortConfigSame.toJSON(message.managementPortConfigSame);
    }
    if (message.portConnectionSpeedMatch !== undefined) {
      obj.portConnectionSpeedMatch = AssertPortConnectionSpeedMatch.toJSON(message.portConnectionSpeedMatch);
    }
    if (message.portExpectedNeighbor !== undefined) {
      obj.portExpectedNeighbor = AssertPortExpectedNeighbor.toJSON(message.portExpectedNeighbor);
    }
    if (message.portLinkDown !== undefined) {
      obj.portLinkDown = AssertPortLinkDown.toJSON(message.portLinkDown);
    }
    if (message.portLinkUp !== undefined) {
      obj.portLinkUp = AssertPortLinkUp.toJSON(message.portLinkUp);
    }
    if (message.portNotConnectedToFabric !== undefined) {
      obj.portNotConnectedToFabric = AssertPortNotConnectedToFabric.toJSON(message.portNotConnectedToFabric);
    }
    if (message.portSpeedConsistent !== undefined) {
      obj.portSpeedConsistent = AssertPortSpeedConsistent.toJSON(message.portSpeedConsistent);
    }
    if (message.staticDefaultRouteExists !== undefined) {
      obj.staticDefaultRouteExists = AssertStaticDefaultRouteExists.toJSON(message.staticDefaultRouteExists);
    }
    if (message.vlanHasTraffic !== undefined) {
      obj.vlanHasTraffic = AssertVlanHasTraffic.toJSON(message.vlanHasTraffic);
    }
    if (message.deviceResourceUsageBelowThreshold !== undefined) {
      obj.deviceResourceUsageBelowThreshold = AssertDeviceResourceUsageBelowThreshold.toJSON(
        message.deviceResourceUsageBelowThreshold,
      );
    }
    if (message.configAddedToDb !== undefined) {
      obj.configAddedToDb = AssertConfigAddedToDb.toJSON(message.configAddedToDb);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertHistoryApiRecord>, I>>(base?: I): AssertHistoryApiRecord {
    return AssertHistoryApiRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertHistoryApiRecord>, I>>(object: I): AssertHistoryApiRecord {
    const message = createBaseAssertHistoryApiRecord();
    message.assertType = object.assertType ?? "";
    message.deviceConnectedToCloud =
      (object.deviceConnectedToCloud !== undefined && object.deviceConnectedToCloud !== null)
        ? AssertDeviceConnectedToCloud.fromPartial(object.deviceConnectedToCloud)
        : undefined;
    message.deviceConnectedToFabric =
      (object.deviceConnectedToFabric !== undefined && object.deviceConnectedToFabric !== null)
        ? AssertDeviceConnectedToFabric.fromPartial(object.deviceConnectedToFabric)
        : undefined;
    message.managementPortConfigSame =
      (object.managementPortConfigSame !== undefined && object.managementPortConfigSame !== null)
        ? AssertManagementPortConfigSame.fromPartial(object.managementPortConfigSame)
        : undefined;
    message.portConnectionSpeedMatch =
      (object.portConnectionSpeedMatch !== undefined && object.portConnectionSpeedMatch !== null)
        ? AssertPortConnectionSpeedMatch.fromPartial(object.portConnectionSpeedMatch)
        : undefined;
    message.portExpectedNeighbor = (object.portExpectedNeighbor !== undefined && object.portExpectedNeighbor !== null)
      ? AssertPortExpectedNeighbor.fromPartial(object.portExpectedNeighbor)
      : undefined;
    message.portLinkDown = (object.portLinkDown !== undefined && object.portLinkDown !== null)
      ? AssertPortLinkDown.fromPartial(object.portLinkDown)
      : undefined;
    message.portLinkUp = (object.portLinkUp !== undefined && object.portLinkUp !== null)
      ? AssertPortLinkUp.fromPartial(object.portLinkUp)
      : undefined;
    message.portNotConnectedToFabric =
      (object.portNotConnectedToFabric !== undefined && object.portNotConnectedToFabric !== null)
        ? AssertPortNotConnectedToFabric.fromPartial(object.portNotConnectedToFabric)
        : undefined;
    message.portSpeedConsistent = (object.portSpeedConsistent !== undefined && object.portSpeedConsistent !== null)
      ? AssertPortSpeedConsistent.fromPartial(object.portSpeedConsistent)
      : undefined;
    message.staticDefaultRouteExists =
      (object.staticDefaultRouteExists !== undefined && object.staticDefaultRouteExists !== null)
        ? AssertStaticDefaultRouteExists.fromPartial(object.staticDefaultRouteExists)
        : undefined;
    message.vlanHasTraffic = (object.vlanHasTraffic !== undefined && object.vlanHasTraffic !== null)
      ? AssertVlanHasTraffic.fromPartial(object.vlanHasTraffic)
      : undefined;
    message.deviceResourceUsageBelowThreshold =
      (object.deviceResourceUsageBelowThreshold !== undefined && object.deviceResourceUsageBelowThreshold !== null)
        ? AssertDeviceResourceUsageBelowThreshold.fromPartial(object.deviceResourceUsageBelowThreshold)
        : undefined;
    message.configAddedToDb = (object.configAddedToDb !== undefined && object.configAddedToDb !== null)
      ? AssertConfigAddedToDb.fromPartial(object.configAddedToDb)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
