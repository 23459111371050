import React from "react";

/**
 * This interface is a
 * minimum set of metadata
 * and can be extended
 * for different requirements
 */
export interface Config {
  label?: React.ReactNode;
  sortable?: boolean;
  hidden?: boolean;
  size?: number;
}

export type RenderedData = React.ReactElement | React.ReactNode | null;

export interface ColumnConfig<T> {
  render: (val: T) => RenderedData;
  getConfig: () => Config;
  compare?(a: T, b: T): number;
}

export abstract class BaseColumnConfig<T> implements ColumnConfig<T> {
  protected config: Config;
  constructor(config: Config) {
    this.config = config;
  }
  getConfig(): Config {
    return this.config;
  }
  abstract compare?(a: T, b: T): number;
  abstract render(value: T): RenderedData;
}
