// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: assertions/assertion_types.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  AssertDeviceConnectedToCloud,
  AssertDeviceConnectedToFabric,
  AssertDeviceResourceUsageBelowThreshold,
  AssertManagementPortConfigSame,
  AssertPortConnectionSpeedMatch,
  AssertPortExpectedNeighbor,
  AssertPortLinkDown,
  AssertPortLinkUp,
  AssertPortNotConnectedToFabric,
  AssertPortSpeedConsistent,
  AssertStaticDefaultRouteExists,
  AssertVlanHasTraffic,
} from "../schema/schema";

export const protobufPackage = "assertions";

/**
 * PROPRIETARY AND CONFIDENTIAL. Cisco Systems, Inc. considers the contents of this
 * file to be highly confidential trade secret information.
 *
 * COPYRIGHT 2024 Cisco Systems, Inc., All rights reserved.
 *
 * Code generated by protoc-gen-assert-types.  DO NOT EDIT.
 * This file is generated, but its contents are intentionally committed to source control to allow for easier
 * "bootstrapped" builds when building from a fresh checkout.
 */

export enum AssertType {
  ASSERT_TYPE_UNSPECIFIED = 0,
  DEVICE_CONNECTED_TO_CLOUD = 1,
  DEVICE_CONNECTED_TO_FABRIC = 2,
  MANAGEMENT_PORT_CONFIG_SAME = 3,
  PORT_CONNECTION_SPEED_MATCH = 4,
  PORT_EXPECTED_NEIGHBOR = 5,
  PORT_LINK_DOWN = 6,
  PORT_LINK_UP = 7,
  PORT_NOT_CONNECTED_TO_FABRIC = 8,
  PORT_SPEED_CONSISTENT = 9,
  STATIC_DEFAULT_ROUTE_EXISTS = 10,
  VLAN_HAS_TRAFFIC = 11,
  DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD = 12,
  CONFIG_ADDED_TO_DB = 100,
  UNRECOGNIZED = -1,
}

export function assertTypeFromJSON(object: any): AssertType {
  switch (object) {
    case 0:
    case "ASSERT_TYPE_UNSPECIFIED":
      return AssertType.ASSERT_TYPE_UNSPECIFIED;
    case 1:
    case "DEVICE_CONNECTED_TO_CLOUD":
      return AssertType.DEVICE_CONNECTED_TO_CLOUD;
    case 2:
    case "DEVICE_CONNECTED_TO_FABRIC":
      return AssertType.DEVICE_CONNECTED_TO_FABRIC;
    case 3:
    case "MANAGEMENT_PORT_CONFIG_SAME":
      return AssertType.MANAGEMENT_PORT_CONFIG_SAME;
    case 4:
    case "PORT_CONNECTION_SPEED_MATCH":
      return AssertType.PORT_CONNECTION_SPEED_MATCH;
    case 5:
    case "PORT_EXPECTED_NEIGHBOR":
      return AssertType.PORT_EXPECTED_NEIGHBOR;
    case 6:
    case "PORT_LINK_DOWN":
      return AssertType.PORT_LINK_DOWN;
    case 7:
    case "PORT_LINK_UP":
      return AssertType.PORT_LINK_UP;
    case 8:
    case "PORT_NOT_CONNECTED_TO_FABRIC":
      return AssertType.PORT_NOT_CONNECTED_TO_FABRIC;
    case 9:
    case "PORT_SPEED_CONSISTENT":
      return AssertType.PORT_SPEED_CONSISTENT;
    case 10:
    case "STATIC_DEFAULT_ROUTE_EXISTS":
      return AssertType.STATIC_DEFAULT_ROUTE_EXISTS;
    case 11:
    case "VLAN_HAS_TRAFFIC":
      return AssertType.VLAN_HAS_TRAFFIC;
    case 12:
    case "DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD":
      return AssertType.DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD;
    case 100:
    case "CONFIG_ADDED_TO_DB":
      return AssertType.CONFIG_ADDED_TO_DB;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssertType.UNRECOGNIZED;
  }
}

export function assertTypeToJSON(object: AssertType): string {
  switch (object) {
    case AssertType.ASSERT_TYPE_UNSPECIFIED:
      return "ASSERT_TYPE_UNSPECIFIED";
    case AssertType.DEVICE_CONNECTED_TO_CLOUD:
      return "DEVICE_CONNECTED_TO_CLOUD";
    case AssertType.DEVICE_CONNECTED_TO_FABRIC:
      return "DEVICE_CONNECTED_TO_FABRIC";
    case AssertType.MANAGEMENT_PORT_CONFIG_SAME:
      return "MANAGEMENT_PORT_CONFIG_SAME";
    case AssertType.PORT_CONNECTION_SPEED_MATCH:
      return "PORT_CONNECTION_SPEED_MATCH";
    case AssertType.PORT_EXPECTED_NEIGHBOR:
      return "PORT_EXPECTED_NEIGHBOR";
    case AssertType.PORT_LINK_DOWN:
      return "PORT_LINK_DOWN";
    case AssertType.PORT_LINK_UP:
      return "PORT_LINK_UP";
    case AssertType.PORT_NOT_CONNECTED_TO_FABRIC:
      return "PORT_NOT_CONNECTED_TO_FABRIC";
    case AssertType.PORT_SPEED_CONSISTENT:
      return "PORT_SPEED_CONSISTENT";
    case AssertType.STATIC_DEFAULT_ROUTE_EXISTS:
      return "STATIC_DEFAULT_ROUTE_EXISTS";
    case AssertType.VLAN_HAS_TRAFFIC:
      return "VLAN_HAS_TRAFFIC";
    case AssertType.DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD:
      return "DEVICE_RESOURCE_USAGE_BELOW_THRESHOLD";
    case AssertType.CONFIG_ADDED_TO_DB:
      return "CONFIG_ADDED_TO_DB";
    case AssertType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum AssertTransitionStates {
  /** ASSERT_STATE_NO_OP - No transition */
  ASSERT_STATE_NO_OP = 0,
  /** ASSERT_STATE_UNSPECIFIED - From any other state to the unspecified state */
  ASSERT_STATE_UNSPECIFIED = 1,
  /** ASSERT_STATE_UNKNOWN - From any other state to the unknown state */
  ASSERT_STATE_UNKNOWN = 2,
  /** ASSERT_STATE_TRUE - From the unspecified or unknown state to the true state */
  ASSERT_STATE_TRUE = 3,
  /** ASSERT_STATE_FALSE - From any other state to the false state */
  ASSERT_STATE_FALSE = 4,
  /** ASSERT_STATE_CLEARED - From the false state to the true state */
  ASSERT_STATE_CLEARED = 5,
  /** CONFIG_ADDED - Reserved for the CONFIG_ADDED_TO_DB AssertType */
  CONFIG_ADDED = 6,
  UNRECOGNIZED = -1,
}

export function assertTransitionStatesFromJSON(object: any): AssertTransitionStates {
  switch (object) {
    case 0:
    case "ASSERT_STATE_NO_OP":
      return AssertTransitionStates.ASSERT_STATE_NO_OP;
    case 1:
    case "ASSERT_STATE_UNSPECIFIED":
      return AssertTransitionStates.ASSERT_STATE_UNSPECIFIED;
    case 2:
    case "ASSERT_STATE_UNKNOWN":
      return AssertTransitionStates.ASSERT_STATE_UNKNOWN;
    case 3:
    case "ASSERT_STATE_TRUE":
      return AssertTransitionStates.ASSERT_STATE_TRUE;
    case 4:
    case "ASSERT_STATE_FALSE":
      return AssertTransitionStates.ASSERT_STATE_FALSE;
    case 5:
    case "ASSERT_STATE_CLEARED":
      return AssertTransitionStates.ASSERT_STATE_CLEARED;
    case 6:
    case "CONFIG_ADDED":
      return AssertTransitionStates.CONFIG_ADDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssertTransitionStates.UNRECOGNIZED;
  }
}

export function assertTransitionStatesToJSON(object: AssertTransitionStates): string {
  switch (object) {
    case AssertTransitionStates.ASSERT_STATE_NO_OP:
      return "ASSERT_STATE_NO_OP";
    case AssertTransitionStates.ASSERT_STATE_UNSPECIFIED:
      return "ASSERT_STATE_UNSPECIFIED";
    case AssertTransitionStates.ASSERT_STATE_UNKNOWN:
      return "ASSERT_STATE_UNKNOWN";
    case AssertTransitionStates.ASSERT_STATE_TRUE:
      return "ASSERT_STATE_TRUE";
    case AssertTransitionStates.ASSERT_STATE_FALSE:
      return "ASSERT_STATE_FALSE";
    case AssertTransitionStates.ASSERT_STATE_CLEARED:
      return "ASSERT_STATE_CLEARED";
    case AssertTransitionStates.CONFIG_ADDED:
      return "CONFIG_ADDED";
    case AssertTransitionStates.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Assertion {
  assertType: AssertType;
  deviceConnectedToCloud: AssertDeviceConnectedToCloud | undefined;
  deviceConnectedToFabric: AssertDeviceConnectedToFabric | undefined;
  managementPortConfigSame: AssertManagementPortConfigSame | undefined;
  portConnectionSpeedMatch: AssertPortConnectionSpeedMatch | undefined;
  portExpectedNeighbor: AssertPortExpectedNeighbor | undefined;
  portLinkDown: AssertPortLinkDown | undefined;
  portLinkUp: AssertPortLinkUp | undefined;
  portNotConnectedToFabric: AssertPortNotConnectedToFabric | undefined;
  portSpeedConsistent: AssertPortSpeedConsistent | undefined;
  staticDefaultRouteExists: AssertStaticDefaultRouteExists | undefined;
  vlanHasTraffic: AssertVlanHasTraffic | undefined;
  deviceResourceUsageBelowThreshold: AssertDeviceResourceUsageBelowThreshold | undefined;
}

function createBaseAssertion(): Assertion {
  return {
    assertType: 0,
    deviceConnectedToCloud: undefined,
    deviceConnectedToFabric: undefined,
    managementPortConfigSame: undefined,
    portConnectionSpeedMatch: undefined,
    portExpectedNeighbor: undefined,
    portLinkDown: undefined,
    portLinkUp: undefined,
    portNotConnectedToFabric: undefined,
    portSpeedConsistent: undefined,
    staticDefaultRouteExists: undefined,
    vlanHasTraffic: undefined,
    deviceResourceUsageBelowThreshold: undefined,
  };
}

export const Assertion = {
  encode(message: Assertion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.assertType !== 0) {
      writer.uint32(8).int32(message.assertType);
    }
    if (message.deviceConnectedToCloud !== undefined) {
      AssertDeviceConnectedToCloud.encode(message.deviceConnectedToCloud, writer.uint32(18).fork()).ldelim();
    }
    if (message.deviceConnectedToFabric !== undefined) {
      AssertDeviceConnectedToFabric.encode(message.deviceConnectedToFabric, writer.uint32(26).fork()).ldelim();
    }
    if (message.managementPortConfigSame !== undefined) {
      AssertManagementPortConfigSame.encode(message.managementPortConfigSame, writer.uint32(34).fork()).ldelim();
    }
    if (message.portConnectionSpeedMatch !== undefined) {
      AssertPortConnectionSpeedMatch.encode(message.portConnectionSpeedMatch, writer.uint32(42).fork()).ldelim();
    }
    if (message.portExpectedNeighbor !== undefined) {
      AssertPortExpectedNeighbor.encode(message.portExpectedNeighbor, writer.uint32(50).fork()).ldelim();
    }
    if (message.portLinkDown !== undefined) {
      AssertPortLinkDown.encode(message.portLinkDown, writer.uint32(58).fork()).ldelim();
    }
    if (message.portLinkUp !== undefined) {
      AssertPortLinkUp.encode(message.portLinkUp, writer.uint32(66).fork()).ldelim();
    }
    if (message.portNotConnectedToFabric !== undefined) {
      AssertPortNotConnectedToFabric.encode(message.portNotConnectedToFabric, writer.uint32(74).fork()).ldelim();
    }
    if (message.portSpeedConsistent !== undefined) {
      AssertPortSpeedConsistent.encode(message.portSpeedConsistent, writer.uint32(82).fork()).ldelim();
    }
    if (message.staticDefaultRouteExists !== undefined) {
      AssertStaticDefaultRouteExists.encode(message.staticDefaultRouteExists, writer.uint32(90).fork()).ldelim();
    }
    if (message.vlanHasTraffic !== undefined) {
      AssertVlanHasTraffic.encode(message.vlanHasTraffic, writer.uint32(98).fork()).ldelim();
    }
    if (message.deviceResourceUsageBelowThreshold !== undefined) {
      AssertDeviceResourceUsageBelowThreshold.encode(
        message.deviceResourceUsageBelowThreshold,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assertion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.assertType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceConnectedToCloud = AssertDeviceConnectedToCloud.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceConnectedToFabric = AssertDeviceConnectedToFabric.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.managementPortConfigSame = AssertManagementPortConfigSame.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.portConnectionSpeedMatch = AssertPortConnectionSpeedMatch.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.portExpectedNeighbor = AssertPortExpectedNeighbor.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.portLinkDown = AssertPortLinkDown.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.portLinkUp = AssertPortLinkUp.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.portNotConnectedToFabric = AssertPortNotConnectedToFabric.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.portSpeedConsistent = AssertPortSpeedConsistent.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.staticDefaultRouteExists = AssertStaticDefaultRouteExists.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.vlanHasTraffic = AssertVlanHasTraffic.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.deviceResourceUsageBelowThreshold = AssertDeviceResourceUsageBelowThreshold.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Assertion {
    return {
      assertType: isSet(object.assertType) ? assertTypeFromJSON(object.assertType) : 0,
      deviceConnectedToCloud: isSet(object.deviceConnectedToCloud)
        ? AssertDeviceConnectedToCloud.fromJSON(object.deviceConnectedToCloud)
        : undefined,
      deviceConnectedToFabric: isSet(object.deviceConnectedToFabric)
        ? AssertDeviceConnectedToFabric.fromJSON(object.deviceConnectedToFabric)
        : undefined,
      managementPortConfigSame: isSet(object.managementPortConfigSame)
        ? AssertManagementPortConfigSame.fromJSON(object.managementPortConfigSame)
        : undefined,
      portConnectionSpeedMatch: isSet(object.portConnectionSpeedMatch)
        ? AssertPortConnectionSpeedMatch.fromJSON(object.portConnectionSpeedMatch)
        : undefined,
      portExpectedNeighbor: isSet(object.portExpectedNeighbor)
        ? AssertPortExpectedNeighbor.fromJSON(object.portExpectedNeighbor)
        : undefined,
      portLinkDown: isSet(object.portLinkDown) ? AssertPortLinkDown.fromJSON(object.portLinkDown) : undefined,
      portLinkUp: isSet(object.portLinkUp) ? AssertPortLinkUp.fromJSON(object.portLinkUp) : undefined,
      portNotConnectedToFabric: isSet(object.portNotConnectedToFabric)
        ? AssertPortNotConnectedToFabric.fromJSON(object.portNotConnectedToFabric)
        : undefined,
      portSpeedConsistent: isSet(object.portSpeedConsistent)
        ? AssertPortSpeedConsistent.fromJSON(object.portSpeedConsistent)
        : undefined,
      staticDefaultRouteExists: isSet(object.staticDefaultRouteExists)
        ? AssertStaticDefaultRouteExists.fromJSON(object.staticDefaultRouteExists)
        : undefined,
      vlanHasTraffic: isSet(object.vlanHasTraffic) ? AssertVlanHasTraffic.fromJSON(object.vlanHasTraffic) : undefined,
      deviceResourceUsageBelowThreshold: isSet(object.deviceResourceUsageBelowThreshold)
        ? AssertDeviceResourceUsageBelowThreshold.fromJSON(object.deviceResourceUsageBelowThreshold)
        : undefined,
    };
  },

  toJSON(message: Assertion): unknown {
    const obj: any = {};
    if (message.assertType !== 0) {
      obj.assertType = assertTypeToJSON(message.assertType);
    }
    if (message.deviceConnectedToCloud !== undefined) {
      obj.deviceConnectedToCloud = AssertDeviceConnectedToCloud.toJSON(message.deviceConnectedToCloud);
    }
    if (message.deviceConnectedToFabric !== undefined) {
      obj.deviceConnectedToFabric = AssertDeviceConnectedToFabric.toJSON(message.deviceConnectedToFabric);
    }
    if (message.managementPortConfigSame !== undefined) {
      obj.managementPortConfigSame = AssertManagementPortConfigSame.toJSON(message.managementPortConfigSame);
    }
    if (message.portConnectionSpeedMatch !== undefined) {
      obj.portConnectionSpeedMatch = AssertPortConnectionSpeedMatch.toJSON(message.portConnectionSpeedMatch);
    }
    if (message.portExpectedNeighbor !== undefined) {
      obj.portExpectedNeighbor = AssertPortExpectedNeighbor.toJSON(message.portExpectedNeighbor);
    }
    if (message.portLinkDown !== undefined) {
      obj.portLinkDown = AssertPortLinkDown.toJSON(message.portLinkDown);
    }
    if (message.portLinkUp !== undefined) {
      obj.portLinkUp = AssertPortLinkUp.toJSON(message.portLinkUp);
    }
    if (message.portNotConnectedToFabric !== undefined) {
      obj.portNotConnectedToFabric = AssertPortNotConnectedToFabric.toJSON(message.portNotConnectedToFabric);
    }
    if (message.portSpeedConsistent !== undefined) {
      obj.portSpeedConsistent = AssertPortSpeedConsistent.toJSON(message.portSpeedConsistent);
    }
    if (message.staticDefaultRouteExists !== undefined) {
      obj.staticDefaultRouteExists = AssertStaticDefaultRouteExists.toJSON(message.staticDefaultRouteExists);
    }
    if (message.vlanHasTraffic !== undefined) {
      obj.vlanHasTraffic = AssertVlanHasTraffic.toJSON(message.vlanHasTraffic);
    }
    if (message.deviceResourceUsageBelowThreshold !== undefined) {
      obj.deviceResourceUsageBelowThreshold = AssertDeviceResourceUsageBelowThreshold.toJSON(
        message.deviceResourceUsageBelowThreshold,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Assertion>, I>>(base?: I): Assertion {
    return Assertion.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Assertion>, I>>(object: I): Assertion {
    const message = createBaseAssertion();
    message.assertType = object.assertType ?? 0;
    message.deviceConnectedToCloud =
      (object.deviceConnectedToCloud !== undefined && object.deviceConnectedToCloud !== null)
        ? AssertDeviceConnectedToCloud.fromPartial(object.deviceConnectedToCloud)
        : undefined;
    message.deviceConnectedToFabric =
      (object.deviceConnectedToFabric !== undefined && object.deviceConnectedToFabric !== null)
        ? AssertDeviceConnectedToFabric.fromPartial(object.deviceConnectedToFabric)
        : undefined;
    message.managementPortConfigSame =
      (object.managementPortConfigSame !== undefined && object.managementPortConfigSame !== null)
        ? AssertManagementPortConfigSame.fromPartial(object.managementPortConfigSame)
        : undefined;
    message.portConnectionSpeedMatch =
      (object.portConnectionSpeedMatch !== undefined && object.portConnectionSpeedMatch !== null)
        ? AssertPortConnectionSpeedMatch.fromPartial(object.portConnectionSpeedMatch)
        : undefined;
    message.portExpectedNeighbor = (object.portExpectedNeighbor !== undefined && object.portExpectedNeighbor !== null)
      ? AssertPortExpectedNeighbor.fromPartial(object.portExpectedNeighbor)
      : undefined;
    message.portLinkDown = (object.portLinkDown !== undefined && object.portLinkDown !== null)
      ? AssertPortLinkDown.fromPartial(object.portLinkDown)
      : undefined;
    message.portLinkUp = (object.portLinkUp !== undefined && object.portLinkUp !== null)
      ? AssertPortLinkUp.fromPartial(object.portLinkUp)
      : undefined;
    message.portNotConnectedToFabric =
      (object.portNotConnectedToFabric !== undefined && object.portNotConnectedToFabric !== null)
        ? AssertPortNotConnectedToFabric.fromPartial(object.portNotConnectedToFabric)
        : undefined;
    message.portSpeedConsistent = (object.portSpeedConsistent !== undefined && object.portSpeedConsistent !== null)
      ? AssertPortSpeedConsistent.fromPartial(object.portSpeedConsistent)
      : undefined;
    message.staticDefaultRouteExists =
      (object.staticDefaultRouteExists !== undefined && object.staticDefaultRouteExists !== null)
        ? AssertStaticDefaultRouteExists.fromPartial(object.staticDefaultRouteExists)
        : undefined;
    message.vlanHasTraffic = (object.vlanHasTraffic !== undefined && object.vlanHasTraffic !== null)
      ? AssertVlanHasTraffic.fromPartial(object.vlanHasTraffic)
      : undefined;
    message.deviceResourceUsageBelowThreshold =
      (object.deviceResourceUsageBelowThreshold !== undefined && object.deviceResourceUsageBelowThreshold !== null)
        ? AssertDeviceResourceUsageBelowThreshold.fromPartial(object.deviceResourceUsageBelowThreshold)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
