import VFlex from "./vFlex";
import React from "react";
import "./labelFieldContainer.scss";
import { withShowToggle } from "./hoc/withShowToggle";

type LabelSize = "sm" | "md" | "lg";
interface LabelFieldContainerProps {
  readonly name: string; // used for qa tag
  readonly label: string;
  readonly children: React.ReactElement;
  readonly size?: LabelSize;
  readonly required?: boolean;
  readonly classname?: string;
}

export const LabelFieldContainer = withShowToggle(
  (props: LabelFieldContainerProps) => {
    const {
      label,
      children,
      name,
      size = "sm",
      required = false,
      classname
    } = props;
    return (
      <VFlex
        id={name}
        className={`cnc-label-field-container ${classname}`}
        gap={"xs"}
      >
        <div className={`label ${size}`}>
          {label}
          {required && <span className="label-required">*</span>}
        </div>
        {children}
      </VFlex>
    );
  }
);
