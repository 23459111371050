import * as React from "react";
import { useState } from "react";
import { Text } from "@magnetic/text";
import { Info } from "@magnetic/icons";

import { STPStates } from "src/components/tables/columnConfigs/spanningTreePortStateColumn";
import { VFlex } from "src/components";
import { Popover } from "@magnetic/popover";
import { Constants } from "src/utils";
import "./stpLegend.scss";

export const STPLegend = ({
  blockingOnly = false
}: {
  readonly blockingOnly: boolean;
}) => {
  const [items] = useState<React.ReactNode[]>(() => {
    return Object.entries(STPStates)
      .filter(([, { blocking }]) => (blockingOnly ? blocking : true))
      .sort((a, b) => a[1].order - b[1].order)
      .map(([key, { Icon, txt }]) => {
        if (txt !== Constants.EMPTY_VALUE) {
          return (
            <div key={`${key}-${txt}`} className="stp-legend-item">
              {Icon}
              <Text size="p4" weight={400}>
                {txt}
              </Text>
            </div>
          );
        }
        return null;
      });
  });

  return (
    <Popover
      className="stp-legend"
      title={
        <VFlex className="button-bar" gap={8}>
          <Text size="p3" weight="semi-bold">
            STP states
          </Text>
          {items}
        </VFlex>
      }
    >
      <Info className="hover-target" size={14} weight="bold" />
    </Popover>
  );
};
