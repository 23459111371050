import { Message } from "src/gen/schema/syncfollow/syncfollow";
import { StreamManager } from "./streamManager";
import { ErrorHandler } from "./core/streamClient";
import { MD5 } from "src/utils/md5";
import { StreamPool } from "./streamPool";
import { SextantStreamManager } from "./sextantStreamManager";
import {
  MessageManager,
  MessageReducer,
  MessageTransformer
} from "./core/messageManager";
import { BilgeStreamManager } from "./bilgeStreamManager";

export type SuccessCallback<T> = (data: T) => void;

/**
 * Data source pool like DataSource
 * but with a pool of connections
 */
export class PooledDataSource<TKey, TValue extends StreamManager> {
  private pool: StreamPool<TKey, TValue>;

  constructor(sm: StreamPool<TKey, TValue>) {
    this.pool = sm;
  }

  subscribe<TRequest extends Message, TResponse>(
    fabricId: TKey,
    request: TRequest,
    transform: MessageTransformer<TResponse>,
    reducer: MessageReducer<TResponse>,
    onSuccess: SuccessCallback<TResponse>,
    onError: ErrorHandler
  ): () => void {
    /**
     * This is an id based on the request converted
     * to a MD5 hash. if there is a duplicate request
     * they will hash out to the same and, we can
     * reuse that stream.
     */
    const groupId = MD5.hashJson(request);
    /**
     * This will either get an existing stream or
     * create a new one.
     */
    const pool = this.pool.useStream(fabricId);
    if (pool) {
      let stream = pool.getStream(groupId);
      /* no stream so add a new one. */
      if (!stream) {
        stream = pool.addStream(request, transform, reducer);
      }
      if (stream) {
        /**
         * stream.subscribe() returns the cleanup
         * function used to unsubscribe.
         */
        return stream.subscribe(onSuccess, onError);
      }
    }
    /**
     * Because Typescript... there
     * will always be a stream but
     * typescript says it is possible
     * so need to handle the compiler
     * here.
     */
    return MessageManager.unsubscribe;
  }
}

export class SextantDataSource extends PooledDataSource<
  string | undefined,
  SextantStreamManager
> {}

export class BilgeDataSource extends PooledDataSource<
  string | undefined,
  BilgeStreamManager
> {}
