// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: sextant/rest_devices.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { ManagementPort } from "../models/models";

export const protobufPackage = "sextant";

export interface DeviceManagementPortsResponse {
  /** The management port configurations reported by a device. */
  ports: ManagementPort[];
}

export interface GetDeviceManagementPortsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The device id or name. */
  deviceId: string;
}

function createBaseDeviceManagementPortsResponse(): DeviceManagementPortsResponse {
  return { ports: [] };
}

export const DeviceManagementPortsResponse = {
  encode(message: DeviceManagementPortsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ports) {
      ManagementPort.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceManagementPortsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceManagementPortsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ports.push(ManagementPort.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceManagementPortsResponse {
    return {
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => ManagementPort.fromJSON(e)) : [],
    };
  },

  toJSON(message: DeviceManagementPortsResponse): unknown {
    const obj: any = {};
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => ManagementPort.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceManagementPortsResponse>, I>>(base?: I): DeviceManagementPortsResponse {
    return DeviceManagementPortsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceManagementPortsResponse>, I>>(
    object: I,
  ): DeviceManagementPortsResponse {
    const message = createBaseDeviceManagementPortsResponse();
    message.ports = object.ports?.map((e) => ManagementPort.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDeviceManagementPortsRequest(): GetDeviceManagementPortsRequest {
  return { fabricId: "", deviceId: "" };
}

export const GetDeviceManagementPortsRequest = {
  encode(message: GetDeviceManagementPortsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.deviceId !== "") {
      writer.uint32(18).string(message.deviceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeviceManagementPortsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceManagementPortsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceManagementPortsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
    };
  },

  toJSON(message: GetDeviceManagementPortsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeviceManagementPortsRequest>, I>>(base?: I): GetDeviceManagementPortsRequest {
    return GetDeviceManagementPortsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeviceManagementPortsRequest>, I>>(
    object: I,
  ): GetDeviceManagementPortsRequest {
    const message = createBaseGetDeviceManagementPortsRequest();
    message.fabricId = object.fabricId ?? "";
    message.deviceId = object.deviceId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
