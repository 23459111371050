import React, { useCallback, useEffect, useRef, useState } from "react";
import GlobalStore from "./appState";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./provider/dataProvider";
import { logger } from "./globals";
import { router as newRouter } from "./routes";
import { Shell } from "./shell";
import { PageLoader } from "components/pageLoader";
import { LoginContext } from "./loginContext";
import { Apis } from "../utils/api";
import { GetUserSessionResponse } from "../gen/schema/brig/bapi";
import { differenceInSeconds } from "date-fns";
import { MessageManager } from "./streaming/core/messageManager";
import { BILGE_ID } from "src/utils/api/streaming/bilge";
import { setTheme } from "@magnetic/theme";

declare global {
  interface Window {
    cnc: GlobalStore;
    // logger: logger.Logger;
    env: {
      VITE_API_HOST_PORT: string; // map the env variables
    };
  }
}

const switchTenant = (id?: string) => {
  if (id) {
    Apis.Auth.switchTenant(id).then(() => {
      location.pathname = "/fabrics";
    });
  }
};

const refresh = (): Promise<boolean> => {
  return Apis.Auth.refresh()
    .then((resp: Response) => {
      return resp.status === 200;
    })
    .catch((err: Error) => {
      /**
       * if getUserSession fails then
       * the user will be sent to the
       * login screen
       */
      logger.error("Get User Session Exception:", err);
      return false;
    });
};

export const noop = () => {};

const REFRESH_WINDOW = 60 * 5; // 5 minutes in seconds
const IDLE_TIMEOUT = 60 * 30; // 30 minutes in seconds

export default function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [session, setSession] = useState<GetUserSessionResponse>();
  const form = useRef<HTMLFormElement>(null);
  const expiry = session?.expiry;

  const logout = useCallback((e?: React.MouseEvent<unknown>) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setSession(undefined);
    form.current?.submit();
  }, []);

  const getUserSession = useCallback((): Promise<boolean> => {
    return Apis.Brig.getUserSession()
      .then((resp2: GetUserSessionResponse) => {
        /**
         * if getUserSession succeeds then
         * the user has a valid cookie and
         * will go to the landing page.
         */
        setSession(resp2);
        return true;
      })
      .catch(() => {
        return false;
      });
  }, []);

  /**
   * Initialize the App
   */
  useEffect(() => {
    setLoading(true);
    getUserSession().then(() => {
      setLoading(false);
    });

    if (!window.cnc) {
      window.cnc = new GlobalStore();
    }
    logger.setLevel(0); // probably change later for prod

    if (window.cnc.settings.get("darkMode")) {
      setTheme("dark-classic");
    } else {
      setTheme("light-classic");
    }
  }, [getUserSession]);

  useEffect(() => {
    if (session?.orgId) {
      /**
       * Added so we are not trying to
       * connect to the websocket until
       * we have logged in. Otherwise, it
       * will cause error and keep trying
       * to connect. This will manager the
       * org level streaming.
       */
      const bilge = window.cnc.bilgePool.useStream(BILGE_ID);
      if (bilge) {
        bilge.init();
        return () => bilge.close();
      }
    }
    return MessageManager.unsubscribe;
  }, [session?.orgId]);

  /**
   * Initialize the Idle timeout &
   * refresh handler code.
   */
  useEffect(() => {
    if (expiry) {
      let last: Date = new Date();
      const sessionCheck = () => {
        const now = new Date();
        /**
         * TODO: Adjust timout to actual requirement at some point
         * If there has not been any activity within the
         * last 30 minutes, the user is idle and will be logged
         * out of the system
         */
        if (differenceInSeconds(now, last) > IDLE_TIMEOUT) {
          logout();
        }
        if (expiry) {
          /**
           * If the user has not hit the idle timed out,
           * and is within the 5 minutes of the expiry
           * time of the session. The UI will refresh
           * the token to keep it alive.
           */
          if (differenceInSeconds(expiry, now) < REFRESH_WINDOW) {
            refresh().then((success: boolean) => {
              if (success) {
                getUserSession();
              }
            });
          }
        }
      };
      const id: NodeJS.Timeout = setInterval(sessionCheck, 30000);
      /**
       * TORT-3467 WebUI: token refresh timer gets reset upon page load
       * Added to address bug automation
       * testing introduced by refreshing
       * the browser every 5 seconds.
       */
      sessionCheck();

      const body = document.body;
      /**
       * Event handlers for body.onclick, body.oncontextmenu, &
       * body.onmousemove all use the same function pointer to
       * update the Sessions idleSinceMilliseconds. This will
       * be used to drive idle logout.
       */
      body.onclick =
        body.oncontextmenu =
        body.onmousemove =
          () => {
            last = new Date();
          };

      return () => {
        clearInterval(id);
        body.onclick = body.oncontextmenu = body.onmousemove = noop;
      };
    }
    return noop;
  }, [getUserSession, expiry, logout]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <LoginContext.Provider
      value={{ session, getUserSession, logout, switchTenant }}
    >
      <div className="App">
        <form ref={form} method="post" action="/ui/v1/auth/logout" />
        {/**
         * DataProvider needs to be outside
         * the router or the data will not
         * really be shared
         */}
        <DataProvider>
          <BrowserRouter>
            <Shell>
              {/*Commenting out for time being, to prevent scroll to the top */}
              {/*<ScrollToTop />*/}
              {newRouter()}
            </Shell>
          </BrowserRouter>
        </DataProvider>
      </div>
    </LoginContext.Provider>
  );
}
