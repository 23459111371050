import { ActivityTimeline } from "@magnetic/activity-timeline";
import React, { useMemo } from "react";
import { DATE_FORMAT } from "src/core/provider/dataProvider";
import { ActivityEvent, FabricCandidate } from "src/gen/schema/configd/api";
import {
  objectTypeToJSON,
  operationToJSON
} from "src/gen/schema/models/global";
import VFlex from "components/vFlex";
import HFlex from "components/hFlex";
import { compareDesc, format } from "date-fns";
import "./previewChanges.scss";
import { EmptyContainer } from "components/emptyContainer";

interface PreviewChangesProps {
  readonly candidate: FabricCandidate | undefined;
}

export const PreviewChanges = ({ candidate }: PreviewChangesProps) => {
  const items = useMemo(() => {
    if (candidate) {
      const activityEventsMap = new Map<string, ActivityEvent[]>();

      /**
       * Need to sort the events by date and user.
       * That way we can group changes the same
       * way they are displayed in the mockup.
       */
      candidate.events
        .sort((a: ActivityEvent, b: ActivityEvent) => {
          return compareDesc(a.timestamp ?? 0, b.timestamp ?? 0);
        })
        /**
         * Need to now group the events by date and user.
         * using foreach to populate a map. The key for
         * The map will be a string "username|timestamp".
         */
        .forEach((e: ActivityEvent) => {
          const key: string = `${e.username}|${format(
            e.timestamp ?? 0,
            DATE_FORMAT
          )}`;
          const tmp = activityEventsMap.get(key) ?? [];
          activityEventsMap.set(key, [...tmp, e]);
        });

      /**
       * iterate over the map to generate the
       * ActivityTimeline Items and the details
       * of each provisioning event
       */
      const elements: React.ReactElement[] = [];
      activityEventsMap.forEach((v: ActivityEvent[], k: string) => {
        const [user, ts] = k.split("|");
        const activityEvents = v.map((e: ActivityEvent) => {
          const operation = operationToJSON(e.operation).toLowerCase();
          const type = objectTypeToJSON(e.objectType)
            .replace("_", " ")
            .toLowerCase();

          return (
            <span
              className="transaction-event"
              key={`${candidate.txnId}-${type}-${operation}-${e.objectName}`}
            >{`${operation} ${type} "${e.objectName}"`}</span>
          );
        });

        /**
         * ActivityTimeline is not very flexible,
         * I basically need to put all the data in
         * to the header element.
         */
        const content = (
          <VFlex gap={8}>
            <HFlex
              justify="space-between"
              align="center"
              className="transaction-title"
            >
              <span className="created-by">{user}</span>
              <time className="mds-activity-timeline-item-content-time">
                {ts}
              </time>
            </HFlex>
            <VFlex className="transaction-event-list" gap={8}>
              {activityEvents}
            </VFlex>
          </VFlex>
        );

        elements.push(
          <ActivityTimeline.Item
            key={`${user}${ts}`}
            heading={content}
            status="complete"
            time={-1}
          />
        );
      });
      if (elements?.length) {
        return elements;
      }
    }
    return <EmptyContainer text="No changes pending" />;
  }, [candidate]);

  return (
    <div className="preview-changes">
      <ActivityTimeline>{items}</ActivityTimeline>
    </div>
  );
};
