import { Header } from "@magnetic/header";
import { Terminal } from "@magnetic/icons";
import { useContext } from "react";
import { DataProviderContext } from "src/core/provider/dataProvider";
import { useManageMockShellModal } from "./manageMockShellHooks";

export const MockShellGlobalHeader = () => {
  const { fabric } = useContext(DataProviderContext);
  const { openManageMockShellModal, manageMockShellModal } =
    useManageMockShellModal();

  if (!fabric) {
    return null;
  }

  return (
    <>
      <Header.Button
        icon={<Terminal size={22} weight="bold" />}
        label="Quick command"
        onClick={() => {
          openManageMockShellModal(true);
        }}
      />
      {manageMockShellModal}
    </>
  );
};
