// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: syncfollow/syncfollow.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { EchoRequest, EchoResponse } from "../common/echo";
import { SchemaType, schemaTypeFromJSON, schemaTypeToJSON } from "../schema_path/schema_path";
import { DataSeries } from "./syncfollow_threadlist";

export const protobufPackage = "syncfollow";

export enum ClientType {
  CLIENT_UNSPECIFIED = 0,
  LIRA = 1,
  FRED = 2,
  RUDDER = 3,
  AZIMUTH = 4,
  SEXTANT = 5,
  BILGE = 6,
  CONFIGD = 7,
  BRIG = 8,
  KRANG = 9,
  OTHER = 10,
  UNRECOGNIZED = -1,
}

export function clientTypeFromJSON(object: any): ClientType {
  switch (object) {
    case 0:
    case "CLIENT_UNSPECIFIED":
      return ClientType.CLIENT_UNSPECIFIED;
    case 1:
    case "LIRA":
      return ClientType.LIRA;
    case 2:
    case "FRED":
      return ClientType.FRED;
    case 3:
    case "RUDDER":
      return ClientType.RUDDER;
    case 4:
    case "AZIMUTH":
      return ClientType.AZIMUTH;
    case 5:
    case "SEXTANT":
      return ClientType.SEXTANT;
    case 6:
    case "BILGE":
      return ClientType.BILGE;
    case 7:
    case "CONFIGD":
      return ClientType.CONFIGD;
    case 8:
    case "BRIG":
      return ClientType.BRIG;
    case 9:
    case "KRANG":
      return ClientType.KRANG;
    case 10:
    case "OTHER":
      return ClientType.OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClientType.UNRECOGNIZED;
  }
}

export function clientTypeToJSON(object: ClientType): string {
  switch (object) {
    case ClientType.CLIENT_UNSPECIFIED:
      return "CLIENT_UNSPECIFIED";
    case ClientType.LIRA:
      return "LIRA";
    case ClientType.FRED:
      return "FRED";
    case ClientType.RUDDER:
      return "RUDDER";
    case ClientType.AZIMUTH:
      return "AZIMUTH";
    case ClientType.SEXTANT:
      return "SEXTANT";
    case ClientType.BILGE:
      return "BILGE";
    case ClientType.CONFIGD:
      return "CONFIGD";
    case ClientType.BRIG:
      return "BRIG";
    case ClientType.KRANG:
      return "KRANG";
    case ClientType.OTHER:
      return "OTHER";
    case ClientType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ErrorCode {
  ERR_UNSPECIFIED = 0,
  ERR_DESERIALIZATION_ERROR = 1,
  ERR_UNSUPPORTED_TYPE = 2,
  ERR_UNKNOWN_ID = 3,
  ERR_MISSING_ID = 4,
  ERR_SERVER_REJECTED = 5,
  ERR_FORBIDDEN = 6,
  ERR_UNAUTHORIZED = 7,
  ERR_MAX_SYNCS_REACHED = 8,
  ERR_INVALID_FILTER = 9,
  UNRECOGNIZED = -1,
}

export function errorCodeFromJSON(object: any): ErrorCode {
  switch (object) {
    case 0:
    case "ERR_UNSPECIFIED":
      return ErrorCode.ERR_UNSPECIFIED;
    case 1:
    case "ERR_DESERIALIZATION_ERROR":
      return ErrorCode.ERR_DESERIALIZATION_ERROR;
    case 2:
    case "ERR_UNSUPPORTED_TYPE":
      return ErrorCode.ERR_UNSUPPORTED_TYPE;
    case 3:
    case "ERR_UNKNOWN_ID":
      return ErrorCode.ERR_UNKNOWN_ID;
    case 4:
    case "ERR_MISSING_ID":
      return ErrorCode.ERR_MISSING_ID;
    case 5:
    case "ERR_SERVER_REJECTED":
      return ErrorCode.ERR_SERVER_REJECTED;
    case 6:
    case "ERR_FORBIDDEN":
      return ErrorCode.ERR_FORBIDDEN;
    case 7:
    case "ERR_UNAUTHORIZED":
      return ErrorCode.ERR_UNAUTHORIZED;
    case 8:
    case "ERR_MAX_SYNCS_REACHED":
      return ErrorCode.ERR_MAX_SYNCS_REACHED;
    case 9:
    case "ERR_INVALID_FILTER":
      return ErrorCode.ERR_INVALID_FILTER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ErrorCode.UNRECOGNIZED;
  }
}

export function errorCodeToJSON(object: ErrorCode): string {
  switch (object) {
    case ErrorCode.ERR_UNSPECIFIED:
      return "ERR_UNSPECIFIED";
    case ErrorCode.ERR_DESERIALIZATION_ERROR:
      return "ERR_DESERIALIZATION_ERROR";
    case ErrorCode.ERR_UNSUPPORTED_TYPE:
      return "ERR_UNSUPPORTED_TYPE";
    case ErrorCode.ERR_UNKNOWN_ID:
      return "ERR_UNKNOWN_ID";
    case ErrorCode.ERR_MISSING_ID:
      return "ERR_MISSING_ID";
    case ErrorCode.ERR_SERVER_REJECTED:
      return "ERR_SERVER_REJECTED";
    case ErrorCode.ERR_FORBIDDEN:
      return "ERR_FORBIDDEN";
    case ErrorCode.ERR_UNAUTHORIZED:
      return "ERR_UNAUTHORIZED";
    case ErrorCode.ERR_MAX_SYNCS_REACHED:
      return "ERR_MAX_SYNCS_REACHED";
    case ErrorCode.ERR_INVALID_FILTER:
      return "ERR_INVALID_FILTER";
    case ErrorCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FilterOp {
  UNSPECIFIED = 0,
  EQ = 1,
  NOT_EQ = 2,
  STARTS_WITH = 3,
  ENDS_WITH = 4,
  GT = 5,
  GT_EQ = 6,
  LT = 7,
  LT_EQ = 8,
  UNRECOGNIZED = -1,
}

export function filterOpFromJSON(object: any): FilterOp {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return FilterOp.UNSPECIFIED;
    case 1:
    case "EQ":
      return FilterOp.EQ;
    case 2:
    case "NOT_EQ":
      return FilterOp.NOT_EQ;
    case 3:
    case "STARTS_WITH":
      return FilterOp.STARTS_WITH;
    case 4:
    case "ENDS_WITH":
      return FilterOp.ENDS_WITH;
    case 5:
    case "GT":
      return FilterOp.GT;
    case 6:
    case "GT_EQ":
      return FilterOp.GT_EQ;
    case 7:
    case "LT":
      return FilterOp.LT;
    case 8:
    case "LT_EQ":
      return FilterOp.LT_EQ;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FilterOp.UNRECOGNIZED;
  }
}

export function filterOpToJSON(object: FilterOp): string {
  switch (object) {
    case FilterOp.UNSPECIFIED:
      return "UNSPECIFIED";
    case FilterOp.EQ:
      return "EQ";
    case FilterOp.NOT_EQ:
      return "NOT_EQ";
    case FilterOp.STARTS_WITH:
      return "STARTS_WITH";
    case FilterOp.ENDS_WITH:
      return "ENDS_WITH";
    case FilterOp.GT:
      return "GT";
    case FilterOp.GT_EQ:
      return "GT_EQ";
    case FilterOp.LT:
      return "LT";
    case FilterOp.LT_EQ:
      return "LT_EQ";
    case FilterOp.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum QueryType {
  Invalid = 0,
  Sync = 1,
  Follow = 2,
  SyncAndFollow = 3,
  Stop = 4,
  UNRECOGNIZED = -1,
}

export function queryTypeFromJSON(object: any): QueryType {
  switch (object) {
    case 0:
    case "Invalid":
      return QueryType.Invalid;
    case 1:
    case "Sync":
      return QueryType.Sync;
    case 2:
    case "Follow":
      return QueryType.Follow;
    case 3:
    case "SyncAndFollow":
      return QueryType.SyncAndFollow;
    case 4:
    case "Stop":
      return QueryType.Stop;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QueryType.UNRECOGNIZED;
  }
}

export function queryTypeToJSON(object: QueryType): string {
  switch (object) {
    case QueryType.Invalid:
      return "Invalid";
    case QueryType.Sync:
      return "Sync";
    case QueryType.Follow:
      return "Follow";
    case QueryType.SyncAndFollow:
      return "SyncAndFollow";
    case QueryType.Stop:
      return "Stop";
    case QueryType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MarkerType {
  None = 0,
  /** StartOfSync - Indicates the start of a sync section */
  StartOfSync = 1,
  /** EndOfSync - Indicates the end of the sync section */
  EndOfSync = 2,
  /** StartOfFollow - Indicates the start of a follow section */
  StartOfFollow = 3,
  /** UpToDate - Indicates the follow has caught up with the tail of the threadlist */
  UpToDate = 4,
  /** ResumeFailed - Indicates that the resume failed, the client should re-request a full sync */
  ResumeFailed = 5,
  /** StillAlive - Indicates the sync section is still alive */
  StillAlive = 6,
  UNRECOGNIZED = -1,
}

export function markerTypeFromJSON(object: any): MarkerType {
  switch (object) {
    case 0:
    case "None":
      return MarkerType.None;
    case 1:
    case "StartOfSync":
      return MarkerType.StartOfSync;
    case 2:
    case "EndOfSync":
      return MarkerType.EndOfSync;
    case 3:
    case "StartOfFollow":
      return MarkerType.StartOfFollow;
    case 4:
    case "UpToDate":
      return MarkerType.UpToDate;
    case 5:
    case "ResumeFailed":
      return MarkerType.ResumeFailed;
    case 6:
    case "StillAlive":
      return MarkerType.StillAlive;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MarkerType.UNRECOGNIZED;
  }
}

export function markerTypeToJSON(object: MarkerType): string {
  switch (object) {
    case MarkerType.None:
      return "None";
    case MarkerType.StartOfSync:
      return "StartOfSync";
    case MarkerType.EndOfSync:
      return "EndOfSync";
    case MarkerType.StartOfFollow:
      return "StartOfFollow";
    case MarkerType.UpToDate:
      return "UpToDate";
    case MarkerType.ResumeFailed:
      return "ResumeFailed";
    case MarkerType.StillAlive:
      return "StillAlive";
    case MarkerType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Message {
  /**
   * This could use oneof but the code gen does not support it
   * Only set one of these in each message
   */
  request: Request | undefined;
  data: Data | undefined;
  echoRequest: EchoRequest | undefined;
  echoResponse: EchoResponse | undefined;
  error: Error | undefined;
  hello: Hello | undefined;
  simpleRequest: SimpleRequest | undefined;
}

export interface Hello {
  clientType: ClientType;
  clientId: string;
}

export interface Error {
  syncId: string;
  errorMessage: string;
  httpStatusCode: number;
  errorCode: ErrorCode;
  /** If fatal_error is set, the client should disconnect and wait at least backoff_time_ms before retrying */
  fatalError: boolean;
  backoffTimeMs: number;
}

export interface IndexFilter {
  /** index to apply filter to */
  index: SchemaType;
  /** filter operation to apply */
  filterOp: FilterOp;
  /** value to apply to check against the index using the filterOp */
  filterVal: string;
}

export interface ObjectFilter {
  /** a list of index filters to apply */
  indexFilters: IndexFilter[];
  /** a list of types to apply (or empty to apply to all types) */
  types: SchemaType[];
}

export interface MonitoredObjects {
  /** A list of arbitrary object IDs to monitor. */
  objectId: string[];
  /** A list of switch IDs to monitor.  If any switch IDs are not known, the request will fail. */
  switchId: string[];
  /** A list of fabric IDs to monitor. */
  fabricId: string[];
  /** A list of types to monitor.  Every object ID will monitor all types. */
  types: SchemaType[];
  /** optional list of filters to restrict which objects are monitored */
  filters: ObjectFilter[];
}

export interface Request {
  /** Optional - can be set if the client wishes to multiplex multiple streaming result sets. Subsequent requests for the same sync_id will replace any previous sync for that ID */
  syncId: string;
  objects: MonitoredObjects[];
  query: QueryType;
  /** Indicates the offset to resume from for each type. If any single resume_offset cannot be applied then the server will respond with a Data message with a marker of ResumeFailed, client is responsible for re-requesting a full sync */
  resumeOffsets: StreamOffset[];
  /** Indicates that the client wants the path_string field populated for telemetry items. */
  includePathString: boolean;
}

/** SimpleRequest is a simplified version of Request that allows only a SyncAndFollow query for a single object_id and type */
export interface SimpleRequest {
  /** Optional - can be set if the client wishes to multiplex multiple streaming result sets. Subsequent requests for the same sync_id will replace any previous sync for that ID */
  syncId: string;
  /** An arbitrary object ID, together with type idenfifies a single threadlist to monitor. */
  objectId: string;
  /** A single type, together with object_id idenfifies a single threadlist to monitor. */
  type: SchemaType;
}

export interface StreamOffset {
  objectId: string;
  type: SchemaType;
  instance: number;
  offset: number;
}

export interface Data {
  /** Refers back to the sync_id that was set in the request */
  syncId: string;
  /** The data points */
  series:
    | DataSeries
    | undefined;
  /** Start/end of sync marker */
  marker: MarkerType;
  /** Monotonically increasing sequence number for sends on this sync_id. If there are any gaps seen at the receiving end then it should close the connection. */
  sequence: number;
}

function createBaseMessage(): Message {
  return {
    request: undefined,
    data: undefined,
    echoRequest: undefined,
    echoResponse: undefined,
    error: undefined,
    hello: undefined,
    simpleRequest: undefined,
  };
}

export const Message = {
  encode(message: Message, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      Request.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    if (message.data !== undefined) {
      Data.encode(message.data, writer.uint32(18).fork()).ldelim();
    }
    if (message.echoRequest !== undefined) {
      EchoRequest.encode(message.echoRequest, writer.uint32(26).fork()).ldelim();
    }
    if (message.echoResponse !== undefined) {
      EchoResponse.encode(message.echoResponse, writer.uint32(34).fork()).ldelim();
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(42).fork()).ldelim();
    }
    if (message.hello !== undefined) {
      Hello.encode(message.hello, writer.uint32(50).fork()).ldelim();
    }
    if (message.simpleRequest !== undefined) {
      SimpleRequest.encode(message.simpleRequest, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = Request.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = Data.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.echoRequest = EchoRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.echoResponse = EchoResponse.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.hello = Hello.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.simpleRequest = SimpleRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Message {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      data: isSet(object.data) ? Data.fromJSON(object.data) : undefined,
      echoRequest: isSet(object.echoRequest) ? EchoRequest.fromJSON(object.echoRequest) : undefined,
      echoResponse: isSet(object.echoResponse) ? EchoResponse.fromJSON(object.echoResponse) : undefined,
      error: isSet(object.error) ? Error.fromJSON(object.error) : undefined,
      hello: isSet(object.hello) ? Hello.fromJSON(object.hello) : undefined,
      simpleRequest: isSet(object.simpleRequest) ? SimpleRequest.fromJSON(object.simpleRequest) : undefined,
    };
  },

  toJSON(message: Message): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.data !== undefined) {
      obj.data = Data.toJSON(message.data);
    }
    if (message.echoRequest !== undefined) {
      obj.echoRequest = EchoRequest.toJSON(message.echoRequest);
    }
    if (message.echoResponse !== undefined) {
      obj.echoResponse = EchoResponse.toJSON(message.echoResponse);
    }
    if (message.error !== undefined) {
      obj.error = Error.toJSON(message.error);
    }
    if (message.hello !== undefined) {
      obj.hello = Hello.toJSON(message.hello);
    }
    if (message.simpleRequest !== undefined) {
      obj.simpleRequest = SimpleRequest.toJSON(message.simpleRequest);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Message>, I>>(base?: I): Message {
    return Message.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Message>, I>>(object: I): Message {
    const message = createBaseMessage();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.data = (object.data !== undefined && object.data !== null) ? Data.fromPartial(object.data) : undefined;
    message.echoRequest = (object.echoRequest !== undefined && object.echoRequest !== null)
      ? EchoRequest.fromPartial(object.echoRequest)
      : undefined;
    message.echoResponse = (object.echoResponse !== undefined && object.echoResponse !== null)
      ? EchoResponse.fromPartial(object.echoResponse)
      : undefined;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.hello = (object.hello !== undefined && object.hello !== null) ? Hello.fromPartial(object.hello) : undefined;
    message.simpleRequest = (object.simpleRequest !== undefined && object.simpleRequest !== null)
      ? SimpleRequest.fromPartial(object.simpleRequest)
      : undefined;
    return message;
  },
};

function createBaseHello(): Hello {
  return { clientType: 0, clientId: "" };
}

export const Hello = {
  encode(message: Hello, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientType !== 0) {
      writer.uint32(8).int32(message.clientType);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Hello {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHello();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.clientType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Hello {
    return {
      clientType: isSet(object.clientType) ? clientTypeFromJSON(object.clientType) : 0,
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: Hello): unknown {
    const obj: any = {};
    if (message.clientType !== 0) {
      obj.clientType = clientTypeToJSON(message.clientType);
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Hello>, I>>(base?: I): Hello {
    return Hello.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Hello>, I>>(object: I): Hello {
    const message = createBaseHello();
    message.clientType = object.clientType ?? 0;
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseError(): Error {
  return { syncId: "", errorMessage: "", httpStatusCode: 0, errorCode: 0, fatalError: false, backoffTimeMs: 0 };
}

export const Error = {
  encode(message: Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.syncId !== "") {
      writer.uint32(10).string(message.syncId);
    }
    if (message.errorMessage !== "") {
      writer.uint32(18).string(message.errorMessage);
    }
    if (message.httpStatusCode !== 0) {
      writer.uint32(24).uint32(message.httpStatusCode);
    }
    if (message.errorCode !== 0) {
      writer.uint32(32).int32(message.errorCode);
    }
    if (message.fatalError !== false) {
      writer.uint32(40).bool(message.fatalError);
    }
    if (message.backoffTimeMs !== 0) {
      writer.uint32(48).uint32(message.backoffTimeMs);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Error {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.syncId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.httpStatusCode = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.errorCode = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.fatalError = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.backoffTimeMs = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Error {
    return {
      syncId: isSet(object.syncId) ? globalThis.String(object.syncId) : "",
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
      httpStatusCode: isSet(object.httpStatusCode) ? globalThis.Number(object.httpStatusCode) : 0,
      errorCode: isSet(object.errorCode) ? errorCodeFromJSON(object.errorCode) : 0,
      fatalError: isSet(object.fatalError) ? globalThis.Boolean(object.fatalError) : false,
      backoffTimeMs: isSet(object.backoffTimeMs) ? globalThis.Number(object.backoffTimeMs) : 0,
    };
  },

  toJSON(message: Error): unknown {
    const obj: any = {};
    if (message.syncId !== "") {
      obj.syncId = message.syncId;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    if (message.httpStatusCode !== 0) {
      obj.httpStatusCode = Math.round(message.httpStatusCode);
    }
    if (message.errorCode !== 0) {
      obj.errorCode = errorCodeToJSON(message.errorCode);
    }
    if (message.fatalError !== false) {
      obj.fatalError = message.fatalError;
    }
    if (message.backoffTimeMs !== 0) {
      obj.backoffTimeMs = Math.round(message.backoffTimeMs);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Error>, I>>(base?: I): Error {
    return Error.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Error>, I>>(object: I): Error {
    const message = createBaseError();
    message.syncId = object.syncId ?? "";
    message.errorMessage = object.errorMessage ?? "";
    message.httpStatusCode = object.httpStatusCode ?? 0;
    message.errorCode = object.errorCode ?? 0;
    message.fatalError = object.fatalError ?? false;
    message.backoffTimeMs = object.backoffTimeMs ?? 0;
    return message;
  },
};

function createBaseIndexFilter(): IndexFilter {
  return { index: 0, filterOp: 0, filterVal: "" };
}

export const IndexFilter = {
  encode(message: IndexFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.index !== 0) {
      writer.uint32(8).int32(message.index);
    }
    if (message.filterOp !== 0) {
      writer.uint32(16).int32(message.filterOp);
    }
    if (message.filterVal !== "") {
      writer.uint32(26).string(message.filterVal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IndexFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIndexFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.index = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.filterOp = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filterVal = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IndexFilter {
    return {
      index: isSet(object.index) ? schemaTypeFromJSON(object.index) : 0,
      filterOp: isSet(object.filterOp) ? filterOpFromJSON(object.filterOp) : 0,
      filterVal: isSet(object.filterVal) ? globalThis.String(object.filterVal) : "",
    };
  },

  toJSON(message: IndexFilter): unknown {
    const obj: any = {};
    if (message.index !== 0) {
      obj.index = schemaTypeToJSON(message.index);
    }
    if (message.filterOp !== 0) {
      obj.filterOp = filterOpToJSON(message.filterOp);
    }
    if (message.filterVal !== "") {
      obj.filterVal = message.filterVal;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IndexFilter>, I>>(base?: I): IndexFilter {
    return IndexFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IndexFilter>, I>>(object: I): IndexFilter {
    const message = createBaseIndexFilter();
    message.index = object.index ?? 0;
    message.filterOp = object.filterOp ?? 0;
    message.filterVal = object.filterVal ?? "";
    return message;
  },
};

function createBaseObjectFilter(): ObjectFilter {
  return { indexFilters: [], types: [] };
}

export const ObjectFilter = {
  encode(message: ObjectFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.indexFilters) {
      IndexFilter.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.types) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObjectFilter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObjectFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.indexFilters.push(IndexFilter.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag === 16) {
            message.types.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.types.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ObjectFilter {
    return {
      indexFilters: globalThis.Array.isArray(object?.indexFilters)
        ? object.indexFilters.map((e: any) => IndexFilter.fromJSON(e))
        : [],
      types: globalThis.Array.isArray(object?.types) ? object.types.map((e: any) => schemaTypeFromJSON(e)) : [],
    };
  },

  toJSON(message: ObjectFilter): unknown {
    const obj: any = {};
    if (message.indexFilters?.length) {
      obj.indexFilters = message.indexFilters.map((e) => IndexFilter.toJSON(e));
    }
    if (message.types?.length) {
      obj.types = message.types.map((e) => schemaTypeToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ObjectFilter>, I>>(base?: I): ObjectFilter {
    return ObjectFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ObjectFilter>, I>>(object: I): ObjectFilter {
    const message = createBaseObjectFilter();
    message.indexFilters = object.indexFilters?.map((e) => IndexFilter.fromPartial(e)) || [];
    message.types = object.types?.map((e) => e) || [];
    return message;
  },
};

function createBaseMonitoredObjects(): MonitoredObjects {
  return { objectId: [], switchId: [], fabricId: [], types: [], filters: [] };
}

export const MonitoredObjects = {
  encode(message: MonitoredObjects, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectId) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.switchId) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.fabricId) {
      writer.uint32(26).string(v!);
    }
    writer.uint32(34).fork();
    for (const v of message.types) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.filters) {
      ObjectFilter.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MonitoredObjects {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMonitoredObjects();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectId.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.switchId.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId.push(reader.string());
          continue;
        case 4:
          if (tag === 32) {
            message.types.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.types.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.filters.push(ObjectFilter.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MonitoredObjects {
    return {
      objectId: globalThis.Array.isArray(object?.objectId) ? object.objectId.map((e: any) => globalThis.String(e)) : [],
      switchId: globalThis.Array.isArray(object?.switchId) ? object.switchId.map((e: any) => globalThis.String(e)) : [],
      fabricId: globalThis.Array.isArray(object?.fabricId) ? object.fabricId.map((e: any) => globalThis.String(e)) : [],
      types: globalThis.Array.isArray(object?.types) ? object.types.map((e: any) => schemaTypeFromJSON(e)) : [],
      filters: globalThis.Array.isArray(object?.filters)
        ? object.filters.map((e: any) => ObjectFilter.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MonitoredObjects): unknown {
    const obj: any = {};
    if (message.objectId?.length) {
      obj.objectId = message.objectId;
    }
    if (message.switchId?.length) {
      obj.switchId = message.switchId;
    }
    if (message.fabricId?.length) {
      obj.fabricId = message.fabricId;
    }
    if (message.types?.length) {
      obj.types = message.types.map((e) => schemaTypeToJSON(e));
    }
    if (message.filters?.length) {
      obj.filters = message.filters.map((e) => ObjectFilter.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MonitoredObjects>, I>>(base?: I): MonitoredObjects {
    return MonitoredObjects.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MonitoredObjects>, I>>(object: I): MonitoredObjects {
    const message = createBaseMonitoredObjects();
    message.objectId = object.objectId?.map((e) => e) || [];
    message.switchId = object.switchId?.map((e) => e) || [];
    message.fabricId = object.fabricId?.map((e) => e) || [];
    message.types = object.types?.map((e) => e) || [];
    message.filters = object.filters?.map((e) => ObjectFilter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRequest(): Request {
  return { syncId: "", objects: [], query: 0, resumeOffsets: [], includePathString: false };
}

export const Request = {
  encode(message: Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.syncId !== "") {
      writer.uint32(10).string(message.syncId);
    }
    for (const v of message.objects) {
      MonitoredObjects.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.query !== 0) {
      writer.uint32(48).int32(message.query);
    }
    for (const v of message.resumeOffsets) {
      StreamOffset.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.includePathString !== false) {
      writer.uint32(80).bool(message.includePathString);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.syncId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.objects.push(MonitoredObjects.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.query = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.resumeOffsets.push(StreamOffset.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.includePathString = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Request {
    return {
      syncId: isSet(object.syncId) ? globalThis.String(object.syncId) : "",
      objects: globalThis.Array.isArray(object?.objects)
        ? object.objects.map((e: any) => MonitoredObjects.fromJSON(e))
        : [],
      query: isSet(object.query) ? queryTypeFromJSON(object.query) : 0,
      resumeOffsets: globalThis.Array.isArray(object?.resumeOffsets)
        ? object.resumeOffsets.map((e: any) => StreamOffset.fromJSON(e))
        : [],
      includePathString: isSet(object.includePathString) ? globalThis.Boolean(object.includePathString) : false,
    };
  },

  toJSON(message: Request): unknown {
    const obj: any = {};
    if (message.syncId !== "") {
      obj.syncId = message.syncId;
    }
    if (message.objects?.length) {
      obj.objects = message.objects.map((e) => MonitoredObjects.toJSON(e));
    }
    if (message.query !== 0) {
      obj.query = queryTypeToJSON(message.query);
    }
    if (message.resumeOffsets?.length) {
      obj.resumeOffsets = message.resumeOffsets.map((e) => StreamOffset.toJSON(e));
    }
    if (message.includePathString !== false) {
      obj.includePathString = message.includePathString;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Request>, I>>(base?: I): Request {
    return Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Request>, I>>(object: I): Request {
    const message = createBaseRequest();
    message.syncId = object.syncId ?? "";
    message.objects = object.objects?.map((e) => MonitoredObjects.fromPartial(e)) || [];
    message.query = object.query ?? 0;
    message.resumeOffsets = object.resumeOffsets?.map((e) => StreamOffset.fromPartial(e)) || [];
    message.includePathString = object.includePathString ?? false;
    return message;
  },
};

function createBaseSimpleRequest(): SimpleRequest {
  return { syncId: "", objectId: "", type: 0 };
}

export const SimpleRequest = {
  encode(message: SimpleRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.syncId !== "") {
      writer.uint32(10).string(message.syncId);
    }
    if (message.objectId !== "") {
      writer.uint32(18).string(message.objectId);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.syncId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SimpleRequest {
    return {
      syncId: isSet(object.syncId) ? globalThis.String(object.syncId) : "",
      objectId: isSet(object.objectId) ? globalThis.String(object.objectId) : "",
      type: isSet(object.type) ? schemaTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: SimpleRequest): unknown {
    const obj: any = {};
    if (message.syncId !== "") {
      obj.syncId = message.syncId;
    }
    if (message.objectId !== "") {
      obj.objectId = message.objectId;
    }
    if (message.type !== 0) {
      obj.type = schemaTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SimpleRequest>, I>>(base?: I): SimpleRequest {
    return SimpleRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SimpleRequest>, I>>(object: I): SimpleRequest {
    const message = createBaseSimpleRequest();
    message.syncId = object.syncId ?? "";
    message.objectId = object.objectId ?? "";
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseStreamOffset(): StreamOffset {
  return { objectId: "", type: 0, instance: 0, offset: 0 };
}

export const StreamOffset = {
  encode(message: StreamOffset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.objectId !== "") {
      writer.uint32(10).string(message.objectId);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.instance !== 0) {
      writer.uint32(24).uint32(message.instance);
    }
    if (message.offset !== 0) {
      writer.uint32(32).uint64(message.offset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StreamOffset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStreamOffset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.instance = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.offset = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StreamOffset {
    return {
      objectId: isSet(object.objectId) ? globalThis.String(object.objectId) : "",
      type: isSet(object.type) ? schemaTypeFromJSON(object.type) : 0,
      instance: isSet(object.instance) ? globalThis.Number(object.instance) : 0,
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
    };
  },

  toJSON(message: StreamOffset): unknown {
    const obj: any = {};
    if (message.objectId !== "") {
      obj.objectId = message.objectId;
    }
    if (message.type !== 0) {
      obj.type = schemaTypeToJSON(message.type);
    }
    if (message.instance !== 0) {
      obj.instance = Math.round(message.instance);
    }
    if (message.offset !== 0) {
      obj.offset = Math.round(message.offset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StreamOffset>, I>>(base?: I): StreamOffset {
    return StreamOffset.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StreamOffset>, I>>(object: I): StreamOffset {
    const message = createBaseStreamOffset();
    message.objectId = object.objectId ?? "";
    message.type = object.type ?? 0;
    message.instance = object.instance ?? 0;
    message.offset = object.offset ?? 0;
    return message;
  },
};

function createBaseData(): Data {
  return { syncId: "", series: undefined, marker: 0, sequence: 0 };
}

export const Data = {
  encode(message: Data, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.syncId !== "") {
      writer.uint32(10).string(message.syncId);
    }
    if (message.series !== undefined) {
      DataSeries.encode(message.series, writer.uint32(18).fork()).ldelim();
    }
    if (message.marker !== 0) {
      writer.uint32(24).int32(message.marker);
    }
    if (message.sequence !== 0) {
      writer.uint32(32).uint64(message.sequence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Data {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.syncId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.series = DataSeries.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.marker = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sequence = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Data {
    return {
      syncId: isSet(object.syncId) ? globalThis.String(object.syncId) : "",
      series: isSet(object.series) ? DataSeries.fromJSON(object.series) : undefined,
      marker: isSet(object.marker) ? markerTypeFromJSON(object.marker) : 0,
      sequence: isSet(object.sequence) ? globalThis.Number(object.sequence) : 0,
    };
  },

  toJSON(message: Data): unknown {
    const obj: any = {};
    if (message.syncId !== "") {
      obj.syncId = message.syncId;
    }
    if (message.series !== undefined) {
      obj.series = DataSeries.toJSON(message.series);
    }
    if (message.marker !== 0) {
      obj.marker = markerTypeToJSON(message.marker);
    }
    if (message.sequence !== 0) {
      obj.sequence = Math.round(message.sequence);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Data>, I>>(base?: I): Data {
    return Data.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Data>, I>>(object: I): Data {
    const message = createBaseData();
    message.syncId = object.syncId ?? "";
    message.series = (object.series !== undefined && object.series !== null)
      ? DataSeries.fromPartial(object.series)
      : undefined;
    message.marker = object.marker ?? 0;
    message.sequence = object.sequence ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
