// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: bilge/service.proto

/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { EchoRequest, EchoResponse } from "../common/echo";
import { Empty } from "../common/empty";
import {
  CablingRequest,
  CablingResponse,
  CliCommandRequest,
  CliCommandResponse,
  DeleteLinkedAccountRequest,
  GetCandidateInfoWithPasscodeRequest,
  GetCandidateInfoWithPasscodeResponse,
  GetLinkedAccountsRequest,
  GetLinkedAccountsResponse,
  SetLinkedAccountRequest,
  SetLinkedAccountResponse,
  UpdateLinkedAccountRequest,
  UpdateLinkedAccountResponse,
} from "./api";

export const protobufPackage = "bilge";

export interface Bilge {
  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse>;
  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse>;
  /** Create a new chatbot linked account. */
  SetLinkedAccount(
    request: DeepPartial<SetLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetLinkedAccountResponse>;
  /**
   * Update an existing chatbot linked account.
   *
   * The update can enable/disable the admin state of the linked account.
   */
  UpdateLinkedAccount(
    request: DeepPartial<UpdateLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateLinkedAccountResponse>;
  /** Mark an existing chatbot linked account as DELETED. */
  DeleteLinkedAccount(request: DeepPartial<DeleteLinkedAccountRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Get a set of existing linked accounts.
   *
   * The API caller's organization is used implicitly as a request filter.
   * A chat_platform query parameter can be set to retrieve the records specific to that platform.
   */
  GetLinkedAccounts(
    request: DeepPartial<GetLinkedAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetLinkedAccountsResponse>;
  /**
   * Get a linked account candidate associated with a passcode.
   *
   * A linked account candidate contains all information to identify a remote chatbot user account.
   */
  GetChatAccountCandidateWithPasscode(
    request: DeepPartial<GetCandidateInfoWithPasscodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCandidateInfoWithPasscodeResponse>;
  /** Run a CLI command */
  CliCommand(request: DeepPartial<CliCommandRequest>, metadata?: grpc.Metadata): Promise<CliCommandResponse>;
  /** Request a cabling solution */
  Cabling(request: DeepPartial<CablingRequest>, metadata?: grpc.Metadata): Promise<CablingResponse>;
}

export class BilgeClientImpl implements Bilge {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Echo = this.Echo.bind(this);
    this.StreamEcho = this.StreamEcho.bind(this);
    this.SetLinkedAccount = this.SetLinkedAccount.bind(this);
    this.UpdateLinkedAccount = this.UpdateLinkedAccount.bind(this);
    this.DeleteLinkedAccount = this.DeleteLinkedAccount.bind(this);
    this.GetLinkedAccounts = this.GetLinkedAccounts.bind(this);
    this.GetChatAccountCandidateWithPasscode = this.GetChatAccountCandidateWithPasscode.bind(this);
    this.CliCommand = this.CliCommand.bind(this);
    this.Cabling = this.Cabling.bind(this);
  }

  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse> {
    return this.rpc.unary(BilgeEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse> {
    return this.rpc.invoke(BilgeStreamEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  SetLinkedAccount(
    request: DeepPartial<SetLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetLinkedAccountResponse> {
    return this.rpc.unary(BilgeSetLinkedAccountDesc, SetLinkedAccountRequest.fromPartial(request), metadata);
  }

  UpdateLinkedAccount(
    request: DeepPartial<UpdateLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateLinkedAccountResponse> {
    return this.rpc.unary(BilgeUpdateLinkedAccountDesc, UpdateLinkedAccountRequest.fromPartial(request), metadata);
  }

  DeleteLinkedAccount(request: DeepPartial<DeleteLinkedAccountRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(BilgeDeleteLinkedAccountDesc, DeleteLinkedAccountRequest.fromPartial(request), metadata);
  }

  GetLinkedAccounts(
    request: DeepPartial<GetLinkedAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetLinkedAccountsResponse> {
    return this.rpc.unary(BilgeGetLinkedAccountsDesc, GetLinkedAccountsRequest.fromPartial(request), metadata);
  }

  GetChatAccountCandidateWithPasscode(
    request: DeepPartial<GetCandidateInfoWithPasscodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCandidateInfoWithPasscodeResponse> {
    return this.rpc.unary(
      BilgeGetChatAccountCandidateWithPasscodeDesc,
      GetCandidateInfoWithPasscodeRequest.fromPartial(request),
      metadata,
    );
  }

  CliCommand(request: DeepPartial<CliCommandRequest>, metadata?: grpc.Metadata): Promise<CliCommandResponse> {
    return this.rpc.unary(BilgeCliCommandDesc, CliCommandRequest.fromPartial(request), metadata);
  }

  Cabling(request: DeepPartial<CablingRequest>, metadata?: grpc.Metadata): Promise<CablingResponse> {
    return this.rpc.unary(BilgeCablingDesc, CablingRequest.fromPartial(request), metadata);
  }
}

export const BilgeDesc = { serviceName: "bilge.Bilge" };

export const BilgeEchoDesc: UnaryMethodDefinitionish = {
  methodName: "Echo",
  service: BilgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BilgeStreamEchoDesc: UnaryMethodDefinitionish = {
  methodName: "StreamEcho",
  service: BilgeDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BilgeSetLinkedAccountDesc: UnaryMethodDefinitionish = {
  methodName: "SetLinkedAccount",
  service: BilgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetLinkedAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetLinkedAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BilgeUpdateLinkedAccountDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateLinkedAccount",
  service: BilgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateLinkedAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateLinkedAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BilgeDeleteLinkedAccountDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteLinkedAccount",
  service: BilgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteLinkedAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BilgeGetLinkedAccountsDesc: UnaryMethodDefinitionish = {
  methodName: "GetLinkedAccounts",
  service: BilgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLinkedAccountsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetLinkedAccountsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BilgeGetChatAccountCandidateWithPasscodeDesc: UnaryMethodDefinitionish = {
  methodName: "GetChatAccountCandidateWithPasscode",
  service: BilgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCandidateInfoWithPasscodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCandidateInfoWithPasscodeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BilgeCliCommandDesc: UnaryMethodDefinitionish = {
  methodName: "CliCommand",
  service: BilgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CliCommandRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CliCommandResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BilgeCablingDesc: UnaryMethodDefinitionish = {
  methodName: "Cabling",
  service: BilgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CablingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CablingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes ?? [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const transport = this.options.streamingTransport ?? this.options.transport;
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          ...(transport !== undefined ? { transport } : {}),
          metadata: maybeCombinedMetadata ?? {},
          debug: this.options.debug ?? false,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
