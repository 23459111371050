import * as React from "react";
import { JSONTree } from "react-json-tree";
import { grpc } from "grpc-web-client";
import "./dataViewer.scss";

// Data Viewer: can handle protobuf messages and json formats
interface DataViewerProps {
  readonly data:
    | grpc.ProtobufMessage
    | grpc.ProtobufMessage[]
    | unknown
    | undefined;
  readonly expandDepth?: number;
  readonly hideRoot?: boolean;
}

// JSON Viewer
interface JsonProps {
  readonly data: unknown | undefined;
  readonly expandDepth?: number;
  readonly hideRoot?: boolean;
}

function JsonViewer({
  data = undefined,
  expandDepth = 1,
  hideRoot = false
}: JsonProps) {
  const getExpandDepth = React.useCallback(
    (
      keyPath: readonly (string | number)[],
      _data: [unknown] | unknown,
      level: number
    ): boolean => {
      return level < (expandDepth || 0); // or should never happen as its a default prop
    },
    [expandDepth]
  );

  const theme = React.useMemo(() => {
    return {
      scheme: "monokai",
      author: "wimer hazenberg (http://www.monokai.nl)",
      base00: "#272822",
      base01: "#383830",
      base02: "#49483e",
      base03: "#75715e",
      base04: "#a59f85",
      base05: "#f8f8f2",
      base06: "#f5f4f1",
      base07: "#f9f8f5",
      base08: "#f92672",
      base09: "#fd971f",
      base0A: "#f4bf75",
      base0B: "#a6e22e",
      base0C: "#a1efe4",
      base0D: "#66d9ef",
      base0E: "#ae81ff",
      base0F: "#cc6633"
    };
  }, []);

  if (data) {
    return (
      <div className={"xw-data-viewer"}>
        <JSONTree
          theme={theme}
          invertTheme={true}
          data={data}
          shouldExpandNodeInitially={getExpandDepth}
          hideRoot={hideRoot}
        />
      </div>
    );
  } else {
    return <span>...</span>;
  }
}

export default function DataViewer({
  data = undefined,
  expandDepth = 3,
  hideRoot = false
}: DataViewerProps) {
  let dataObj;
  if (data === undefined) {
    dataObj = undefined;
  } else if (!!(data as grpc.ProtobufMessage).toObject) {
    dataObj = (data as grpc.ProtobufMessage).toObject();
    // } else if (
    //   (this.props.data as grpc.ProtobufMessage[]).length
    //    && (this.props.data as grpc.ProtobufMessage[])[0]?.toObject?()
    // ) {
    //   data = (this.props.data as grpc.ProtobufMessage[]).map((d) => {
    //     return d.toObject();
    //   });
  } else {
    // assume json compatible
    dataObj = data;
  }

  return (
    <JsonViewer data={dataObj} expandDepth={expandDepth} hideRoot={hideRoot} />
  );
}
