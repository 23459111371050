import { LineChart } from "@magnetic/charts";
import { Container } from "@magnetic/container";
import { Header } from "@magnetic/header";
import { Heading } from "@magnetic/heading";
import { Link } from "@magnetic/link";
import { useEffect, useMemo, useState, useTransition } from "react";
import HFlex from "../components/hFlex";
import {
  NumberColumn,
  StringColumn,
  TimeColumn
} from "../components/tables/columnConfigs";
import { CopyColumn } from "../components/tables/columnConfigs/copyColumn";
import TypedTable from "../components/typedTable";
import VFlex from "../components/vFlex";
import useElementResizeObserver from "../hooks/useElementResizeObserver";
import { StreamingCachePrintType } from "./dataflow/streamingCache";
import { JsxColumn } from "../components/tables/columnConfigs/jsxColumn";
import { Stream } from "./streaming/core/stream";
import { Message } from "../gen/schema/syncfollow/syncfollow";
import DataViewer from "src/components/dataViewer";

type DataPoint = {
  id: number;
  x: number; // timestamp
  y: number;
  kind: string;
};

const StreamingMetricsChart = (props: {
  readonly openSeries: DataPoint[];
  readonly pendingSeries: DataPoint[];
}) => {
  const [containerRef, width] = useElementResizeObserver<HTMLDivElement>();

  return (
    <div ref={containerRef} className="port-counter-chart">
      <LineChart
        animate={true}
        motionConfig="stiff"
        colors={["#049fd9", "#cc2d37"]}
        axes={{
          xAxis: {
            fontSize: 12,
            format: (d) => new Date(d).toLocaleTimeString(),
            tickValues: 3
          },
          yAxis: {
            fontSize: 12,
            tickValues: 2
          }
        }}
        baseline={1}
        fill={false}
        data={
          [
            {
              data: props.openSeries,
              id: "open"
            },
            {
              data: props.pendingSeries,
              id: "pending"
            }
          ] as any /* eslint-disable-line @typescript-eslint/no-explicit-any */
          // need any because of chart implementation
        }
        fontSize={12}
        height={200}
        lineWidth={3}
        width={width}
        gridX={true}
        gridY={true}
        theme={{
          fontSize: 12,
          margin: {
            bottom: 40,
            left: 80,
            right: 0,
            top: 20
          }
        }}
      />
    </div>
  );
};

const StreamingCacheEntriesTable = (props: {
  readonly data: StreamingCachePrintType[];
}) => {
  const columns = useMemo(() => {
    return {
      service: new StringColumn({
        label: "Service"
      }),
      schemaType: new StringColumn({
        label: "Schema Type"
      }),
      objectId: new StringColumn({
        label: "ObjectId"
      }),
      lastMessage: new TimeColumn({
        label: "Last Message"
      }),
      messages: new NumberColumn({
        label: "Messages"
      }),
      observers: new NumberColumn({
        label: "Observers"
      }),
      dataJsx: new JsxColumn({
        label: "Merged Data"
      }),
      data: new CopyColumn({
        label: "Copy Data"
      }),
      syncatLink: new JsxColumn({
        label: "Sync Monitor"
      }),
      requestJsx: new JsxColumn({
        label: "Actual Request"
      })
    };
  }, []);

  // inject a link that has a link to the syncat tool with filters prepopulated via url parameters
  const data = props.data.map((d) => {
    let link = "/dev/syncfollow?schemaType=" + d.schemaType;

    const objectId = d.objectId || d.switchId || d.fabricId;
    link += "&objectId=" + objectId;
    link += "&url=" + encodeURIComponent(d.url);

    return {
      ...d,
      ...{
        syncatLink: (
          <Link className="cnc-small-font" href={link}>
            Run
          </Link>
        ),
        objectId: objectId
      },
      dataJsx: <DataViewer data={d.data} expandDepth={0} />,
      requestJsx: <DataViewer data={d.requestObject} expandDepth={0} />
    };
  });

  return <TypedTable name="streamingCache" columns={columns} data={data} />;
};

// goal of this component is to make it easy to monitor current streams
export const StreamingCacheMonitor = (): JSX.Element => {
  const POLL_INTERVAL_MS = 200;
  const MAX_POINTS = 600;

  const width =
    // @ts-ignore
    document.getElementsByClassName("cnc-page-layout")[0]?.offsetWidth - 92;

  const CONTAINER_STYLE = {
    width: width,
    height: "85vh",
    overflow: "scroll"
  };

  // Add streams by type to component state.
  const [openSeries, setOpenSeries] = useState<DataPoint[]>([]);
  const [lastOpenCount, setLastOpenCount] = useState<number>();
  const [openingSeries, setOpeningSeries] = useState<DataPoint[]>([]);
  const [lastOpeningCount, setLastOpeningCount] = useState<number>();
  const [cacheContent, setCacheContent] = useState<StreamingCachePrintType[]>(
    []
  );

  const [, startTransition] = useTransition();

  // poll cache status every interval and update the series state data accordingly
  useEffect(() => {
    const interval = setInterval(() => {
      // non-blocking
      startTransition(() => {
        const now = Date.now();
        const openStreams = window.cnc.getStreams();
        const openStreamCount = openStreams.length;
        const consumers = openStreams.reduce(
          (count: number, s: Stream<Message, unknown>) => {
            return count + s.followers();
          },
          0
        );

        setOpenSeries((open: DataPoint[]) => {
          // add new data point and truncate
          return open
            .concat([
              {
                id: now, // stable id to make the animations blend, this may work when meraki fixes their chart
                kind: "a",
                x: now,
                y: openStreamCount
              }
            ])
            .slice(-MAX_POINTS); // trucate series to n max points])
        });
        setLastOpenCount(openStreamCount);

        setOpeningSeries((opening: DataPoint[]) => {
          // add new data point and truncate
          return opening
            .concat([
              {
                id: now, // stable id to make the animations blend, this may work when meraki fixes their chart
                kind: "b",
                x: now,
                y: consumers
              }
            ])
            .slice(-MAX_POINTS); // trucate series to n max points
        });
        setLastOpeningCount(consumers);

        // update cache content data for table view/export
        setCacheContent(window.cnc.showContent());
      });
    }, POLL_INTERVAL_MS);
    return () => clearInterval(interval);
  }, []);

  // Render two buttons in the header, first indicating open connections (check), second pending unopen connections (bell)
  return (
    <>
      <Header.Dropdown
        icon="tasks"
        label="Streaming Monitor"
        panel={true}
        alert={lastOpenCount}
      >
        <Container style={CONTAINER_STYLE}>
          <HFlex>
            <Heading>Streaming Monitor</Heading>
            <VFlex gap="xxs">
              <span>
                <span>{"Open Streams: "}</span>
                <span style={{ color: "#049fd9" }}>{lastOpenCount}</span>
              </span>
              <span>
                <span>{"Total Consumers: "}</span>
                <span style={{ color: "#cc2d37" }}>{lastOpeningCount}</span>
              </span>
            </VFlex>
          </HFlex>
          <StreamingMetricsChart
            openSeries={openSeries}
            pendingSeries={openingSeries}
          />
          <StreamingCacheEntriesTable data={cacheContent} />
        </Container>
      </Header.Dropdown>
    </>
  );
};
