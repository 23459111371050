import { Notification } from "@magnetic/notification";
import React, { useContext } from "react";
import { ApiError } from "src/core/apiError";
import {
  CandidateMode,
  DataProviderContext
} from "../core/provider/dataProvider";
import { ErrorCode } from "src/gen/schema/syncfollow/syncfollow";

interface ErrorNotificationProps {
  readonly error?: ApiError;
  readonly msg?: string;
  readonly onClose?: () => void;
}

export const ErrorNotification = ({
  error,
  msg,
  onClose
}: ErrorNotificationProps) => {
  if (error || msg) {
    const text = error ? error.getMessage() : msg;
    return (
      <Notification title="Error" status="negative" onClose={onClose}>
        {text}
      </Notification>
    );
  }
  return null;
};

/**
 * Error for managing Sextant specific errors
 * @constructor
 */
export const SextantErrorNotification = ({
  error,
  msg
}: ErrorNotificationProps) => {
  const { mode } = useContext(DataProviderContext);
  if (error) {
    if (
      mode === CandidateMode.Edit &&
      error.getCode() === ErrorCode.ERR_FORBIDDEN
    ) {
      return null;
    }
  }
  return <ErrorNotification error={error} msg={msg} />;
};
