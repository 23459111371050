// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/chatbot.proto

/* eslint-disable */

export const protobufPackage = "models";

/** Supported chatbot platforms */
export enum ChatPlatform {
  PLATFORM_UNSPECIFIED = 0,
  PLATFORM_DISCORD = 1,
  PLATFORM_WEBEX = 2,
  PLATFORM_SLACK = 3,
  PLATFORM_MS_TEAMS = 4,
  UNRECOGNIZED = -1,
}

export function chatPlatformFromJSON(object: any): ChatPlatform {
  switch (object) {
    case 0:
    case "PLATFORM_UNSPECIFIED":
      return ChatPlatform.PLATFORM_UNSPECIFIED;
    case 1:
    case "PLATFORM_DISCORD":
      return ChatPlatform.PLATFORM_DISCORD;
    case 2:
    case "PLATFORM_WEBEX":
      return ChatPlatform.PLATFORM_WEBEX;
    case 3:
    case "PLATFORM_SLACK":
      return ChatPlatform.PLATFORM_SLACK;
    case 4:
    case "PLATFORM_MS_TEAMS":
      return ChatPlatform.PLATFORM_MS_TEAMS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChatPlatform.UNRECOGNIZED;
  }
}

export function chatPlatformToJSON(object: ChatPlatform): string {
  switch (object) {
    case ChatPlatform.PLATFORM_UNSPECIFIED:
      return "PLATFORM_UNSPECIFIED";
    case ChatPlatform.PLATFORM_DISCORD:
      return "PLATFORM_DISCORD";
    case ChatPlatform.PLATFORM_WEBEX:
      return "PLATFORM_WEBEX";
    case ChatPlatform.PLATFORM_SLACK:
      return "PLATFORM_SLACK";
    case ChatPlatform.PLATFORM_MS_TEAMS:
      return "PLATFORM_MS_TEAMS";
    case ChatPlatform.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
