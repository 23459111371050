import { CopyToClipboard } from "@magnetic/copy-to-clipboard";
import { BaseColumnConfig } from "./baseColumnConfig";
import React from "react";
import { Constants } from "src/utils/constants";

export class CopyColumn<T extends object> extends BaseColumnConfig<T> {
  compare(a: T, b: T): number {
    const aVal = JSON.stringify(a, null, "\t");
    const bVal = JSON.stringify(b, null, "\t");
    return aVal.localeCompare(bVal);
  }
  render(val: T) {
    if (!val || Object.keys(val).length === 0) {
      return <div>{Constants.EMPTY_VALUE}</div>;
    }
    return <CopyToClipboard.IconOnly value={JSON.stringify(val, null, "\t")} />;
  }
}
