import {
  CablingRequest,
  CablingResponse,
  CliCommandRequest,
  CliCommandResponse,
  DeleteLinkedAccountRequest,
  GetCandidateInfoWithPasscodeRequest,
  GetCandidateInfoWithPasscodeResponse,
  GetLinkedAccountsRequest,
  GetLinkedAccountsResponse,
  SetLinkedAccountRequest,
  SetLinkedAccountResponse,
  UpdateLinkedAccountRequest,
  UpdateLinkedAccountResponse
} from "src/gen/schema/bilge/api";
import { GrpcDebugableWebImpl } from "src/utils/api/grpc/grpcDebugableWebImpl";
import { BilgeClientImpl, DeepPartial } from "src/gen/schema/bilge/service";
import { Empty } from "src/gen/schema/common/empty";

const rpc = new GrpcDebugableWebImpl("", {
  debug: false // change later
});
const bilgeClient = new BilgeClientImpl(rpc);

export const Bilge = {
  cabling: (req: Partial<CablingRequest>): Promise<CablingResponse> => {
    return bilgeClient.Cabling(req);
  },
  cliCommands: (
    req: Partial<CliCommandRequest>
  ): Promise<CliCommandResponse> => {
    return bilgeClient.CliCommand(req);
  },
  deleteLinkedAccount(
    req: DeepPartial<DeleteLinkedAccountRequest>
  ): Promise<Empty> {
    return bilgeClient.DeleteLinkedAccount(req);
  },
  getChatAccountCandidateWithPasscode(
    req: Partial<GetCandidateInfoWithPasscodeRequest>
  ): Promise<GetCandidateInfoWithPasscodeResponse> {
    return bilgeClient.GetChatAccountCandidateWithPasscode(req);
  },
  getLinkedAccounts: (
    req: Partial<GetLinkedAccountsRequest>
  ): Promise<GetLinkedAccountsResponse> => {
    return bilgeClient.GetLinkedAccounts(req);
  },
  setLinkedAccount: (
    req: Partial<SetLinkedAccountRequest>
  ): Promise<SetLinkedAccountResponse> => {
    return bilgeClient.SetLinkedAccount(req);
  },
  updateLinkedAccount: (
    req: Partial<UpdateLinkedAccountRequest>
  ): Promise<UpdateLinkedAccountResponse> => {
    return bilgeClient.UpdateLinkedAccount(req);
  }
};
