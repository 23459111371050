import { Container } from "@magnetic/container";
import { Header } from "@magnetic/header";
import { Heading } from "@magnetic/heading";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition
} from "react";
import DataViewer from "src/components/dataViewer";
import {
  StringColumn,
  TimeColumn,
  YesNoColumn
} from "../components/tables/columnConfigs";
import { CopyColumn } from "../components/tables/columnConfigs/copyColumn";
import { JsxColumn } from "../components/tables/columnConfigs/jsxColumn";
import TypedTable, { TableData } from "../components/typedTable";
import { Button } from "@magnetic/button";
import HFlex from "src/components/hFlex";
import { UnaryApiContext } from "src/utils/api/grpc/grpcDebugableWebImpl";

const UnaryHistoryMonitorTable = (props: {
  readonly data: UnaryApiContext[];
  readonly showRoot: boolean;
}) => {
  const columns = useMemo(() => {
    return {
      service: new StringColumn({
        label: "Service"
      }),
      method: new StringColumn({
        label: "Method"
      }),
      requestTs: new TimeColumn({
        label: "Request at"
      }),
      requestData: new JsxColumn({
        label: "Request"
      }),
      copyReq: new CopyColumn({
        label: "Copy"
      }),
      isError: new YesNoColumn({
        label: "Error?"
      }),
      responseTs: new TimeColumn({
        label: "Response at"
      }),
      responseData: new JsxColumn({
        label: "Response"
      }),
      copyResp: new CopyColumn({
        label: "Copy"
      })
    };
  }, []);

  const [tableData, setTableData] = useState<TableData<typeof columns>[]>([]);

  // inject Data Viewers
  useEffect(() => {
    const newTableData = props.data
      .map((d) => {
        return {
          ...d,
          ...{
            requestData: (
              <div style={{ width: 200 }}>
                <DataViewer
                  data={d.request}
                  hideRoot={!props.showRoot}
                  expandDepth={0}
                />
              </div>
            ),
            copyReq: d.request,
            responseData: (
              <div style={{ width: 350 }}>
                <DataViewer
                  data={d.response}
                  hideRoot={!props.showRoot}
                  expandDepth={0}
                />
              </div>
            ),
            copyResp: d.response,
            isError: !!d.isError // coherce into true/false
          }
        };
      })
      .reverse();
    setTableData(newTableData);
    return;
  }, [props.data, props.showRoot]);

  return (
    <TypedTable name="unaryHistoryMonitor" columns={columns} data={tableData} />
  );
};

// goal of this component is to make it easy to see all unary calls
export const UnaryHistoryMonitor = (): JSX.Element => {
  const POLL_INTERVAL_MS = 500;

  const width =
    // @ts-ignore
    document.getElementsByClassName("cnc-page-layout")[0]?.offsetWidth - 92;

  const CONTAINER_STYLE = {
    width: width,
    height: "85vh",
    overflow: "scroll"
  };

  const [unaryHistoryState, setUnaryHistoryState] = useState<UnaryApiContext[]>(
    []
  );
  const [, startTransition] = useTransition();

  const [showRoot, setShowRoot] = useState<boolean>(true);

  // poll unary history every interval and update state if it has changed
  useEffect(() => {
    const interval = setInterval(() => {
      // non-blocking
      startTransition(() => {
        const unaryHistory = window.cnc.unaryHistory;
        // only update if array is new reference
        if (unaryHistory !== unaryHistoryState) {
          setUnaryHistoryState(unaryHistory);
        }
      });
    }, POLL_INTERVAL_MS);
    return () => clearInterval(interval);
  }, [unaryHistoryState]);

  const handleClear = useCallback(() => {
    window.cnc.unaryHistory = [];
  }, []);

  const toggleRoot = useCallback(() => {
    setShowRoot(!showRoot);
  }, [showRoot]);

  return (
    <>
      <Header.Dropdown
        icon="downloads"
        label="Unary History"
        panel={true}
        alert={unaryHistoryState.length}
      >
        <Container style={CONTAINER_STYLE}>
          <HFlex>
            <Heading>Unary History</Heading>
            <Button size="sm" kind="tertiary" onClick={handleClear}>
              Clear History
            </Button>
            <Button size="sm" kind="tertiary" onClick={toggleRoot}>
              {showRoot ? "Expand " : "Collapse "} Root
            </Button>
          </HFlex>
          <UnaryHistoryMonitorTable
            data={unaryHistoryState}
            showRoot={showRoot}
          />
        </Container>
      </Header.Dropdown>
    </>
  );
};
