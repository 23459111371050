import { ReST } from "./rest";

const BASE = `/ui/v1/auth`;
export const Auth = {
  login: (payload: string[]): Promise<Response> => {
    const body: string = payload.join("&");
    return ReST.post(`${BASE}/login/okta`, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  },
  /**
   * @param id - tenant id as a string
   */
  switchTenant: (id: string): Promise<boolean> => {
    return ReST.put(`${BASE}/sessions/${id}`).then(
      () => {
        window.location.reload();
        return true;
      },
      () => {
        return false;
      }
    );
  },
  /**
   * @param expiry This is a Dev mode only param - used for testing
   */
  refresh: (expiry?: number): Promise<Response> => {
    let args: string = "";
    if (expiry) {
      args = `expiry=now+${expiry}s`;
    }
    return ReST.post(`${BASE}/refresh`, args);
  }
};
