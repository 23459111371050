import { useMemo, useState } from "react";
import { ManageMockShellModal } from "./manageMockShellModal";

type ManageMockShellModalPayload = {
  openManageMockShellModal: (open: boolean) => void;
  manageMockShellModal: React.ReactElement | null;
};
export const useManageMockShellModal = (): ManageMockShellModalPayload => {
  const [mockShellModal, setMockShellModal] = useState<boolean>(false);
  const manageMockShellModal = useMemo(() => {
    const handleClose = () => {
      setMockShellModal(false);
    };
    if (mockShellModal) {
      return <ManageMockShellModal onClose={handleClose} />;
    }
    return null;
  }, [mockShellModal]);
  return {
    openManageMockShellModal: setMockShellModal,
    manageMockShellModal
  };
};
