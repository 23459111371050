import { Parser, Json, getParser } from "./parser";
/**
 * Sends an HTTP request using the Fetch API.
 *
 * @param {string} url - The URL to which the request is sent.
 * @param {string} method - The HTTP method to use (e.g., GET, POST, PUT, DELETE).
 * @param {string} [body] - The body of the request, if applicable.
 * @param {RequestInit} [options] - Additional options to customize the request.
 * @returns {Promise<Response>} A promise that resolves to the response of the request.
 */
const request = (
  url: string,
  method: string,
  body?: string,
  options?: RequestInit | undefined
): Promise<Response> => {
  const opts: RequestInit | undefined = {
    method: method,
    mode: "no-cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body,
    ...options
  };
  return fetch(url, opts);
};

export const ReST = {
  get: (url: string, options?: RequestInit | undefined): Promise<Response> => {
    return request(url, "GET", undefined, options);
  },
  post: (
    url: string,
    body: string = "",
    options?: RequestInit | undefined
  ): Promise<Response> => {
    return request(url, "POST", body, options);
  },
  put: (
    url: string,
    body: string = "",
    options?: RequestInit | undefined
  ): Promise<Response> => {
    /**
     * Overriding the mode with "same-origin" in the fetch
     * config since no-cors does not support PUT!
     *
     * `{ ...options, mode: "same-origin" }`
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Request/mode
     * - no-cors
     *
     * Prevents the method from being anything other
     * than HEAD, GET or POST, and the headers from
     * being anything other than CORS-safelisted request
     * headers. If any ServiceWorkers intercept these requests,
     * they may not add or override any headers except for
     * those that are CORS-safelisted request headers.
     * In addition, JavaScript may not access any properties
     * of the resulting Response.
     * This ensures that ServiceWorkers do not affect the semantics
     * of the Web and prevents security and privacy issues
     * arising from leaking data across domains.
     */
    return request(url, "PUT", body, { ...options, mode: "same-origin" });
  },
  delete: (
    url: string,
    body: string = "",
    options?: RequestInit | undefined
  ): Promise<Response> => {
    /**
     * Overriding the mode with "same-origin" in the fetch
     * config since no-cors does not support DELETE!
     * same as PUT above
     */
    return request(url, "DELETE", body, { ...options, mode: "same-origin" });
  },
  /**
   * Initial stab at a ReST Infra that
   * will be typesafe. It will consume and
   * return the existing protobuf objects
   * the UI already uses.
   *
   * TODO: make shure we can generate post data properly
   */
  TypeSafe: {
    get: <TResponse>(
      url: string,
      transform: (json: Json) => TResponse,
      options?: RequestInit | undefined
    ): Promise<TResponse> => {
      const parser = new Parser(transform);
      return ReST.get(url, options).then(
        (resp: Response) => parser.parse(resp),
        (err) => Promise.reject(err)
      );
    },
    post: <TResponse>(
      url: string,
      body: string = "",
      transform: (json: Json) => TResponse,
      options?: RequestInit | undefined
    ): Promise<TResponse> => {
      const parse = getParser(transform);
      return ReST.post(url, body, options).then(parse, (err) =>
        Promise.reject(err)
      );
    },
    put: <TResponse>(
      url: string,
      body: string = "",
      transform: (json: Json) => TResponse,
      options?: RequestInit | undefined
    ): Promise<TResponse> => {
      const parse = getParser(transform);
      return ReST.put(url, body, options).then(parse, (err) =>
        Promise.reject(err)
      );
    },
    delete: <TResponse>(
      url: string,
      body: string = "",
      transform: (json: Json) => TResponse,
      options?: RequestInit | undefined
    ): Promise<TResponse> => {
      const parse = getParser(transform);
      return ReST.delete(url, body, options).then(parse, (err) =>
        Promise.reject(err)
      );
    }
  }
};
