// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: schema_path/path_id.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { SchemaType, schemaTypeFromJSON, schemaTypeToJSON } from "./schema_path";

export const protobufPackage = "schema_path";

/**
 * PROPRIETARY AND CONFIDENTIAL. Cisco Systems, Inc. considers the contents of this
 * file to be highly confidential trade secret information.
 *
 * COPYRIGHT 2024 Cisco Systems, Inc., All rights reserved.
 *
 * Code generated by protoc-gen-pathid.  DO NOT EDIT.
 * This file is generated, but its contents are intentionally committed to source control to allow for easier
 * "bootstrapped" builds when building from a fresh checkout.
 */

/** PathId identifies the specific "schema coordinates" identifier of an object in the schema tree. */
export interface PathId {
  /** Indicates that this object was deleted. */
  deleted: boolean;
  /** Optional string representation of the schema path.  Only populated on request. */
  pathString: string;
  type: SchemaType;
  fabric: string;
  device: string;
  fan: number;
  psu: number;
  tempSensor: number;
  lineCard: number;
  port: number;
  dacQsfp: number;
  fiberQsfp: number;
  breakout: number;
  portNeighbor: string;
  vtep: number;
  vniPort: number;
  mac: number;
  procTable: number;
  portVlan: string;
  vlanState: number;
  qsfpDomSensor: number;
  portConfig: number;
  nodeConfig: number;
  fabricConfig: string;
  fabricConfigEvent: number;
  l2Fdb: number;
  portCounters: number;
  remedyAction: string;
  qsfpInfo: number;
  fabricDevice: string;
  assertConfigPortLinkUp: number;
  assertPortLinkUp: number;
  assertConfigDeviceConnectedToCloud: number;
  assertDeviceConnectedToCloud: number;
  assertConfigPortExpectedNeighbor: number;
  assertPortExpectedNeighbor: number;
  assertConfigPortNotConnectedToFabric: number;
  assertPortNotConnectedToFabric: number;
  assertConfigDeviceConnectedToFabric: number;
  assertDeviceConnectedToFabric: number;
  assertConfigPortLinkDown: number;
  assertPortLinkDown: number;
  assertConfigPortConnectionSpeedMatch: number;
  assertPortConnectionSpeedMatch: number;
  assertConfigPortSpeedConsistent: number;
  assertPortSpeedConsistent: number;
  underlayInfo: number;
  proxyStats: number;
  vlanCounters: number;
  testPlaceHolder1Secret: number;
  assertConfigVlanHasTraffic: number;
  assertVlanHasTraffic: number;
  remoteVtep: number;
  qsfpDomThreshold: number;
  sextantFabricAssignment: string;
  l3Table: string;
  vlanVniMap: number;
  proxyConnectionState: string;
  proxyConnectionStats: number;
  l2Mac: string;
  bmc: number;
  smartNicDpu: number;
  serverVoltageSensor: number;
  serverTemperatureSensor: number;
  ipRouteTable: string;
  vrf: string;
  ipNextHop: number;
  ipPrefix: string;
  subInterface: number;
  assertConfigStaticDefaultRouteExists: number;
  assertStaticDefaultRouteExists: number;
  spyglassBrokerEntry: string;
  managementState: string;
  assertConfigManagementPortConfigSame: number;
  assertManagementPortConfigSame: number;
  fabricConfigNotification: number;
  spyglassGingerEntry: number;
  plannedConfig: number;
  routedInterfaceCounters: number;
  ipmRawLatencyCounters: number;
  ipmProcLatencyCounters: number;
  ipmProcLossCounters: number;
  ipmLivenessCounters: number;
  ipmSessionId: string;
  systemStats: number;
  testRootOne: string;
  testRootTwo: string;
  testMidOne: number;
  testLeafOne: string;
  testIpAddress: string;
  testIpPrefix: string;
  testMacAddress: string;
  assertConfigDeviceResourceUsageBelowThreshold: number;
  assertDeviceResourceUsageBelowThreshold: number;
  fabricTransaction: number;
  bgpNeighborSummary: string;
  bmcConfig: number;
  deviceConnection: number;
  bgpNeighborFamily: number;
  assertConfigBgpPeerEstablished: number;
  assertBgpPeerEstablished: number;
  spyglassSshSessionEntry: number;
  portChannelMemberState: string;
  portChannelState: string;
  assertConfigIpmLatencyBelowThreshold: number;
  assertIpmLatencyBelowThreshold: number;
  assertConfigIpmLossBelowThreshold: number;
  assertIpmLossBelowThreshold: number;
  spyglassDevice: string;
  spyglassTcpListener: number;
  spyglassTcpSession: number;
  unknownVlan: number;
  unknownVlanPort: string;
  assertConfigExpectedPortBreakoutExists: number;
  assertExpectedPortBreakoutExists: number;
  assertConfigSubInterfaceUp: number;
  assertSubInterfaceUp: number;
  assertSubInterfaceDown: number;
  assertConfigSubInterfaceDown: number;
  deviceClaim: number;
  aggregatedDeviceClaim: string;
  assertPortChannelMemberUp: number;
  assertConfigPortChannelMemberUp: number;
  esiInfo: string;
  esiVtepInfo: string;
  orgUuid: string;
  fabricUuid: string;
  orgServiceProto: string;
  orgServiceSvc: string;
  orgServiceRoute: number;
  apiPath: string;
  apiPathMethod: string;
  apiServiceMapping: number;
  orgToCellPairMapping: number;
  tenantUuid: string;
  userEmail: string;
  managementConfig: number;
  serviceInstanceHealth: string;
  fabricAssignmentStatus: string;
  kubernetesServiceInstance: string;
  bearerToken: string;
  apiKey: string;
  fabricMemoryUsage: string;
  typeMemoryUsage: number;
  manifestRunningImageStatus: number;
  manifestPartitionFileCacheStatus: string;
  stpPortState: string;
  stpVlanState: number;
  stpVlanPortState: string;
  assertDeviceEnvPsuUsageBelowThreshold: number;
  assertDeviceEnvFanUsageBelowThreshold: number;
  assertDeviceEnvTempSensorUsageBelowThreshold: number;
  assertConfigDeviceEnvPsuUsageBelowThreshold: number;
  assertConfigDeviceEnvFanUsageBelowThreshold: number;
  assertConfigDeviceEnvTempSensorUsageBelowThreshold: number;
  unpackAndAssembleTask: number;
  rackAndPowerOnTask: number;
  claimAndBindTask: number;
  portSetupTask: number;
  fabricConnectionTask: number;
  negativeConnectionTask: number;
  application: number;
  resourceAssignment: string;
  node: string;
  nodeLineCard: number;
  nodePort: number;
  nodeBreakout: number;
}

function createBasePathId(): PathId {
  return {
    deleted: false,
    pathString: "",
    type: 0,
    fabric: "",
    device: "",
    fan: 0,
    psu: 0,
    tempSensor: 0,
    lineCard: 0,
    port: 0,
    dacQsfp: 0,
    fiberQsfp: 0,
    breakout: 0,
    portNeighbor: "",
    vtep: 0,
    vniPort: 0,
    mac: 0,
    procTable: 0,
    portVlan: "",
    vlanState: 0,
    qsfpDomSensor: 0,
    portConfig: 0,
    nodeConfig: 0,
    fabricConfig: "",
    fabricConfigEvent: 0,
    l2Fdb: 0,
    portCounters: 0,
    remedyAction: "",
    qsfpInfo: 0,
    fabricDevice: "",
    assertConfigPortLinkUp: 0,
    assertPortLinkUp: 0,
    assertConfigDeviceConnectedToCloud: 0,
    assertDeviceConnectedToCloud: 0,
    assertConfigPortExpectedNeighbor: 0,
    assertPortExpectedNeighbor: 0,
    assertConfigPortNotConnectedToFabric: 0,
    assertPortNotConnectedToFabric: 0,
    assertConfigDeviceConnectedToFabric: 0,
    assertDeviceConnectedToFabric: 0,
    assertConfigPortLinkDown: 0,
    assertPortLinkDown: 0,
    assertConfigPortConnectionSpeedMatch: 0,
    assertPortConnectionSpeedMatch: 0,
    assertConfigPortSpeedConsistent: 0,
    assertPortSpeedConsistent: 0,
    underlayInfo: 0,
    proxyStats: 0,
    vlanCounters: 0,
    testPlaceHolder1Secret: 0,
    assertConfigVlanHasTraffic: 0,
    assertVlanHasTraffic: 0,
    remoteVtep: 0,
    qsfpDomThreshold: 0,
    sextantFabricAssignment: "",
    l3Table: "",
    vlanVniMap: 0,
    proxyConnectionState: "",
    proxyConnectionStats: 0,
    l2Mac: "",
    bmc: 0,
    smartNicDpu: 0,
    serverVoltageSensor: 0,
    serverTemperatureSensor: 0,
    ipRouteTable: "",
    vrf: "",
    ipNextHop: 0,
    ipPrefix: "",
    subInterface: 0,
    assertConfigStaticDefaultRouteExists: 0,
    assertStaticDefaultRouteExists: 0,
    spyglassBrokerEntry: "",
    managementState: "",
    assertConfigManagementPortConfigSame: 0,
    assertManagementPortConfigSame: 0,
    fabricConfigNotification: 0,
    spyglassGingerEntry: 0,
    plannedConfig: 0,
    routedInterfaceCounters: 0,
    ipmRawLatencyCounters: 0,
    ipmProcLatencyCounters: 0,
    ipmProcLossCounters: 0,
    ipmLivenessCounters: 0,
    ipmSessionId: "",
    systemStats: 0,
    testRootOne: "",
    testRootTwo: "",
    testMidOne: 0,
    testLeafOne: "",
    testIpAddress: "",
    testIpPrefix: "",
    testMacAddress: "",
    assertConfigDeviceResourceUsageBelowThreshold: 0,
    assertDeviceResourceUsageBelowThreshold: 0,
    fabricTransaction: 0,
    bgpNeighborSummary: "",
    bmcConfig: 0,
    deviceConnection: 0,
    bgpNeighborFamily: 0,
    assertConfigBgpPeerEstablished: 0,
    assertBgpPeerEstablished: 0,
    spyglassSshSessionEntry: 0,
    portChannelMemberState: "",
    portChannelState: "",
    assertConfigIpmLatencyBelowThreshold: 0,
    assertIpmLatencyBelowThreshold: 0,
    assertConfigIpmLossBelowThreshold: 0,
    assertIpmLossBelowThreshold: 0,
    spyglassDevice: "",
    spyglassTcpListener: 0,
    spyglassTcpSession: 0,
    unknownVlan: 0,
    unknownVlanPort: "",
    assertConfigExpectedPortBreakoutExists: 0,
    assertExpectedPortBreakoutExists: 0,
    assertConfigSubInterfaceUp: 0,
    assertSubInterfaceUp: 0,
    assertSubInterfaceDown: 0,
    assertConfigSubInterfaceDown: 0,
    deviceClaim: 0,
    aggregatedDeviceClaim: "",
    assertPortChannelMemberUp: 0,
    assertConfigPortChannelMemberUp: 0,
    esiInfo: "",
    esiVtepInfo: "",
    orgUuid: "",
    fabricUuid: "",
    orgServiceProto: "",
    orgServiceSvc: "",
    orgServiceRoute: 0,
    apiPath: "",
    apiPathMethod: "",
    apiServiceMapping: 0,
    orgToCellPairMapping: 0,
    tenantUuid: "",
    userEmail: "",
    managementConfig: 0,
    serviceInstanceHealth: "",
    fabricAssignmentStatus: "",
    kubernetesServiceInstance: "",
    bearerToken: "",
    apiKey: "",
    fabricMemoryUsage: "",
    typeMemoryUsage: 0,
    manifestRunningImageStatus: 0,
    manifestPartitionFileCacheStatus: "",
    stpPortState: "",
    stpVlanState: 0,
    stpVlanPortState: "",
    assertDeviceEnvPsuUsageBelowThreshold: 0,
    assertDeviceEnvFanUsageBelowThreshold: 0,
    assertDeviceEnvTempSensorUsageBelowThreshold: 0,
    assertConfigDeviceEnvPsuUsageBelowThreshold: 0,
    assertConfigDeviceEnvFanUsageBelowThreshold: 0,
    assertConfigDeviceEnvTempSensorUsageBelowThreshold: 0,
    unpackAndAssembleTask: 0,
    rackAndPowerOnTask: 0,
    claimAndBindTask: 0,
    portSetupTask: 0,
    fabricConnectionTask: 0,
    negativeConnectionTask: 0,
    application: 0,
    resourceAssignment: "",
    node: "",
    nodeLineCard: 0,
    nodePort: 0,
    nodeBreakout: 0,
  };
}

export const PathId = {
  encode(message: PathId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deleted !== false) {
      writer.uint32(24).bool(message.deleted);
    }
    if (message.pathString !== "") {
      writer.uint32(34).string(message.pathString);
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.fabric !== "") {
      writer.uint32(194).string(message.fabric);
    }
    if (message.device !== "") {
      writer.uint32(202).string(message.device);
    }
    if (message.fan !== 0) {
      writer.uint32(208).uint32(message.fan);
    }
    if (message.psu !== 0) {
      writer.uint32(216).uint32(message.psu);
    }
    if (message.tempSensor !== 0) {
      writer.uint32(224).uint32(message.tempSensor);
    }
    if (message.lineCard !== 0) {
      writer.uint32(232).uint32(message.lineCard);
    }
    if (message.port !== 0) {
      writer.uint32(240).uint32(message.port);
    }
    if (message.dacQsfp !== 0) {
      writer.uint32(248).uint32(message.dacQsfp);
    }
    if (message.fiberQsfp !== 0) {
      writer.uint32(256).uint32(message.fiberQsfp);
    }
    if (message.breakout !== 0) {
      writer.uint32(264).uint32(message.breakout);
    }
    if (message.portNeighbor !== "") {
      writer.uint32(274).string(message.portNeighbor);
    }
    if (message.vtep !== 0) {
      writer.uint32(280).uint32(message.vtep);
    }
    if (message.vniPort !== 0) {
      writer.uint32(288).uint32(message.vniPort);
    }
    if (message.mac !== 0) {
      writer.uint32(296).uint32(message.mac);
    }
    if (message.procTable !== 0) {
      writer.uint32(304).uint32(message.procTable);
    }
    if (message.portVlan !== "") {
      writer.uint32(314).string(message.portVlan);
    }
    if (message.vlanState !== 0) {
      writer.uint32(320).uint32(message.vlanState);
    }
    if (message.qsfpDomSensor !== 0) {
      writer.uint32(336).uint32(message.qsfpDomSensor);
    }
    if (message.portConfig !== 0) {
      writer.uint32(376).uint32(message.portConfig);
    }
    if (message.nodeConfig !== 0) {
      writer.uint32(384).uint32(message.nodeConfig);
    }
    if (message.fabricConfig !== "") {
      writer.uint32(394).string(message.fabricConfig);
    }
    if (message.fabricConfigEvent !== 0) {
      writer.uint32(400).uint32(message.fabricConfigEvent);
    }
    if (message.l2Fdb !== 0) {
      writer.uint32(408).uint32(message.l2Fdb);
    }
    if (message.portCounters !== 0) {
      writer.uint32(416).uint32(message.portCounters);
    }
    if (message.remedyAction !== "") {
      writer.uint32(426).string(message.remedyAction);
    }
    if (message.qsfpInfo !== 0) {
      writer.uint32(432).uint32(message.qsfpInfo);
    }
    if (message.fabricDevice !== "") {
      writer.uint32(442).string(message.fabricDevice);
    }
    if (message.assertConfigPortLinkUp !== 0) {
      writer.uint32(448).uint32(message.assertConfigPortLinkUp);
    }
    if (message.assertPortLinkUp !== 0) {
      writer.uint32(456).uint32(message.assertPortLinkUp);
    }
    if (message.assertConfigDeviceConnectedToCloud !== 0) {
      writer.uint32(464).uint32(message.assertConfigDeviceConnectedToCloud);
    }
    if (message.assertDeviceConnectedToCloud !== 0) {
      writer.uint32(472).uint32(message.assertDeviceConnectedToCloud);
    }
    if (message.assertConfigPortExpectedNeighbor !== 0) {
      writer.uint32(480).uint32(message.assertConfigPortExpectedNeighbor);
    }
    if (message.assertPortExpectedNeighbor !== 0) {
      writer.uint32(488).uint32(message.assertPortExpectedNeighbor);
    }
    if (message.assertConfigPortNotConnectedToFabric !== 0) {
      writer.uint32(496).uint32(message.assertConfigPortNotConnectedToFabric);
    }
    if (message.assertPortNotConnectedToFabric !== 0) {
      writer.uint32(504).uint32(message.assertPortNotConnectedToFabric);
    }
    if (message.assertConfigDeviceConnectedToFabric !== 0) {
      writer.uint32(512).uint32(message.assertConfigDeviceConnectedToFabric);
    }
    if (message.assertDeviceConnectedToFabric !== 0) {
      writer.uint32(520).uint32(message.assertDeviceConnectedToFabric);
    }
    if (message.assertConfigPortLinkDown !== 0) {
      writer.uint32(528).uint32(message.assertConfigPortLinkDown);
    }
    if (message.assertPortLinkDown !== 0) {
      writer.uint32(536).uint32(message.assertPortLinkDown);
    }
    if (message.assertConfigPortConnectionSpeedMatch !== 0) {
      writer.uint32(544).uint32(message.assertConfigPortConnectionSpeedMatch);
    }
    if (message.assertPortConnectionSpeedMatch !== 0) {
      writer.uint32(552).uint32(message.assertPortConnectionSpeedMatch);
    }
    if (message.assertConfigPortSpeedConsistent !== 0) {
      writer.uint32(560).uint32(message.assertConfigPortSpeedConsistent);
    }
    if (message.assertPortSpeedConsistent !== 0) {
      writer.uint32(568).uint32(message.assertPortSpeedConsistent);
    }
    if (message.underlayInfo !== 0) {
      writer.uint32(584).uint32(message.underlayInfo);
    }
    if (message.proxyStats !== 0) {
      writer.uint32(592).uint32(message.proxyStats);
    }
    if (message.vlanCounters !== 0) {
      writer.uint32(600).uint32(message.vlanCounters);
    }
    if (message.testPlaceHolder1Secret !== 0) {
      writer.uint32(608).uint32(message.testPlaceHolder1Secret);
    }
    if (message.assertConfigVlanHasTraffic !== 0) {
      writer.uint32(616).uint32(message.assertConfigVlanHasTraffic);
    }
    if (message.assertVlanHasTraffic !== 0) {
      writer.uint32(624).uint32(message.assertVlanHasTraffic);
    }
    if (message.remoteVtep !== 0) {
      writer.uint32(632).uint32(message.remoteVtep);
    }
    if (message.qsfpDomThreshold !== 0) {
      writer.uint32(640).uint32(message.qsfpDomThreshold);
    }
    if (message.sextantFabricAssignment !== "") {
      writer.uint32(650).string(message.sextantFabricAssignment);
    }
    if (message.l3Table !== "") {
      writer.uint32(658).string(message.l3Table);
    }
    if (message.vlanVniMap !== 0) {
      writer.uint32(664).uint32(message.vlanVniMap);
    }
    if (message.proxyConnectionState !== "") {
      writer.uint32(674).string(message.proxyConnectionState);
    }
    if (message.proxyConnectionStats !== 0) {
      writer.uint32(680).uint32(message.proxyConnectionStats);
    }
    if (message.l2Mac !== "") {
      writer.uint32(690).string(message.l2Mac);
    }
    if (message.bmc !== 0) {
      writer.uint32(696).uint32(message.bmc);
    }
    if (message.smartNicDpu !== 0) {
      writer.uint32(704).uint32(message.smartNicDpu);
    }
    if (message.serverVoltageSensor !== 0) {
      writer.uint32(712).uint32(message.serverVoltageSensor);
    }
    if (message.serverTemperatureSensor !== 0) {
      writer.uint32(720).uint32(message.serverTemperatureSensor);
    }
    if (message.ipRouteTable !== "") {
      writer.uint32(730).string(message.ipRouteTable);
    }
    if (message.vrf !== "") {
      writer.uint32(738).string(message.vrf);
    }
    if (message.ipNextHop !== 0) {
      writer.uint32(744).uint32(message.ipNextHop);
    }
    if (message.ipPrefix !== "") {
      writer.uint32(754).string(message.ipPrefix);
    }
    if (message.subInterface !== 0) {
      writer.uint32(760).uint32(message.subInterface);
    }
    if (message.assertConfigStaticDefaultRouteExists !== 0) {
      writer.uint32(768).uint32(message.assertConfigStaticDefaultRouteExists);
    }
    if (message.assertStaticDefaultRouteExists !== 0) {
      writer.uint32(776).uint32(message.assertStaticDefaultRouteExists);
    }
    if (message.spyglassBrokerEntry !== "") {
      writer.uint32(786).string(message.spyglassBrokerEntry);
    }
    if (message.managementState !== "") {
      writer.uint32(794).string(message.managementState);
    }
    if (message.assertConfigManagementPortConfigSame !== 0) {
      writer.uint32(816).uint32(message.assertConfigManagementPortConfigSame);
    }
    if (message.assertManagementPortConfigSame !== 0) {
      writer.uint32(824).uint32(message.assertManagementPortConfigSame);
    }
    if (message.fabricConfigNotification !== 0) {
      writer.uint32(832).uint32(message.fabricConfigNotification);
    }
    if (message.spyglassGingerEntry !== 0) {
      writer.uint32(840).uint32(message.spyglassGingerEntry);
    }
    if (message.plannedConfig !== 0) {
      writer.uint32(848).uint32(message.plannedConfig);
    }
    if (message.routedInterfaceCounters !== 0) {
      writer.uint32(856).uint32(message.routedInterfaceCounters);
    }
    if (message.ipmRawLatencyCounters !== 0) {
      writer.uint32(864).uint32(message.ipmRawLatencyCounters);
    }
    if (message.ipmProcLatencyCounters !== 0) {
      writer.uint32(872).uint32(message.ipmProcLatencyCounters);
    }
    if (message.ipmProcLossCounters !== 0) {
      writer.uint32(880).uint32(message.ipmProcLossCounters);
    }
    if (message.ipmLivenessCounters !== 0) {
      writer.uint32(888).uint32(message.ipmLivenessCounters);
    }
    if (message.ipmSessionId !== "") {
      writer.uint32(898).string(message.ipmSessionId);
    }
    if (message.systemStats !== 0) {
      writer.uint32(952).uint32(message.systemStats);
    }
    if (message.testRootOne !== "") {
      writer.uint32(962).string(message.testRootOne);
    }
    if (message.testRootTwo !== "") {
      writer.uint32(970).string(message.testRootTwo);
    }
    if (message.testMidOne !== 0) {
      writer.uint32(976).uint32(message.testMidOne);
    }
    if (message.testLeafOne !== "") {
      writer.uint32(994).string(message.testLeafOne);
    }
    if (message.testIpAddress !== "") {
      writer.uint32(1010).string(message.testIpAddress);
    }
    if (message.testIpPrefix !== "") {
      writer.uint32(1018).string(message.testIpPrefix);
    }
    if (message.testMacAddress !== "") {
      writer.uint32(1026).string(message.testMacAddress);
    }
    if (message.assertConfigDeviceResourceUsageBelowThreshold !== 0) {
      writer.uint32(1032).uint32(message.assertConfigDeviceResourceUsageBelowThreshold);
    }
    if (message.assertDeviceResourceUsageBelowThreshold !== 0) {
      writer.uint32(1040).uint32(message.assertDeviceResourceUsageBelowThreshold);
    }
    if (message.fabricTransaction !== 0) {
      writer.uint32(1048).int64(message.fabricTransaction);
    }
    if (message.bgpNeighborSummary !== "") {
      writer.uint32(1066).string(message.bgpNeighborSummary);
    }
    if (message.bmcConfig !== 0) {
      writer.uint32(1072).uint32(message.bmcConfig);
    }
    if (message.deviceConnection !== 0) {
      writer.uint32(1080).uint32(message.deviceConnection);
    }
    if (message.bgpNeighborFamily !== 0) {
      writer.uint32(1088).uint32(message.bgpNeighborFamily);
    }
    if (message.assertConfigBgpPeerEstablished !== 0) {
      writer.uint32(1096).uint32(message.assertConfigBgpPeerEstablished);
    }
    if (message.assertBgpPeerEstablished !== 0) {
      writer.uint32(1104).uint32(message.assertBgpPeerEstablished);
    }
    if (message.spyglassSshSessionEntry !== 0) {
      writer.uint32(1112).uint32(message.spyglassSshSessionEntry);
    }
    if (message.portChannelMemberState !== "") {
      writer.uint32(1122).string(message.portChannelMemberState);
    }
    if (message.portChannelState !== "") {
      writer.uint32(1130).string(message.portChannelState);
    }
    if (message.assertConfigIpmLatencyBelowThreshold !== 0) {
      writer.uint32(1136).uint32(message.assertConfigIpmLatencyBelowThreshold);
    }
    if (message.assertIpmLatencyBelowThreshold !== 0) {
      writer.uint32(1144).uint32(message.assertIpmLatencyBelowThreshold);
    }
    if (message.assertConfigIpmLossBelowThreshold !== 0) {
      writer.uint32(1152).uint32(message.assertConfigIpmLossBelowThreshold);
    }
    if (message.assertIpmLossBelowThreshold !== 0) {
      writer.uint32(1160).uint32(message.assertIpmLossBelowThreshold);
    }
    if (message.spyglassDevice !== "") {
      writer.uint32(1170).string(message.spyglassDevice);
    }
    if (message.spyglassTcpListener !== 0) {
      writer.uint32(1176).uint32(message.spyglassTcpListener);
    }
    if (message.spyglassTcpSession !== 0) {
      writer.uint32(1184).uint32(message.spyglassTcpSession);
    }
    if (message.unknownVlan !== 0) {
      writer.uint32(1192).uint32(message.unknownVlan);
    }
    if (message.unknownVlanPort !== "") {
      writer.uint32(1202).string(message.unknownVlanPort);
    }
    if (message.assertConfigExpectedPortBreakoutExists !== 0) {
      writer.uint32(1208).uint32(message.assertConfigExpectedPortBreakoutExists);
    }
    if (message.assertExpectedPortBreakoutExists !== 0) {
      writer.uint32(1216).uint32(message.assertExpectedPortBreakoutExists);
    }
    if (message.assertConfigSubInterfaceUp !== 0) {
      writer.uint32(1224).uint32(message.assertConfigSubInterfaceUp);
    }
    if (message.assertSubInterfaceUp !== 0) {
      writer.uint32(1232).uint32(message.assertSubInterfaceUp);
    }
    if (message.assertSubInterfaceDown !== 0) {
      writer.uint32(1240).uint32(message.assertSubInterfaceDown);
    }
    if (message.assertConfigSubInterfaceDown !== 0) {
      writer.uint32(1248).uint32(message.assertConfigSubInterfaceDown);
    }
    if (message.deviceClaim !== 0) {
      writer.uint32(1264).uint32(message.deviceClaim);
    }
    if (message.aggregatedDeviceClaim !== "") {
      writer.uint32(1274).string(message.aggregatedDeviceClaim);
    }
    if (message.assertPortChannelMemberUp !== 0) {
      writer.uint32(1280).uint32(message.assertPortChannelMemberUp);
    }
    if (message.assertConfigPortChannelMemberUp !== 0) {
      writer.uint32(1288).uint32(message.assertConfigPortChannelMemberUp);
    }
    if (message.esiInfo !== "") {
      writer.uint32(1298).string(message.esiInfo);
    }
    if (message.esiVtepInfo !== "") {
      writer.uint32(1306).string(message.esiVtepInfo);
    }
    if (message.orgUuid !== "") {
      writer.uint32(1762).string(message.orgUuid);
    }
    if (message.fabricUuid !== "") {
      writer.uint32(1770).string(message.fabricUuid);
    }
    if (message.orgServiceProto !== "") {
      writer.uint32(1778).string(message.orgServiceProto);
    }
    if (message.orgServiceSvc !== "") {
      writer.uint32(1786).string(message.orgServiceSvc);
    }
    if (message.orgServiceRoute !== 0) {
      writer.uint32(1792).uint32(message.orgServiceRoute);
    }
    if (message.apiPath !== "") {
      writer.uint32(1802).string(message.apiPath);
    }
    if (message.apiPathMethod !== "") {
      writer.uint32(1810).string(message.apiPathMethod);
    }
    if (message.apiServiceMapping !== 0) {
      writer.uint32(1816).uint32(message.apiServiceMapping);
    }
    if (message.orgToCellPairMapping !== 0) {
      writer.uint32(1824).uint32(message.orgToCellPairMapping);
    }
    if (message.tenantUuid !== "") {
      writer.uint32(1834).string(message.tenantUuid);
    }
    if (message.userEmail !== "") {
      writer.uint32(1842).string(message.userEmail);
    }
    if (message.managementConfig !== 0) {
      writer.uint32(1848).uint32(message.managementConfig);
    }
    if (message.serviceInstanceHealth !== "") {
      writer.uint32(1858).string(message.serviceInstanceHealth);
    }
    if (message.fabricAssignmentStatus !== "") {
      writer.uint32(1866).string(message.fabricAssignmentStatus);
    }
    if (message.kubernetesServiceInstance !== "") {
      writer.uint32(1882).string(message.kubernetesServiceInstance);
    }
    if (message.bearerToken !== "") {
      writer.uint32(1938).string(message.bearerToken);
    }
    if (message.apiKey !== "") {
      writer.uint32(1946).string(message.apiKey);
    }
    if (message.fabricMemoryUsage !== "") {
      writer.uint32(1954).string(message.fabricMemoryUsage);
    }
    if (message.typeMemoryUsage !== 0) {
      writer.uint32(1960).uint32(message.typeMemoryUsage);
    }
    if (message.manifestRunningImageStatus !== 0) {
      writer.uint32(1968).uint32(message.manifestRunningImageStatus);
    }
    if (message.manifestPartitionFileCacheStatus !== "") {
      writer.uint32(1978).string(message.manifestPartitionFileCacheStatus);
    }
    if (message.stpPortState !== "") {
      writer.uint32(1986).string(message.stpPortState);
    }
    if (message.stpVlanState !== 0) {
      writer.uint32(1992).uint32(message.stpVlanState);
    }
    if (message.stpVlanPortState !== "") {
      writer.uint32(2002).string(message.stpVlanPortState);
    }
    if (message.assertDeviceEnvPsuUsageBelowThreshold !== 0) {
      writer.uint32(2008).uint32(message.assertDeviceEnvPsuUsageBelowThreshold);
    }
    if (message.assertDeviceEnvFanUsageBelowThreshold !== 0) {
      writer.uint32(2016).uint32(message.assertDeviceEnvFanUsageBelowThreshold);
    }
    if (message.assertDeviceEnvTempSensorUsageBelowThreshold !== 0) {
      writer.uint32(2024).uint32(message.assertDeviceEnvTempSensorUsageBelowThreshold);
    }
    if (message.assertConfigDeviceEnvPsuUsageBelowThreshold !== 0) {
      writer.uint32(2032).uint32(message.assertConfigDeviceEnvPsuUsageBelowThreshold);
    }
    if (message.assertConfigDeviceEnvFanUsageBelowThreshold !== 0) {
      writer.uint32(2040).uint32(message.assertConfigDeviceEnvFanUsageBelowThreshold);
    }
    if (message.assertConfigDeviceEnvTempSensorUsageBelowThreshold !== 0) {
      writer.uint32(2048).uint32(message.assertConfigDeviceEnvTempSensorUsageBelowThreshold);
    }
    if (message.unpackAndAssembleTask !== 0) {
      writer.uint32(2064).uint32(message.unpackAndAssembleTask);
    }
    if (message.rackAndPowerOnTask !== 0) {
      writer.uint32(2072).uint32(message.rackAndPowerOnTask);
    }
    if (message.claimAndBindTask !== 0) {
      writer.uint32(2080).uint32(message.claimAndBindTask);
    }
    if (message.portSetupTask !== 0) {
      writer.uint32(2088).uint32(message.portSetupTask);
    }
    if (message.fabricConnectionTask !== 0) {
      writer.uint32(2096).uint32(message.fabricConnectionTask);
    }
    if (message.negativeConnectionTask !== 0) {
      writer.uint32(2104).uint32(message.negativeConnectionTask);
    }
    if (message.application !== 0) {
      writer.uint32(2112).uint32(message.application);
    }
    if (message.resourceAssignment !== "") {
      writer.uint32(2122).string(message.resourceAssignment);
    }
    if (message.node !== "") {
      writer.uint32(2130).string(message.node);
    }
    if (message.nodeLineCard !== 0) {
      writer.uint32(2136).uint32(message.nodeLineCard);
    }
    if (message.nodePort !== 0) {
      writer.uint32(2144).uint32(message.nodePort);
    }
    if (message.nodeBreakout !== 0) {
      writer.uint32(2152).uint32(message.nodeBreakout);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PathId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePathId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deleted = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pathString = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.fabric = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.device = reader.string();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.fan = reader.uint32();
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.psu = reader.uint32();
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.tempSensor = reader.uint32();
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.lineCard = reader.uint32();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.port = reader.uint32();
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.dacQsfp = reader.uint32();
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.fiberQsfp = reader.uint32();
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.breakout = reader.uint32();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.portNeighbor = reader.string();
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.vtep = reader.uint32();
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.vniPort = reader.uint32();
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.mac = reader.uint32();
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.procTable = reader.uint32();
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.portVlan = reader.string();
          continue;
        case 40:
          if (tag !== 320) {
            break;
          }

          message.vlanState = reader.uint32();
          continue;
        case 42:
          if (tag !== 336) {
            break;
          }

          message.qsfpDomSensor = reader.uint32();
          continue;
        case 47:
          if (tag !== 376) {
            break;
          }

          message.portConfig = reader.uint32();
          continue;
        case 48:
          if (tag !== 384) {
            break;
          }

          message.nodeConfig = reader.uint32();
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.fabricConfig = reader.string();
          continue;
        case 50:
          if (tag !== 400) {
            break;
          }

          message.fabricConfigEvent = reader.uint32();
          continue;
        case 51:
          if (tag !== 408) {
            break;
          }

          message.l2Fdb = reader.uint32();
          continue;
        case 52:
          if (tag !== 416) {
            break;
          }

          message.portCounters = reader.uint32();
          continue;
        case 53:
          if (tag !== 426) {
            break;
          }

          message.remedyAction = reader.string();
          continue;
        case 54:
          if (tag !== 432) {
            break;
          }

          message.qsfpInfo = reader.uint32();
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.fabricDevice = reader.string();
          continue;
        case 56:
          if (tag !== 448) {
            break;
          }

          message.assertConfigPortLinkUp = reader.uint32();
          continue;
        case 57:
          if (tag !== 456) {
            break;
          }

          message.assertPortLinkUp = reader.uint32();
          continue;
        case 58:
          if (tag !== 464) {
            break;
          }

          message.assertConfigDeviceConnectedToCloud = reader.uint32();
          continue;
        case 59:
          if (tag !== 472) {
            break;
          }

          message.assertDeviceConnectedToCloud = reader.uint32();
          continue;
        case 60:
          if (tag !== 480) {
            break;
          }

          message.assertConfigPortExpectedNeighbor = reader.uint32();
          continue;
        case 61:
          if (tag !== 488) {
            break;
          }

          message.assertPortExpectedNeighbor = reader.uint32();
          continue;
        case 62:
          if (tag !== 496) {
            break;
          }

          message.assertConfigPortNotConnectedToFabric = reader.uint32();
          continue;
        case 63:
          if (tag !== 504) {
            break;
          }

          message.assertPortNotConnectedToFabric = reader.uint32();
          continue;
        case 64:
          if (tag !== 512) {
            break;
          }

          message.assertConfigDeviceConnectedToFabric = reader.uint32();
          continue;
        case 65:
          if (tag !== 520) {
            break;
          }

          message.assertDeviceConnectedToFabric = reader.uint32();
          continue;
        case 66:
          if (tag !== 528) {
            break;
          }

          message.assertConfigPortLinkDown = reader.uint32();
          continue;
        case 67:
          if (tag !== 536) {
            break;
          }

          message.assertPortLinkDown = reader.uint32();
          continue;
        case 68:
          if (tag !== 544) {
            break;
          }

          message.assertConfigPortConnectionSpeedMatch = reader.uint32();
          continue;
        case 69:
          if (tag !== 552) {
            break;
          }

          message.assertPortConnectionSpeedMatch = reader.uint32();
          continue;
        case 70:
          if (tag !== 560) {
            break;
          }

          message.assertConfigPortSpeedConsistent = reader.uint32();
          continue;
        case 71:
          if (tag !== 568) {
            break;
          }

          message.assertPortSpeedConsistent = reader.uint32();
          continue;
        case 73:
          if (tag !== 584) {
            break;
          }

          message.underlayInfo = reader.uint32();
          continue;
        case 74:
          if (tag !== 592) {
            break;
          }

          message.proxyStats = reader.uint32();
          continue;
        case 75:
          if (tag !== 600) {
            break;
          }

          message.vlanCounters = reader.uint32();
          continue;
        case 76:
          if (tag !== 608) {
            break;
          }

          message.testPlaceHolder1Secret = reader.uint32();
          continue;
        case 77:
          if (tag !== 616) {
            break;
          }

          message.assertConfigVlanHasTraffic = reader.uint32();
          continue;
        case 78:
          if (tag !== 624) {
            break;
          }

          message.assertVlanHasTraffic = reader.uint32();
          continue;
        case 79:
          if (tag !== 632) {
            break;
          }

          message.remoteVtep = reader.uint32();
          continue;
        case 80:
          if (tag !== 640) {
            break;
          }

          message.qsfpDomThreshold = reader.uint32();
          continue;
        case 81:
          if (tag !== 650) {
            break;
          }

          message.sextantFabricAssignment = reader.string();
          continue;
        case 82:
          if (tag !== 658) {
            break;
          }

          message.l3Table = reader.string();
          continue;
        case 83:
          if (tag !== 664) {
            break;
          }

          message.vlanVniMap = reader.uint32();
          continue;
        case 84:
          if (tag !== 674) {
            break;
          }

          message.proxyConnectionState = reader.string();
          continue;
        case 85:
          if (tag !== 680) {
            break;
          }

          message.proxyConnectionStats = reader.uint32();
          continue;
        case 86:
          if (tag !== 690) {
            break;
          }

          message.l2Mac = reader.string();
          continue;
        case 87:
          if (tag !== 696) {
            break;
          }

          message.bmc = reader.uint32();
          continue;
        case 88:
          if (tag !== 704) {
            break;
          }

          message.smartNicDpu = reader.uint32();
          continue;
        case 89:
          if (tag !== 712) {
            break;
          }

          message.serverVoltageSensor = reader.uint32();
          continue;
        case 90:
          if (tag !== 720) {
            break;
          }

          message.serverTemperatureSensor = reader.uint32();
          continue;
        case 91:
          if (tag !== 730) {
            break;
          }

          message.ipRouteTable = reader.string();
          continue;
        case 92:
          if (tag !== 738) {
            break;
          }

          message.vrf = reader.string();
          continue;
        case 93:
          if (tag !== 744) {
            break;
          }

          message.ipNextHop = reader.uint32();
          continue;
        case 94:
          if (tag !== 754) {
            break;
          }

          message.ipPrefix = reader.string();
          continue;
        case 95:
          if (tag !== 760) {
            break;
          }

          message.subInterface = reader.uint32();
          continue;
        case 96:
          if (tag !== 768) {
            break;
          }

          message.assertConfigStaticDefaultRouteExists = reader.uint32();
          continue;
        case 97:
          if (tag !== 776) {
            break;
          }

          message.assertStaticDefaultRouteExists = reader.uint32();
          continue;
        case 98:
          if (tag !== 786) {
            break;
          }

          message.spyglassBrokerEntry = reader.string();
          continue;
        case 99:
          if (tag !== 794) {
            break;
          }

          message.managementState = reader.string();
          continue;
        case 102:
          if (tag !== 816) {
            break;
          }

          message.assertConfigManagementPortConfigSame = reader.uint32();
          continue;
        case 103:
          if (tag !== 824) {
            break;
          }

          message.assertManagementPortConfigSame = reader.uint32();
          continue;
        case 104:
          if (tag !== 832) {
            break;
          }

          message.fabricConfigNotification = reader.uint32();
          continue;
        case 105:
          if (tag !== 840) {
            break;
          }

          message.spyglassGingerEntry = reader.uint32();
          continue;
        case 106:
          if (tag !== 848) {
            break;
          }

          message.plannedConfig = reader.uint32();
          continue;
        case 107:
          if (tag !== 856) {
            break;
          }

          message.routedInterfaceCounters = reader.uint32();
          continue;
        case 108:
          if (tag !== 864) {
            break;
          }

          message.ipmRawLatencyCounters = reader.uint32();
          continue;
        case 109:
          if (tag !== 872) {
            break;
          }

          message.ipmProcLatencyCounters = reader.uint32();
          continue;
        case 110:
          if (tag !== 880) {
            break;
          }

          message.ipmProcLossCounters = reader.uint32();
          continue;
        case 111:
          if (tag !== 888) {
            break;
          }

          message.ipmLivenessCounters = reader.uint32();
          continue;
        case 112:
          if (tag !== 898) {
            break;
          }

          message.ipmSessionId = reader.string();
          continue;
        case 119:
          if (tag !== 952) {
            break;
          }

          message.systemStats = reader.uint32();
          continue;
        case 120:
          if (tag !== 962) {
            break;
          }

          message.testRootOne = reader.string();
          continue;
        case 121:
          if (tag !== 970) {
            break;
          }

          message.testRootTwo = reader.string();
          continue;
        case 122:
          if (tag !== 976) {
            break;
          }

          message.testMidOne = reader.uint32();
          continue;
        case 124:
          if (tag !== 994) {
            break;
          }

          message.testLeafOne = reader.string();
          continue;
        case 126:
          if (tag !== 1010) {
            break;
          }

          message.testIpAddress = reader.string();
          continue;
        case 127:
          if (tag !== 1018) {
            break;
          }

          message.testIpPrefix = reader.string();
          continue;
        case 128:
          if (tag !== 1026) {
            break;
          }

          message.testMacAddress = reader.string();
          continue;
        case 129:
          if (tag !== 1032) {
            break;
          }

          message.assertConfigDeviceResourceUsageBelowThreshold = reader.uint32();
          continue;
        case 130:
          if (tag !== 1040) {
            break;
          }

          message.assertDeviceResourceUsageBelowThreshold = reader.uint32();
          continue;
        case 131:
          if (tag !== 1048) {
            break;
          }

          message.fabricTransaction = longToNumber(reader.int64() as Long);
          continue;
        case 133:
          if (tag !== 1066) {
            break;
          }

          message.bgpNeighborSummary = reader.string();
          continue;
        case 134:
          if (tag !== 1072) {
            break;
          }

          message.bmcConfig = reader.uint32();
          continue;
        case 135:
          if (tag !== 1080) {
            break;
          }

          message.deviceConnection = reader.uint32();
          continue;
        case 136:
          if (tag !== 1088) {
            break;
          }

          message.bgpNeighborFamily = reader.uint32();
          continue;
        case 137:
          if (tag !== 1096) {
            break;
          }

          message.assertConfigBgpPeerEstablished = reader.uint32();
          continue;
        case 138:
          if (tag !== 1104) {
            break;
          }

          message.assertBgpPeerEstablished = reader.uint32();
          continue;
        case 139:
          if (tag !== 1112) {
            break;
          }

          message.spyglassSshSessionEntry = reader.uint32();
          continue;
        case 140:
          if (tag !== 1122) {
            break;
          }

          message.portChannelMemberState = reader.string();
          continue;
        case 141:
          if (tag !== 1130) {
            break;
          }

          message.portChannelState = reader.string();
          continue;
        case 142:
          if (tag !== 1136) {
            break;
          }

          message.assertConfigIpmLatencyBelowThreshold = reader.uint32();
          continue;
        case 143:
          if (tag !== 1144) {
            break;
          }

          message.assertIpmLatencyBelowThreshold = reader.uint32();
          continue;
        case 144:
          if (tag !== 1152) {
            break;
          }

          message.assertConfigIpmLossBelowThreshold = reader.uint32();
          continue;
        case 145:
          if (tag !== 1160) {
            break;
          }

          message.assertIpmLossBelowThreshold = reader.uint32();
          continue;
        case 146:
          if (tag !== 1170) {
            break;
          }

          message.spyglassDevice = reader.string();
          continue;
        case 147:
          if (tag !== 1176) {
            break;
          }

          message.spyglassTcpListener = reader.uint32();
          continue;
        case 148:
          if (tag !== 1184) {
            break;
          }

          message.spyglassTcpSession = reader.uint32();
          continue;
        case 149:
          if (tag !== 1192) {
            break;
          }

          message.unknownVlan = reader.uint32();
          continue;
        case 150:
          if (tag !== 1202) {
            break;
          }

          message.unknownVlanPort = reader.string();
          continue;
        case 151:
          if (tag !== 1208) {
            break;
          }

          message.assertConfigExpectedPortBreakoutExists = reader.uint32();
          continue;
        case 152:
          if (tag !== 1216) {
            break;
          }

          message.assertExpectedPortBreakoutExists = reader.uint32();
          continue;
        case 153:
          if (tag !== 1224) {
            break;
          }

          message.assertConfigSubInterfaceUp = reader.uint32();
          continue;
        case 154:
          if (tag !== 1232) {
            break;
          }

          message.assertSubInterfaceUp = reader.uint32();
          continue;
        case 155:
          if (tag !== 1240) {
            break;
          }

          message.assertSubInterfaceDown = reader.uint32();
          continue;
        case 156:
          if (tag !== 1248) {
            break;
          }

          message.assertConfigSubInterfaceDown = reader.uint32();
          continue;
        case 158:
          if (tag !== 1264) {
            break;
          }

          message.deviceClaim = reader.uint32();
          continue;
        case 159:
          if (tag !== 1274) {
            break;
          }

          message.aggregatedDeviceClaim = reader.string();
          continue;
        case 160:
          if (tag !== 1280) {
            break;
          }

          message.assertPortChannelMemberUp = reader.uint32();
          continue;
        case 161:
          if (tag !== 1288) {
            break;
          }

          message.assertConfigPortChannelMemberUp = reader.uint32();
          continue;
        case 162:
          if (tag !== 1298) {
            break;
          }

          message.esiInfo = reader.string();
          continue;
        case 163:
          if (tag !== 1306) {
            break;
          }

          message.esiVtepInfo = reader.string();
          continue;
        case 220:
          if (tag !== 1762) {
            break;
          }

          message.orgUuid = reader.string();
          continue;
        case 221:
          if (tag !== 1770) {
            break;
          }

          message.fabricUuid = reader.string();
          continue;
        case 222:
          if (tag !== 1778) {
            break;
          }

          message.orgServiceProto = reader.string();
          continue;
        case 223:
          if (tag !== 1786) {
            break;
          }

          message.orgServiceSvc = reader.string();
          continue;
        case 224:
          if (tag !== 1792) {
            break;
          }

          message.orgServiceRoute = reader.uint32();
          continue;
        case 225:
          if (tag !== 1802) {
            break;
          }

          message.apiPath = reader.string();
          continue;
        case 226:
          if (tag !== 1810) {
            break;
          }

          message.apiPathMethod = reader.string();
          continue;
        case 227:
          if (tag !== 1816) {
            break;
          }

          message.apiServiceMapping = reader.uint32();
          continue;
        case 228:
          if (tag !== 1824) {
            break;
          }

          message.orgToCellPairMapping = reader.uint32();
          continue;
        case 229:
          if (tag !== 1834) {
            break;
          }

          message.tenantUuid = reader.string();
          continue;
        case 230:
          if (tag !== 1842) {
            break;
          }

          message.userEmail = reader.string();
          continue;
        case 231:
          if (tag !== 1848) {
            break;
          }

          message.managementConfig = reader.uint32();
          continue;
        case 232:
          if (tag !== 1858) {
            break;
          }

          message.serviceInstanceHealth = reader.string();
          continue;
        case 233:
          if (tag !== 1866) {
            break;
          }

          message.fabricAssignmentStatus = reader.string();
          continue;
        case 235:
          if (tag !== 1882) {
            break;
          }

          message.kubernetesServiceInstance = reader.string();
          continue;
        case 242:
          if (tag !== 1938) {
            break;
          }

          message.bearerToken = reader.string();
          continue;
        case 243:
          if (tag !== 1946) {
            break;
          }

          message.apiKey = reader.string();
          continue;
        case 244:
          if (tag !== 1954) {
            break;
          }

          message.fabricMemoryUsage = reader.string();
          continue;
        case 245:
          if (tag !== 1960) {
            break;
          }

          message.typeMemoryUsage = reader.uint32();
          continue;
        case 246:
          if (tag !== 1968) {
            break;
          }

          message.manifestRunningImageStatus = reader.uint32();
          continue;
        case 247:
          if (tag !== 1978) {
            break;
          }

          message.manifestPartitionFileCacheStatus = reader.string();
          continue;
        case 248:
          if (tag !== 1986) {
            break;
          }

          message.stpPortState = reader.string();
          continue;
        case 249:
          if (tag !== 1992) {
            break;
          }

          message.stpVlanState = reader.uint32();
          continue;
        case 250:
          if (tag !== 2002) {
            break;
          }

          message.stpVlanPortState = reader.string();
          continue;
        case 251:
          if (tag !== 2008) {
            break;
          }

          message.assertDeviceEnvPsuUsageBelowThreshold = reader.uint32();
          continue;
        case 252:
          if (tag !== 2016) {
            break;
          }

          message.assertDeviceEnvFanUsageBelowThreshold = reader.uint32();
          continue;
        case 253:
          if (tag !== 2024) {
            break;
          }

          message.assertDeviceEnvTempSensorUsageBelowThreshold = reader.uint32();
          continue;
        case 254:
          if (tag !== 2032) {
            break;
          }

          message.assertConfigDeviceEnvPsuUsageBelowThreshold = reader.uint32();
          continue;
        case 255:
          if (tag !== 2040) {
            break;
          }

          message.assertConfigDeviceEnvFanUsageBelowThreshold = reader.uint32();
          continue;
        case 256:
          if (tag !== 2048) {
            break;
          }

          message.assertConfigDeviceEnvTempSensorUsageBelowThreshold = reader.uint32();
          continue;
        case 258:
          if (tag !== 2064) {
            break;
          }

          message.unpackAndAssembleTask = reader.uint32();
          continue;
        case 259:
          if (tag !== 2072) {
            break;
          }

          message.rackAndPowerOnTask = reader.uint32();
          continue;
        case 260:
          if (tag !== 2080) {
            break;
          }

          message.claimAndBindTask = reader.uint32();
          continue;
        case 261:
          if (tag !== 2088) {
            break;
          }

          message.portSetupTask = reader.uint32();
          continue;
        case 262:
          if (tag !== 2096) {
            break;
          }

          message.fabricConnectionTask = reader.uint32();
          continue;
        case 263:
          if (tag !== 2104) {
            break;
          }

          message.negativeConnectionTask = reader.uint32();
          continue;
        case 264:
          if (tag !== 2112) {
            break;
          }

          message.application = reader.uint32();
          continue;
        case 265:
          if (tag !== 2122) {
            break;
          }

          message.resourceAssignment = reader.string();
          continue;
        case 266:
          if (tag !== 2130) {
            break;
          }

          message.node = reader.string();
          continue;
        case 267:
          if (tag !== 2136) {
            break;
          }

          message.nodeLineCard = reader.uint32();
          continue;
        case 268:
          if (tag !== 2144) {
            break;
          }

          message.nodePort = reader.uint32();
          continue;
        case 269:
          if (tag !== 2152) {
            break;
          }

          message.nodeBreakout = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PathId {
    return {
      deleted: isSet(object.deleted) ? globalThis.Boolean(object.deleted) : false,
      pathString: isSet(object.pathString) ? globalThis.String(object.pathString) : "",
      type: isSet(object.type) ? schemaTypeFromJSON(object.type) : 0,
      fabric: isSet(object.fabric) ? globalThis.String(object.fabric) : "",
      device: isSet(object.device) ? globalThis.String(object.device) : "",
      fan: isSet(object.fan) ? globalThis.Number(object.fan) : 0,
      psu: isSet(object.psu) ? globalThis.Number(object.psu) : 0,
      tempSensor: isSet(object.tempSensor) ? globalThis.Number(object.tempSensor) : 0,
      lineCard: isSet(object.lineCard) ? globalThis.Number(object.lineCard) : 0,
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
      dacQsfp: isSet(object.dacQsfp) ? globalThis.Number(object.dacQsfp) : 0,
      fiberQsfp: isSet(object.fiberQsfp) ? globalThis.Number(object.fiberQsfp) : 0,
      breakout: isSet(object.breakout) ? globalThis.Number(object.breakout) : 0,
      portNeighbor: isSet(object.portNeighbor) ? globalThis.String(object.portNeighbor) : "",
      vtep: isSet(object.vtep) ? globalThis.Number(object.vtep) : 0,
      vniPort: isSet(object.vniPort) ? globalThis.Number(object.vniPort) : 0,
      mac: isSet(object.mac) ? globalThis.Number(object.mac) : 0,
      procTable: isSet(object.procTable) ? globalThis.Number(object.procTable) : 0,
      portVlan: isSet(object.portVlan) ? globalThis.String(object.portVlan) : "",
      vlanState: isSet(object.vlanState) ? globalThis.Number(object.vlanState) : 0,
      qsfpDomSensor: isSet(object.qsfpDomSensor) ? globalThis.Number(object.qsfpDomSensor) : 0,
      portConfig: isSet(object.portConfig) ? globalThis.Number(object.portConfig) : 0,
      nodeConfig: isSet(object.nodeConfig) ? globalThis.Number(object.nodeConfig) : 0,
      fabricConfig: isSet(object.fabricConfig) ? globalThis.String(object.fabricConfig) : "",
      fabricConfigEvent: isSet(object.fabricConfigEvent) ? globalThis.Number(object.fabricConfigEvent) : 0,
      l2Fdb: isSet(object.l2Fdb) ? globalThis.Number(object.l2Fdb) : 0,
      portCounters: isSet(object.portCounters) ? globalThis.Number(object.portCounters) : 0,
      remedyAction: isSet(object.remedyAction) ? globalThis.String(object.remedyAction) : "",
      qsfpInfo: isSet(object.qsfpInfo) ? globalThis.Number(object.qsfpInfo) : 0,
      fabricDevice: isSet(object.fabricDevice) ? globalThis.String(object.fabricDevice) : "",
      assertConfigPortLinkUp: isSet(object.assertConfigPortLinkUp)
        ? globalThis.Number(object.assertConfigPortLinkUp)
        : 0,
      assertPortLinkUp: isSet(object.assertPortLinkUp) ? globalThis.Number(object.assertPortLinkUp) : 0,
      assertConfigDeviceConnectedToCloud: isSet(object.assertConfigDeviceConnectedToCloud)
        ? globalThis.Number(object.assertConfigDeviceConnectedToCloud)
        : 0,
      assertDeviceConnectedToCloud: isSet(object.assertDeviceConnectedToCloud)
        ? globalThis.Number(object.assertDeviceConnectedToCloud)
        : 0,
      assertConfigPortExpectedNeighbor: isSet(object.assertConfigPortExpectedNeighbor)
        ? globalThis.Number(object.assertConfigPortExpectedNeighbor)
        : 0,
      assertPortExpectedNeighbor: isSet(object.assertPortExpectedNeighbor)
        ? globalThis.Number(object.assertPortExpectedNeighbor)
        : 0,
      assertConfigPortNotConnectedToFabric: isSet(object.assertConfigPortNotConnectedToFabric)
        ? globalThis.Number(object.assertConfigPortNotConnectedToFabric)
        : 0,
      assertPortNotConnectedToFabric: isSet(object.assertPortNotConnectedToFabric)
        ? globalThis.Number(object.assertPortNotConnectedToFabric)
        : 0,
      assertConfigDeviceConnectedToFabric: isSet(object.assertConfigDeviceConnectedToFabric)
        ? globalThis.Number(object.assertConfigDeviceConnectedToFabric)
        : 0,
      assertDeviceConnectedToFabric: isSet(object.assertDeviceConnectedToFabric)
        ? globalThis.Number(object.assertDeviceConnectedToFabric)
        : 0,
      assertConfigPortLinkDown: isSet(object.assertConfigPortLinkDown)
        ? globalThis.Number(object.assertConfigPortLinkDown)
        : 0,
      assertPortLinkDown: isSet(object.assertPortLinkDown) ? globalThis.Number(object.assertPortLinkDown) : 0,
      assertConfigPortConnectionSpeedMatch: isSet(object.assertConfigPortConnectionSpeedMatch)
        ? globalThis.Number(object.assertConfigPortConnectionSpeedMatch)
        : 0,
      assertPortConnectionSpeedMatch: isSet(object.assertPortConnectionSpeedMatch)
        ? globalThis.Number(object.assertPortConnectionSpeedMatch)
        : 0,
      assertConfigPortSpeedConsistent: isSet(object.assertConfigPortSpeedConsistent)
        ? globalThis.Number(object.assertConfigPortSpeedConsistent)
        : 0,
      assertPortSpeedConsistent: isSet(object.assertPortSpeedConsistent)
        ? globalThis.Number(object.assertPortSpeedConsistent)
        : 0,
      underlayInfo: isSet(object.underlayInfo) ? globalThis.Number(object.underlayInfo) : 0,
      proxyStats: isSet(object.proxyStats) ? globalThis.Number(object.proxyStats) : 0,
      vlanCounters: isSet(object.vlanCounters) ? globalThis.Number(object.vlanCounters) : 0,
      testPlaceHolder1Secret: isSet(object.testPlaceHolder1Secret)
        ? globalThis.Number(object.testPlaceHolder1Secret)
        : 0,
      assertConfigVlanHasTraffic: isSet(object.assertConfigVlanHasTraffic)
        ? globalThis.Number(object.assertConfigVlanHasTraffic)
        : 0,
      assertVlanHasTraffic: isSet(object.assertVlanHasTraffic) ? globalThis.Number(object.assertVlanHasTraffic) : 0,
      remoteVtep: isSet(object.remoteVtep) ? globalThis.Number(object.remoteVtep) : 0,
      qsfpDomThreshold: isSet(object.qsfpDomThreshold) ? globalThis.Number(object.qsfpDomThreshold) : 0,
      sextantFabricAssignment: isSet(object.sextantFabricAssignment)
        ? globalThis.String(object.sextantFabricAssignment)
        : "",
      l3Table: isSet(object.l3Table) ? globalThis.String(object.l3Table) : "",
      vlanVniMap: isSet(object.vlanVniMap) ? globalThis.Number(object.vlanVniMap) : 0,
      proxyConnectionState: isSet(object.proxyConnectionState) ? globalThis.String(object.proxyConnectionState) : "",
      proxyConnectionStats: isSet(object.proxyConnectionStats) ? globalThis.Number(object.proxyConnectionStats) : 0,
      l2Mac: isSet(object.l2Mac) ? globalThis.String(object.l2Mac) : "",
      bmc: isSet(object.bmc) ? globalThis.Number(object.bmc) : 0,
      smartNicDpu: isSet(object.smartNicDpu) ? globalThis.Number(object.smartNicDpu) : 0,
      serverVoltageSensor: isSet(object.serverVoltageSensor) ? globalThis.Number(object.serverVoltageSensor) : 0,
      serverTemperatureSensor: isSet(object.serverTemperatureSensor)
        ? globalThis.Number(object.serverTemperatureSensor)
        : 0,
      ipRouteTable: isSet(object.ipRouteTable) ? globalThis.String(object.ipRouteTable) : "",
      vrf: isSet(object.vrf) ? globalThis.String(object.vrf) : "",
      ipNextHop: isSet(object.ipNextHop) ? globalThis.Number(object.ipNextHop) : 0,
      ipPrefix: isSet(object.ipPrefix) ? globalThis.String(object.ipPrefix) : "",
      subInterface: isSet(object.subInterface) ? globalThis.Number(object.subInterface) : 0,
      assertConfigStaticDefaultRouteExists: isSet(object.assertConfigStaticDefaultRouteExists)
        ? globalThis.Number(object.assertConfigStaticDefaultRouteExists)
        : 0,
      assertStaticDefaultRouteExists: isSet(object.assertStaticDefaultRouteExists)
        ? globalThis.Number(object.assertStaticDefaultRouteExists)
        : 0,
      spyglassBrokerEntry: isSet(object.spyglassBrokerEntry) ? globalThis.String(object.spyglassBrokerEntry) : "",
      managementState: isSet(object.managementState) ? globalThis.String(object.managementState) : "",
      assertConfigManagementPortConfigSame: isSet(object.assertConfigManagementPortConfigSame)
        ? globalThis.Number(object.assertConfigManagementPortConfigSame)
        : 0,
      assertManagementPortConfigSame: isSet(object.assertManagementPortConfigSame)
        ? globalThis.Number(object.assertManagementPortConfigSame)
        : 0,
      fabricConfigNotification: isSet(object.fabricConfigNotification)
        ? globalThis.Number(object.fabricConfigNotification)
        : 0,
      spyglassGingerEntry: isSet(object.spyglassGingerEntry) ? globalThis.Number(object.spyglassGingerEntry) : 0,
      plannedConfig: isSet(object.plannedConfig) ? globalThis.Number(object.plannedConfig) : 0,
      routedInterfaceCounters: isSet(object.routedInterfaceCounters)
        ? globalThis.Number(object.routedInterfaceCounters)
        : 0,
      ipmRawLatencyCounters: isSet(object.ipmRawLatencyCounters) ? globalThis.Number(object.ipmRawLatencyCounters) : 0,
      ipmProcLatencyCounters: isSet(object.ipmProcLatencyCounters)
        ? globalThis.Number(object.ipmProcLatencyCounters)
        : 0,
      ipmProcLossCounters: isSet(object.ipmProcLossCounters) ? globalThis.Number(object.ipmProcLossCounters) : 0,
      ipmLivenessCounters: isSet(object.ipmLivenessCounters) ? globalThis.Number(object.ipmLivenessCounters) : 0,
      ipmSessionId: isSet(object.ipmSessionId) ? globalThis.String(object.ipmSessionId) : "",
      systemStats: isSet(object.systemStats) ? globalThis.Number(object.systemStats) : 0,
      testRootOne: isSet(object.testRootOne) ? globalThis.String(object.testRootOne) : "",
      testRootTwo: isSet(object.testRootTwo) ? globalThis.String(object.testRootTwo) : "",
      testMidOne: isSet(object.testMidOne) ? globalThis.Number(object.testMidOne) : 0,
      testLeafOne: isSet(object.testLeafOne) ? globalThis.String(object.testLeafOne) : "",
      testIpAddress: isSet(object.testIpAddress) ? globalThis.String(object.testIpAddress) : "",
      testIpPrefix: isSet(object.testIpPrefix) ? globalThis.String(object.testIpPrefix) : "",
      testMacAddress: isSet(object.testMacAddress) ? globalThis.String(object.testMacAddress) : "",
      assertConfigDeviceResourceUsageBelowThreshold: isSet(object.assertConfigDeviceResourceUsageBelowThreshold)
        ? globalThis.Number(object.assertConfigDeviceResourceUsageBelowThreshold)
        : 0,
      assertDeviceResourceUsageBelowThreshold: isSet(object.assertDeviceResourceUsageBelowThreshold)
        ? globalThis.Number(object.assertDeviceResourceUsageBelowThreshold)
        : 0,
      fabricTransaction: isSet(object.fabricTransaction) ? globalThis.Number(object.fabricTransaction) : 0,
      bgpNeighborSummary: isSet(object.bgpNeighborSummary) ? globalThis.String(object.bgpNeighborSummary) : "",
      bmcConfig: isSet(object.bmcConfig) ? globalThis.Number(object.bmcConfig) : 0,
      deviceConnection: isSet(object.deviceConnection) ? globalThis.Number(object.deviceConnection) : 0,
      bgpNeighborFamily: isSet(object.bgpNeighborFamily) ? globalThis.Number(object.bgpNeighborFamily) : 0,
      assertConfigBgpPeerEstablished: isSet(object.assertConfigBgpPeerEstablished)
        ? globalThis.Number(object.assertConfigBgpPeerEstablished)
        : 0,
      assertBgpPeerEstablished: isSet(object.assertBgpPeerEstablished)
        ? globalThis.Number(object.assertBgpPeerEstablished)
        : 0,
      spyglassSshSessionEntry: isSet(object.spyglassSshSessionEntry)
        ? globalThis.Number(object.spyglassSshSessionEntry)
        : 0,
      portChannelMemberState: isSet(object.portChannelMemberState)
        ? globalThis.String(object.portChannelMemberState)
        : "",
      portChannelState: isSet(object.portChannelState) ? globalThis.String(object.portChannelState) : "",
      assertConfigIpmLatencyBelowThreshold: isSet(object.assertConfigIpmLatencyBelowThreshold)
        ? globalThis.Number(object.assertConfigIpmLatencyBelowThreshold)
        : 0,
      assertIpmLatencyBelowThreshold: isSet(object.assertIpmLatencyBelowThreshold)
        ? globalThis.Number(object.assertIpmLatencyBelowThreshold)
        : 0,
      assertConfigIpmLossBelowThreshold: isSet(object.assertConfigIpmLossBelowThreshold)
        ? globalThis.Number(object.assertConfigIpmLossBelowThreshold)
        : 0,
      assertIpmLossBelowThreshold: isSet(object.assertIpmLossBelowThreshold)
        ? globalThis.Number(object.assertIpmLossBelowThreshold)
        : 0,
      spyglassDevice: isSet(object.spyglassDevice) ? globalThis.String(object.spyglassDevice) : "",
      spyglassTcpListener: isSet(object.spyglassTcpListener) ? globalThis.Number(object.spyglassTcpListener) : 0,
      spyglassTcpSession: isSet(object.spyglassTcpSession) ? globalThis.Number(object.spyglassTcpSession) : 0,
      unknownVlan: isSet(object.unknownVlan) ? globalThis.Number(object.unknownVlan) : 0,
      unknownVlanPort: isSet(object.unknownVlanPort) ? globalThis.String(object.unknownVlanPort) : "",
      assertConfigExpectedPortBreakoutExists: isSet(object.assertConfigExpectedPortBreakoutExists)
        ? globalThis.Number(object.assertConfigExpectedPortBreakoutExists)
        : 0,
      assertExpectedPortBreakoutExists: isSet(object.assertExpectedPortBreakoutExists)
        ? globalThis.Number(object.assertExpectedPortBreakoutExists)
        : 0,
      assertConfigSubInterfaceUp: isSet(object.assertConfigSubInterfaceUp)
        ? globalThis.Number(object.assertConfigSubInterfaceUp)
        : 0,
      assertSubInterfaceUp: isSet(object.assertSubInterfaceUp) ? globalThis.Number(object.assertSubInterfaceUp) : 0,
      assertSubInterfaceDown: isSet(object.assertSubInterfaceDown)
        ? globalThis.Number(object.assertSubInterfaceDown)
        : 0,
      assertConfigSubInterfaceDown: isSet(object.assertConfigSubInterfaceDown)
        ? globalThis.Number(object.assertConfigSubInterfaceDown)
        : 0,
      deviceClaim: isSet(object.deviceClaim) ? globalThis.Number(object.deviceClaim) : 0,
      aggregatedDeviceClaim: isSet(object.aggregatedDeviceClaim) ? globalThis.String(object.aggregatedDeviceClaim) : "",
      assertPortChannelMemberUp: isSet(object.assertPortChannelMemberUp)
        ? globalThis.Number(object.assertPortChannelMemberUp)
        : 0,
      assertConfigPortChannelMemberUp: isSet(object.assertConfigPortChannelMemberUp)
        ? globalThis.Number(object.assertConfigPortChannelMemberUp)
        : 0,
      esiInfo: isSet(object.esiInfo) ? globalThis.String(object.esiInfo) : "",
      esiVtepInfo: isSet(object.esiVtepInfo) ? globalThis.String(object.esiVtepInfo) : "",
      orgUuid: isSet(object.orgUuid) ? globalThis.String(object.orgUuid) : "",
      fabricUuid: isSet(object.fabricUuid) ? globalThis.String(object.fabricUuid) : "",
      orgServiceProto: isSet(object.orgServiceProto) ? globalThis.String(object.orgServiceProto) : "",
      orgServiceSvc: isSet(object.orgServiceSvc) ? globalThis.String(object.orgServiceSvc) : "",
      orgServiceRoute: isSet(object.orgServiceRoute) ? globalThis.Number(object.orgServiceRoute) : 0,
      apiPath: isSet(object.apiPath) ? globalThis.String(object.apiPath) : "",
      apiPathMethod: isSet(object.apiPathMethod) ? globalThis.String(object.apiPathMethod) : "",
      apiServiceMapping: isSet(object.apiServiceMapping) ? globalThis.Number(object.apiServiceMapping) : 0,
      orgToCellPairMapping: isSet(object.orgToCellPairMapping) ? globalThis.Number(object.orgToCellPairMapping) : 0,
      tenantUuid: isSet(object.tenantUuid) ? globalThis.String(object.tenantUuid) : "",
      userEmail: isSet(object.userEmail) ? globalThis.String(object.userEmail) : "",
      managementConfig: isSet(object.managementConfig) ? globalThis.Number(object.managementConfig) : 0,
      serviceInstanceHealth: isSet(object.serviceInstanceHealth) ? globalThis.String(object.serviceInstanceHealth) : "",
      fabricAssignmentStatus: isSet(object.fabricAssignmentStatus)
        ? globalThis.String(object.fabricAssignmentStatus)
        : "",
      kubernetesServiceInstance: isSet(object.kubernetesServiceInstance)
        ? globalThis.String(object.kubernetesServiceInstance)
        : "",
      bearerToken: isSet(object.bearerToken) ? globalThis.String(object.bearerToken) : "",
      apiKey: isSet(object.apiKey) ? globalThis.String(object.apiKey) : "",
      fabricMemoryUsage: isSet(object.fabricMemoryUsage) ? globalThis.String(object.fabricMemoryUsage) : "",
      typeMemoryUsage: isSet(object.typeMemoryUsage) ? globalThis.Number(object.typeMemoryUsage) : 0,
      manifestRunningImageStatus: isSet(object.manifestRunningImageStatus)
        ? globalThis.Number(object.manifestRunningImageStatus)
        : 0,
      manifestPartitionFileCacheStatus: isSet(object.manifestPartitionFileCacheStatus)
        ? globalThis.String(object.manifestPartitionFileCacheStatus)
        : "",
      stpPortState: isSet(object.stpPortState) ? globalThis.String(object.stpPortState) : "",
      stpVlanState: isSet(object.stpVlanState) ? globalThis.Number(object.stpVlanState) : 0,
      stpVlanPortState: isSet(object.stpVlanPortState) ? globalThis.String(object.stpVlanPortState) : "",
      assertDeviceEnvPsuUsageBelowThreshold: isSet(object.assertDeviceEnvPsuUsageBelowThreshold)
        ? globalThis.Number(object.assertDeviceEnvPsuUsageBelowThreshold)
        : 0,
      assertDeviceEnvFanUsageBelowThreshold: isSet(object.assertDeviceEnvFanUsageBelowThreshold)
        ? globalThis.Number(object.assertDeviceEnvFanUsageBelowThreshold)
        : 0,
      assertDeviceEnvTempSensorUsageBelowThreshold: isSet(object.assertDeviceEnvTempSensorUsageBelowThreshold)
        ? globalThis.Number(object.assertDeviceEnvTempSensorUsageBelowThreshold)
        : 0,
      assertConfigDeviceEnvPsuUsageBelowThreshold: isSet(object.assertConfigDeviceEnvPsuUsageBelowThreshold)
        ? globalThis.Number(object.assertConfigDeviceEnvPsuUsageBelowThreshold)
        : 0,
      assertConfigDeviceEnvFanUsageBelowThreshold: isSet(object.assertConfigDeviceEnvFanUsageBelowThreshold)
        ? globalThis.Number(object.assertConfigDeviceEnvFanUsageBelowThreshold)
        : 0,
      assertConfigDeviceEnvTempSensorUsageBelowThreshold:
        isSet(object.assertConfigDeviceEnvTempSensorUsageBelowThreshold)
          ? globalThis.Number(object.assertConfigDeviceEnvTempSensorUsageBelowThreshold)
          : 0,
      unpackAndAssembleTask: isSet(object.unpackAndAssembleTask) ? globalThis.Number(object.unpackAndAssembleTask) : 0,
      rackAndPowerOnTask: isSet(object.rackAndPowerOnTask) ? globalThis.Number(object.rackAndPowerOnTask) : 0,
      claimAndBindTask: isSet(object.claimAndBindTask) ? globalThis.Number(object.claimAndBindTask) : 0,
      portSetupTask: isSet(object.portSetupTask) ? globalThis.Number(object.portSetupTask) : 0,
      fabricConnectionTask: isSet(object.fabricConnectionTask) ? globalThis.Number(object.fabricConnectionTask) : 0,
      negativeConnectionTask: isSet(object.negativeConnectionTask)
        ? globalThis.Number(object.negativeConnectionTask)
        : 0,
      application: isSet(object.application) ? globalThis.Number(object.application) : 0,
      resourceAssignment: isSet(object.resourceAssignment) ? globalThis.String(object.resourceAssignment) : "",
      node: isSet(object.node) ? globalThis.String(object.node) : "",
      nodeLineCard: isSet(object.nodeLineCard) ? globalThis.Number(object.nodeLineCard) : 0,
      nodePort: isSet(object.nodePort) ? globalThis.Number(object.nodePort) : 0,
      nodeBreakout: isSet(object.nodeBreakout) ? globalThis.Number(object.nodeBreakout) : 0,
    };
  },

  toJSON(message: PathId): unknown {
    const obj: any = {};
    if (message.deleted !== false) {
      obj.deleted = message.deleted;
    }
    if (message.pathString !== "") {
      obj.pathString = message.pathString;
    }
    if (message.type !== 0) {
      obj.type = schemaTypeToJSON(message.type);
    }
    if (message.fabric !== "") {
      obj.fabric = message.fabric;
    }
    if (message.device !== "") {
      obj.device = message.device;
    }
    if (message.fan !== 0) {
      obj.fan = Math.round(message.fan);
    }
    if (message.psu !== 0) {
      obj.psu = Math.round(message.psu);
    }
    if (message.tempSensor !== 0) {
      obj.tempSensor = Math.round(message.tempSensor);
    }
    if (message.lineCard !== 0) {
      obj.lineCard = Math.round(message.lineCard);
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port);
    }
    if (message.dacQsfp !== 0) {
      obj.dacQsfp = Math.round(message.dacQsfp);
    }
    if (message.fiberQsfp !== 0) {
      obj.fiberQsfp = Math.round(message.fiberQsfp);
    }
    if (message.breakout !== 0) {
      obj.breakout = Math.round(message.breakout);
    }
    if (message.portNeighbor !== "") {
      obj.portNeighbor = message.portNeighbor;
    }
    if (message.vtep !== 0) {
      obj.vtep = Math.round(message.vtep);
    }
    if (message.vniPort !== 0) {
      obj.vniPort = Math.round(message.vniPort);
    }
    if (message.mac !== 0) {
      obj.mac = Math.round(message.mac);
    }
    if (message.procTable !== 0) {
      obj.procTable = Math.round(message.procTable);
    }
    if (message.portVlan !== "") {
      obj.portVlan = message.portVlan;
    }
    if (message.vlanState !== 0) {
      obj.vlanState = Math.round(message.vlanState);
    }
    if (message.qsfpDomSensor !== 0) {
      obj.qsfpDomSensor = Math.round(message.qsfpDomSensor);
    }
    if (message.portConfig !== 0) {
      obj.portConfig = Math.round(message.portConfig);
    }
    if (message.nodeConfig !== 0) {
      obj.nodeConfig = Math.round(message.nodeConfig);
    }
    if (message.fabricConfig !== "") {
      obj.fabricConfig = message.fabricConfig;
    }
    if (message.fabricConfigEvent !== 0) {
      obj.fabricConfigEvent = Math.round(message.fabricConfigEvent);
    }
    if (message.l2Fdb !== 0) {
      obj.l2Fdb = Math.round(message.l2Fdb);
    }
    if (message.portCounters !== 0) {
      obj.portCounters = Math.round(message.portCounters);
    }
    if (message.remedyAction !== "") {
      obj.remedyAction = message.remedyAction;
    }
    if (message.qsfpInfo !== 0) {
      obj.qsfpInfo = Math.round(message.qsfpInfo);
    }
    if (message.fabricDevice !== "") {
      obj.fabricDevice = message.fabricDevice;
    }
    if (message.assertConfigPortLinkUp !== 0) {
      obj.assertConfigPortLinkUp = Math.round(message.assertConfigPortLinkUp);
    }
    if (message.assertPortLinkUp !== 0) {
      obj.assertPortLinkUp = Math.round(message.assertPortLinkUp);
    }
    if (message.assertConfigDeviceConnectedToCloud !== 0) {
      obj.assertConfigDeviceConnectedToCloud = Math.round(message.assertConfigDeviceConnectedToCloud);
    }
    if (message.assertDeviceConnectedToCloud !== 0) {
      obj.assertDeviceConnectedToCloud = Math.round(message.assertDeviceConnectedToCloud);
    }
    if (message.assertConfigPortExpectedNeighbor !== 0) {
      obj.assertConfigPortExpectedNeighbor = Math.round(message.assertConfigPortExpectedNeighbor);
    }
    if (message.assertPortExpectedNeighbor !== 0) {
      obj.assertPortExpectedNeighbor = Math.round(message.assertPortExpectedNeighbor);
    }
    if (message.assertConfigPortNotConnectedToFabric !== 0) {
      obj.assertConfigPortNotConnectedToFabric = Math.round(message.assertConfigPortNotConnectedToFabric);
    }
    if (message.assertPortNotConnectedToFabric !== 0) {
      obj.assertPortNotConnectedToFabric = Math.round(message.assertPortNotConnectedToFabric);
    }
    if (message.assertConfigDeviceConnectedToFabric !== 0) {
      obj.assertConfigDeviceConnectedToFabric = Math.round(message.assertConfigDeviceConnectedToFabric);
    }
    if (message.assertDeviceConnectedToFabric !== 0) {
      obj.assertDeviceConnectedToFabric = Math.round(message.assertDeviceConnectedToFabric);
    }
    if (message.assertConfigPortLinkDown !== 0) {
      obj.assertConfigPortLinkDown = Math.round(message.assertConfigPortLinkDown);
    }
    if (message.assertPortLinkDown !== 0) {
      obj.assertPortLinkDown = Math.round(message.assertPortLinkDown);
    }
    if (message.assertConfigPortConnectionSpeedMatch !== 0) {
      obj.assertConfigPortConnectionSpeedMatch = Math.round(message.assertConfigPortConnectionSpeedMatch);
    }
    if (message.assertPortConnectionSpeedMatch !== 0) {
      obj.assertPortConnectionSpeedMatch = Math.round(message.assertPortConnectionSpeedMatch);
    }
    if (message.assertConfigPortSpeedConsistent !== 0) {
      obj.assertConfigPortSpeedConsistent = Math.round(message.assertConfigPortSpeedConsistent);
    }
    if (message.assertPortSpeedConsistent !== 0) {
      obj.assertPortSpeedConsistent = Math.round(message.assertPortSpeedConsistent);
    }
    if (message.underlayInfo !== 0) {
      obj.underlayInfo = Math.round(message.underlayInfo);
    }
    if (message.proxyStats !== 0) {
      obj.proxyStats = Math.round(message.proxyStats);
    }
    if (message.vlanCounters !== 0) {
      obj.vlanCounters = Math.round(message.vlanCounters);
    }
    if (message.testPlaceHolder1Secret !== 0) {
      obj.testPlaceHolder1Secret = Math.round(message.testPlaceHolder1Secret);
    }
    if (message.assertConfigVlanHasTraffic !== 0) {
      obj.assertConfigVlanHasTraffic = Math.round(message.assertConfigVlanHasTraffic);
    }
    if (message.assertVlanHasTraffic !== 0) {
      obj.assertVlanHasTraffic = Math.round(message.assertVlanHasTraffic);
    }
    if (message.remoteVtep !== 0) {
      obj.remoteVtep = Math.round(message.remoteVtep);
    }
    if (message.qsfpDomThreshold !== 0) {
      obj.qsfpDomThreshold = Math.round(message.qsfpDomThreshold);
    }
    if (message.sextantFabricAssignment !== "") {
      obj.sextantFabricAssignment = message.sextantFabricAssignment;
    }
    if (message.l3Table !== "") {
      obj.l3Table = message.l3Table;
    }
    if (message.vlanVniMap !== 0) {
      obj.vlanVniMap = Math.round(message.vlanVniMap);
    }
    if (message.proxyConnectionState !== "") {
      obj.proxyConnectionState = message.proxyConnectionState;
    }
    if (message.proxyConnectionStats !== 0) {
      obj.proxyConnectionStats = Math.round(message.proxyConnectionStats);
    }
    if (message.l2Mac !== "") {
      obj.l2Mac = message.l2Mac;
    }
    if (message.bmc !== 0) {
      obj.bmc = Math.round(message.bmc);
    }
    if (message.smartNicDpu !== 0) {
      obj.smartNicDpu = Math.round(message.smartNicDpu);
    }
    if (message.serverVoltageSensor !== 0) {
      obj.serverVoltageSensor = Math.round(message.serverVoltageSensor);
    }
    if (message.serverTemperatureSensor !== 0) {
      obj.serverTemperatureSensor = Math.round(message.serverTemperatureSensor);
    }
    if (message.ipRouteTable !== "") {
      obj.ipRouteTable = message.ipRouteTable;
    }
    if (message.vrf !== "") {
      obj.vrf = message.vrf;
    }
    if (message.ipNextHop !== 0) {
      obj.ipNextHop = Math.round(message.ipNextHop);
    }
    if (message.ipPrefix !== "") {
      obj.ipPrefix = message.ipPrefix;
    }
    if (message.subInterface !== 0) {
      obj.subInterface = Math.round(message.subInterface);
    }
    if (message.assertConfigStaticDefaultRouteExists !== 0) {
      obj.assertConfigStaticDefaultRouteExists = Math.round(message.assertConfigStaticDefaultRouteExists);
    }
    if (message.assertStaticDefaultRouteExists !== 0) {
      obj.assertStaticDefaultRouteExists = Math.round(message.assertStaticDefaultRouteExists);
    }
    if (message.spyglassBrokerEntry !== "") {
      obj.spyglassBrokerEntry = message.spyglassBrokerEntry;
    }
    if (message.managementState !== "") {
      obj.managementState = message.managementState;
    }
    if (message.assertConfigManagementPortConfigSame !== 0) {
      obj.assertConfigManagementPortConfigSame = Math.round(message.assertConfigManagementPortConfigSame);
    }
    if (message.assertManagementPortConfigSame !== 0) {
      obj.assertManagementPortConfigSame = Math.round(message.assertManagementPortConfigSame);
    }
    if (message.fabricConfigNotification !== 0) {
      obj.fabricConfigNotification = Math.round(message.fabricConfigNotification);
    }
    if (message.spyglassGingerEntry !== 0) {
      obj.spyglassGingerEntry = Math.round(message.spyglassGingerEntry);
    }
    if (message.plannedConfig !== 0) {
      obj.plannedConfig = Math.round(message.plannedConfig);
    }
    if (message.routedInterfaceCounters !== 0) {
      obj.routedInterfaceCounters = Math.round(message.routedInterfaceCounters);
    }
    if (message.ipmRawLatencyCounters !== 0) {
      obj.ipmRawLatencyCounters = Math.round(message.ipmRawLatencyCounters);
    }
    if (message.ipmProcLatencyCounters !== 0) {
      obj.ipmProcLatencyCounters = Math.round(message.ipmProcLatencyCounters);
    }
    if (message.ipmProcLossCounters !== 0) {
      obj.ipmProcLossCounters = Math.round(message.ipmProcLossCounters);
    }
    if (message.ipmLivenessCounters !== 0) {
      obj.ipmLivenessCounters = Math.round(message.ipmLivenessCounters);
    }
    if (message.ipmSessionId !== "") {
      obj.ipmSessionId = message.ipmSessionId;
    }
    if (message.systemStats !== 0) {
      obj.systemStats = Math.round(message.systemStats);
    }
    if (message.testRootOne !== "") {
      obj.testRootOne = message.testRootOne;
    }
    if (message.testRootTwo !== "") {
      obj.testRootTwo = message.testRootTwo;
    }
    if (message.testMidOne !== 0) {
      obj.testMidOne = Math.round(message.testMidOne);
    }
    if (message.testLeafOne !== "") {
      obj.testLeafOne = message.testLeafOne;
    }
    if (message.testIpAddress !== "") {
      obj.testIpAddress = message.testIpAddress;
    }
    if (message.testIpPrefix !== "") {
      obj.testIpPrefix = message.testIpPrefix;
    }
    if (message.testMacAddress !== "") {
      obj.testMacAddress = message.testMacAddress;
    }
    if (message.assertConfigDeviceResourceUsageBelowThreshold !== 0) {
      obj.assertConfigDeviceResourceUsageBelowThreshold = Math.round(
        message.assertConfigDeviceResourceUsageBelowThreshold,
      );
    }
    if (message.assertDeviceResourceUsageBelowThreshold !== 0) {
      obj.assertDeviceResourceUsageBelowThreshold = Math.round(message.assertDeviceResourceUsageBelowThreshold);
    }
    if (message.fabricTransaction !== 0) {
      obj.fabricTransaction = Math.round(message.fabricTransaction);
    }
    if (message.bgpNeighborSummary !== "") {
      obj.bgpNeighborSummary = message.bgpNeighborSummary;
    }
    if (message.bmcConfig !== 0) {
      obj.bmcConfig = Math.round(message.bmcConfig);
    }
    if (message.deviceConnection !== 0) {
      obj.deviceConnection = Math.round(message.deviceConnection);
    }
    if (message.bgpNeighborFamily !== 0) {
      obj.bgpNeighborFamily = Math.round(message.bgpNeighborFamily);
    }
    if (message.assertConfigBgpPeerEstablished !== 0) {
      obj.assertConfigBgpPeerEstablished = Math.round(message.assertConfigBgpPeerEstablished);
    }
    if (message.assertBgpPeerEstablished !== 0) {
      obj.assertBgpPeerEstablished = Math.round(message.assertBgpPeerEstablished);
    }
    if (message.spyglassSshSessionEntry !== 0) {
      obj.spyglassSshSessionEntry = Math.round(message.spyglassSshSessionEntry);
    }
    if (message.portChannelMemberState !== "") {
      obj.portChannelMemberState = message.portChannelMemberState;
    }
    if (message.portChannelState !== "") {
      obj.portChannelState = message.portChannelState;
    }
    if (message.assertConfigIpmLatencyBelowThreshold !== 0) {
      obj.assertConfigIpmLatencyBelowThreshold = Math.round(message.assertConfigIpmLatencyBelowThreshold);
    }
    if (message.assertIpmLatencyBelowThreshold !== 0) {
      obj.assertIpmLatencyBelowThreshold = Math.round(message.assertIpmLatencyBelowThreshold);
    }
    if (message.assertConfigIpmLossBelowThreshold !== 0) {
      obj.assertConfigIpmLossBelowThreshold = Math.round(message.assertConfigIpmLossBelowThreshold);
    }
    if (message.assertIpmLossBelowThreshold !== 0) {
      obj.assertIpmLossBelowThreshold = Math.round(message.assertIpmLossBelowThreshold);
    }
    if (message.spyglassDevice !== "") {
      obj.spyglassDevice = message.spyglassDevice;
    }
    if (message.spyglassTcpListener !== 0) {
      obj.spyglassTcpListener = Math.round(message.spyglassTcpListener);
    }
    if (message.spyglassTcpSession !== 0) {
      obj.spyglassTcpSession = Math.round(message.spyglassTcpSession);
    }
    if (message.unknownVlan !== 0) {
      obj.unknownVlan = Math.round(message.unknownVlan);
    }
    if (message.unknownVlanPort !== "") {
      obj.unknownVlanPort = message.unknownVlanPort;
    }
    if (message.assertConfigExpectedPortBreakoutExists !== 0) {
      obj.assertConfigExpectedPortBreakoutExists = Math.round(message.assertConfigExpectedPortBreakoutExists);
    }
    if (message.assertExpectedPortBreakoutExists !== 0) {
      obj.assertExpectedPortBreakoutExists = Math.round(message.assertExpectedPortBreakoutExists);
    }
    if (message.assertConfigSubInterfaceUp !== 0) {
      obj.assertConfigSubInterfaceUp = Math.round(message.assertConfigSubInterfaceUp);
    }
    if (message.assertSubInterfaceUp !== 0) {
      obj.assertSubInterfaceUp = Math.round(message.assertSubInterfaceUp);
    }
    if (message.assertSubInterfaceDown !== 0) {
      obj.assertSubInterfaceDown = Math.round(message.assertSubInterfaceDown);
    }
    if (message.assertConfigSubInterfaceDown !== 0) {
      obj.assertConfigSubInterfaceDown = Math.round(message.assertConfigSubInterfaceDown);
    }
    if (message.deviceClaim !== 0) {
      obj.deviceClaim = Math.round(message.deviceClaim);
    }
    if (message.aggregatedDeviceClaim !== "") {
      obj.aggregatedDeviceClaim = message.aggregatedDeviceClaim;
    }
    if (message.assertPortChannelMemberUp !== 0) {
      obj.assertPortChannelMemberUp = Math.round(message.assertPortChannelMemberUp);
    }
    if (message.assertConfigPortChannelMemberUp !== 0) {
      obj.assertConfigPortChannelMemberUp = Math.round(message.assertConfigPortChannelMemberUp);
    }
    if (message.esiInfo !== "") {
      obj.esiInfo = message.esiInfo;
    }
    if (message.esiVtepInfo !== "") {
      obj.esiVtepInfo = message.esiVtepInfo;
    }
    if (message.orgUuid !== "") {
      obj.orgUuid = message.orgUuid;
    }
    if (message.fabricUuid !== "") {
      obj.fabricUuid = message.fabricUuid;
    }
    if (message.orgServiceProto !== "") {
      obj.orgServiceProto = message.orgServiceProto;
    }
    if (message.orgServiceSvc !== "") {
      obj.orgServiceSvc = message.orgServiceSvc;
    }
    if (message.orgServiceRoute !== 0) {
      obj.orgServiceRoute = Math.round(message.orgServiceRoute);
    }
    if (message.apiPath !== "") {
      obj.apiPath = message.apiPath;
    }
    if (message.apiPathMethod !== "") {
      obj.apiPathMethod = message.apiPathMethod;
    }
    if (message.apiServiceMapping !== 0) {
      obj.apiServiceMapping = Math.round(message.apiServiceMapping);
    }
    if (message.orgToCellPairMapping !== 0) {
      obj.orgToCellPairMapping = Math.round(message.orgToCellPairMapping);
    }
    if (message.tenantUuid !== "") {
      obj.tenantUuid = message.tenantUuid;
    }
    if (message.userEmail !== "") {
      obj.userEmail = message.userEmail;
    }
    if (message.managementConfig !== 0) {
      obj.managementConfig = Math.round(message.managementConfig);
    }
    if (message.serviceInstanceHealth !== "") {
      obj.serviceInstanceHealth = message.serviceInstanceHealth;
    }
    if (message.fabricAssignmentStatus !== "") {
      obj.fabricAssignmentStatus = message.fabricAssignmentStatus;
    }
    if (message.kubernetesServiceInstance !== "") {
      obj.kubernetesServiceInstance = message.kubernetesServiceInstance;
    }
    if (message.bearerToken !== "") {
      obj.bearerToken = message.bearerToken;
    }
    if (message.apiKey !== "") {
      obj.apiKey = message.apiKey;
    }
    if (message.fabricMemoryUsage !== "") {
      obj.fabricMemoryUsage = message.fabricMemoryUsage;
    }
    if (message.typeMemoryUsage !== 0) {
      obj.typeMemoryUsage = Math.round(message.typeMemoryUsage);
    }
    if (message.manifestRunningImageStatus !== 0) {
      obj.manifestRunningImageStatus = Math.round(message.manifestRunningImageStatus);
    }
    if (message.manifestPartitionFileCacheStatus !== "") {
      obj.manifestPartitionFileCacheStatus = message.manifestPartitionFileCacheStatus;
    }
    if (message.stpPortState !== "") {
      obj.stpPortState = message.stpPortState;
    }
    if (message.stpVlanState !== 0) {
      obj.stpVlanState = Math.round(message.stpVlanState);
    }
    if (message.stpVlanPortState !== "") {
      obj.stpVlanPortState = message.stpVlanPortState;
    }
    if (message.assertDeviceEnvPsuUsageBelowThreshold !== 0) {
      obj.assertDeviceEnvPsuUsageBelowThreshold = Math.round(message.assertDeviceEnvPsuUsageBelowThreshold);
    }
    if (message.assertDeviceEnvFanUsageBelowThreshold !== 0) {
      obj.assertDeviceEnvFanUsageBelowThreshold = Math.round(message.assertDeviceEnvFanUsageBelowThreshold);
    }
    if (message.assertDeviceEnvTempSensorUsageBelowThreshold !== 0) {
      obj.assertDeviceEnvTempSensorUsageBelowThreshold = Math.round(
        message.assertDeviceEnvTempSensorUsageBelowThreshold,
      );
    }
    if (message.assertConfigDeviceEnvPsuUsageBelowThreshold !== 0) {
      obj.assertConfigDeviceEnvPsuUsageBelowThreshold = Math.round(message.assertConfigDeviceEnvPsuUsageBelowThreshold);
    }
    if (message.assertConfigDeviceEnvFanUsageBelowThreshold !== 0) {
      obj.assertConfigDeviceEnvFanUsageBelowThreshold = Math.round(message.assertConfigDeviceEnvFanUsageBelowThreshold);
    }
    if (message.assertConfigDeviceEnvTempSensorUsageBelowThreshold !== 0) {
      obj.assertConfigDeviceEnvTempSensorUsageBelowThreshold = Math.round(
        message.assertConfigDeviceEnvTempSensorUsageBelowThreshold,
      );
    }
    if (message.unpackAndAssembleTask !== 0) {
      obj.unpackAndAssembleTask = Math.round(message.unpackAndAssembleTask);
    }
    if (message.rackAndPowerOnTask !== 0) {
      obj.rackAndPowerOnTask = Math.round(message.rackAndPowerOnTask);
    }
    if (message.claimAndBindTask !== 0) {
      obj.claimAndBindTask = Math.round(message.claimAndBindTask);
    }
    if (message.portSetupTask !== 0) {
      obj.portSetupTask = Math.round(message.portSetupTask);
    }
    if (message.fabricConnectionTask !== 0) {
      obj.fabricConnectionTask = Math.round(message.fabricConnectionTask);
    }
    if (message.negativeConnectionTask !== 0) {
      obj.negativeConnectionTask = Math.round(message.negativeConnectionTask);
    }
    if (message.application !== 0) {
      obj.application = Math.round(message.application);
    }
    if (message.resourceAssignment !== "") {
      obj.resourceAssignment = message.resourceAssignment;
    }
    if (message.node !== "") {
      obj.node = message.node;
    }
    if (message.nodeLineCard !== 0) {
      obj.nodeLineCard = Math.round(message.nodeLineCard);
    }
    if (message.nodePort !== 0) {
      obj.nodePort = Math.round(message.nodePort);
    }
    if (message.nodeBreakout !== 0) {
      obj.nodeBreakout = Math.round(message.nodeBreakout);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PathId>, I>>(base?: I): PathId {
    return PathId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PathId>, I>>(object: I): PathId {
    const message = createBasePathId();
    message.deleted = object.deleted ?? false;
    message.pathString = object.pathString ?? "";
    message.type = object.type ?? 0;
    message.fabric = object.fabric ?? "";
    message.device = object.device ?? "";
    message.fan = object.fan ?? 0;
    message.psu = object.psu ?? 0;
    message.tempSensor = object.tempSensor ?? 0;
    message.lineCard = object.lineCard ?? 0;
    message.port = object.port ?? 0;
    message.dacQsfp = object.dacQsfp ?? 0;
    message.fiberQsfp = object.fiberQsfp ?? 0;
    message.breakout = object.breakout ?? 0;
    message.portNeighbor = object.portNeighbor ?? "";
    message.vtep = object.vtep ?? 0;
    message.vniPort = object.vniPort ?? 0;
    message.mac = object.mac ?? 0;
    message.procTable = object.procTable ?? 0;
    message.portVlan = object.portVlan ?? "";
    message.vlanState = object.vlanState ?? 0;
    message.qsfpDomSensor = object.qsfpDomSensor ?? 0;
    message.portConfig = object.portConfig ?? 0;
    message.nodeConfig = object.nodeConfig ?? 0;
    message.fabricConfig = object.fabricConfig ?? "";
    message.fabricConfigEvent = object.fabricConfigEvent ?? 0;
    message.l2Fdb = object.l2Fdb ?? 0;
    message.portCounters = object.portCounters ?? 0;
    message.remedyAction = object.remedyAction ?? "";
    message.qsfpInfo = object.qsfpInfo ?? 0;
    message.fabricDevice = object.fabricDevice ?? "";
    message.assertConfigPortLinkUp = object.assertConfigPortLinkUp ?? 0;
    message.assertPortLinkUp = object.assertPortLinkUp ?? 0;
    message.assertConfigDeviceConnectedToCloud = object.assertConfigDeviceConnectedToCloud ?? 0;
    message.assertDeviceConnectedToCloud = object.assertDeviceConnectedToCloud ?? 0;
    message.assertConfigPortExpectedNeighbor = object.assertConfigPortExpectedNeighbor ?? 0;
    message.assertPortExpectedNeighbor = object.assertPortExpectedNeighbor ?? 0;
    message.assertConfigPortNotConnectedToFabric = object.assertConfigPortNotConnectedToFabric ?? 0;
    message.assertPortNotConnectedToFabric = object.assertPortNotConnectedToFabric ?? 0;
    message.assertConfigDeviceConnectedToFabric = object.assertConfigDeviceConnectedToFabric ?? 0;
    message.assertDeviceConnectedToFabric = object.assertDeviceConnectedToFabric ?? 0;
    message.assertConfigPortLinkDown = object.assertConfigPortLinkDown ?? 0;
    message.assertPortLinkDown = object.assertPortLinkDown ?? 0;
    message.assertConfigPortConnectionSpeedMatch = object.assertConfigPortConnectionSpeedMatch ?? 0;
    message.assertPortConnectionSpeedMatch = object.assertPortConnectionSpeedMatch ?? 0;
    message.assertConfigPortSpeedConsistent = object.assertConfigPortSpeedConsistent ?? 0;
    message.assertPortSpeedConsistent = object.assertPortSpeedConsistent ?? 0;
    message.underlayInfo = object.underlayInfo ?? 0;
    message.proxyStats = object.proxyStats ?? 0;
    message.vlanCounters = object.vlanCounters ?? 0;
    message.testPlaceHolder1Secret = object.testPlaceHolder1Secret ?? 0;
    message.assertConfigVlanHasTraffic = object.assertConfigVlanHasTraffic ?? 0;
    message.assertVlanHasTraffic = object.assertVlanHasTraffic ?? 0;
    message.remoteVtep = object.remoteVtep ?? 0;
    message.qsfpDomThreshold = object.qsfpDomThreshold ?? 0;
    message.sextantFabricAssignment = object.sextantFabricAssignment ?? "";
    message.l3Table = object.l3Table ?? "";
    message.vlanVniMap = object.vlanVniMap ?? 0;
    message.proxyConnectionState = object.proxyConnectionState ?? "";
    message.proxyConnectionStats = object.proxyConnectionStats ?? 0;
    message.l2Mac = object.l2Mac ?? "";
    message.bmc = object.bmc ?? 0;
    message.smartNicDpu = object.smartNicDpu ?? 0;
    message.serverVoltageSensor = object.serverVoltageSensor ?? 0;
    message.serverTemperatureSensor = object.serverTemperatureSensor ?? 0;
    message.ipRouteTable = object.ipRouteTable ?? "";
    message.vrf = object.vrf ?? "";
    message.ipNextHop = object.ipNextHop ?? 0;
    message.ipPrefix = object.ipPrefix ?? "";
    message.subInterface = object.subInterface ?? 0;
    message.assertConfigStaticDefaultRouteExists = object.assertConfigStaticDefaultRouteExists ?? 0;
    message.assertStaticDefaultRouteExists = object.assertStaticDefaultRouteExists ?? 0;
    message.spyglassBrokerEntry = object.spyglassBrokerEntry ?? "";
    message.managementState = object.managementState ?? "";
    message.assertConfigManagementPortConfigSame = object.assertConfigManagementPortConfigSame ?? 0;
    message.assertManagementPortConfigSame = object.assertManagementPortConfigSame ?? 0;
    message.fabricConfigNotification = object.fabricConfigNotification ?? 0;
    message.spyglassGingerEntry = object.spyglassGingerEntry ?? 0;
    message.plannedConfig = object.plannedConfig ?? 0;
    message.routedInterfaceCounters = object.routedInterfaceCounters ?? 0;
    message.ipmRawLatencyCounters = object.ipmRawLatencyCounters ?? 0;
    message.ipmProcLatencyCounters = object.ipmProcLatencyCounters ?? 0;
    message.ipmProcLossCounters = object.ipmProcLossCounters ?? 0;
    message.ipmLivenessCounters = object.ipmLivenessCounters ?? 0;
    message.ipmSessionId = object.ipmSessionId ?? "";
    message.systemStats = object.systemStats ?? 0;
    message.testRootOne = object.testRootOne ?? "";
    message.testRootTwo = object.testRootTwo ?? "";
    message.testMidOne = object.testMidOne ?? 0;
    message.testLeafOne = object.testLeafOne ?? "";
    message.testIpAddress = object.testIpAddress ?? "";
    message.testIpPrefix = object.testIpPrefix ?? "";
    message.testMacAddress = object.testMacAddress ?? "";
    message.assertConfigDeviceResourceUsageBelowThreshold = object.assertConfigDeviceResourceUsageBelowThreshold ?? 0;
    message.assertDeviceResourceUsageBelowThreshold = object.assertDeviceResourceUsageBelowThreshold ?? 0;
    message.fabricTransaction = object.fabricTransaction ?? 0;
    message.bgpNeighborSummary = object.bgpNeighborSummary ?? "";
    message.bmcConfig = object.bmcConfig ?? 0;
    message.deviceConnection = object.deviceConnection ?? 0;
    message.bgpNeighborFamily = object.bgpNeighborFamily ?? 0;
    message.assertConfigBgpPeerEstablished = object.assertConfigBgpPeerEstablished ?? 0;
    message.assertBgpPeerEstablished = object.assertBgpPeerEstablished ?? 0;
    message.spyglassSshSessionEntry = object.spyglassSshSessionEntry ?? 0;
    message.portChannelMemberState = object.portChannelMemberState ?? "";
    message.portChannelState = object.portChannelState ?? "";
    message.assertConfigIpmLatencyBelowThreshold = object.assertConfigIpmLatencyBelowThreshold ?? 0;
    message.assertIpmLatencyBelowThreshold = object.assertIpmLatencyBelowThreshold ?? 0;
    message.assertConfigIpmLossBelowThreshold = object.assertConfigIpmLossBelowThreshold ?? 0;
    message.assertIpmLossBelowThreshold = object.assertIpmLossBelowThreshold ?? 0;
    message.spyglassDevice = object.spyglassDevice ?? "";
    message.spyglassTcpListener = object.spyglassTcpListener ?? 0;
    message.spyglassTcpSession = object.spyglassTcpSession ?? 0;
    message.unknownVlan = object.unknownVlan ?? 0;
    message.unknownVlanPort = object.unknownVlanPort ?? "";
    message.assertConfigExpectedPortBreakoutExists = object.assertConfigExpectedPortBreakoutExists ?? 0;
    message.assertExpectedPortBreakoutExists = object.assertExpectedPortBreakoutExists ?? 0;
    message.assertConfigSubInterfaceUp = object.assertConfigSubInterfaceUp ?? 0;
    message.assertSubInterfaceUp = object.assertSubInterfaceUp ?? 0;
    message.assertSubInterfaceDown = object.assertSubInterfaceDown ?? 0;
    message.assertConfigSubInterfaceDown = object.assertConfigSubInterfaceDown ?? 0;
    message.deviceClaim = object.deviceClaim ?? 0;
    message.aggregatedDeviceClaim = object.aggregatedDeviceClaim ?? "";
    message.assertPortChannelMemberUp = object.assertPortChannelMemberUp ?? 0;
    message.assertConfigPortChannelMemberUp = object.assertConfigPortChannelMemberUp ?? 0;
    message.esiInfo = object.esiInfo ?? "";
    message.esiVtepInfo = object.esiVtepInfo ?? "";
    message.orgUuid = object.orgUuid ?? "";
    message.fabricUuid = object.fabricUuid ?? "";
    message.orgServiceProto = object.orgServiceProto ?? "";
    message.orgServiceSvc = object.orgServiceSvc ?? "";
    message.orgServiceRoute = object.orgServiceRoute ?? 0;
    message.apiPath = object.apiPath ?? "";
    message.apiPathMethod = object.apiPathMethod ?? "";
    message.apiServiceMapping = object.apiServiceMapping ?? 0;
    message.orgToCellPairMapping = object.orgToCellPairMapping ?? 0;
    message.tenantUuid = object.tenantUuid ?? "";
    message.userEmail = object.userEmail ?? "";
    message.managementConfig = object.managementConfig ?? 0;
    message.serviceInstanceHealth = object.serviceInstanceHealth ?? "";
    message.fabricAssignmentStatus = object.fabricAssignmentStatus ?? "";
    message.kubernetesServiceInstance = object.kubernetesServiceInstance ?? "";
    message.bearerToken = object.bearerToken ?? "";
    message.apiKey = object.apiKey ?? "";
    message.fabricMemoryUsage = object.fabricMemoryUsage ?? "";
    message.typeMemoryUsage = object.typeMemoryUsage ?? 0;
    message.manifestRunningImageStatus = object.manifestRunningImageStatus ?? 0;
    message.manifestPartitionFileCacheStatus = object.manifestPartitionFileCacheStatus ?? "";
    message.stpPortState = object.stpPortState ?? "";
    message.stpVlanState = object.stpVlanState ?? 0;
    message.stpVlanPortState = object.stpVlanPortState ?? "";
    message.assertDeviceEnvPsuUsageBelowThreshold = object.assertDeviceEnvPsuUsageBelowThreshold ?? 0;
    message.assertDeviceEnvFanUsageBelowThreshold = object.assertDeviceEnvFanUsageBelowThreshold ?? 0;
    message.assertDeviceEnvTempSensorUsageBelowThreshold = object.assertDeviceEnvTempSensorUsageBelowThreshold ?? 0;
    message.assertConfigDeviceEnvPsuUsageBelowThreshold = object.assertConfigDeviceEnvPsuUsageBelowThreshold ?? 0;
    message.assertConfigDeviceEnvFanUsageBelowThreshold = object.assertConfigDeviceEnvFanUsageBelowThreshold ?? 0;
    message.assertConfigDeviceEnvTempSensorUsageBelowThreshold =
      object.assertConfigDeviceEnvTempSensorUsageBelowThreshold ?? 0;
    message.unpackAndAssembleTask = object.unpackAndAssembleTask ?? 0;
    message.rackAndPowerOnTask = object.rackAndPowerOnTask ?? 0;
    message.claimAndBindTask = object.claimAndBindTask ?? 0;
    message.portSetupTask = object.portSetupTask ?? 0;
    message.fabricConnectionTask = object.fabricConnectionTask ?? 0;
    message.negativeConnectionTask = object.negativeConnectionTask ?? 0;
    message.application = object.application ?? 0;
    message.resourceAssignment = object.resourceAssignment ?? "";
    message.node = object.node ?? "";
    message.nodeLineCard = object.nodeLineCard ?? 0;
    message.nodePort = object.nodePort ?? 0;
    message.nodeBreakout = object.nodeBreakout ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
