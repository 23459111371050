import {
  InputProps as MagneticInputProps,
  Input as MagneticInput
} from "@magnetic/input";
import React, { InputHTMLAttributes } from "react";

interface InputProps extends MagneticInputProps {}

export type Ref = HTMLInputElement;

export const blockInvalidChar = (
  e: React.KeyboardEvent<HTMLInputElement>,
  chars: string[]
) => {
  return chars.includes(e.key) && e.preventDefault();
};

export const Input = React.forwardRef<Ref, InputProps>((props, ref) => {
  //@ts-ignore TODO figure out the cause of this error.input fields does something weird with props
  return <MagneticInput ref={ref} {...props} />;
});

export const InputPassword = React.forwardRef<Ref, InputProps>((props, ref) => {
  //@ts-ignore TODO figure out the cause of this error.input fields does something weird with props
  return <MagneticInput.Password ref={ref} {...props} />;
});

interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {}

export const CheckboxInput = React.forwardRef<Ref, CheckboxInputProps>(
  (props, ref) => {
    return <input ref={ref} {...props} />;
  }
);
