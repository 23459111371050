// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/fabrics.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { NodeRole, nodeRoleFromJSON, nodeRoleToJSON, OsType, osTypeFromJSON, osTypeToJSON } from "../models/fabric";

export const protobufPackage = "configd";

export interface GetDevicesRequest {
}

/** Metadata about a device. */
export interface Device {
  /** The device id. */
  deviceId: string;
  /** The node id to which the device is bound. */
  nodeId: string;
  /** The device serial number. */
  serialNumber: string;
  /** The device model name. */
  modelName: string;
  /** The fabric id that the device belong to. */
  fabricId: string;
  /** The rack id where the device is installed. */
  rackId: string;
  /** The OS type for the device. */
  osType: OsType;
  /** The roles supported by the device. */
  roles: NodeRole[];
  /** The name of the device. */
  name: string;
}

export interface GetDevicesResponse {
  devices: Device[];
}

export interface AssignUnknownDeviceRequest {
  deviceId: string;
  serialNum: string;
  modelName: string;
}

export interface AssignUnknownDeviceResponse {
  autoClaim: boolean;
}

function createBaseGetDevicesRequest(): GetDevicesRequest {
  return {};
}

export const GetDevicesRequest = {
  encode(_: GetDevicesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDevicesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDevicesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetDevicesRequest {
    return {};
  },

  toJSON(_: GetDevicesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDevicesRequest>, I>>(base?: I): GetDevicesRequest {
    return GetDevicesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDevicesRequest>, I>>(_: I): GetDevicesRequest {
    const message = createBaseGetDevicesRequest();
    return message;
  },
};

function createBaseDevice(): Device {
  return {
    deviceId: "",
    nodeId: "",
    serialNumber: "",
    modelName: "",
    fabricId: "",
    rackId: "",
    osType: 0,
    roles: [],
    name: "",
  };
}

export const Device = {
  encode(message: Device, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.serialNumber !== "") {
      writer.uint32(26).string(message.serialNumber);
    }
    if (message.modelName !== "") {
      writer.uint32(34).string(message.modelName);
    }
    if (message.fabricId !== "") {
      writer.uint32(42).string(message.fabricId);
    }
    if (message.rackId !== "") {
      writer.uint32(50).string(message.rackId);
    }
    if (message.osType !== 0) {
      writer.uint32(56).int32(message.osType);
    }
    writer.uint32(66).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.name !== "") {
      writer.uint32(74).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Device {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.rackId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.osType = reader.int32() as any;
          continue;
        case 8:
          if (tag === 64) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Device {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      rackId: isSet(object.rackId) ? globalThis.String(object.rackId) : "",
      osType: isSet(object.osType) ? osTypeFromJSON(object.osType) : 0,
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => nodeRoleFromJSON(e)) : [],
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: Device): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.rackId !== "") {
      obj.rackId = message.rackId;
    }
    if (message.osType !== 0) {
      obj.osType = osTypeToJSON(message.osType);
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => nodeRoleToJSON(e));
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Device>, I>>(base?: I): Device {
    return Device.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Device>, I>>(object: I): Device {
    const message = createBaseDevice();
    message.deviceId = object.deviceId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.modelName = object.modelName ?? "";
    message.fabricId = object.fabricId ?? "";
    message.rackId = object.rackId ?? "";
    message.osType = object.osType ?? 0;
    message.roles = object.roles?.map((e) => e) || [];
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseGetDevicesResponse(): GetDevicesResponse {
  return { devices: [] };
}

export const GetDevicesResponse = {
  encode(message: GetDevicesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.devices) {
      Device.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDevicesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDevicesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.devices.push(Device.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDevicesResponse {
    return {
      devices: globalThis.Array.isArray(object?.devices) ? object.devices.map((e: any) => Device.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetDevicesResponse): unknown {
    const obj: any = {};
    if (message.devices?.length) {
      obj.devices = message.devices.map((e) => Device.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDevicesResponse>, I>>(base?: I): GetDevicesResponse {
    return GetDevicesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDevicesResponse>, I>>(object: I): GetDevicesResponse {
    const message = createBaseGetDevicesResponse();
    message.devices = object.devices?.map((e) => Device.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAssignUnknownDeviceRequest(): AssignUnknownDeviceRequest {
  return { deviceId: "", serialNum: "", modelName: "" };
}

export const AssignUnknownDeviceRequest = {
  encode(message: AssignUnknownDeviceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.serialNum !== "") {
      writer.uint32(18).string(message.serialNum);
    }
    if (message.modelName !== "") {
      writer.uint32(26).string(message.modelName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignUnknownDeviceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignUnknownDeviceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serialNum = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modelName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssignUnknownDeviceRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      serialNum: isSet(object.serialNum) ? globalThis.String(object.serialNum) : "",
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
    };
  },

  toJSON(message: AssignUnknownDeviceRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.serialNum !== "") {
      obj.serialNum = message.serialNum;
    }
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssignUnknownDeviceRequest>, I>>(base?: I): AssignUnknownDeviceRequest {
    return AssignUnknownDeviceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssignUnknownDeviceRequest>, I>>(object: I): AssignUnknownDeviceRequest {
    const message = createBaseAssignUnknownDeviceRequest();
    message.deviceId = object.deviceId ?? "";
    message.serialNum = object.serialNum ?? "";
    message.modelName = object.modelName ?? "";
    return message;
  },
};

function createBaseAssignUnknownDeviceResponse(): AssignUnknownDeviceResponse {
  return { autoClaim: false };
}

export const AssignUnknownDeviceResponse = {
  encode(message: AssignUnknownDeviceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.autoClaim !== false) {
      writer.uint32(8).bool(message.autoClaim);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignUnknownDeviceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignUnknownDeviceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.autoClaim = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssignUnknownDeviceResponse {
    return { autoClaim: isSet(object.autoClaim) ? globalThis.Boolean(object.autoClaim) : false };
  },

  toJSON(message: AssignUnknownDeviceResponse): unknown {
    const obj: any = {};
    if (message.autoClaim !== false) {
      obj.autoClaim = message.autoClaim;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssignUnknownDeviceResponse>, I>>(base?: I): AssignUnknownDeviceResponse {
    return AssignUnknownDeviceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssignUnknownDeviceResponse>, I>>(object: I): AssignUnknownDeviceResponse {
    const message = createBaseAssignUnknownDeviceResponse();
    message.autoClaim = object.autoClaim ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
