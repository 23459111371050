/**
 * Represents a JSON primitive value.
 * Can be a string, number, boolean, or null.
 */
type JsonPrimitive = string | number | boolean | null;

/**
 * Represents a JSON array.
 * An array of JSON values.
 */
type JsonArray = Json[];

/**
 * Represents a JSON object.
 * An object with string keys and JSON values.
 */
type JsonObject = { [key: string]: Json };

/**
 * Represents a composite JSON value.
 * Can be either a JSON array or a JSON object.
 */
type JsonComposite = JsonArray | JsonObject;

/**
 * Represents a JSON value.
 * Can be a primitive, array, or object.
 */
export type Json = JsonPrimitive | JsonComposite;

/**
 * TODO: Class Parser vs. Curried function getParser
 * Need to decide if this is better as
 * a class or if it should just be a
 * closure that returns the parser
 * function.
 *
 * The Parser will parses response from a
 * fetch request and transforms the JSON
 * data into a protobuf class. This is
 * just abstracting out a lot of redundant
 * boilerplate code.
 *
 * @template TResponse - The type of the transformed response.
 * @param {(Json) => TResponse} parser - A function to transform the JSON data.
 * @returns {(Response) => Promise<TResponse>} A function that takes a Response object and returns a Promise of the transformed response.
 */
export class Parser<TResponse> {
  constructor(private parser: (json: Json) => TResponse) {}
  parse(resp: Response): Promise<TResponse> {
    /**
     * make sure we are not getting
     * back an error of some kind.
     * this might cause an exception
     * if we try to use resp.json().then
     */
    if (resp.ok) {
      /**
       * If the response is OK, parse the JSON data.
       *
       * @param {Json} json - The JSON data from the response.
       * @returns A promise that resolves to the transformed response.
       */
      return resp.json().then(
        (json) => {
          const output: TResponse = this.parser(json);
          return Promise.resolve(output);
        },
        (e) => {
          return Promise.reject(e);
        }
      );
    } else {
      /**
       * If the response is not OK,
       * get the error text.
       *
       * @param {string} err - The error text from the response.
       * @returns A promise that rejects with the error text.
       */
      return resp.text().then((err) => {
        return Promise.reject(err);
      });
    }
  }
}

export const getParser = <TResponse>(
  parser: (json: Json) => TResponse
): ((resp: Response) => Promise<TResponse>) => {
  return (resp: Response): Promise<TResponse> => {
    /**
     * make sure we are not getting
     * back an error of some kind.
     * this might cause an exception
     * if we try to use resp.json().then
     */
    if (resp.ok) {
      /**
       * If the response is OK, parse the JSON data.
       *
       * @param {Json} json - The JSON data from the response.
       * @returns A promise that resolves to the transformed response.
       */
      return resp.json().then(
        (json) => {
          const output: TResponse = parser(json);
          return Promise.resolve(output);
        },
        (e) => {
          return Promise.reject(e);
        }
      );
    } else {
      /**
       * If the response is not OK,
       * get the error text.
       *
       * @param {string} err - The error text from the response.
       * @returns {Promise<never>} A promise that rejects with the error text.
       */
      return resp.text().then((err) => {
        return Promise.reject(err);
      });
    }
  };
};
