import { Text } from "@magnetic/text";
import "./globalFooter.scss";

export const GlobalFooter = (props: {
  readonly showCopyright?: boolean;
  readonly textSize?: string;
}) => {
  const { showCopyright = true, textSize = "p4" } = props;
  return (
    <footer>
      {showCopyright && (
        <cite>© {new Date().getFullYear()} Cisco Systems, Inc.</cite>
      )}
      <div>
        <Text size={textSize}>
          <a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html">
            Privacy policy
          </a>
        </Text>
        <Text size={textSize}>
          <a href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html">
            Terms of service
          </a>
        </Text>
      </div>
    </footer>
  );
};
