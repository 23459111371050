// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: sextant/service.proto

/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { EchoRequest, EchoResponse } from "../common/echo";
import { Empty } from "../common/empty";
import {
  AssertCounterReportResponse,
  AssertHistoryApiRecordResponse,
  AssertRestCounterReportRequest,
  AssertRestRecordRequest,
  HistoryRequest,
  HistoryResponse,
} from "../reader/reader_api";
import { AssertionsResponse, GetDeviceAssertionsRequest, GetDevicePortAssertionsRequest } from "./rest_assertions";
import { CountersResponse, GetDeviceCountersRequest, GetDevicePortCountersRequest } from "./rest_counters";
import { DeviceManagementPortsResponse, GetDeviceManagementPortsRequest } from "./rest_devices";
import {
  CreateRefPointRequest,
  FileStreamResponse,
  GeneralRefPointRequest,
  RefPoint,
  RefPointList,
  SpecificRefPointRequest,
} from "./rest_reference_points";
import { GetDeviceSensorsRequest, SensorsResponse } from "./rest_sensors";

export const protobufPackage = "sextant";

export interface Sextant {
  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse>;
  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse>;
  /** Get history data on port statistics. */
  DruidTelemetryHistoryReader(request: DeepPartial<HistoryRequest>, metadata?: grpc.Metadata): Promise<HistoryResponse>;
  /** Return the management port configuration reported by a device. */
  GetDeviceManagementPorts(
    request: DeepPartial<GetDeviceManagementPortsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeviceManagementPortsResponse>;
  /** Get the list of assertions of a specific device. */
  GetDeviceAssertions(
    request: DeepPartial<GetDeviceAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse>;
  /** Get the list of assertions for a specific port of a device. */
  GetDevicePortAssertions(
    request: DeepPartial<GetDevicePortAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse>;
  /** Get the list of counters information of a specific device. */
  GetDeviceCounters(
    request: DeepPartial<GetDeviceCountersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CountersResponse>;
  /** Get the list of port counters information on a specific port of a device. */
  GetDevicePortCounters(
    request: DeepPartial<GetDevicePortCountersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CountersResponse>;
  /** Get the list of sensor information of a specific device. */
  GetDeviceSensors(request: DeepPartial<GetDeviceSensorsRequest>, metadata?: grpc.Metadata): Promise<SensorsResponse>;
  /** Query chassis assertion history messages, internal low-level API for UI */
  QueryAssertionHistoryMessage(
    request: DeepPartial<HistoryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<HistoryResponse>;
  /** Get the list of assertion counters and type history */
  GetAssertionHistoryCounterReport(
    request: DeepPartial<AssertRestCounterReportRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertCounterReportResponse>;
  /** Get the list of assertion history records */
  GetAssertionHistoryRecord(
    request: DeepPartial<AssertRestRecordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertHistoryApiRecordResponse>;
  /**
   * Get list of all reference point metadata for a fabric
   *
   * Returns all the created reference points for a fabric.
   * This may be an empty list, or a list contianing either one or both of
   * the 'reference' or 'provisional' reference points.
   */
  GetAllRefPoints(request: DeepPartial<GeneralRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPointList>;
  /**
   * Get metadata for the named reference point
   *
   * Returns the metadata for the requested reference point.
   * The name must be either 'provisional' or 'reference'.
   *
   * Returns 'not found' error status if the requested reference point does not exist.
   */
  GetRefPoint(request: DeepPartial<SpecificRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPoint>;
  /**
   * Delete a reference point
   *
   * Deletes a reference point and any associated data, such as diffs.
   */
  DeleteRefPoint(request: DeepPartial<SpecificRefPointRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  /**
   * Create a 'provisional' reference point
   *
   * Create a reference point called 'provisional'.
   * This will immediately create and return the metadata, whilst queueing an
   * asynchronous job to collect and record the state of the fabric.
   *
   * The progress of the job can be tracked via the reference point metadata.
   *
   * Once created, a 'provisional' reference point can either be promoted to 'reference'
   * or compared with an existing 'reference'.
   */
  CreateProvisionalRefPoint(request: DeepPartial<CreateRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPoint>;
  /**
   * Promote a 'provisional' reference point to 'reference'
   *
   * Convert the reference point named 'provisional' to 'reference' and return the updated
   * reference point metadata.
   *
   * This allows a new 'provisional' reference point to later be created and compared to 'reference'.
   *
   * Returns 'conflict' error status if there is already a 'reference' reference point for the fabric.
   */
  PromoteProvisionalRefPoint(request: DeepPartial<GeneralRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPoint>;
  /**
   * Create a diff between 'reference' and 'provisional' reference points
   *
   * Queue and asynchrnous job to compare the current 'provisional' and 'reference'
   * reference point records.
   *
   * The progress of this job can be tracked via the 'reference' reference point metadata.
   *
   * The 'reference' reference point metadata will be returned.
   */
  CreateRefPointDiff(request: DeepPartial<GeneralRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPoint>;
  /**
   * Get the diff data file
   *
   * Stream a diff data file that was previously created.
   *
   * This binary file will contain lists of objects that were added, removed and changed
   * in the time between the creation of the 'reference' and 'provisional' reference point.
   */
  GetRefPointDiff(request: DeepPartial<GeneralRefPointRequest>, metadata?: grpc.Metadata): Promise<FileStreamResponse>;
}

export class SextantClientImpl implements Sextant {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Echo = this.Echo.bind(this);
    this.StreamEcho = this.StreamEcho.bind(this);
    this.DruidTelemetryHistoryReader = this.DruidTelemetryHistoryReader.bind(this);
    this.GetDeviceManagementPorts = this.GetDeviceManagementPorts.bind(this);
    this.GetDeviceAssertions = this.GetDeviceAssertions.bind(this);
    this.GetDevicePortAssertions = this.GetDevicePortAssertions.bind(this);
    this.GetDeviceCounters = this.GetDeviceCounters.bind(this);
    this.GetDevicePortCounters = this.GetDevicePortCounters.bind(this);
    this.GetDeviceSensors = this.GetDeviceSensors.bind(this);
    this.QueryAssertionHistoryMessage = this.QueryAssertionHistoryMessage.bind(this);
    this.GetAssertionHistoryCounterReport = this.GetAssertionHistoryCounterReport.bind(this);
    this.GetAssertionHistoryRecord = this.GetAssertionHistoryRecord.bind(this);
    this.GetAllRefPoints = this.GetAllRefPoints.bind(this);
    this.GetRefPoint = this.GetRefPoint.bind(this);
    this.DeleteRefPoint = this.DeleteRefPoint.bind(this);
    this.CreateProvisionalRefPoint = this.CreateProvisionalRefPoint.bind(this);
    this.PromoteProvisionalRefPoint = this.PromoteProvisionalRefPoint.bind(this);
    this.CreateRefPointDiff = this.CreateRefPointDiff.bind(this);
    this.GetRefPointDiff = this.GetRefPointDiff.bind(this);
  }

  Echo(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Promise<EchoResponse> {
    return this.rpc.unary(SextantEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  StreamEcho(request: DeepPartial<EchoRequest>, metadata?: grpc.Metadata): Observable<EchoResponse> {
    return this.rpc.invoke(SextantStreamEchoDesc, EchoRequest.fromPartial(request), metadata);
  }

  DruidTelemetryHistoryReader(
    request: DeepPartial<HistoryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<HistoryResponse> {
    return this.rpc.unary(SextantDruidTelemetryHistoryReaderDesc, HistoryRequest.fromPartial(request), metadata);
  }

  GetDeviceManagementPorts(
    request: DeepPartial<GetDeviceManagementPortsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeviceManagementPortsResponse> {
    return this.rpc.unary(
      SextantGetDeviceManagementPortsDesc,
      GetDeviceManagementPortsRequest.fromPartial(request),
      metadata,
    );
  }

  GetDeviceAssertions(
    request: DeepPartial<GetDeviceAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse> {
    return this.rpc.unary(SextantGetDeviceAssertionsDesc, GetDeviceAssertionsRequest.fromPartial(request), metadata);
  }

  GetDevicePortAssertions(
    request: DeepPartial<GetDevicePortAssertionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertionsResponse> {
    return this.rpc.unary(
      SextantGetDevicePortAssertionsDesc,
      GetDevicePortAssertionsRequest.fromPartial(request),
      metadata,
    );
  }

  GetDeviceCounters(
    request: DeepPartial<GetDeviceCountersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CountersResponse> {
    return this.rpc.unary(SextantGetDeviceCountersDesc, GetDeviceCountersRequest.fromPartial(request), metadata);
  }

  GetDevicePortCounters(
    request: DeepPartial<GetDevicePortCountersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CountersResponse> {
    return this.rpc.unary(
      SextantGetDevicePortCountersDesc,
      GetDevicePortCountersRequest.fromPartial(request),
      metadata,
    );
  }

  GetDeviceSensors(request: DeepPartial<GetDeviceSensorsRequest>, metadata?: grpc.Metadata): Promise<SensorsResponse> {
    return this.rpc.unary(SextantGetDeviceSensorsDesc, GetDeviceSensorsRequest.fromPartial(request), metadata);
  }

  QueryAssertionHistoryMessage(
    request: DeepPartial<HistoryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<HistoryResponse> {
    return this.rpc.unary(SextantQueryAssertionHistoryMessageDesc, HistoryRequest.fromPartial(request), metadata);
  }

  GetAssertionHistoryCounterReport(
    request: DeepPartial<AssertRestCounterReportRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertCounterReportResponse> {
    return this.rpc.unary(
      SextantGetAssertionHistoryCounterReportDesc,
      AssertRestCounterReportRequest.fromPartial(request),
      metadata,
    );
  }

  GetAssertionHistoryRecord(
    request: DeepPartial<AssertRestRecordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AssertHistoryApiRecordResponse> {
    return this.rpc.unary(SextantGetAssertionHistoryRecordDesc, AssertRestRecordRequest.fromPartial(request), metadata);
  }

  GetAllRefPoints(request: DeepPartial<GeneralRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPointList> {
    return this.rpc.unary(SextantGetAllRefPointsDesc, GeneralRefPointRequest.fromPartial(request), metadata);
  }

  GetRefPoint(request: DeepPartial<SpecificRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPoint> {
    return this.rpc.unary(SextantGetRefPointDesc, SpecificRefPointRequest.fromPartial(request), metadata);
  }

  DeleteRefPoint(request: DeepPartial<SpecificRefPointRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(SextantDeleteRefPointDesc, SpecificRefPointRequest.fromPartial(request), metadata);
  }

  CreateProvisionalRefPoint(request: DeepPartial<CreateRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPoint> {
    return this.rpc.unary(SextantCreateProvisionalRefPointDesc, CreateRefPointRequest.fromPartial(request), metadata);
  }

  PromoteProvisionalRefPoint(
    request: DeepPartial<GeneralRefPointRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RefPoint> {
    return this.rpc.unary(SextantPromoteProvisionalRefPointDesc, GeneralRefPointRequest.fromPartial(request), metadata);
  }

  CreateRefPointDiff(request: DeepPartial<GeneralRefPointRequest>, metadata?: grpc.Metadata): Promise<RefPoint> {
    return this.rpc.unary(SextantCreateRefPointDiffDesc, GeneralRefPointRequest.fromPartial(request), metadata);
  }

  GetRefPointDiff(request: DeepPartial<GeneralRefPointRequest>, metadata?: grpc.Metadata): Promise<FileStreamResponse> {
    return this.rpc.unary(SextantGetRefPointDiffDesc, GeneralRefPointRequest.fromPartial(request), metadata);
  }
}

export const SextantDesc = { serviceName: "sextant.Sextant" };

export const SextantEchoDesc: UnaryMethodDefinitionish = {
  methodName: "Echo",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantStreamEchoDesc: UnaryMethodDefinitionish = {
  methodName: "StreamEcho",
  service: SextantDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return EchoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = EchoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantDruidTelemetryHistoryReaderDesc: UnaryMethodDefinitionish = {
  methodName: "DruidTelemetryHistoryReader",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return HistoryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HistoryResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDeviceManagementPortsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDeviceManagementPorts",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDeviceManagementPortsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeviceManagementPortsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDeviceAssertionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDeviceAssertions",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDeviceAssertionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDevicePortAssertionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDevicePortAssertions",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDevicePortAssertionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDeviceCountersDesc: UnaryMethodDefinitionish = {
  methodName: "GetDeviceCounters",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDeviceCountersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CountersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDevicePortCountersDesc: UnaryMethodDefinitionish = {
  methodName: "GetDevicePortCounters",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDevicePortCountersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CountersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetDeviceSensorsDesc: UnaryMethodDefinitionish = {
  methodName: "GetDeviceSensors",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDeviceSensorsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SensorsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantQueryAssertionHistoryMessageDesc: UnaryMethodDefinitionish = {
  methodName: "QueryAssertionHistoryMessage",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return HistoryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HistoryResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetAssertionHistoryCounterReportDesc: UnaryMethodDefinitionish = {
  methodName: "GetAssertionHistoryCounterReport",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AssertRestCounterReportRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertCounterReportResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetAssertionHistoryRecordDesc: UnaryMethodDefinitionish = {
  methodName: "GetAssertionHistoryRecord",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AssertRestRecordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AssertHistoryApiRecordResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetAllRefPointsDesc: UnaryMethodDefinitionish = {
  methodName: "GetAllRefPoints",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GeneralRefPointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPointList.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetRefPointDesc: UnaryMethodDefinitionish = {
  methodName: "GetRefPoint",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SpecificRefPointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantDeleteRefPointDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteRefPoint",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SpecificRefPointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantCreateProvisionalRefPointDesc: UnaryMethodDefinitionish = {
  methodName: "CreateProvisionalRefPoint",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateRefPointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantPromoteProvisionalRefPointDesc: UnaryMethodDefinitionish = {
  methodName: "PromoteProvisionalRefPoint",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GeneralRefPointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantCreateRefPointDiffDesc: UnaryMethodDefinitionish = {
  methodName: "CreateRefPointDiff",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GeneralRefPointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RefPoint.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SextantGetRefPointDiffDesc: UnaryMethodDefinitionish = {
  methodName: "GetRefPointDiff",
  service: SextantDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GeneralRefPointRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FileStreamResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes ?? [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const transport = this.options.streamingTransport ?? this.options.transport;
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          ...(transport !== undefined ? { transport } : {}),
          metadata: maybeCombinedMetadata ?? {},
          debug: this.options.debug ?? false,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
