// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: brig/btest.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import {
  BoolValue,
  DoubleValue,
  FloatValue,
  Int32Value,
  Int64Value,
  StringValue,
  UInt32Value,
  UInt64Value,
} from "../google/protobuf/wrappers";

export const protobufPackage = "brig";

export enum ParamState {
  PARAM_STATE_UNSPECIFIED = 0,
  PARAM_STATE_ONE = 1,
  PARAM_STATE_TWO = 2,
  PARAM_STATE_THREE = 3,
  UNRECOGNIZED = -1,
}

export function paramStateFromJSON(object: any): ParamState {
  switch (object) {
    case 0:
    case "PARAM_STATE_UNSPECIFIED":
      return ParamState.PARAM_STATE_UNSPECIFIED;
    case 1:
    case "PARAM_STATE_ONE":
      return ParamState.PARAM_STATE_ONE;
    case 2:
    case "PARAM_STATE_TWO":
      return ParamState.PARAM_STATE_TWO;
    case 3:
    case "PARAM_STATE_THREE":
      return ParamState.PARAM_STATE_THREE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ParamState.UNRECOGNIZED;
  }
}

export function paramStateToJSON(object: ParamState): string {
  switch (object) {
    case ParamState.PARAM_STATE_UNSPECIFIED:
      return "PARAM_STATE_UNSPECIFIED";
    case ParamState.PARAM_STATE_ONE:
      return "PARAM_STATE_ONE";
    case ParamState.PARAM_STATE_TWO:
      return "PARAM_STATE_TWO";
    case ParamState.PARAM_STATE_THREE:
      return "PARAM_STATE_THREE";
    case ParamState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum WrapperTypes {
  WRAPPER_TYPES_UNSPECIFIED = 0,
  SET = 1,
  ZERO = 2,
  UNRECOGNIZED = -1,
}

export function wrapperTypesFromJSON(object: any): WrapperTypes {
  switch (object) {
    case 0:
    case "WRAPPER_TYPES_UNSPECIFIED":
      return WrapperTypes.WRAPPER_TYPES_UNSPECIFIED;
    case 1:
    case "SET":
      return WrapperTypes.SET;
    case 2:
    case "ZERO":
      return WrapperTypes.ZERO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WrapperTypes.UNRECOGNIZED;
  }
}

export function wrapperTypesToJSON(object: WrapperTypes): string {
  switch (object) {
    case WrapperTypes.WRAPPER_TYPES_UNSPECIFIED:
      return "WRAPPER_TYPES_UNSPECIFIED";
    case WrapperTypes.SET:
      return "SET";
    case WrapperTypes.ZERO:
      return "ZERO";
    case WrapperTypes.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface EmbeddedParams {
  ei32: number;
  estr: string[];
  estate: ParamState;
}

/** ParamsRequest lists all possible parameter Protobuf types for query parameters. */
export interface ParamsRequest {
  /** Path parameters. */
  pathParamStr: string;
  pathParamI32: number;
  /** Query parameters, non-repeated fields, str=foo&i32=34 */
  str: string;
  state: ParamState;
  newState: ParamState;
  i32: number;
  i64: number;
  ui32: number;
  ui64: number;
  f32: number;
  f64: number;
  enabled: boolean;
  startTime: string;
  endTime: string;
  /** Query parameters, repeated fields, rStr=foo&rStr=bar&rI32=34&rI32=99 */
  rStr: string[];
  rState: ParamState[];
  rI32: number[];
  rI64: number[];
  rUi32: number[];
  rUi64: number[];
  rF32: number[];
  rF64: number[];
  rEnabled: boolean[];
  rStartTime: string[];
  rEndTime: string[];
  /** Query parameters for embedded message type. Not ideal. */
  embedded:
    | EmbeddedParams
    | undefined;
  /** Timestamp values. */
  startTimeTs: Date | undefined;
  endTimeTs:
    | Date
    | undefined;
  /** Test various states of the wrapper types, on, zero'd or just not set. */
  wrappers: WrapperTypes;
}

/** ParamsResponse is the same as ParamsRequest. */
export interface ParamsResponse {
  /** Path parameters. */
  pathParamStr: string;
  pathParamI32: number;
  /** Non-repeated fields. */
  str: string;
  state: ParamState;
  newState: ParamState;
  i32: number;
  i64: number;
  ui32: number;
  ui64: number;
  f32: number;
  f64: number;
  enabled: boolean;
  startTime: string;
  endTime: string;
  /** Repeated fields. */
  rStr: string[];
  rState: ParamState[];
  rI32: number[];
  rI64: number[];
  rUi32: number[];
  rUi64: number[];
  rF32: number[];
  rF64: number[];
  rEnabled: boolean[];
  rStartTime: string[];
  rEndTime: string[];
  /** Query parameters for embedded message type. */
  embedded:
    | EmbeddedParams
    | undefined;
  /** Timestamp values. */
  startTimeTs: Date | undefined;
  endTimeTs:
    | Date
    | undefined;
  /** Wrapper types. */
  wBool: boolean | undefined;
  wInt32: number | undefined;
  wUint32: number | undefined;
  wInt64: number | undefined;
  wUint64: number | undefined;
  wDouble: number | undefined;
  wFloat: number | undefined;
  wStr: string | undefined;
}

/** HttpRequest is used to test http handlers which directly write to the response writer. */
export interface HttpRequest {
  /** The total number of messages to create and send. */
  numMessages: number;
  /** Pause sending this number of messages and sleep. Default is 100 messages. */
  pauseAfter: number;
  /** Sleep for a number of seconds between blocks of messages. Default is 1 second. */
  sleepFor: number;
}

export interface HttpMessage {
  email: string;
  streetNumber: number;
  city: string;
  state: string;
  zip: number;
}

export interface HttpResponse {
  name: string;
  description: string;
  messages: HttpMessage[];
  totalMessages: number;
}

function createBaseEmbeddedParams(): EmbeddedParams {
  return { ei32: 0, estr: [], estate: 0 };
}

export const EmbeddedParams = {
  encode(message: EmbeddedParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ei32 !== 0) {
      writer.uint32(8).int32(message.ei32);
    }
    for (const v of message.estr) {
      writer.uint32(18).string(v!);
    }
    if (message.estate !== 0) {
      writer.uint32(24).int32(message.estate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EmbeddedParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmbeddedParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ei32 = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.estr.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.estate = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EmbeddedParams {
    return {
      ei32: isSet(object.ei32) ? globalThis.Number(object.ei32) : 0,
      estr: globalThis.Array.isArray(object?.estr) ? object.estr.map((e: any) => globalThis.String(e)) : [],
      estate: isSet(object.estate) ? paramStateFromJSON(object.estate) : 0,
    };
  },

  toJSON(message: EmbeddedParams): unknown {
    const obj: any = {};
    if (message.ei32 !== 0) {
      obj.ei32 = Math.round(message.ei32);
    }
    if (message.estr?.length) {
      obj.estr = message.estr;
    }
    if (message.estate !== 0) {
      obj.estate = paramStateToJSON(message.estate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmbeddedParams>, I>>(base?: I): EmbeddedParams {
    return EmbeddedParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmbeddedParams>, I>>(object: I): EmbeddedParams {
    const message = createBaseEmbeddedParams();
    message.ei32 = object.ei32 ?? 0;
    message.estr = object.estr?.map((e) => e) || [];
    message.estate = object.estate ?? 0;
    return message;
  },
};

function createBaseParamsRequest(): ParamsRequest {
  return {
    pathParamStr: "",
    pathParamI32: 0,
    str: "",
    state: 0,
    newState: 0,
    i32: 0,
    i64: 0,
    ui32: 0,
    ui64: 0,
    f32: 0,
    f64: 0,
    enabled: false,
    startTime: "",
    endTime: "",
    rStr: [],
    rState: [],
    rI32: [],
    rI64: [],
    rUi32: [],
    rUi64: [],
    rF32: [],
    rF64: [],
    rEnabled: [],
    rStartTime: [],
    rEndTime: [],
    embedded: undefined,
    startTimeTs: undefined,
    endTimeTs: undefined,
    wrappers: 0,
  };
}

export const ParamsRequest = {
  encode(message: ParamsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pathParamStr !== "") {
      writer.uint32(10).string(message.pathParamStr);
    }
    if (message.pathParamI32 !== 0) {
      writer.uint32(16).int32(message.pathParamI32);
    }
    if (message.str !== "") {
      writer.uint32(26).string(message.str);
    }
    if (message.state !== 0) {
      writer.uint32(32).int32(message.state);
    }
    if (message.newState !== 0) {
      writer.uint32(256).int32(message.newState);
    }
    if (message.i32 !== 0) {
      writer.uint32(40).int32(message.i32);
    }
    if (message.i64 !== 0) {
      writer.uint32(48).int64(message.i64);
    }
    if (message.ui32 !== 0) {
      writer.uint32(56).uint32(message.ui32);
    }
    if (message.ui64 !== 0) {
      writer.uint32(64).uint64(message.ui64);
    }
    if (message.f32 !== 0) {
      writer.uint32(77).float(message.f32);
    }
    if (message.f64 !== 0) {
      writer.uint32(81).double(message.f64);
    }
    if (message.enabled !== false) {
      writer.uint32(88).bool(message.enabled);
    }
    if (message.startTime !== "") {
      writer.uint32(98).string(message.startTime);
    }
    if (message.endTime !== "") {
      writer.uint32(106).string(message.endTime);
    }
    for (const v of message.rStr) {
      writer.uint32(114).string(v!);
    }
    writer.uint32(122).fork();
    for (const v of message.rState) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(130).fork();
    for (const v of message.rI32) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(138).fork();
    for (const v of message.rI64) {
      writer.int64(v);
    }
    writer.ldelim();
    writer.uint32(146).fork();
    for (const v of message.rUi32) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(154).fork();
    for (const v of message.rUi64) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(162).fork();
    for (const v of message.rF32) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(170).fork();
    for (const v of message.rF64) {
      writer.double(v);
    }
    writer.ldelim();
    writer.uint32(178).fork();
    for (const v of message.rEnabled) {
      writer.bool(v);
    }
    writer.ldelim();
    for (const v of message.rStartTime) {
      writer.uint32(186).string(v!);
    }
    for (const v of message.rEndTime) {
      writer.uint32(194).string(v!);
    }
    if (message.embedded !== undefined) {
      EmbeddedParams.encode(message.embedded, writer.uint32(202).fork()).ldelim();
    }
    if (message.startTimeTs !== undefined) {
      Timestamp.encode(toTimestamp(message.startTimeTs), writer.uint32(242).fork()).ldelim();
    }
    if (message.endTimeTs !== undefined) {
      Timestamp.encode(toTimestamp(message.endTimeTs), writer.uint32(250).fork()).ldelim();
    }
    if (message.wrappers !== 0) {
      writer.uint32(320).int32(message.wrappers);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ParamsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParamsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pathParamStr = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pathParamI32 = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.str = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.newState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.i32 = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.i64 = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.ui32 = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.ui64 = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.f32 = reader.float();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.f64 = reader.double();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.startTime = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.endTime = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.rStr.push(reader.string());
          continue;
        case 15:
          if (tag === 120) {
            message.rState.push(reader.int32() as any);

            continue;
          }

          if (tag === 122) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rState.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 16:
          if (tag === 128) {
            message.rI32.push(reader.int32());

            continue;
          }

          if (tag === 130) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rI32.push(reader.int32());
            }

            continue;
          }

          break;
        case 17:
          if (tag === 136) {
            message.rI64.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 138) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rI64.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 18:
          if (tag === 144) {
            message.rUi32.push(reader.uint32());

            continue;
          }

          if (tag === 146) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rUi32.push(reader.uint32());
            }

            continue;
          }

          break;
        case 19:
          if (tag === 152) {
            message.rUi64.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 154) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rUi64.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 20:
          if (tag === 165) {
            message.rF32.push(reader.float());

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rF32.push(reader.float());
            }

            continue;
          }

          break;
        case 21:
          if (tag === 169) {
            message.rF64.push(reader.double());

            continue;
          }

          if (tag === 170) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rF64.push(reader.double());
            }

            continue;
          }

          break;
        case 22:
          if (tag === 176) {
            message.rEnabled.push(reader.bool());

            continue;
          }

          if (tag === 178) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rEnabled.push(reader.bool());
            }

            continue;
          }

          break;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.rStartTime.push(reader.string());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.rEndTime.push(reader.string());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.embedded = EmbeddedParams.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.startTimeTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.endTimeTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 40:
          if (tag !== 320) {
            break;
          }

          message.wrappers = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ParamsRequest {
    return {
      pathParamStr: isSet(object.pathParamStr) ? globalThis.String(object.pathParamStr) : "",
      pathParamI32: isSet(object.pathParamI32) ? globalThis.Number(object.pathParamI32) : 0,
      str: isSet(object.str) ? globalThis.String(object.str) : "",
      state: isSet(object.state) ? paramStateFromJSON(object.state) : 0,
      newState: isSet(object.newState) ? paramStateFromJSON(object.newState) : 0,
      i32: isSet(object.i32) ? globalThis.Number(object.i32) : 0,
      i64: isSet(object.i64) ? globalThis.Number(object.i64) : 0,
      ui32: isSet(object.ui32) ? globalThis.Number(object.ui32) : 0,
      ui64: isSet(object.ui64) ? globalThis.Number(object.ui64) : 0,
      f32: isSet(object.f32) ? globalThis.Number(object.f32) : 0,
      f64: isSet(object.f64) ? globalThis.Number(object.f64) : 0,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : "",
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : "",
      rStr: globalThis.Array.isArray(object?.rStr) ? object.rStr.map((e: any) => globalThis.String(e)) : [],
      rState: globalThis.Array.isArray(object?.rState) ? object.rState.map((e: any) => paramStateFromJSON(e)) : [],
      rI32: globalThis.Array.isArray(object?.rI32) ? object.rI32.map((e: any) => globalThis.Number(e)) : [],
      rI64: globalThis.Array.isArray(object?.rI64) ? object.rI64.map((e: any) => globalThis.Number(e)) : [],
      rUi32: globalThis.Array.isArray(object?.rUi32) ? object.rUi32.map((e: any) => globalThis.Number(e)) : [],
      rUi64: globalThis.Array.isArray(object?.rUi64) ? object.rUi64.map((e: any) => globalThis.Number(e)) : [],
      rF32: globalThis.Array.isArray(object?.rF32) ? object.rF32.map((e: any) => globalThis.Number(e)) : [],
      rF64: globalThis.Array.isArray(object?.rF64) ? object.rF64.map((e: any) => globalThis.Number(e)) : [],
      rEnabled: globalThis.Array.isArray(object?.rEnabled)
        ? object.rEnabled.map((e: any) => globalThis.Boolean(e))
        : [],
      rStartTime: globalThis.Array.isArray(object?.rStartTime)
        ? object.rStartTime.map((e: any) => globalThis.String(e))
        : [],
      rEndTime: globalThis.Array.isArray(object?.rEndTime) ? object.rEndTime.map((e: any) => globalThis.String(e)) : [],
      embedded: isSet(object.embedded) ? EmbeddedParams.fromJSON(object.embedded) : undefined,
      startTimeTs: isSet(object.startTimeTs) ? fromJsonTimestamp(object.startTimeTs) : undefined,
      endTimeTs: isSet(object.endTimeTs) ? fromJsonTimestamp(object.endTimeTs) : undefined,
      wrappers: isSet(object.wrappers) ? wrapperTypesFromJSON(object.wrappers) : 0,
    };
  },

  toJSON(message: ParamsRequest): unknown {
    const obj: any = {};
    if (message.pathParamStr !== "") {
      obj.pathParamStr = message.pathParamStr;
    }
    if (message.pathParamI32 !== 0) {
      obj.pathParamI32 = Math.round(message.pathParamI32);
    }
    if (message.str !== "") {
      obj.str = message.str;
    }
    if (message.state !== 0) {
      obj.state = paramStateToJSON(message.state);
    }
    if (message.newState !== 0) {
      obj.newState = paramStateToJSON(message.newState);
    }
    if (message.i32 !== 0) {
      obj.i32 = Math.round(message.i32);
    }
    if (message.i64 !== 0) {
      obj.i64 = Math.round(message.i64);
    }
    if (message.ui32 !== 0) {
      obj.ui32 = Math.round(message.ui32);
    }
    if (message.ui64 !== 0) {
      obj.ui64 = Math.round(message.ui64);
    }
    if (message.f32 !== 0) {
      obj.f32 = message.f32;
    }
    if (message.f64 !== 0) {
      obj.f64 = message.f64;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.startTime !== "") {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== "") {
      obj.endTime = message.endTime;
    }
    if (message.rStr?.length) {
      obj.rStr = message.rStr;
    }
    if (message.rState?.length) {
      obj.rState = message.rState.map((e) => paramStateToJSON(e));
    }
    if (message.rI32?.length) {
      obj.rI32 = message.rI32.map((e) => Math.round(e));
    }
    if (message.rI64?.length) {
      obj.rI64 = message.rI64.map((e) => Math.round(e));
    }
    if (message.rUi32?.length) {
      obj.rUi32 = message.rUi32.map((e) => Math.round(e));
    }
    if (message.rUi64?.length) {
      obj.rUi64 = message.rUi64.map((e) => Math.round(e));
    }
    if (message.rF32?.length) {
      obj.rF32 = message.rF32;
    }
    if (message.rF64?.length) {
      obj.rF64 = message.rF64;
    }
    if (message.rEnabled?.length) {
      obj.rEnabled = message.rEnabled;
    }
    if (message.rStartTime?.length) {
      obj.rStartTime = message.rStartTime;
    }
    if (message.rEndTime?.length) {
      obj.rEndTime = message.rEndTime;
    }
    if (message.embedded !== undefined) {
      obj.embedded = EmbeddedParams.toJSON(message.embedded);
    }
    if (message.startTimeTs !== undefined) {
      obj.startTimeTs = message.startTimeTs.toISOString();
    }
    if (message.endTimeTs !== undefined) {
      obj.endTimeTs = message.endTimeTs.toISOString();
    }
    if (message.wrappers !== 0) {
      obj.wrappers = wrapperTypesToJSON(message.wrappers);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ParamsRequest>, I>>(base?: I): ParamsRequest {
    return ParamsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ParamsRequest>, I>>(object: I): ParamsRequest {
    const message = createBaseParamsRequest();
    message.pathParamStr = object.pathParamStr ?? "";
    message.pathParamI32 = object.pathParamI32 ?? 0;
    message.str = object.str ?? "";
    message.state = object.state ?? 0;
    message.newState = object.newState ?? 0;
    message.i32 = object.i32 ?? 0;
    message.i64 = object.i64 ?? 0;
    message.ui32 = object.ui32 ?? 0;
    message.ui64 = object.ui64 ?? 0;
    message.f32 = object.f32 ?? 0;
    message.f64 = object.f64 ?? 0;
    message.enabled = object.enabled ?? false;
    message.startTime = object.startTime ?? "";
    message.endTime = object.endTime ?? "";
    message.rStr = object.rStr?.map((e) => e) || [];
    message.rState = object.rState?.map((e) => e) || [];
    message.rI32 = object.rI32?.map((e) => e) || [];
    message.rI64 = object.rI64?.map((e) => e) || [];
    message.rUi32 = object.rUi32?.map((e) => e) || [];
    message.rUi64 = object.rUi64?.map((e) => e) || [];
    message.rF32 = object.rF32?.map((e) => e) || [];
    message.rF64 = object.rF64?.map((e) => e) || [];
    message.rEnabled = object.rEnabled?.map((e) => e) || [];
    message.rStartTime = object.rStartTime?.map((e) => e) || [];
    message.rEndTime = object.rEndTime?.map((e) => e) || [];
    message.embedded = (object.embedded !== undefined && object.embedded !== null)
      ? EmbeddedParams.fromPartial(object.embedded)
      : undefined;
    message.startTimeTs = object.startTimeTs ?? undefined;
    message.endTimeTs = object.endTimeTs ?? undefined;
    message.wrappers = object.wrappers ?? 0;
    return message;
  },
};

function createBaseParamsResponse(): ParamsResponse {
  return {
    pathParamStr: "",
    pathParamI32: 0,
    str: "",
    state: 0,
    newState: 0,
    i32: 0,
    i64: 0,
    ui32: 0,
    ui64: 0,
    f32: 0,
    f64: 0,
    enabled: false,
    startTime: "",
    endTime: "",
    rStr: [],
    rState: [],
    rI32: [],
    rI64: [],
    rUi32: [],
    rUi64: [],
    rF32: [],
    rF64: [],
    rEnabled: [],
    rStartTime: [],
    rEndTime: [],
    embedded: undefined,
    startTimeTs: undefined,
    endTimeTs: undefined,
    wBool: undefined,
    wInt32: undefined,
    wUint32: undefined,
    wInt64: undefined,
    wUint64: undefined,
    wDouble: undefined,
    wFloat: undefined,
    wStr: undefined,
  };
}

export const ParamsResponse = {
  encode(message: ParamsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pathParamStr !== "") {
      writer.uint32(10).string(message.pathParamStr);
    }
    if (message.pathParamI32 !== 0) {
      writer.uint32(16).int32(message.pathParamI32);
    }
    if (message.str !== "") {
      writer.uint32(26).string(message.str);
    }
    if (message.state !== 0) {
      writer.uint32(32).int32(message.state);
    }
    if (message.newState !== 0) {
      writer.uint32(256).int32(message.newState);
    }
    if (message.i32 !== 0) {
      writer.uint32(40).int32(message.i32);
    }
    if (message.i64 !== 0) {
      writer.uint32(48).int64(message.i64);
    }
    if (message.ui32 !== 0) {
      writer.uint32(56).uint32(message.ui32);
    }
    if (message.ui64 !== 0) {
      writer.uint32(64).uint64(message.ui64);
    }
    if (message.f32 !== 0) {
      writer.uint32(77).float(message.f32);
    }
    if (message.f64 !== 0) {
      writer.uint32(81).double(message.f64);
    }
    if (message.enabled !== false) {
      writer.uint32(88).bool(message.enabled);
    }
    if (message.startTime !== "") {
      writer.uint32(98).string(message.startTime);
    }
    if (message.endTime !== "") {
      writer.uint32(106).string(message.endTime);
    }
    for (const v of message.rStr) {
      writer.uint32(114).string(v!);
    }
    writer.uint32(122).fork();
    for (const v of message.rState) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(130).fork();
    for (const v of message.rI32) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(138).fork();
    for (const v of message.rI64) {
      writer.int64(v);
    }
    writer.ldelim();
    writer.uint32(146).fork();
    for (const v of message.rUi32) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(154).fork();
    for (const v of message.rUi64) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(162).fork();
    for (const v of message.rF32) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(170).fork();
    for (const v of message.rF64) {
      writer.double(v);
    }
    writer.ldelim();
    writer.uint32(178).fork();
    for (const v of message.rEnabled) {
      writer.bool(v);
    }
    writer.ldelim();
    for (const v of message.rStartTime) {
      writer.uint32(186).string(v!);
    }
    for (const v of message.rEndTime) {
      writer.uint32(194).string(v!);
    }
    if (message.embedded !== undefined) {
      EmbeddedParams.encode(message.embedded, writer.uint32(202).fork()).ldelim();
    }
    if (message.startTimeTs !== undefined) {
      Timestamp.encode(toTimestamp(message.startTimeTs), writer.uint32(242).fork()).ldelim();
    }
    if (message.endTimeTs !== undefined) {
      Timestamp.encode(toTimestamp(message.endTimeTs), writer.uint32(250).fork()).ldelim();
    }
    if (message.wBool !== undefined) {
      BoolValue.encode({ value: message.wBool! }, writer.uint32(322).fork()).ldelim();
    }
    if (message.wInt32 !== undefined) {
      Int32Value.encode({ value: message.wInt32! }, writer.uint32(330).fork()).ldelim();
    }
    if (message.wUint32 !== undefined) {
      UInt32Value.encode({ value: message.wUint32! }, writer.uint32(338).fork()).ldelim();
    }
    if (message.wInt64 !== undefined) {
      Int64Value.encode({ value: message.wInt64! }, writer.uint32(346).fork()).ldelim();
    }
    if (message.wUint64 !== undefined) {
      UInt64Value.encode({ value: message.wUint64! }, writer.uint32(354).fork()).ldelim();
    }
    if (message.wDouble !== undefined) {
      DoubleValue.encode({ value: message.wDouble! }, writer.uint32(362).fork()).ldelim();
    }
    if (message.wFloat !== undefined) {
      FloatValue.encode({ value: message.wFloat! }, writer.uint32(370).fork()).ldelim();
    }
    if (message.wStr !== undefined) {
      StringValue.encode({ value: message.wStr! }, writer.uint32(378).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ParamsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParamsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pathParamStr = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pathParamI32 = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.str = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.newState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.i32 = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.i64 = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.ui32 = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.ui64 = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.f32 = reader.float();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.f64 = reader.double();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.startTime = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.endTime = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.rStr.push(reader.string());
          continue;
        case 15:
          if (tag === 120) {
            message.rState.push(reader.int32() as any);

            continue;
          }

          if (tag === 122) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rState.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 16:
          if (tag === 128) {
            message.rI32.push(reader.int32());

            continue;
          }

          if (tag === 130) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rI32.push(reader.int32());
            }

            continue;
          }

          break;
        case 17:
          if (tag === 136) {
            message.rI64.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 138) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rI64.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 18:
          if (tag === 144) {
            message.rUi32.push(reader.uint32());

            continue;
          }

          if (tag === 146) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rUi32.push(reader.uint32());
            }

            continue;
          }

          break;
        case 19:
          if (tag === 152) {
            message.rUi64.push(longToNumber(reader.uint64() as Long));

            continue;
          }

          if (tag === 154) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rUi64.push(longToNumber(reader.uint64() as Long));
            }

            continue;
          }

          break;
        case 20:
          if (tag === 165) {
            message.rF32.push(reader.float());

            continue;
          }

          if (tag === 162) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rF32.push(reader.float());
            }

            continue;
          }

          break;
        case 21:
          if (tag === 169) {
            message.rF64.push(reader.double());

            continue;
          }

          if (tag === 170) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rF64.push(reader.double());
            }

            continue;
          }

          break;
        case 22:
          if (tag === 176) {
            message.rEnabled.push(reader.bool());

            continue;
          }

          if (tag === 178) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rEnabled.push(reader.bool());
            }

            continue;
          }

          break;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.rStartTime.push(reader.string());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.rEndTime.push(reader.string());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.embedded = EmbeddedParams.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.startTimeTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.endTimeTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.wBool = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.wInt32 = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.wUint32 = UInt32Value.decode(reader, reader.uint32()).value;
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.wInt64 = Int64Value.decode(reader, reader.uint32()).value;
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.wUint64 = UInt64Value.decode(reader, reader.uint32()).value;
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.wDouble = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 46:
          if (tag !== 370) {
            break;
          }

          message.wFloat = FloatValue.decode(reader, reader.uint32()).value;
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.wStr = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ParamsResponse {
    return {
      pathParamStr: isSet(object.pathParamStr) ? globalThis.String(object.pathParamStr) : "",
      pathParamI32: isSet(object.pathParamI32) ? globalThis.Number(object.pathParamI32) : 0,
      str: isSet(object.str) ? globalThis.String(object.str) : "",
      state: isSet(object.state) ? paramStateFromJSON(object.state) : 0,
      newState: isSet(object.newState) ? paramStateFromJSON(object.newState) : 0,
      i32: isSet(object.i32) ? globalThis.Number(object.i32) : 0,
      i64: isSet(object.i64) ? globalThis.Number(object.i64) : 0,
      ui32: isSet(object.ui32) ? globalThis.Number(object.ui32) : 0,
      ui64: isSet(object.ui64) ? globalThis.Number(object.ui64) : 0,
      f32: isSet(object.f32) ? globalThis.Number(object.f32) : 0,
      f64: isSet(object.f64) ? globalThis.Number(object.f64) : 0,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      startTime: isSet(object.startTime) ? globalThis.String(object.startTime) : "",
      endTime: isSet(object.endTime) ? globalThis.String(object.endTime) : "",
      rStr: globalThis.Array.isArray(object?.rStr) ? object.rStr.map((e: any) => globalThis.String(e)) : [],
      rState: globalThis.Array.isArray(object?.rState) ? object.rState.map((e: any) => paramStateFromJSON(e)) : [],
      rI32: globalThis.Array.isArray(object?.rI32) ? object.rI32.map((e: any) => globalThis.Number(e)) : [],
      rI64: globalThis.Array.isArray(object?.rI64) ? object.rI64.map((e: any) => globalThis.Number(e)) : [],
      rUi32: globalThis.Array.isArray(object?.rUi32) ? object.rUi32.map((e: any) => globalThis.Number(e)) : [],
      rUi64: globalThis.Array.isArray(object?.rUi64) ? object.rUi64.map((e: any) => globalThis.Number(e)) : [],
      rF32: globalThis.Array.isArray(object?.rF32) ? object.rF32.map((e: any) => globalThis.Number(e)) : [],
      rF64: globalThis.Array.isArray(object?.rF64) ? object.rF64.map((e: any) => globalThis.Number(e)) : [],
      rEnabled: globalThis.Array.isArray(object?.rEnabled)
        ? object.rEnabled.map((e: any) => globalThis.Boolean(e))
        : [],
      rStartTime: globalThis.Array.isArray(object?.rStartTime)
        ? object.rStartTime.map((e: any) => globalThis.String(e))
        : [],
      rEndTime: globalThis.Array.isArray(object?.rEndTime) ? object.rEndTime.map((e: any) => globalThis.String(e)) : [],
      embedded: isSet(object.embedded) ? EmbeddedParams.fromJSON(object.embedded) : undefined,
      startTimeTs: isSet(object.startTimeTs) ? fromJsonTimestamp(object.startTimeTs) : undefined,
      endTimeTs: isSet(object.endTimeTs) ? fromJsonTimestamp(object.endTimeTs) : undefined,
      wBool: isSet(object.wBool) ? Boolean(object.wBool) : undefined,
      wInt32: isSet(object.wInt32) ? Number(object.wInt32) : undefined,
      wUint32: isSet(object.wUint32) ? Number(object.wUint32) : undefined,
      wInt64: isSet(object.wInt64) ? Number(object.wInt64) : undefined,
      wUint64: isSet(object.wUint64) ? Number(object.wUint64) : undefined,
      wDouble: isSet(object.wDouble) ? Number(object.wDouble) : undefined,
      wFloat: isSet(object.wFloat) ? Number(object.wFloat) : undefined,
      wStr: isSet(object.wStr) ? String(object.wStr) : undefined,
    };
  },

  toJSON(message: ParamsResponse): unknown {
    const obj: any = {};
    if (message.pathParamStr !== "") {
      obj.pathParamStr = message.pathParamStr;
    }
    if (message.pathParamI32 !== 0) {
      obj.pathParamI32 = Math.round(message.pathParamI32);
    }
    if (message.str !== "") {
      obj.str = message.str;
    }
    if (message.state !== 0) {
      obj.state = paramStateToJSON(message.state);
    }
    if (message.newState !== 0) {
      obj.newState = paramStateToJSON(message.newState);
    }
    if (message.i32 !== 0) {
      obj.i32 = Math.round(message.i32);
    }
    if (message.i64 !== 0) {
      obj.i64 = Math.round(message.i64);
    }
    if (message.ui32 !== 0) {
      obj.ui32 = Math.round(message.ui32);
    }
    if (message.ui64 !== 0) {
      obj.ui64 = Math.round(message.ui64);
    }
    if (message.f32 !== 0) {
      obj.f32 = message.f32;
    }
    if (message.f64 !== 0) {
      obj.f64 = message.f64;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.startTime !== "") {
      obj.startTime = message.startTime;
    }
    if (message.endTime !== "") {
      obj.endTime = message.endTime;
    }
    if (message.rStr?.length) {
      obj.rStr = message.rStr;
    }
    if (message.rState?.length) {
      obj.rState = message.rState.map((e) => paramStateToJSON(e));
    }
    if (message.rI32?.length) {
      obj.rI32 = message.rI32.map((e) => Math.round(e));
    }
    if (message.rI64?.length) {
      obj.rI64 = message.rI64.map((e) => Math.round(e));
    }
    if (message.rUi32?.length) {
      obj.rUi32 = message.rUi32.map((e) => Math.round(e));
    }
    if (message.rUi64?.length) {
      obj.rUi64 = message.rUi64.map((e) => Math.round(e));
    }
    if (message.rF32?.length) {
      obj.rF32 = message.rF32;
    }
    if (message.rF64?.length) {
      obj.rF64 = message.rF64;
    }
    if (message.rEnabled?.length) {
      obj.rEnabled = message.rEnabled;
    }
    if (message.rStartTime?.length) {
      obj.rStartTime = message.rStartTime;
    }
    if (message.rEndTime?.length) {
      obj.rEndTime = message.rEndTime;
    }
    if (message.embedded !== undefined) {
      obj.embedded = EmbeddedParams.toJSON(message.embedded);
    }
    if (message.startTimeTs !== undefined) {
      obj.startTimeTs = message.startTimeTs.toISOString();
    }
    if (message.endTimeTs !== undefined) {
      obj.endTimeTs = message.endTimeTs.toISOString();
    }
    if (message.wBool !== undefined) {
      obj.wBool = message.wBool;
    }
    if (message.wInt32 !== undefined) {
      obj.wInt32 = message.wInt32;
    }
    if (message.wUint32 !== undefined) {
      obj.wUint32 = message.wUint32;
    }
    if (message.wInt64 !== undefined) {
      obj.wInt64 = message.wInt64;
    }
    if (message.wUint64 !== undefined) {
      obj.wUint64 = message.wUint64;
    }
    if (message.wDouble !== undefined) {
      obj.wDouble = message.wDouble;
    }
    if (message.wFloat !== undefined) {
      obj.wFloat = message.wFloat;
    }
    if (message.wStr !== undefined) {
      obj.wStr = message.wStr;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ParamsResponse>, I>>(base?: I): ParamsResponse {
    return ParamsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ParamsResponse>, I>>(object: I): ParamsResponse {
    const message = createBaseParamsResponse();
    message.pathParamStr = object.pathParamStr ?? "";
    message.pathParamI32 = object.pathParamI32 ?? 0;
    message.str = object.str ?? "";
    message.state = object.state ?? 0;
    message.newState = object.newState ?? 0;
    message.i32 = object.i32 ?? 0;
    message.i64 = object.i64 ?? 0;
    message.ui32 = object.ui32 ?? 0;
    message.ui64 = object.ui64 ?? 0;
    message.f32 = object.f32 ?? 0;
    message.f64 = object.f64 ?? 0;
    message.enabled = object.enabled ?? false;
    message.startTime = object.startTime ?? "";
    message.endTime = object.endTime ?? "";
    message.rStr = object.rStr?.map((e) => e) || [];
    message.rState = object.rState?.map((e) => e) || [];
    message.rI32 = object.rI32?.map((e) => e) || [];
    message.rI64 = object.rI64?.map((e) => e) || [];
    message.rUi32 = object.rUi32?.map((e) => e) || [];
    message.rUi64 = object.rUi64?.map((e) => e) || [];
    message.rF32 = object.rF32?.map((e) => e) || [];
    message.rF64 = object.rF64?.map((e) => e) || [];
    message.rEnabled = object.rEnabled?.map((e) => e) || [];
    message.rStartTime = object.rStartTime?.map((e) => e) || [];
    message.rEndTime = object.rEndTime?.map((e) => e) || [];
    message.embedded = (object.embedded !== undefined && object.embedded !== null)
      ? EmbeddedParams.fromPartial(object.embedded)
      : undefined;
    message.startTimeTs = object.startTimeTs ?? undefined;
    message.endTimeTs = object.endTimeTs ?? undefined;
    message.wBool = object.wBool ?? undefined;
    message.wInt32 = object.wInt32 ?? undefined;
    message.wUint32 = object.wUint32 ?? undefined;
    message.wInt64 = object.wInt64 ?? undefined;
    message.wUint64 = object.wUint64 ?? undefined;
    message.wDouble = object.wDouble ?? undefined;
    message.wFloat = object.wFloat ?? undefined;
    message.wStr = object.wStr ?? undefined;
    return message;
  },
};

function createBaseHttpRequest(): HttpRequest {
  return { numMessages: 0, pauseAfter: 0, sleepFor: 0 };
}

export const HttpRequest = {
  encode(message: HttpRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.numMessages !== 0) {
      writer.uint32(8).int32(message.numMessages);
    }
    if (message.pauseAfter !== 0) {
      writer.uint32(16).int32(message.pauseAfter);
    }
    if (message.sleepFor !== 0) {
      writer.uint32(24).int32(message.sleepFor);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HttpRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHttpRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numMessages = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pauseAfter = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sleepFor = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HttpRequest {
    return {
      numMessages: isSet(object.numMessages) ? globalThis.Number(object.numMessages) : 0,
      pauseAfter: isSet(object.pauseAfter) ? globalThis.Number(object.pauseAfter) : 0,
      sleepFor: isSet(object.sleepFor) ? globalThis.Number(object.sleepFor) : 0,
    };
  },

  toJSON(message: HttpRequest): unknown {
    const obj: any = {};
    if (message.numMessages !== 0) {
      obj.numMessages = Math.round(message.numMessages);
    }
    if (message.pauseAfter !== 0) {
      obj.pauseAfter = Math.round(message.pauseAfter);
    }
    if (message.sleepFor !== 0) {
      obj.sleepFor = Math.round(message.sleepFor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HttpRequest>, I>>(base?: I): HttpRequest {
    return HttpRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HttpRequest>, I>>(object: I): HttpRequest {
    const message = createBaseHttpRequest();
    message.numMessages = object.numMessages ?? 0;
    message.pauseAfter = object.pauseAfter ?? 0;
    message.sleepFor = object.sleepFor ?? 0;
    return message;
  },
};

function createBaseHttpMessage(): HttpMessage {
  return { email: "", streetNumber: 0, city: "", state: "", zip: 0 };
}

export const HttpMessage = {
  encode(message: HttpMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.streetNumber !== 0) {
      writer.uint32(16).int32(message.streetNumber);
    }
    if (message.city !== "") {
      writer.uint32(26).string(message.city);
    }
    if (message.state !== "") {
      writer.uint32(34).string(message.state);
    }
    if (message.zip !== 0) {
      writer.uint32(40).int32(message.zip);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HttpMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHttpMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.streetNumber = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.city = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.state = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.zip = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HttpMessage {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      streetNumber: isSet(object.streetNumber) ? globalThis.Number(object.streetNumber) : 0,
      city: isSet(object.city) ? globalThis.String(object.city) : "",
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      zip: isSet(object.zip) ? globalThis.Number(object.zip) : 0,
    };
  },

  toJSON(message: HttpMessage): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.streetNumber !== 0) {
      obj.streetNumber = Math.round(message.streetNumber);
    }
    if (message.city !== "") {
      obj.city = message.city;
    }
    if (message.state !== "") {
      obj.state = message.state;
    }
    if (message.zip !== 0) {
      obj.zip = Math.round(message.zip);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HttpMessage>, I>>(base?: I): HttpMessage {
    return HttpMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HttpMessage>, I>>(object: I): HttpMessage {
    const message = createBaseHttpMessage();
    message.email = object.email ?? "";
    message.streetNumber = object.streetNumber ?? 0;
    message.city = object.city ?? "";
    message.state = object.state ?? "";
    message.zip = object.zip ?? 0;
    return message;
  },
};

function createBaseHttpResponse(): HttpResponse {
  return { name: "", description: "", messages: [], totalMessages: 0 };
}

export const HttpResponse = {
  encode(message: HttpResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    for (const v of message.messages) {
      HttpMessage.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.totalMessages !== 0) {
      writer.uint32(32).int32(message.totalMessages);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HttpResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHttpResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.messages.push(HttpMessage.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.totalMessages = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HttpResponse {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      messages: globalThis.Array.isArray(object?.messages)
        ? object.messages.map((e: any) => HttpMessage.fromJSON(e))
        : [],
      totalMessages: isSet(object.totalMessages) ? globalThis.Number(object.totalMessages) : 0,
    };
  },

  toJSON(message: HttpResponse): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.messages?.length) {
      obj.messages = message.messages.map((e) => HttpMessage.toJSON(e));
    }
    if (message.totalMessages !== 0) {
      obj.totalMessages = Math.round(message.totalMessages);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HttpResponse>, I>>(base?: I): HttpResponse {
    return HttpResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HttpResponse>, I>>(object: I): HttpResponse {
    const message = createBaseHttpResponse();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.messages = object.messages?.map((e) => HttpMessage.fromPartial(e)) || [];
    message.totalMessages = object.totalMessages ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
