// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/assertions.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { PathId } from "../schema_path/path_id";

export const protobufPackage = "configd";

/** Specifies the latch state for an assertion. */
export enum LatchState {
  LATCH_STATE_UNSPECIFIED = 0,
  LATCH_STATE_LATCH = 1,
  LATCH_STATE_UNLATCH = 2,
  UNRECOGNIZED = -1,
}

export function latchStateFromJSON(object: any): LatchState {
  switch (object) {
    case 0:
    case "LATCH_STATE_UNSPECIFIED":
      return LatchState.LATCH_STATE_UNSPECIFIED;
    case 1:
    case "LATCH_STATE_LATCH":
      return LatchState.LATCH_STATE_LATCH;
    case 2:
    case "LATCH_STATE_UNLATCH":
      return LatchState.LATCH_STATE_UNLATCH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LatchState.UNRECOGNIZED;
  }
}

export function latchStateToJSON(object: LatchState): string {
  switch (object) {
    case LatchState.LATCH_STATE_UNSPECIFIED:
      return "LATCH_STATE_UNSPECIFIED";
    case LatchState.LATCH_STATE_LATCH:
      return "LATCH_STATE_LATCH";
    case LatchState.LATCH_STATE_UNLATCH:
      return "LATCH_STATE_UNLATCH";
    case LatchState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Assertion {
  /** The assertion path. */
  path:
    | PathId
    | undefined;
  /** The assertion state. */
  state: LatchState;
}

/** A response to latching one or more assertions. */
export interface AssertionsResponse {
  /** The list of assertions which were succesfully latched. */
  assertions: Assertion[];
}

/** Latch one or more assertions. */
export interface LatchAssertionsRequest {
  /** The list of assertions to be latched. */
  assertions: Assertion[];
}

function createBaseAssertion(): Assertion {
  return { path: undefined, state: 0 };
}

export const Assertion = {
  encode(message: Assertion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== undefined) {
      PathId.encode(message.path, writer.uint32(10).fork()).ldelim();
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Assertion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Assertion {
    return {
      path: isSet(object.path) ? PathId.fromJSON(object.path) : undefined,
      state: isSet(object.state) ? latchStateFromJSON(object.state) : 0,
    };
  },

  toJSON(message: Assertion): unknown {
    const obj: any = {};
    if (message.path !== undefined) {
      obj.path = PathId.toJSON(message.path);
    }
    if (message.state !== 0) {
      obj.state = latchStateToJSON(message.state);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Assertion>, I>>(base?: I): Assertion {
    return Assertion.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Assertion>, I>>(object: I): Assertion {
    const message = createBaseAssertion();
    message.path = (object.path !== undefined && object.path !== null) ? PathId.fromPartial(object.path) : undefined;
    message.state = object.state ?? 0;
    return message;
  },
};

function createBaseAssertionsResponse(): AssertionsResponse {
  return { assertions: [] };
}

export const AssertionsResponse = {
  encode(message: AssertionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assertions) {
      Assertion.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assertions.push(Assertion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertionsResponse {
    return {
      assertions: globalThis.Array.isArray(object?.assertions)
        ? object.assertions.map((e: any) => Assertion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AssertionsResponse): unknown {
    const obj: any = {};
    if (message.assertions?.length) {
      obj.assertions = message.assertions.map((e) => Assertion.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertionsResponse>, I>>(base?: I): AssertionsResponse {
    return AssertionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertionsResponse>, I>>(object: I): AssertionsResponse {
    const message = createBaseAssertionsResponse();
    message.assertions = object.assertions?.map((e) => Assertion.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLatchAssertionsRequest(): LatchAssertionsRequest {
  return { assertions: [] };
}

export const LatchAssertionsRequest = {
  encode(message: LatchAssertionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assertions) {
      Assertion.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LatchAssertionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLatchAssertionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assertions.push(Assertion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LatchAssertionsRequest {
    return {
      assertions: globalThis.Array.isArray(object?.assertions)
        ? object.assertions.map((e: any) => Assertion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LatchAssertionsRequest): unknown {
    const obj: any = {};
    if (message.assertions?.length) {
      obj.assertions = message.assertions.map((e) => Assertion.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LatchAssertionsRequest>, I>>(base?: I): LatchAssertionsRequest {
    return LatchAssertionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LatchAssertionsRequest>, I>>(object: I): LatchAssertionsRequest {
    const message = createBaseLatchAssertionsRequest();
    message.assertions = object.assertions?.map((e) => Assertion.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
