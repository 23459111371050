import { Link } from "@magnetic/link";
import { Icon } from "@magnetic/nav";
import { Popover } from "@magnetic/popover";
import {
  getMockShellArrowKeysHelp,
  getMockShellCtrlKeysHelp,
  getMockShellHelp,
  getMockShellMetaKeysHelp
} from "./mockShellUtils";

const commands = getMockShellHelp();
const ctrlCmds = getMockShellCtrlKeysHelp();
const arrowCmds = getMockShellArrowKeysHelp();
const metaCmds = getMockShellMetaKeysHelp();

export const MockShellHelp = () => {
  return (
    <Popover
      size="lg"
      placement="bottom-end"
      title={
        <>
          {Object.entries(commands).map(([cmd, desc]) => {
            return (
              <div className="cmd-container" key={cmd}>
                <div className="shell-cmd">
                  <span className="cmd">{cmd}</span>
                </div>
                <div className="cmd-desc">{desc}</div>
              </div>
            );
          })}
          {Object.entries(ctrlCmds).map(([cmd, desc]) => {
            return (
              <div className="cmd-container" key={cmd}>
                <div className="shell-cmd">
                  <span className="cmd">Ctrl</span>+
                  <span className="cmd">{cmd}</span>
                </div>
                <div className="cmd-desc">{desc}</div>
              </div>
            );
          })}
          {Object.entries(arrowCmds).map(([cmd, desc]) => {
            return (
              <div className="cmd-container" key={cmd}>
                <div className="shell-cmd">
                  <span className="cmd">{cmd}</span>
                </div>
                <div className="cmd-desc">{desc}</div>
              </div>
            );
          })}
          {Object.entries(metaCmds).map(([cmd, desc]) => {
            return (
              <div className="cmd-container" key={cmd}>
                <div className="shell-cmd">
                  <span className="cmd">Meta</span>+
                  <span className="cmd">{cmd}</span>
                </div>
                <div className="cmd-desc">{desc}</div>
              </div>
            );
          })}
        </>
      }
    >
      <Link className="hover-target">
        <Icon kind="help" />
      </Link>
    </Popover>
  );
};
