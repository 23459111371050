// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/rest_ports.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { NetworkPort, PortBreakout } from "../models/models";

export const protobufPackage = "configd";

export interface SetPortsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
  /** The set of ports for the nodes. */
  ports: NetworkPort[];
}

/** Get the list of ports configuration information for a specific node in a fabric. */
export interface GetPortsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** Get a single named port. */
export interface GetPortRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
  /** The port id or name. */
  portId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

/** Reset a specific port configuration for a node in a fabric to its default values. */
export interface ResetPortRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
  /** The port id or name. */
  portId: string;
}

export interface PortsResponse {
  nodeId: string;
  ports: NetworkPort[];
}

export interface GetNodePortBreakoutsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

export interface GetNodePortBreakoutRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
  /** The breakout port id or name. */
  breakoutId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
  /** Include object metadata in the response. */
  includeMetadata: boolean;
}

export interface AddNodePortBreakoutsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
  /** The breakout configurations to be added. */
  breakouts: PortBreakout[];
}

export interface UpdateNodePortBreakoutRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
  /** The breakout port id or name. */
  breakoutId: string;
  /** The breakout port configuration. */
  breakout: PortBreakout | undefined;
}

export interface DeleteNodePortBreakoutRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** A node id or name. */
  nodeId: string;
  /** The breakout port id or name. */
  breakoutId: string;
}

export interface NodePortBreakoutsResponse {
  breakouts: PortBreakout[];
}

function createBaseSetPortsRequest(): SetPortsRequest {
  return { fabricId: "", nodeId: "", ports: [] };
}

export const SetPortsRequest = {
  encode(message: SetPortsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    for (const v of message.ports) {
      NetworkPort.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPortsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPortsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ports.push(NetworkPort.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetPortsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => NetworkPort.fromJSON(e)) : [],
    };
  },

  toJSON(message: SetPortsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => NetworkPort.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetPortsRequest>, I>>(base?: I): SetPortsRequest {
    return SetPortsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetPortsRequest>, I>>(object: I): SetPortsRequest {
    const message = createBaseSetPortsRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.ports = object.ports?.map((e) => NetworkPort.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetPortsRequest(): GetPortsRequest {
  return { fabricId: "", nodeId: "", candidate: "", includeMetadata: false };
}

export const GetPortsRequest = {
  encode(message: GetPortsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPortsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPortsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPortsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetPortsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPortsRequest>, I>>(base?: I): GetPortsRequest {
    return GetPortsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPortsRequest>, I>>(object: I): GetPortsRequest {
    const message = createBaseGetPortsRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetPortRequest(): GetPortRequest {
  return { fabricId: "", nodeId: "", portId: "", candidate: "", includeMetadata: false };
}

export const GetPortRequest = {
  encode(message: GetPortRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.portId !== "") {
      writer.uint32(26).string(message.portId);
    }
    if (message.candidate !== "") {
      writer.uint32(34).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(40).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPortRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPortRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.portId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPortRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      portId: isSet(object.portId) ? globalThis.String(object.portId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetPortRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.portId !== "") {
      obj.portId = message.portId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPortRequest>, I>>(base?: I): GetPortRequest {
    return GetPortRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPortRequest>, I>>(object: I): GetPortRequest {
    const message = createBaseGetPortRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.portId = object.portId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseResetPortRequest(): ResetPortRequest {
  return { fabricId: "", nodeId: "", portId: "" };
}

export const ResetPortRequest = {
  encode(message: ResetPortRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.portId !== "") {
      writer.uint32(26).string(message.portId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetPortRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetPortRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.portId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResetPortRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      portId: isSet(object.portId) ? globalThis.String(object.portId) : "",
    };
  },

  toJSON(message: ResetPortRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.portId !== "") {
      obj.portId = message.portId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetPortRequest>, I>>(base?: I): ResetPortRequest {
    return ResetPortRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetPortRequest>, I>>(object: I): ResetPortRequest {
    const message = createBaseResetPortRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.portId = object.portId ?? "";
    return message;
  },
};

function createBasePortsResponse(): PortsResponse {
  return { nodeId: "", ports: [] };
}

export const PortsResponse = {
  encode(message: PortsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    for (const v of message.ports) {
      NetworkPort.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ports.push(NetworkPort.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortsResponse {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => NetworkPort.fromJSON(e)) : [],
    };
  },

  toJSON(message: PortsResponse): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => NetworkPort.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortsResponse>, I>>(base?: I): PortsResponse {
    return PortsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortsResponse>, I>>(object: I): PortsResponse {
    const message = createBasePortsResponse();
    message.nodeId = object.nodeId ?? "";
    message.ports = object.ports?.map((e) => NetworkPort.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetNodePortBreakoutsRequest(): GetNodePortBreakoutsRequest {
  return { fabricId: "", nodeId: "", candidate: "", includeMetadata: false };
}

export const GetNodePortBreakoutsRequest = {
  encode(message: GetNodePortBreakoutsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(32).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodePortBreakoutsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodePortBreakoutsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodePortBreakoutsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetNodePortBreakoutsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodePortBreakoutsRequest>, I>>(base?: I): GetNodePortBreakoutsRequest {
    return GetNodePortBreakoutsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodePortBreakoutsRequest>, I>>(object: I): GetNodePortBreakoutsRequest {
    const message = createBaseGetNodePortBreakoutsRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseGetNodePortBreakoutRequest(): GetNodePortBreakoutRequest {
  return { fabricId: "", nodeId: "", breakoutId: "", candidate: "", includeMetadata: false };
}

export const GetNodePortBreakoutRequest = {
  encode(message: GetNodePortBreakoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.breakoutId !== "") {
      writer.uint32(26).string(message.breakoutId);
    }
    if (message.candidate !== "") {
      writer.uint32(34).string(message.candidate);
    }
    if (message.includeMetadata !== false) {
      writer.uint32(40).bool(message.includeMetadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodePortBreakoutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodePortBreakoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.breakoutId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeMetadata = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodePortBreakoutRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      breakoutId: isSet(object.breakoutId) ? globalThis.String(object.breakoutId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      includeMetadata: isSet(object.includeMetadata) ? globalThis.Boolean(object.includeMetadata) : false,
    };
  },

  toJSON(message: GetNodePortBreakoutRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.breakoutId !== "") {
      obj.breakoutId = message.breakoutId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.includeMetadata !== false) {
      obj.includeMetadata = message.includeMetadata;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodePortBreakoutRequest>, I>>(base?: I): GetNodePortBreakoutRequest {
    return GetNodePortBreakoutRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodePortBreakoutRequest>, I>>(object: I): GetNodePortBreakoutRequest {
    const message = createBaseGetNodePortBreakoutRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.breakoutId = object.breakoutId ?? "";
    message.candidate = object.candidate ?? "";
    message.includeMetadata = object.includeMetadata ?? false;
    return message;
  },
};

function createBaseAddNodePortBreakoutsRequest(): AddNodePortBreakoutsRequest {
  return { fabricId: "", nodeId: "", breakouts: [] };
}

export const AddNodePortBreakoutsRequest = {
  encode(message: AddNodePortBreakoutsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    for (const v of message.breakouts) {
      PortBreakout.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddNodePortBreakoutsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddNodePortBreakoutsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.breakouts.push(PortBreakout.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddNodePortBreakoutsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      breakouts: globalThis.Array.isArray(object?.breakouts)
        ? object.breakouts.map((e: any) => PortBreakout.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddNodePortBreakoutsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.breakouts?.length) {
      obj.breakouts = message.breakouts.map((e) => PortBreakout.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddNodePortBreakoutsRequest>, I>>(base?: I): AddNodePortBreakoutsRequest {
    return AddNodePortBreakoutsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddNodePortBreakoutsRequest>, I>>(object: I): AddNodePortBreakoutsRequest {
    const message = createBaseAddNodePortBreakoutsRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.breakouts = object.breakouts?.map((e) => PortBreakout.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateNodePortBreakoutRequest(): UpdateNodePortBreakoutRequest {
  return { fabricId: "", nodeId: "", breakoutId: "", breakout: undefined };
}

export const UpdateNodePortBreakoutRequest = {
  encode(message: UpdateNodePortBreakoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.breakoutId !== "") {
      writer.uint32(26).string(message.breakoutId);
    }
    if (message.breakout !== undefined) {
      PortBreakout.encode(message.breakout, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateNodePortBreakoutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNodePortBreakoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.breakoutId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.breakout = PortBreakout.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateNodePortBreakoutRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      breakoutId: isSet(object.breakoutId) ? globalThis.String(object.breakoutId) : "",
      breakout: isSet(object.breakout) ? PortBreakout.fromJSON(object.breakout) : undefined,
    };
  },

  toJSON(message: UpdateNodePortBreakoutRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.breakoutId !== "") {
      obj.breakoutId = message.breakoutId;
    }
    if (message.breakout !== undefined) {
      obj.breakout = PortBreakout.toJSON(message.breakout);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateNodePortBreakoutRequest>, I>>(base?: I): UpdateNodePortBreakoutRequest {
    return UpdateNodePortBreakoutRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateNodePortBreakoutRequest>, I>>(
    object: I,
  ): UpdateNodePortBreakoutRequest {
    const message = createBaseUpdateNodePortBreakoutRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.breakoutId = object.breakoutId ?? "";
    message.breakout = (object.breakout !== undefined && object.breakout !== null)
      ? PortBreakout.fromPartial(object.breakout)
      : undefined;
    return message;
  },
};

function createBaseDeleteNodePortBreakoutRequest(): DeleteNodePortBreakoutRequest {
  return { fabricId: "", nodeId: "", breakoutId: "" };
}

export const DeleteNodePortBreakoutRequest = {
  encode(message: DeleteNodePortBreakoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.breakoutId !== "") {
      writer.uint32(26).string(message.breakoutId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteNodePortBreakoutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNodePortBreakoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.breakoutId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteNodePortBreakoutRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      breakoutId: isSet(object.breakoutId) ? globalThis.String(object.breakoutId) : "",
    };
  },

  toJSON(message: DeleteNodePortBreakoutRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.breakoutId !== "") {
      obj.breakoutId = message.breakoutId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteNodePortBreakoutRequest>, I>>(base?: I): DeleteNodePortBreakoutRequest {
    return DeleteNodePortBreakoutRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteNodePortBreakoutRequest>, I>>(
    object: I,
  ): DeleteNodePortBreakoutRequest {
    const message = createBaseDeleteNodePortBreakoutRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.breakoutId = object.breakoutId ?? "";
    return message;
  },
};

function createBaseNodePortBreakoutsResponse(): NodePortBreakoutsResponse {
  return { breakouts: [] };
}

export const NodePortBreakoutsResponse = {
  encode(message: NodePortBreakoutsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.breakouts) {
      PortBreakout.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NodePortBreakoutsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodePortBreakoutsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.breakouts.push(PortBreakout.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodePortBreakoutsResponse {
    return {
      breakouts: globalThis.Array.isArray(object?.breakouts)
        ? object.breakouts.map((e: any) => PortBreakout.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NodePortBreakoutsResponse): unknown {
    const obj: any = {};
    if (message.breakouts?.length) {
      obj.breakouts = message.breakouts.map((e) => PortBreakout.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodePortBreakoutsResponse>, I>>(base?: I): NodePortBreakoutsResponse {
    return NodePortBreakoutsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodePortBreakoutsResponse>, I>>(object: I): NodePortBreakoutsResponse {
    const message = createBaseNodePortBreakoutsResponse();
    message.breakouts = object.breakouts?.map((e) => PortBreakout.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
