import { Drawer } from "@magnetic/drawer";
import React from "react";
import { withShowToggle } from "components/hoc/withShowToggle";
import { FabricCandidate } from "src/gen/schema/configd/api";
import "./previewChanges.scss";
import { useClickOutside } from "@magnetic/hooks";
import { ApiError } from "src/core/apiError";
import { ErrorNotification } from "components/errorNotification";
import { PreviewChanges } from "./previewChanges";

interface ChangeDrawerProps {
  readonly title: string | React.ReactNode;
  readonly onClose: () => void;
  readonly isOpen: boolean;
  readonly candidate: FabricCandidate | undefined;
  readonly error?: ApiError;
  readonly children?: React.ReactNode | React.ReactNode[];
}

export const CandidateChangesDrawer = withShowToggle(
  ({
    title,
    candidate,
    onClose,
    error,
    children,
    isOpen = false
  }: ChangeDrawerProps) => {
    const node = useClickOutside<HTMLDivElement>((e) => {
      if (
        !(
          (e.target instanceof HTMLAnchorElement ||
            e.target instanceof HTMLButtonElement) &&
          !isOpen
        )
      ) {
        onClose();
      }
    });

    let content = <PreviewChanges candidate={candidate} />;
    if (error) {
      content = <ErrorNotification error={error} />;
    }

    return (
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        className="preview-changes-drawer"
      >
        <div ref={node} className="on-click-outside-container">
          <Drawer.Heading>{title}</Drawer.Heading>
          <Drawer.Content>{content}</Drawer.Content>
          <Drawer.Footer>{children}</Drawer.Footer>
        </div>
      </Drawer>
    );
  }
);
