import React, { useCallback, useState } from "react";
import { PreviewChangesDrawer } from "pages/candidates/previewChangesDrawer";

interface PreviewChangesDrawerSchema {
  previewChangesDrawer: React.ReactElement | null;
  openPreviewChangesDrawer: () => void;
}
export const usePreviewChangesDrawer = (): PreviewChangesDrawerSchema => {
  const [previewChangesDrawer, setPreviewChangesDrawer] =
    useState<React.ReactElement | null>(null);

  const openPreviewChangesDrawer = useCallback(() => {
    const onClose = () => {
      setPreviewChangesDrawer(null);
    };
    setPreviewChangesDrawer(<PreviewChangesDrawer onClose={onClose} />);
  }, []);

  return { previewChangesDrawer, openPreviewChangesDrawer };
};
