import {
  BaseColumnConfig,
  Config
} from "components/tables/columnConfigs/baseColumnConfig";
import {
  StpVlanPortState,
  StpVlanPortStateStatus
} from "src/gen/schema/schema/schema";
import { Constants } from "src/utils/constants";
import HFlex from "components/hFlex";
import {
  ArrowRight,
  Disabled,
  InProgress,
  Negative,
  Record
} from "@magnetic/icons";
import React from "react";
import { STPLegend } from "pages/switches/details/subsections/subcomponents/stpLegend";

export type STPState = {
  order: number;
  blocking: boolean;
  Icon: React.ReactElement;
  txt: string;
};

let i = 0;
export const STPStates: Record<StpVlanPortStateStatus, STPState> = {
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_STATUS_UNSPECIFIED]: {
    order: i++,
    blocking: false,
    Icon: <span />,
    txt: Constants.EMPTY_VALUE
  },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_DISABLED]: {
    order: i++,
    blocking: true,
    Icon: <Disabled />,
    txt: "Disabled"
  },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_LISTENING]: {
    order: i++,
    blocking: false,
    Icon: <InProgress />,
    txt: "Listening"
  },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_LEARNING]: {
    order: i++,
    blocking: false,
    Icon: <InProgress />,
    txt: "Learning"
  },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_FORWARDING]: {
    order: i++,
    blocking: false,
    Icon: <ArrowRight />,
    txt: "Forwarding"
  },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_UNKNOWN]: {
    order: i++,
    blocking: false,
    Icon: <span />,
    txt: Constants.EMPTY_VALUE
  },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_BLOCKING]: {
    order: i++,
    blocking: true,
    Icon: <Negative />,
    txt: "Blocking"
  },

  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_BROKEN]: {
    order: i++,
    blocking: true,
    Icon: <Negative />,
    txt: "Broken"
  },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_BPDU_DIS]: {
    order: i++,
    blocking: true,
    Icon: <Negative />,
    txt: "Blocking. BPDU guard active."
  },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_BLOCKING_ROOT_GUARD_INCONSISTENT]:
    {
      order: i++,
      blocking: true,
      Icon: <Negative />,
      txt: "Blocking root guard inconsistent"
    },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_LISTENING_ROOT_GUARD_INCONSISTENT]:
    {
      order: i++,
      blocking: true,
      Icon: <Negative />,
      txt: "Listening root guard inconsistent"
    },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_LEARNING_ROOT_GUARD_INCONSISTENT]:
    {
      order: i++,
      blocking: true,
      Icon: <Negative />,
      txt: "Learning root guard inconsistent"
    },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_BPDU_DIS_ROOT_GUARD_INCONSISTENT]:
    {
      order: i++,
      blocking: true,
      Icon: <Negative />,
      txt: "BPDU root guard inconsistent"
    },
  [StpVlanPortStateStatus.STP_VLAN_PORT_STATE_BLOCKING_ROOT_INCONSISTENT]: {
    order: i++,
    blocking: true,
    Icon: <Negative />,
    txt: "Blocking root inconsistent"
  },
  [StpVlanPortStateStatus.UNRECOGNIZED]: {
    order: i++,
    blocking: false,
    Icon: <span />,
    txt: Constants.EMPTY_VALUE
  }
};

interface SpanningTreePortStateConfig extends Config {
  blockingOnly?: boolean;
}
export class SpanningTreePortStateColumn extends BaseColumnConfig<StpVlanPortState> {
  constructor({ blockingOnly = false, ...cfg }: SpanningTreePortStateConfig) {
    super({
      label: (
        <HFlex>
          STP state&nbsp;
          <STPLegend blockingOnly={blockingOnly} />
        </HFlex>
      ),
      ...cfg
    });
  }
  compare(a: StpVlanPortState, b: StpVlanPortState) {
    return a.portState - b.portState;
  }
  render(data: StpVlanPortState) {
    if (data) {
      const state: STPState = STPStates[data.portState];
      if (state) {
        return (
          <HFlex gap={2} align="center">
            {state.Icon}
            {state.txt}
          </HFlex>
        );
      }
    }
    return <div>{Constants.EMPTY_VALUE}</div>;
  }
}
