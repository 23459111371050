// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/routing.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  IPAddrType,
  iPAddrTypeFromJSON,
  iPAddrTypeToJSON,
  MatchType,
  matchTypeFromJSON,
  matchTypeToJSON,
  RouteOriginType,
  routeOriginTypeFromJSON,
  routeOriginTypeToJSON,
} from "./fabric";
import { Annotation } from "./global";
import { Metadata, NetworkInterface, PortEndpoint } from "./types";

export const protobufPackage = "models";

/**
 * PerVlanStp encapsulates properties of a Per-Vlan spanning tree protocol config object.
 * There can be only one PerVlanStp config for a fabric.
 */
export interface PerVlanStp {
  /**
   * The identifier of Fabric to which this PerVlanStp belong. Fabric identifier is mandatory,
   * and immutable once set.
   */
  fabricId: string;
  /** Indicates if PerVlanStp is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the object. */
  metadata:
    | Metadata
    | undefined;
  /**
   * Bridge MAC address. User is expected to set the lowest MAC address
   * to make this Fabric as the root of spanning tree.
   */
  stpMac: string;
  /**
   * Switch waits for timeout duration before moving the port to forwarding state when
   * superior BPDUs (Bridge Protocol Data Unit) stop coming to the port. Default value
   * is 30s, and range is 5-600s.
   */
  rootGuardTimeout: number;
  /** Packet forward delay time in seconds. Default value is 15s, and range is 4-30s. */
  forwardDelay: number;
  /** Hello interval in seconds for transmission of BPDUs. Default values is 2s, and range is 1-10s. */
  helloInterval: number;
  /** Maximum time to listen for root bridge in seconds. Default value is 20s, and range is 6-40s. */
  maxAge: number;
  /** Bridge priority value as a multiple of 4096. Default is 32768, and range is 4096-61440. */
  priority: number;
}

/** IpPrefix encapsulates properties of an IP prefix. */
export interface IpPrefix {
  /** IPv4 or IPv6 prefix. Must be in CIDR format. */
  prefix: string;
  /** A short description for the prefix. */
  description: string;
  /** Match condition to be applied. */
  match: MatchType;
  /** Accept or reject (permit or deny). */
  accept: boolean;
}

/** PrefixList encapsulates a set of IPv4 or IPv6 prefixes. */
export interface PrefixList {
  /**
   * The unique identifier of PrefixList. Identifier is required to update an existing
   * PrefixList. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of PrefixList. PrefixList name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of PrefixList. Description can be up to 2000 characters. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if PrefixList is enabled or disabled. */
  enabled: boolean;
  /**
   * Readonly revision metadata of the object. Metadata is not required
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /** IP address type; IPv4 or IPv6. */
  type: IPAddrType;
  /** A set of prefixes. May provide up to 50 prefixes. */
  prefixes: IpPrefix[];
}

/** Community encapsulates properties of a BGP community. */
export interface Community {
  /** The BGP community tag. Maybe in wildcard format; e.g. *:100 or 1000:*. */
  community: string;
  /** A short description for the community. */
  description: string;
  /** Accept or reject (permit or deny). */
  accept: boolean;
}

/**
 * BgpSetGroup encapsulates "SET" part of a BgpRule.
 * E.g. set tag 5000
 */
export interface BgpSetGroup {
  /** Set route origin type (eBGP or iBGP). */
  origin: RouteOriginType;
  /**
   * Set community tag for import or export. For export, BGP stamps outbound routes
   * with this community tag. For route import, BGP sets this tag within the fabric.
   */
  community: string;
  /** Set next-hop IPv4 address for outbound routes (export). */
  ipv4NextHop: string;
  /** Set next-hop IPv6 address for outbound routes (export). */
  ipv6NextHop: string;
  /** Set tag of routes for outbound routes (export). */
  tag: number;
  /** Set as-path for for outbound routes (export). */
  asPath: number[];
}

/**
 * BgpMatchGroup encapsulates "MATCH" part of a BgpRule.
 * E.g. match community <COMMUNITY_LIST>.
 * Note that prefixes are mutually exclusive for matching. That means
 * caller must not provide both IPv4 and IPv6 prefixes.
 */
export interface BgpMatchGroup {
  /** Match on a specific tag. Tag value range is 1-4294967295. */
  tag: number;
  /** IPv4 prefix list identifier. May provide either IPv4 list identifier or IPv4 prefixes, but not both. */
  ipv4PrefixListId: string;
  /** A set of IPv4 prefixes to match on. May provide up to 10 prefixes. */
  ipv4Prefixes: IpPrefix[];
  /** IPv6 prefix list identifier. May provide either IPv6 list identifier or IPv6 prefixes, but not both. */
  ipv6PrefixListId: string;
  /** A set of IPv6 prefixes to match on. May provide up to 10 prefixes. */
  ipv6Prefixes: IpPrefix[];
  /** A set of communities to match on. May provide up to 10 communities. */
  communities: Community[];
}

/** BgpRule encapsulates properties of a BGP rule. */
export interface BgpRule {
  /**
   * The identifier of the rule. Id is also the sequence number of rule within
   * the rule list. User may set the identifier, Maximum value may not exceed 100.
   */
  id: number;
  /** The user-defined description of rule. */
  description: string;
  /** Accept or reject (permit or deny). */
  accept: boolean;
  /** Extended action (E.g. on-match next or on-match goto <ruleId> */
  action: string;
  /** Set of properties on routes. */
  setGroup:
    | BgpSetGroup
    | undefined;
  /** Set of properties to match on. */
  matchGroup: BgpMatchGroup | undefined;
}

/**
 * BgpPolicy encapsulates properties of a BGP peering policy. BgpPolicy
 * objects are attached to BgpPeer object.
 */
export interface BgpPolicy {
  /**
   * The unique identifier of BgpPolicy. Identifier is required to update an existing
   * BgpPolicy. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of BgpPolicy. BgpPolicy name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of BgpPolicy. Description can be up to 2000 characters. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if BgpPolicy is enabled or disabled. */
  enabled: boolean;
  /**
   * Readonly revision metadata of the object. Metadata is not required
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /** Indicates if the policy is a route export or import policy. */
  export: boolean;
  /**
   * The identifier of Fabric to which this BgpPolicy belongs. Fabric identifier
   * is mandatory, and immutable once set.
   */
  fabricId: string;
  /** Indicates that policy is a default BGP policy. */
  isDefault: boolean;
  /** A set of BGP rules. User may specify up to 10 rules. */
  rules: BgpRule[];
}

/**
 * BgpPeer encapsulates properties of BGP peer. Vrf on a border Node must have
 * a BGP or OSPF object to talk to external routers. BgpPeer provides the config
 * to VRF to peer with external router.
 */
export interface BgpPeer {
  /**
   * The unique identifier of BgpPeer. Identifier is required to update an existing
   * BgpPeer. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of BgpPeer. BgpPeer name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of BgpPeer. Description can be up to 2000 characters. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if BgpPeer is enabled or disabled. */
  enabled: boolean;
  /**
   * Readonly revision metadata of the object. Metadata is not required
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /**
   * The identifier of Fabric to which this BgpPeer belongs. Fabric identifier
   * is mandatory, and immutable once set.
   */
  fabricId: string;
  /** Local switch/fabric ASN. */
  localAsn: number;
  /** Asn of the external router. */
  remoteAsn: number;
  /**
   * IPv4 addresses of external router. Must not set IPv6 addresses when
   * IPv4 addresses are set. User may not provide more than one IPv4 address.
   */
  ipv4Addresses: string[];
  /**
   * IPv6 addresses of external router. Must not set IPv4 addresses when
   * IPv6 addresses are set. User may not provide more than one IPv6 address.
   */
  ipv6Addresses: string[];
  /**
   * VRF to which this BgpPeer is attached to. VrfId is mandatory.
   * External router's IP addresses must be reachable in the VRF.
   */
  vrfId: string;
  /** Expected number of hops to the external router. */
  ttl: number;
  /** MD5 password or TCP-AO keychain encryption password in clear text. */
  password: string;
  /** Indicates that authentication is TCP-AO based (not Supported for Beta). */
  tcpAo: boolean;
  /**
   * User selected source interface. Source interface maybe a loopback or a
   * routed port on a switch. BgpPeer applies source interface's IP address
   * to BGP policy (neighbor <IP> update-source <source interface IP>
   */
  sourceInf:
    | NetworkInterface
    | undefined;
  /** BGP export policy identifier. */
  exportPolicyId: string;
  /** BGP import policy identifier. */
  importPolicyId: string;
}

/**
 * Ospf encapsulates properties of OSPF routing object.
 *
 * NOTES:
 * 1) OSPF is meaningful only in a Vrf configured for Border leaf.
 * 2) All ports must be classified as BORDER port.
 */
export interface Ospf {
  /**
   * The unique identifier of Ospf. Identifier is required to update an existing
   * Ospf. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of Ospf. Ospf name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of Ospf. Description can be up to 2000 characters. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if Ospf is enabled or disabled. */
  enabled: boolean;
  /**
   * Readonly revision metadata of the object. Metadata is not required
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /** OSPF member ports or interfaces. */
  ports: PortEndpoint[];
}

export interface Isis {
  /**
   * The unique identifier of Isis. Identifier is required to update an existing
   * Isis. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of Isis. Isis name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of Isis. Description can be up to 2000 characters. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if Isis is enabled or disabled. */
  enabled: boolean;
  /**
   * Readonly revision metadata of the object. Metadata is not required
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /** IS-IS member ports or interfaces. */
  ports: PortEndpoint[];
}

function createBasePerVlanStp(): PerVlanStp {
  return {
    fabricId: "",
    enabled: false,
    metadata: undefined,
    stpMac: "",
    rootGuardTimeout: 0,
    forwardDelay: 0,
    helloInterval: 0,
    maxAge: 0,
    priority: 0,
  };
}

export const PerVlanStp = {
  encode(message: PerVlanStp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.stpMac !== "") {
      writer.uint32(82).string(message.stpMac);
    }
    if (message.rootGuardTimeout !== 0) {
      writer.uint32(88).uint32(message.rootGuardTimeout);
    }
    if (message.forwardDelay !== 0) {
      writer.uint32(96).uint32(message.forwardDelay);
    }
    if (message.helloInterval !== 0) {
      writer.uint32(104).uint32(message.helloInterval);
    }
    if (message.maxAge !== 0) {
      writer.uint32(112).uint32(message.maxAge);
    }
    if (message.priority !== 0) {
      writer.uint32(120).uint32(message.priority);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PerVlanStp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePerVlanStp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.stpMac = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.rootGuardTimeout = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.forwardDelay = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.helloInterval = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.maxAge = reader.uint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.priority = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PerVlanStp {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      stpMac: isSet(object.stpMac) ? globalThis.String(object.stpMac) : "",
      rootGuardTimeout: isSet(object.rootGuardTimeout) ? globalThis.Number(object.rootGuardTimeout) : 0,
      forwardDelay: isSet(object.forwardDelay) ? globalThis.Number(object.forwardDelay) : 0,
      helloInterval: isSet(object.helloInterval) ? globalThis.Number(object.helloInterval) : 0,
      maxAge: isSet(object.maxAge) ? globalThis.Number(object.maxAge) : 0,
      priority: isSet(object.priority) ? globalThis.Number(object.priority) : 0,
    };
  },

  toJSON(message: PerVlanStp): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.stpMac !== "") {
      obj.stpMac = message.stpMac;
    }
    if (message.rootGuardTimeout !== 0) {
      obj.rootGuardTimeout = Math.round(message.rootGuardTimeout);
    }
    if (message.forwardDelay !== 0) {
      obj.forwardDelay = Math.round(message.forwardDelay);
    }
    if (message.helloInterval !== 0) {
      obj.helloInterval = Math.round(message.helloInterval);
    }
    if (message.maxAge !== 0) {
      obj.maxAge = Math.round(message.maxAge);
    }
    if (message.priority !== 0) {
      obj.priority = Math.round(message.priority);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PerVlanStp>, I>>(base?: I): PerVlanStp {
    return PerVlanStp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PerVlanStp>, I>>(object: I): PerVlanStp {
    const message = createBasePerVlanStp();
    message.fabricId = object.fabricId ?? "";
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.stpMac = object.stpMac ?? "";
    message.rootGuardTimeout = object.rootGuardTimeout ?? 0;
    message.forwardDelay = object.forwardDelay ?? 0;
    message.helloInterval = object.helloInterval ?? 0;
    message.maxAge = object.maxAge ?? 0;
    message.priority = object.priority ?? 0;
    return message;
  },
};

function createBaseIpPrefix(): IpPrefix {
  return { prefix: "", description: "", match: 0, accept: false };
}

export const IpPrefix = {
  encode(message: IpPrefix, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.prefix !== "") {
      writer.uint32(10).string(message.prefix);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.match !== 0) {
      writer.uint32(24).int32(message.match);
    }
    if (message.accept !== false) {
      writer.uint32(32).bool(message.accept);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpPrefix {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpPrefix();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.prefix = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.match = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.accept = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpPrefix {
    return {
      prefix: isSet(object.prefix) ? globalThis.String(object.prefix) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      match: isSet(object.match) ? matchTypeFromJSON(object.match) : 0,
      accept: isSet(object.accept) ? globalThis.Boolean(object.accept) : false,
    };
  },

  toJSON(message: IpPrefix): unknown {
    const obj: any = {};
    if (message.prefix !== "") {
      obj.prefix = message.prefix;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.match !== 0) {
      obj.match = matchTypeToJSON(message.match);
    }
    if (message.accept !== false) {
      obj.accept = message.accept;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpPrefix>, I>>(base?: I): IpPrefix {
    return IpPrefix.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpPrefix>, I>>(object: I): IpPrefix {
    const message = createBaseIpPrefix();
    message.prefix = object.prefix ?? "";
    message.description = object.description ?? "";
    message.match = object.match ?? 0;
    message.accept = object.accept ?? false;
    return message;
  },
};

function createBasePrefixList(): PrefixList {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    type: 0,
    prefixes: [],
  };
}

export const PrefixList = {
  encode(message: PrefixList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(80).int32(message.type);
    }
    for (const v of message.prefixes) {
      IpPrefix.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrefixList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrefixList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.prefixes.push(IpPrefix.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrefixList {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      type: isSet(object.type) ? iPAddrTypeFromJSON(object.type) : 0,
      prefixes: globalThis.Array.isArray(object?.prefixes) ? object.prefixes.map((e: any) => IpPrefix.fromJSON(e)) : [],
    };
  },

  toJSON(message: PrefixList): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.type !== 0) {
      obj.type = iPAddrTypeToJSON(message.type);
    }
    if (message.prefixes?.length) {
      obj.prefixes = message.prefixes.map((e) => IpPrefix.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrefixList>, I>>(base?: I): PrefixList {
    return PrefixList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrefixList>, I>>(object: I): PrefixList {
    const message = createBasePrefixList();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.type = object.type ?? 0;
    message.prefixes = object.prefixes?.map((e) => IpPrefix.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCommunity(): Community {
  return { community: "", description: "", accept: false };
}

export const Community = {
  encode(message: Community, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.community !== "") {
      writer.uint32(10).string(message.community);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.accept !== false) {
      writer.uint32(24).bool(message.accept);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Community {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommunity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.community = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.accept = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Community {
    return {
      community: isSet(object.community) ? globalThis.String(object.community) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      accept: isSet(object.accept) ? globalThis.Boolean(object.accept) : false,
    };
  },

  toJSON(message: Community): unknown {
    const obj: any = {};
    if (message.community !== "") {
      obj.community = message.community;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.accept !== false) {
      obj.accept = message.accept;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Community>, I>>(base?: I): Community {
    return Community.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Community>, I>>(object: I): Community {
    const message = createBaseCommunity();
    message.community = object.community ?? "";
    message.description = object.description ?? "";
    message.accept = object.accept ?? false;
    return message;
  },
};

function createBaseBgpSetGroup(): BgpSetGroup {
  return { origin: 0, community: "", ipv4NextHop: "", ipv6NextHop: "", tag: 0, asPath: [] };
}

export const BgpSetGroup = {
  encode(message: BgpSetGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.origin !== 0) {
      writer.uint32(8).int32(message.origin);
    }
    if (message.community !== "") {
      writer.uint32(18).string(message.community);
    }
    if (message.ipv4NextHop !== "") {
      writer.uint32(26).string(message.ipv4NextHop);
    }
    if (message.ipv6NextHop !== "") {
      writer.uint32(34).string(message.ipv6NextHop);
    }
    if (message.tag !== 0) {
      writer.uint32(40).uint32(message.tag);
    }
    writer.uint32(50).fork();
    for (const v of message.asPath) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BgpSetGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBgpSetGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.origin = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.community = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ipv4NextHop = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ipv6NextHop = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.tag = reader.uint32();
          continue;
        case 6:
          if (tag === 48) {
            message.asPath.push(reader.uint32());

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.asPath.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BgpSetGroup {
    return {
      origin: isSet(object.origin) ? routeOriginTypeFromJSON(object.origin) : 0,
      community: isSet(object.community) ? globalThis.String(object.community) : "",
      ipv4NextHop: isSet(object.ipv4NextHop) ? globalThis.String(object.ipv4NextHop) : "",
      ipv6NextHop: isSet(object.ipv6NextHop) ? globalThis.String(object.ipv6NextHop) : "",
      tag: isSet(object.tag) ? globalThis.Number(object.tag) : 0,
      asPath: globalThis.Array.isArray(object?.asPath) ? object.asPath.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: BgpSetGroup): unknown {
    const obj: any = {};
    if (message.origin !== 0) {
      obj.origin = routeOriginTypeToJSON(message.origin);
    }
    if (message.community !== "") {
      obj.community = message.community;
    }
    if (message.ipv4NextHop !== "") {
      obj.ipv4NextHop = message.ipv4NextHop;
    }
    if (message.ipv6NextHop !== "") {
      obj.ipv6NextHop = message.ipv6NextHop;
    }
    if (message.tag !== 0) {
      obj.tag = Math.round(message.tag);
    }
    if (message.asPath?.length) {
      obj.asPath = message.asPath.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BgpSetGroup>, I>>(base?: I): BgpSetGroup {
    return BgpSetGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BgpSetGroup>, I>>(object: I): BgpSetGroup {
    const message = createBaseBgpSetGroup();
    message.origin = object.origin ?? 0;
    message.community = object.community ?? "";
    message.ipv4NextHop = object.ipv4NextHop ?? "";
    message.ipv6NextHop = object.ipv6NextHop ?? "";
    message.tag = object.tag ?? 0;
    message.asPath = object.asPath?.map((e) => e) || [];
    return message;
  },
};

function createBaseBgpMatchGroup(): BgpMatchGroup {
  return { tag: 0, ipv4PrefixListId: "", ipv4Prefixes: [], ipv6PrefixListId: "", ipv6Prefixes: [], communities: [] };
}

export const BgpMatchGroup = {
  encode(message: BgpMatchGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== 0) {
      writer.uint32(8).uint32(message.tag);
    }
    if (message.ipv4PrefixListId !== "") {
      writer.uint32(18).string(message.ipv4PrefixListId);
    }
    for (const v of message.ipv4Prefixes) {
      IpPrefix.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.ipv6PrefixListId !== "") {
      writer.uint32(34).string(message.ipv6PrefixListId);
    }
    for (const v of message.ipv6Prefixes) {
      IpPrefix.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.communities) {
      Community.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BgpMatchGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBgpMatchGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.tag = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ipv4PrefixListId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ipv4Prefixes.push(IpPrefix.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ipv6PrefixListId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ipv6Prefixes.push(IpPrefix.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.communities.push(Community.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BgpMatchGroup {
    return {
      tag: isSet(object.tag) ? globalThis.Number(object.tag) : 0,
      ipv4PrefixListId: isSet(object.ipv4PrefixListId) ? globalThis.String(object.ipv4PrefixListId) : "",
      ipv4Prefixes: globalThis.Array.isArray(object?.ipv4Prefixes)
        ? object.ipv4Prefixes.map((e: any) => IpPrefix.fromJSON(e))
        : [],
      ipv6PrefixListId: isSet(object.ipv6PrefixListId) ? globalThis.String(object.ipv6PrefixListId) : "",
      ipv6Prefixes: globalThis.Array.isArray(object?.ipv6Prefixes)
        ? object.ipv6Prefixes.map((e: any) => IpPrefix.fromJSON(e))
        : [],
      communities: globalThis.Array.isArray(object?.communities)
        ? object.communities.map((e: any) => Community.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BgpMatchGroup): unknown {
    const obj: any = {};
    if (message.tag !== 0) {
      obj.tag = Math.round(message.tag);
    }
    if (message.ipv4PrefixListId !== "") {
      obj.ipv4PrefixListId = message.ipv4PrefixListId;
    }
    if (message.ipv4Prefixes?.length) {
      obj.ipv4Prefixes = message.ipv4Prefixes.map((e) => IpPrefix.toJSON(e));
    }
    if (message.ipv6PrefixListId !== "") {
      obj.ipv6PrefixListId = message.ipv6PrefixListId;
    }
    if (message.ipv6Prefixes?.length) {
      obj.ipv6Prefixes = message.ipv6Prefixes.map((e) => IpPrefix.toJSON(e));
    }
    if (message.communities?.length) {
      obj.communities = message.communities.map((e) => Community.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BgpMatchGroup>, I>>(base?: I): BgpMatchGroup {
    return BgpMatchGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BgpMatchGroup>, I>>(object: I): BgpMatchGroup {
    const message = createBaseBgpMatchGroup();
    message.tag = object.tag ?? 0;
    message.ipv4PrefixListId = object.ipv4PrefixListId ?? "";
    message.ipv4Prefixes = object.ipv4Prefixes?.map((e) => IpPrefix.fromPartial(e)) || [];
    message.ipv6PrefixListId = object.ipv6PrefixListId ?? "";
    message.ipv6Prefixes = object.ipv6Prefixes?.map((e) => IpPrefix.fromPartial(e)) || [];
    message.communities = object.communities?.map((e) => Community.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBgpRule(): BgpRule {
  return { id: 0, description: "", accept: false, action: "", setGroup: undefined, matchGroup: undefined };
}

export const BgpRule = {
  encode(message: BgpRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.accept !== false) {
      writer.uint32(32).bool(message.accept);
    }
    if (message.action !== "") {
      writer.uint32(42).string(message.action);
    }
    if (message.setGroup !== undefined) {
      BgpSetGroup.encode(message.setGroup, writer.uint32(82).fork()).ldelim();
    }
    if (message.matchGroup !== undefined) {
      BgpMatchGroup.encode(message.matchGroup, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BgpRule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBgpRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.accept = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.action = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.setGroup = BgpSetGroup.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.matchGroup = BgpMatchGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BgpRule {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      accept: isSet(object.accept) ? globalThis.Boolean(object.accept) : false,
      action: isSet(object.action) ? globalThis.String(object.action) : "",
      setGroup: isSet(object.setGroup) ? BgpSetGroup.fromJSON(object.setGroup) : undefined,
      matchGroup: isSet(object.matchGroup) ? BgpMatchGroup.fromJSON(object.matchGroup) : undefined,
    };
  },

  toJSON(message: BgpRule): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.accept !== false) {
      obj.accept = message.accept;
    }
    if (message.action !== "") {
      obj.action = message.action;
    }
    if (message.setGroup !== undefined) {
      obj.setGroup = BgpSetGroup.toJSON(message.setGroup);
    }
    if (message.matchGroup !== undefined) {
      obj.matchGroup = BgpMatchGroup.toJSON(message.matchGroup);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BgpRule>, I>>(base?: I): BgpRule {
    return BgpRule.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BgpRule>, I>>(object: I): BgpRule {
    const message = createBaseBgpRule();
    message.id = object.id ?? 0;
    message.description = object.description ?? "";
    message.accept = object.accept ?? false;
    message.action = object.action ?? "";
    message.setGroup = (object.setGroup !== undefined && object.setGroup !== null)
      ? BgpSetGroup.fromPartial(object.setGroup)
      : undefined;
    message.matchGroup = (object.matchGroup !== undefined && object.matchGroup !== null)
      ? BgpMatchGroup.fromPartial(object.matchGroup)
      : undefined;
    return message;
  },
};

function createBaseBgpPolicy(): BgpPolicy {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    export: false,
    fabricId: "",
    isDefault: false,
    rules: [],
  };
}

export const BgpPolicy = {
  encode(message: BgpPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.export !== false) {
      writer.uint32(64).bool(message.export);
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    if (message.isDefault !== false) {
      writer.uint32(80).bool(message.isDefault);
    }
    for (const v of message.rules) {
      BgpRule.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BgpPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBgpPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.export = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.rules.push(BgpRule.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BgpPolicy {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      export: isSet(object.export) ? globalThis.Boolean(object.export) : false,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      isDefault: isSet(object.isDefault) ? globalThis.Boolean(object.isDefault) : false,
      rules: globalThis.Array.isArray(object?.rules) ? object.rules.map((e: any) => BgpRule.fromJSON(e)) : [],
    };
  },

  toJSON(message: BgpPolicy): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.export !== false) {
      obj.export = message.export;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.isDefault !== false) {
      obj.isDefault = message.isDefault;
    }
    if (message.rules?.length) {
      obj.rules = message.rules.map((e) => BgpRule.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BgpPolicy>, I>>(base?: I): BgpPolicy {
    return BgpPolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BgpPolicy>, I>>(object: I): BgpPolicy {
    const message = createBaseBgpPolicy();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.export = object.export ?? false;
    message.fabricId = object.fabricId ?? "";
    message.isDefault = object.isDefault ?? false;
    message.rules = object.rules?.map((e) => BgpRule.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBgpPeer(): BgpPeer {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    localAsn: 0,
    remoteAsn: 0,
    ipv4Addresses: [],
    ipv6Addresses: [],
    vrfId: "",
    ttl: 0,
    password: "",
    tcpAo: false,
    sourceInf: undefined,
    exportPolicyId: "",
    importPolicyId: "",
  };
}

export const BgpPeer = {
  encode(message: BgpPeer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    if (message.localAsn !== 0) {
      writer.uint32(80).uint32(message.localAsn);
    }
    if (message.remoteAsn !== 0) {
      writer.uint32(88).uint32(message.remoteAsn);
    }
    for (const v of message.ipv4Addresses) {
      writer.uint32(98).string(v!);
    }
    for (const v of message.ipv6Addresses) {
      writer.uint32(106).string(v!);
    }
    if (message.vrfId !== "") {
      writer.uint32(114).string(message.vrfId);
    }
    if (message.ttl !== 0) {
      writer.uint32(120).uint32(message.ttl);
    }
    if (message.password !== "") {
      writer.uint32(130).string(message.password);
    }
    if (message.tcpAo !== false) {
      writer.uint32(136).bool(message.tcpAo);
    }
    if (message.sourceInf !== undefined) {
      NetworkInterface.encode(message.sourceInf, writer.uint32(146).fork()).ldelim();
    }
    if (message.exportPolicyId !== "") {
      writer.uint32(162).string(message.exportPolicyId);
    }
    if (message.importPolicyId !== "") {
      writer.uint32(170).string(message.importPolicyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BgpPeer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBgpPeer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.localAsn = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.remoteAsn = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.ipv4Addresses.push(reader.string());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.ipv6Addresses.push(reader.string());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.ttl = reader.uint32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.password = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.tcpAo = reader.bool();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.sourceInf = NetworkInterface.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.exportPolicyId = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.importPolicyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BgpPeer {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      localAsn: isSet(object.localAsn) ? globalThis.Number(object.localAsn) : 0,
      remoteAsn: isSet(object.remoteAsn) ? globalThis.Number(object.remoteAsn) : 0,
      ipv4Addresses: globalThis.Array.isArray(object?.ipv4Addresses)
        ? object.ipv4Addresses.map((e: any) => globalThis.String(e))
        : [],
      ipv6Addresses: globalThis.Array.isArray(object?.ipv6Addresses)
        ? object.ipv6Addresses.map((e: any) => globalThis.String(e))
        : [],
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      ttl: isSet(object.ttl) ? globalThis.Number(object.ttl) : 0,
      password: isSet(object.password) ? globalThis.String(object.password) : "",
      tcpAo: isSet(object.tcpAo) ? globalThis.Boolean(object.tcpAo) : false,
      sourceInf: isSet(object.sourceInf) ? NetworkInterface.fromJSON(object.sourceInf) : undefined,
      exportPolicyId: isSet(object.exportPolicyId) ? globalThis.String(object.exportPolicyId) : "",
      importPolicyId: isSet(object.importPolicyId) ? globalThis.String(object.importPolicyId) : "",
    };
  },

  toJSON(message: BgpPeer): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.localAsn !== 0) {
      obj.localAsn = Math.round(message.localAsn);
    }
    if (message.remoteAsn !== 0) {
      obj.remoteAsn = Math.round(message.remoteAsn);
    }
    if (message.ipv4Addresses?.length) {
      obj.ipv4Addresses = message.ipv4Addresses;
    }
    if (message.ipv6Addresses?.length) {
      obj.ipv6Addresses = message.ipv6Addresses;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.ttl !== 0) {
      obj.ttl = Math.round(message.ttl);
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    if (message.tcpAo !== false) {
      obj.tcpAo = message.tcpAo;
    }
    if (message.sourceInf !== undefined) {
      obj.sourceInf = NetworkInterface.toJSON(message.sourceInf);
    }
    if (message.exportPolicyId !== "") {
      obj.exportPolicyId = message.exportPolicyId;
    }
    if (message.importPolicyId !== "") {
      obj.importPolicyId = message.importPolicyId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BgpPeer>, I>>(base?: I): BgpPeer {
    return BgpPeer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BgpPeer>, I>>(object: I): BgpPeer {
    const message = createBaseBgpPeer();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.localAsn = object.localAsn ?? 0;
    message.remoteAsn = object.remoteAsn ?? 0;
    message.ipv4Addresses = object.ipv4Addresses?.map((e) => e) || [];
    message.ipv6Addresses = object.ipv6Addresses?.map((e) => e) || [];
    message.vrfId = object.vrfId ?? "";
    message.ttl = object.ttl ?? 0;
    message.password = object.password ?? "";
    message.tcpAo = object.tcpAo ?? false;
    message.sourceInf = (object.sourceInf !== undefined && object.sourceInf !== null)
      ? NetworkInterface.fromPartial(object.sourceInf)
      : undefined;
    message.exportPolicyId = object.exportPolicyId ?? "";
    message.importPolicyId = object.importPolicyId ?? "";
    return message;
  },
};

function createBaseOspf(): Ospf {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    ports: [],
  };
}

export const Ospf = {
  encode(message: Ospf, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.ports) {
      PortEndpoint.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ospf {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOspf();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ports.push(PortEndpoint.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ospf {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => PortEndpoint.fromJSON(e)) : [],
    };
  },

  toJSON(message: Ospf): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => PortEndpoint.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ospf>, I>>(base?: I): Ospf {
    return Ospf.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ospf>, I>>(object: I): Ospf {
    const message = createBaseOspf();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.ports = object.ports?.map((e) => PortEndpoint.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIsis(): Isis {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    ports: [],
  };
}

export const Isis = {
  encode(message: Isis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.ports) {
      PortEndpoint.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Isis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIsis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ports.push(PortEndpoint.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Isis {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => PortEndpoint.fromJSON(e)) : [],
    };
  },

  toJSON(message: Isis): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => PortEndpoint.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Isis>, I>>(base?: I): Isis {
    return Isis.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Isis>, I>>(object: I): Isis {
    const message = createBaseIsis();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.ports = object.ports?.map((e) => PortEndpoint.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
